// Customizable Area Start
const primaryColor = "#004d64";

export const EmailAccountLoginStyle: any = {
  Login: {
    maxWidth: "inherit !important",
    padding: "0 !important",
    "& .background-image-block ": {
      position: "relative",
    },
    "& .full-width-container": {
      height: "100%",
      maxHeight: "100vh",
    },
    "& .background-image-block img": {
      height: "100%",
      width: "100%",
      backgroundPosition: "center",
      backgroundSize: "contain",
    },
    "& .image-text-container": {
      position: "absolute",
      bottom: "15%",
      padding: "30px 40px",
      background: primaryColor,
      backgroundBlendMode: "normal",
      width: "100%",
      boxSizing: "border-box",
    },
    "& .image-text-container h1": {
      color: "#ffd700",
      fontSize: 48,
      fontWeight: 700,
      fontFamily: "NotoSans-Regular",
      lineHeight: "65px",
    },
    "& .image-text-container p": {
      color: "#ffd700",
      background: primaryColor,
      backgroundBlendMode: "normal",
      fontSize: 16,
      fontFamily: "NotoSans-Regular",
      lineHeight: "22px",
    },
    "& .form-container": {
      padding: "40px 60px 10px",
      "& .brand-image-container": {
        height: 71.71,
        width: 173,
        "& img": {
          height: "100%",
          width: "100%",
        },
      },
      "& .login-form": {
        "& h2": {
          fontFamily: "NotoSans-Regular",
          fontSize: 40,
          fontWeight: 700,
          color: " #004d64",
          lineHeight: "54px",
        },
        "& .MuiInput-root": {
          height: 56,
          border: "1px dashed #9e9e9e",
          borderRadius: 10,
        },
      },
      "& .forgot-or-remember": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .MuiIconButton-label img": {
          height: 24,
          width: 24,
        },
        "& .MuiFormControlLabel-label": {
          color: primaryColor,
          fontFamily: "NotoSans-Regular",
          fontSize: 16,
          fontWeight: "400",
          lineHeight: "22px",
        },
        "& .MuiCheckbox-colorSecondary.Mui-checked": {
          color: "#004D64",
        },
        "& .remember-me-checkbox-label": {
          display: "flex",
          alignItems: "center",
          gap: "5px",
          "& img": {
            height: 24,
            width: 24,
            cursor: "pointer",
          },
          "& label": {
            color: primaryColor,
            fontFamily: "NotoSans-Regular",
            fontSize: 16,
            fontWeight: "400",
            lineHeight: "22px",
            cursor: "pointer",
          },
        },
        "& .forgot-text": {
          color: " #004d64",
          fontFamily: "NotoSans-Regular",
          fontSize: 18,
          fontWeight: "400",
          cursor: "pointer",
          textDecoration: "underline",
          lineHeight: "25px",
        },
      },
      "& .sign-in-button": {
        marginTop: "15px",
        height: 64,
        borderRadius: 10,
        background: primaryColor,
        backgroundBlendMode: "normal",
        "& .MuiButton-label": {
          color: "#FFD700",
          fontFamily: "NotoSans-Regular",
          fontSize: 24,
          fontWeight: "700",
          lineHeight: "16px",
          textAlign: "center",
          textTransform: "capitalize",
        },
      },
      "& .text-center": {
        textAlign: "center",
        fontFamily: "NotoSans-Regular",
        fontSize: 16,
        lineHeight: "19px",
        color: primaryColor,
        fontWeight: "300",
        marginTop: "10px",
      },
      "& .link-text": {
        color: primaryColor,
        fontWeight: "400",
        fontSize: "18px",
        textDecoration: "underline",
        fontFamily: "NotoSans-SemiBold",
        lineHeight: "22px",
        cursor: "pointer",
      },
      "& .recover-password-text": {
        color: primaryColor,
        fontFamily: "Noto Sans",
        fontSize: 16,
        fontWeight: "400",
      },
      "& .label-error": {
        color: "#d93025",
        fontFamily: "NotoSans-Regular",
        fontSize: 14,
        fontWeight: "300",
        lineHeight: "19px",
      },
      "& .label-error > div": {
        display: "flex",
        gap: "10px",
      },
      "& .label-error .info-error-image": {
        height: 24,
        width: 24,
      },
      "& .label-error .error-text": {
        color: "#d93025",
        fontFamily: "NotoSans-Regular",
        fontSize: 14,
        fontWeight: "300",
      },
    },
  },
};

// Customizable Area End
