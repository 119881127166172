// Customizable Area Start
import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  withStyles,
} from "@material-ui/core";

import SuperTeamController, { Props } from "./SuperTeamController.web";
import { DashboardWebStyles } from "./SuperTeamLeaderDashboardStyle.web";
import {
  closeUpScientistLaboratory,
  threeSimilarcardboardBoxesWithCovers,
  closeUpHandHoldingScanner,
  downArrow,
} from "../../assets";

import DateRangePickerComponent from "../../../../../components/src/DateRangePicker.web";
import SuperTeamDeliveredItem from "./SuperTeamDeliveredItem.web";
import SuperTeamsPositiveSamples from "./SuperTeamsPositiveSamples.web";
import SuperTeamsNegativeSamples from "./SuperTeamsNegativeSamples.web";
import Loader from "../../../../../components/src/Loader.web";

class SuperTeamLeaderDashboard extends SuperTeamController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <Loader loading={this.state.isLoading} />
        {!this.state.showDeliverItems &&
          !this.state.showNegativeSample &&
          !this.state.showPositiveSample && (
            <Container
              maxWidth="lg"
              className={classes.SuperTeamLeader}
              style={{ margin: "20px auto" }}
            >
              <Box className="main-heading">
                <Typography variant="h2">
                  Hello, {this.state.userName || ""}
                </Typography>
                <Box className="right-side">
                  <Button
                    className="calenderButton"
                    variant="contained"
                    onClick={() => this.openOrCloseDateRangePicker(false)}
                  >
                    <span
                      className="calendar-text"
                      title={this.state.activeCalenderValue}
                    >
                      {this.state.activeCalenderValue}
                    </span>
                    <img src={downArrow} alt="arrow" />
                  </Button>
                  {this.state.openDateRange && (
                    <DateRangePickerComponent
                      activeCalenderValue={this.state.activeCalenderValue}
                      range={this.state.range}
                      closeDatePicker={(range: any, name: string) => {
                        this.setState(
                          { range, activeCalenderValue: name },
                          () => {
                            this.openOrCloseDateRangePicker(true);
                          }
                        );
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box margin="20px 0">
                <Grid container spacing={3}>
                  <Grid item md={4} sm={6} xs={12}>
                    <Box className="items-container">
                      <Box className="outer-item-container">
                        <Box className="item-container">
                          <img
                            src={threeSimilarcardboardBoxesWithCovers}
                            alt=""
                          />
                          <Box className="text-container">
                            <Typography className="digit-heading">
                              {this.state.dashboardData.total_sample_collected}
                            </Typography>
                            <Typography className="item-name">
                              TOTAL COLLECTED ITEMS
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="item-container inner-mrg-container">
                          <img
                            src={threeSimilarcardboardBoxesWithCovers}
                            alt=""
                          />
                          <Box className="text-container">
                            <Typography className="digit-heading">
                              {this.state.dashboardData.total_sample_received}
                            </Typography>
                            <Typography className="item-name">
                              TOTAL RECEIVED ITEMS
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="item-container inner-scanner-container">
                          <img src={closeUpHandHoldingScanner} alt="" />
                          <Box className="text-container-left">
                            <Typography className="item-name-product">
                              Please Scan Received Items
                            </Typography>
                            <Typography className="item-click-text">
                              Click Here To Scan
                            </Typography>
                            <Button
                              className="scanningButton"
                              variant="contained"
                              fullWidth
                              onClick={() =>
                                this.setState({
                                  showDeliverItems: true,
                                  sampleValue: "Items",
                                })
                              }
                            >
                              Start Scanning
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Box className="items-container">
                      <Box className="outer-item-container">
                        <Box className="item-container inner-item-container">
                          <img src={closeUpScientistLaboratory} alt="" />
                          <Box className="text-container-left">
                            <Typography className="item-name-product item-name-product-m-12">
                              Total Positive Items Collected
                            </Typography>
                            <Typography className="digit-heading-scanned">
                              {this.state.dashboardData.total_positive_sample}/
                              {this.state.dashboardData.total_sample_received}
                            </Typography>
                            <Typography className="item-percentage">
                              {`${this.state.dashboardData.positivity.toFixed(
                                2
                              )}%`}
                            </Typography>
                            <hr />
                            <Typography className="item-name-product item-name-product-m-40">
                              Please Scan items
                            </Typography>
                            <Typography className="item-click-text">
                              Click Here To Scan
                            </Typography>
                            <Button
                              className="positiveButton"
                              variant="contained"
                              fullWidth
                              onClick={() => this.showPositiveSample()}
                            >
                              Add Positive
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <Box className="items-container">
                      <Box className="outer-item-container">
                        <Box className="item-container inner-item-container">
                          <img src={closeUpScientistLaboratory} alt="" />
                          <Box className="text-container-left">
                            <Typography className="item-name-product item-name-product-m-12">
                              Total Negative Items Collected
                            </Typography>
                            <Typography className="digit-heading-scanned">
                              {this.state.dashboardData.total_negative_sample}/
                              {this.state.dashboardData.total_sample_received}
                            </Typography>
                            <Typography className="item-percentage">
                              {` ${this.state.dashboardData.negativity.toFixed(
                                2
                              )}%`}
                            </Typography>
                            <hr />
                            <Typography className="item-name-product item-name-product-m-40">
                              Please Scan Items
                            </Typography>
                            <Typography className="item-click-text">
                              Click Here To Scan
                            </Typography>
                            <Button
                              className="negativeButton"
                              variant="contained"
                              fullWidth
                              onClick={() => this.showNegativeSample()}
                            >
                              Add Negative
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          )}
        {this.state.showDeliverItems && (
          <SuperTeamDeliveredItem
            onBack={this.hideDeliverItems}
            navigation={this.props.navigation}
          />
        )}
        {this.state.showPositiveSample && (
          <SuperTeamsPositiveSamples
            onBack={this.hidePositiveSample}
            navigation={this.props.navigation}
          />
        )}
        {this.state.showNegativeSample && (
          <SuperTeamsNegativeSamples
            onBack={this.hideNegativeSample}
            navigation={this.props.navigation}
          />
        )}
      </>

      //Merge Engsine End DefaultContainer
    );
  }
}

export default withStyles(DashboardWebStyles)(SuperTeamLeaderDashboard);
// Customizable Area End
