// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import {
  getFormatDate,
  userDetailsFromStorage,
  userRedirectToLoginPage,
} from "../../../../../components/src/utils";
import { toast } from "react-toastify";
import moment from "moment";

export const configJSON = require("../../config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface ShipItem {
  barcode_number: string;
  product_name: string;
  date_collected: string;
  hospital_id: number;
  product_type_id: number;
  total_unit: string;
  hospital_name: string;
  status: string;
  barcode: string[];
  service_provider_name: string;
  docket_number: string[];
  isEditable: boolean;
  attributes: any;
  id: string;
  type: string;
}

interface S {
  isLoading: boolean;
  sampleId: string;
  range: any;
  shipItemData: ShipItem[];
  accessToken: string;
  userId: string;
  filterHospital: string;
  filterProduct: string;
  filterState: string;
  filterCity: string;
  [key: string]: string | boolean | Set<number> | string[] | any['arrayValue'];
  activeCalenderValue: string;
  providerName: string;
  docketNumbers: string[];
  isModalOpen: boolean;
  hospitalList: string[] | any[];
  sampleList: string[] | any[];
  stateList: string[] | any[];
  citiesList: string[] | any[];
  selectedBarcodes: string[];
  page: number;
  rowsPerPage: number;
  selectedHospitalId: number | string;
  selectedSampleId: number | string;
  openReadyToShip: boolean;
  openSuccessModal: boolean;
  openDateRange: boolean;
  selectAllChecked: boolean;
  selectedStartDate: string;
  selectedEndDate: string;
}

interface SS {
  id: any;
}

export default class SamplesToBeShippedController extends BlockComponent<
  Props,
  S,
  SS
> {
  sampleListApiCallId: string | undefined;
  filteredListAPICallId: string | undefined;
  serviceProviderDetailApiCallId: string | undefined;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isLoading: false,
      sampleId: "",
      range: [
        {
          startDate: moment()
            .clone()
            .startOf("month")
            .toDate(),
          endDate: new Date(),
          key: "rollup",
        },
      ],

      shipItemData: [],
      accessToken: "",
      userId: "",
      filterHospital: '',
      filterProduct: '',
      filterState: '',
      filterCity: '',
      activeCalenderValue: 'Date',
      providerName: '',
      docketNumbers: [''],
      isModalOpen: false,
      hospitalList: [],
      sampleList: [],
      stateList: [],
      citiesList: [],
      selectedBarcodes: [],
      page: 1,
      rowsPerPage: 5,
      selectedHospitalId: '',
      selectedSampleId: '',
      openReadyToShip: false,
      openSuccessModal: false,
      openDateRange: false,
      selectAllChecked: false,
      selectedStartDate: '',
      selectedEndDate: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const data = userDetailsFromStorage();
    if (data?.attributes && data.id) {
      this.setState(
        {
          accessToken: data.attributes.token,
          userId: data.id,
        },
        () => {
          this.getSampleListDetails();
        }
      );
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
  }

  getSampleListDetails = async () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.shippingDetailsContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sampleListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.shippingDetailsEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.shippingDetailsMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  filteredSampleList = async (hospitalId: number | string, productId: number | string, state: string, city: string, start_date: string, end_date: string) => {
    this.setState({
      isLoading: true,
      page: 1
    });
    const selectedState = state === 'all_states' ? '' : state;
    const selectedCity = city === 'all_cities' ? '' : city

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.shippingDetailsContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filteredListAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.shippingDetailsEndPoint}?start_date=${start_date}&end_date=${end_date}&hospital_id=${hospitalId}&sample_id=${productId}&state=${selectedState}&city=${selectedCity}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.shippingDetailsMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  serviceProviderDetailsApi = () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.serviceProviderDetailContentType,
    };

    const httpBody = {
      barcodes: this.state.selectedBarcodes,
      service_provider_name: this.state.providerName,
      docket_number: this.state.docketNumbers,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serviceProviderDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.serviceProviderDetailEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.serviceProviderDetailMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUniqueHospitalNames = () => {
    const { hospitalList } = this.state;
    const uniqueHospitalNames: { name: string; id: number }[] = [];

    const uniqueSet = new Set<string>();

    hospitalList.forEach((data) => {
      const hospitalName = data.name;
      const hospitalId = data.id;
      const hospitalObj = { name: hospitalName, id: hospitalId };

      if (!uniqueSet.has(hospitalName)) {
        uniqueSet.add(hospitalName);
        uniqueHospitalNames.push(hospitalObj);
      }
    });

    return uniqueHospitalNames;
  };

  getUniqueProductTypes = () => {
    const { sampleList } = this.state;
    const uniqueSampleNames: { name: string; id: number }[] = [];

    const uniqueSet = new Set<string>();

    sampleList.forEach((data) => {
      const sampleName = data.name;
      const sampleId = data.id;
      const sampleObj = { name: sampleName, id: sampleId };

      if (!uniqueSet.has(sampleName)) {
        uniqueSet.add(sampleName);
        uniqueSampleNames.push(sampleObj);
      }
    });

    return uniqueSampleNames;
  };

  getUniqueStates = () => {
    const { stateList } = this.state;
    const uniqueStateNames: string[] = [];

    const uniqueSet = new Set<string>();

    stateList.forEach((data) => {

      if (!uniqueSet.has(data)) {
        uniqueSet.add(data);
        uniqueStateNames.push(data);
      }
    });

    return uniqueStateNames;
  };

  getUniqueCities = () => {
    const { citiesList } = this.state;
    const uniqueCitiesNames: string[] = [];

    const uniqueSet = new Set<string>();

    citiesList.forEach((data) => {

      if (!uniqueSet.has(data)) {
        uniqueSet.add(data);
        uniqueCitiesNames.push(data);
      }
    });

    return uniqueCitiesNames;
  }


  handleProviderDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleDocketNumberChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const updatedDocketNumbers = [...prevState.docketNumbers];
      updatedDocketNumbers[index] = value;
      return { docketNumbers: updatedDocketNumbers };
    });
  };

  handleAddButtonClick = () => {
    this.setState((prevState) => ({
      docketNumbers: [...prevState.docketNumbers, ""],
    }));
  };

  handleRemoveButtonClick = (index: number) => {
    this.setState((prevState) => {
      const updatedDocketNumbers = [...prevState.docketNumbers];
      updatedDocketNumbers.splice(index, 1);
      return { docketNumbers: updatedDocketNumbers };
    });
  };

  getHospitalId = (selectedId: number) => {
    const { selectedSampleId, filterState, filterCity, selectedStartDate, selectedEndDate } = this.state
    this.setState({ selectedHospitalId: selectedId })
    this.filteredSampleList(selectedId, selectedSampleId, filterState, filterCity, selectedStartDate, selectedEndDate)

  };

  getSampleId = (selectedId: number) => {
    const { selectedHospitalId, filterState, filterCity, selectedStartDate, selectedEndDate } = this.state
    this.setState({ selectedSampleId: selectedId })
    this.filteredSampleList(selectedHospitalId, selectedId, filterState, filterCity, selectedStartDate, selectedEndDate)
  };

  getStates = (state: string) => {
    const { selectedHospitalId, selectedSampleId, filterCity, selectedStartDate, selectedEndDate } = this.state
    if (state) {
      this.filteredSampleList(selectedHospitalId, selectedSampleId, state, filterCity, selectedStartDate, selectedEndDate)
    }
  }

  getCity = (city: string) => {
    const { selectedHospitalId, selectedSampleId, filterState, selectedStartDate, selectedEndDate } = this.state
    if (city) {
      this.filteredSampleList(selectedHospitalId, selectedSampleId, filterState, city, selectedStartDate, selectedEndDate)
    }
  }

  openOrCloseDateRangePicker = (isApiCall: boolean) => {
    const { selectedHospitalId, selectedSampleId, filterState, filterCity } = this.state;

    this.setState({
      openDateRange: !this.state.openDateRange,
    });

    if (isApiCall) {
      const start_date = getFormatDate(this.state.range[0].startDate);
      const end_date = getFormatDate(this.state.range[0].endDate);
      this.setState({ selectedStartDate: start_date, selectedEndDate: end_date })
      this.filteredSampleList(selectedHospitalId, selectedSampleId, filterState, filterCity, start_date, end_date);
    }
  };


  // Handler for handling changes in the filter fields
  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value === 'all_hospitals' || value === 'all_products' || value === 'all_states' || value === 'all_cities') {
      this.setState({
        filterCity: "",
        filterHospital: "",
        filterProduct: "",
        filterState: ""
      })
      this.getSampleListDetails()
    }

    this.setState({
      [name]: value,
      selectedBarcodes: [],
      selectAllChecked: false
    });
  };

  selectAllBarcodesHandler = () => {
    const { shipItemData, selectAllChecked } = this.state;
    const allBarcodes = shipItemData.map((i) => i.barcode_number);
    if (!selectAllChecked) {
      this.setState({ selectedBarcodes: allBarcodes, selectAllChecked: true });
    } else {
      this.setState({ selectedBarcodes: [], selectAllChecked: false });
    }
  };

  handleCheckboxClick = (data: any) => {
    const { selectedBarcodes, shipItemData } = this.state;

    const isBarcodeSelected = selectedBarcodes.includes(data.barcode_number);
    const allBarcodes = shipItemData.map((i) => i.barcode_number);

    this.setState(
      (prevState) => {
        let updatedSelectedBarcodes: string[];

        if (isBarcodeSelected) {
          updatedSelectedBarcodes = prevState.selectedBarcodes.filter(
            (barcode) => barcode !== data.barcode_number
          );
        } else {
          updatedSelectedBarcodes = [...prevState.selectedBarcodes, data.barcode_number];
        }

        return {
          selectAllChecked: allBarcodes.every((barcode) => updatedSelectedBarcodes.includes(barcode)),
          selectedBarcodes: updatedSelectedBarcodes,
        };
      }
    );
  };

  handleModal = () => {
    this.setState({ isModalOpen: true })
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, docketNumbers: [''], providerName: '' })
  }

  handlePageChange = (event: any, value: number) => {
    this.setState({
      page: value,
    });
  };

  providerDetailHandler = () => {
    const { providerName, docketNumbers } = this.state;
    const hasEmptyDocketNumber = docketNumbers.some((docketNumber) => docketNumber === '');

    if (providerName && docketNumbers.length > 0 && !hasEmptyDocketNumber) {
      this.setState({ openReadyToShip: true })
    }
  }

  onSubmitReadyToShipData = () => {
    const { providerName, docketNumbers } = this.state;
    const hasEmptyDocketNumber = docketNumbers.some((docketNumber) => docketNumber === '');

    if (providerName && docketNumbers.length > 0 && !hasEmptyDocketNumber) {
      this.serviceProviderDetailsApi();
    }
  }

  onSuccessButtonClick = () => {
    this.setState(
      {
        openSuccessModal: false,
        isModalOpen: false,
        providerName: '',
        docketNumbers: [''],
        selectedBarcodes: [],
        selectAllChecked: false,
        filterHospital: '',
        filterProduct: '',
        filterState: '',
        filterCity: '',
      },
      () => {
        this.getSampleListDetails();
      },
    );
  };

  handleResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.sampleListApiCallId) {
      if (responseJson.data) {
        const hospitalListWithId = responseJson.data.map((hospital: any) => ({
          id: hospital.hospital_id,
          name: hospital.hospital_name,
        }));
        const productListWithId = responseJson.data.map((sample: any) => ({
          id: sample.sample_id,
          name: sample.sample_name,
        }));
        this.setState({
          shipItemData: responseJson.data,
          hospitalList: hospitalListWithId,
          sampleList: productListWithId,
          stateList: responseJson.data.map((state: any) => state.state),
          citiesList: responseJson.data.map((city: any) => city.city)
        });
      } else {
        toast.error("Something went to wrong please retry");
      }
    }
    if (apiRequestCallId === this.filteredListAPICallId) {
      if (responseJson.data) {
        this.setState({
          shipItemData: responseJson.data
        })
      } else {
        toast.error("Something went to wrong please retry");
      }
    }
    if (apiRequestCallId === this.serviceProviderDetailApiCallId) {
      if (responseJson?.data) {
        this.setState({
          openReadyToShip: false,
          openSuccessModal: true,
        });
      } else {
        toast.error("Something went to wrong please retry");
      }
    }
  };

  handleError = (error: any, apiRequestCallId: string) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else {
      if (apiRequestCallId === this.serviceProviderDetailApiCallId) {
        this.setState({
          openReadyToShip: false,
          openSuccessModal: false
        })
      }
      toast.error("Something went to wrong please retry");
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        this.handleError(responseJson.error, apiRequestCallId);
      }
      if (apiRequestCallId && responseJson && !responseJson.error) {
        this.handleResponse(apiRequestCallId, responseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
  }
}
// Customizable Area End