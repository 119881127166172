// Customizable Area Start
const primaryColor = "#004d64";

export const FranchiseShipCollectedStyles = {
  ShipCollected: {
    "& .details": {
      "& .MuiAccordionSummary-root": {
        height: 64,
        padding: "0 20px",
        borderRadius: "4px",
        backgroundColor: primaryColor,
        color: "#fff",
      },
      "& .accordion-heading": {
        fontSize: "18px",
        lineHeight: "22px",
        fontFamily: "NotoSans-Medium",
        fontWeight: "400",
      },
      "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
        transform: "rotate(90deg)",
      },
      "& .digit": {
        marginRight: "5px",
      },
      "& .MuiAccordionDetails-root": {
        padding: "20px",
        color: "#004C63",
        backgroundColor: "#E5F9FF",
        borderRadius: "0 0 4px 4px",
        backgroundBlendMode: "normal",
        fontFamily: "NotoSans-Regular",
        fontSize: "14px",
        fontWeight: "300",
      },
      "& .MuiPaper-elevation1": {
        boxShadow: "none",
        marginBottom: "20px",
      },
      "& .MuiPaper-elevation1:last-child": {
        boxShadow: "none",
        marginBottom: "0",
      },
      "& .right-side": {
        display: "flex",
        marginRight: "5%",
        gap: "100px",
        alignItems: "center",
        justifyContent: "space-around",
        "& .MuiTypography-body1": {
          color: "#ffffff",
          fontFamily: "NotoSans-Medium",
          fontSize: "18px",
          fontWeight: "400",
        },
      },
      "& .MuiAccordionSummary-content": {
        alignItems: "center",
        justifyContent: "space-between",
      },
      "& .content-right-details": {
        backgroundColor: "#BAEFFF",
        padding: "20px",
        borderRadius: "8px",
        "& .MuiTypography-h6": {
          color: "#65ABC0",
          backgroundBlendMode: "normal",
          fontFamily: "NotoSans-Medium",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "22px",
        },
        "& .MuiTypography-body1": {
          color: "#004D64",
          backgroundBlendMode: "normal",
          fontFamily: "NotoSans-Regular",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "25px",
          padding: "3px 0",
        },
        "& .MuiTypography-body1:last-child": {
          paddingBottom: "0",
        },
      },
    },
    "& .margin-top": {
      marginTop: 5,
    },
    "& .table-header-row": {
      display: "flex",
      alignItems: "center",
      gap: "22%",
      backgroundColor: " #BAEFFF",
      height: "56px",
      padding: "0 20px",
      borderRadius: "8px",
    },
    "& .table-header-data": {
      color: "#004D64",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22px",
      textAlign: "center",
      width: "120px",
    },
    "& .table-row": {
      display: "flex",
      alignItems: "center",
      gap: "22%",
      height: "56px",
      padding: "0 20px",
      borderRadius: "8px 8px 0 0",
      borderBottom: "1px solid #004D64",
    },
    "& .table-row:last-child": {
      borderBottom: "0px",
    },
    "& .table-row-data": {
      color: "#004D64",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22px",
      textAlign: "center",
      minWidth: "100px",
    },
  },
};
// Customizable Area End
