// Customizable Area Start
import React from "react";
import { Dialog, Box, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ReadyShipDialogDesign } from "./ReadyShipDialogStyles.web";

interface IReadyShipDialogProps {
  classes: any;
  open: boolean;
  onClose: () => void;
  message: string;
  onSubmit: () => void;
}

const ReadyShipDialog: React.FC<IReadyShipDialogProps> = ({
  classes,
  open,
  onClose,
  message,
  onSubmit,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={() => onClose()}
      className={classes?.ReadyShipDialog}
    >
      <Box className="dialog-inside-container">
        <Typography className="modal-detail">{message}</Typography>
        <Box className="button-action-container">
          <Button
            variant="contained"
            className="cancel-button"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="yes-button"
            onClick={() => onSubmit()}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default withStyles(ReadyShipDialogDesign)(ReadyShipDialog);
// Customizable Area End
