// Customizable Area Start
export const ReadyShipDialogDesign = {
  ReadyShipDialog: {
    "& .dialog-inside-container": {
      padding: "50px 52px",
      textAlign: "center",
    },
    "& .modal-detail": {
      marginBottom: "35px",
      color: "#004c63",
      fontFamily: "NotoSans-Medium",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    "& .button-action-container": {
      display: "flex",
      gap: "25px",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .cancel-button": {
      height: "64px",
      borderRadius: "4px",
      width: "50%",
      background: "#d8d8d8",
      backgroundBlendMode: "normal",
      "& .MuiButton-label": {
        color: "#1c1c1b",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        textTransform: "capitalize",
      },
    },
    "& .yes-button": {
      height: "64px",
      borderRadius: "4px",
      width: "50%",
      background: "#004d64",
      backgroundBlendMode: "normal",
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        textTransform: "capitalize",
      },
    },
  },
};

// Customizable Area End
