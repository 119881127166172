// Customizable Area Start
import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  withStyles
} from "@material-ui/core";

import TeamLeaderController, {
  Props,
  ActiveProduct
} from "./TeamLeaderController.web";
import { DashboardWebStyles } from "./TeamLeaderDashboardStyle.web";
import { downArrow } from "../../assets";
import DateRangePickerComponent from "../../../../../components/src/DateRangePicker.web";
import Loader from "../../../../../components/src/Loader.web";

class TeamLeaderDashboard extends TeamLeaderController {
  constructor(props: Props) {
    super(props);
  }

  renderCalendarModal = () => {
    return (
      this.state.openDateRange && (
        <DateRangePickerComponent
          activeCalenderValue={this.state.activeCalenderValue}
          range={this.state.range}
          closeDatePicker={(range: any, name: string) => {
            this.setState({ range, activeCalenderValue: name }, () => {
              this.openOrCloseDateRangePicker(true);
            });
          }}
        />
      )
    );
  };

  handleIndexValue(index: number) {
    return index < 9 ? `0${index + 1}` : index + 1;
  }

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <Container maxWidth="lg" style={{ margin: "20px auto" }}>
          <Loader loading={this.state.isLoading} />
          <Box className={classes.TeamLeader}>
            <Box className="main-heading">
              <Typography variant="h2">
                Hello, {this.state.userName || ""}
              </Typography>
              <Box className="right-side">
                <Button
                  className="calenderButton"
                  variant="contained"
                  onClick={() => this.openOrCloseDateRangePicker(false)}
                >
                  <span
                    className="calendar-text"
                    title={this.state.activeCalenderValue}
                  >
                    {this.state.activeCalenderValue}
                  </span>
                  <img src={downArrow} alt="arrow" />
                </Button>
                {this.renderCalendarModal()}
              </Box>{" "}
            </Box>
            <Box margin="20px 0">
              <Grid container spacing={3}>
                <Grid item md={4} sm={6} xs={12}>
                  <Box
                    className={`content-box ${
                      this.state.dashboardActiveProduct ===
                      ActiveProduct.COLLECTED
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      this.setActiveProduct(ActiveProduct.COLLECTED)
                    }
                  >
                    <Typography variant="h1">
                      {this.state.dashboardData.collected_samples}
                    </Typography>
                    <Typography variant="body2">TOTAL COLLECTED</Typography>
                  </Box>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Box
                    className={`content-box ${
                      this.state.dashboardActiveProduct ===
                      ActiveProduct.SHIPPED
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.setActiveProduct(ActiveProduct.SHIPPED)}
                  >
                    <Typography variant="h1">
                      {this.state.dashboardData.shipped_samples}
                    </Typography>
                    <Typography variant="body2">Total Shipped</Typography>
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box
                    className={`content-box ${
                      this.state.dashboardActiveProduct ===
                      ActiveProduct.DELIVERED
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      this.setActiveProduct(ActiveProduct.DELIVERED)
                    }
                  >
                    <Typography variant="h1">
                      {this.state.dashboardData.delivered_samples}
                    </Typography>
                    <Typography variant="body2">Total delivered</Typography>
                  </Box>
                </Grid>
              </Grid>
              {this.state.dashboardProductData.length !== 0 && (
                <>
                  <Box className="items-container">
                    <Box className="items-inside-container">
                      {this.state.dashboardProductData.map(item => (
                        <Box
                          className="outer-item-container"
                          data-test-id="active-sample"
                          key={item.sample_id}
                          onClick={() => {
                            this.setActiveSampleValue(item.sample_id);
                          }}
                        >
                          <Box className="item-container">
                            <img src={item.imageSrc} alt="afPf" />
                            <Box
                              className={`text-container ${
                                this.state.activeSampleId === item.sample_id
                                  ? "text-container-active"
                                  : ""
                              }`}
                            >
                              <Typography
                                className="digit-heading"
                                title={item.value}
                              >
                                {item.value}
                              </Typography>
                              <Typography
                                className="item-name"
                                title={item.product_name}
                              >
                                {item.product_name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  {this.state.activeSampleId !== -1 && (
                    <Box
                      className="product-details"
                      style={{ marginTop: 20, borderRadius: 8 }}
                    >
                      <Grid container className="left-container">
                        <Grid item md={3} sm={3}>
                          <Typography className="data-heading text-align-center">
                            Serial no.
                          </Typography>
                        </Grid>
                        <Grid item md={7} sm={7}>
                          <Typography className="data-heading text-align-center">
                            Hospital Name
                          </Typography>
                        </Grid>
                        <Grid item md={2} sm={2}>
                          <Typography className="data-heading text-align-center">
                            Barcode Qty.
                          </Typography>
                        </Grid>
                      </Grid>
                      {this.state.franchiseHospitalDetail.length !== 0 ? (
                        this.state.franchiseHospitalDetail.map(
                          (hospital_detail, index) => (
                            <Grid container className="row-spacing">
                              <Grid item md={3} sm={3}>
                                <Typography className="data-detail text-align-center">
                                  {this.handleIndexValue(index)}
                                </Typography>
                              </Grid>
                              <Grid item md={7} sm={7}>
                                <Typography className="data-detail text-align-center">
                                  {hospital_detail.hospital_name}
                                </Typography>
                              </Grid>
                              <Grid item md={2} sm={2}>
                                <Typography className="data-detail text-align-center">
                                  {hospital_detail.qunatity}
                                </Typography>
                              </Grid>
                            </Grid>
                          )
                        )
                      ) : (
                        <div className="not-found-tex">No details Found</div>
                      )}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Container>
      </>
      //Merge Engsine End DefaultContainer
    );
  }
}

export default withStyles(DashboardWebStyles)(TeamLeaderDashboard);
// Customizable Area End
