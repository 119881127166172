// Customizable Area Start
const primaryColor = "#004d64";
const whiteColor = "#FFFFFF";

export const SuperTeamLeaderAssociateTLStyles = {
  FranchiseDetail: {
    "& .main-summary": {
      height: 64,
      padding: "0 20px",
      borderRadius: "4px",
      backgroundColor: "#fff",
      color: primaryColor,
      border: "1px solid #004D64",
    },
    "& .main-summary.Mui-expanded": {
      height: 64,
      padding: "0 20px",
      borderRadius: "4px",
      backgroundColor: primaryColor,
      color: "#fff",
      border: "1px solid #004D64",
    },
    "& .main-summary .accordion-heading": {
      fontSize: "18px",
      lineHeight: "22px",
      fontFamily: "NotoSans-SemiBold",
      fontWeight: "400",
    },
    "& .dark-color": {
      display: "block",
    },
    "& .white-color": {
      display: "none",
    },
    "& .main-summary .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)",
      "& .dark-color": {
        display: "none",
      },
      "& .white-color": {
        display: "block",
      },
    },
    "& .main-summary .digit": {
      marginRight: "5px",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "18px",
      fontWeight: "400",
    },
    "& .main-detail .MuiAccordionDetails-root": {
      padding: "20px",
      backgroundColor: "#e5f9ff",
      borderRadius: "0 0 4px 4px",
      backgroundBlendMode: "normal",
    },
    "& .MuiAccordion-root:before": {
      height: 0,
    },
    "& .summary-details .MuiAccordionDetails-root": {
      padding: "20px 0",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      marginBottom: "20px",
    },
    "& .MuiPaper-elevation1:last-child": {
      marginBottom: "0",
    },
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .sub-detail .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "max-content",
    },
    "& .summary-details": {
      width: "100%",
    },
    "& .franchise-name": {
      color: "#004d64",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "18px",
      fontWeight: "400",
    },
    "& .sub-summary": {
      height: "56px",
      borderRadius: "8px",
      background: "#76c9e3",
    },
    "& .summary-details .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .summary-details .MuiPaper-root": {
      backgroundColor: "transparent",
    },
    "& .sub-franchise-detail": {
      margin: "0 0 16px",
      padding: "0 20px",
      "& .sub-summary": {
        background: "#baefff",
      },
      "& .MuiAccordion-root.Mui-expanded": {
        margin: "0",
      },
    },
    "& .no-of-hospital": {
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "19px",
      textTransform: "uppercase",
      marginBottom: "10px",
    },
    "& .hospital-detail": {
      color: "#004D64",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "19px",
      marginBottom: "8px",
    },
    "& .hospital-address": {
      color: "#004c63",
      fontFamily: "NotoSans-Light",
      fontSize: "14px",
      fontWeight: "300",
      lineHeight: "19px",
      marginLeft: "15px",
    },
    "& .hospital-details": {
      width: "100%",
      padding: "0 20px",
      boxSizing: "border-box",
    },
  },
};
// Customizable Area End
