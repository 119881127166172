// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import {
  userDetailsFromStorage,
  userRedirectToLoginPage,
} from "../../../../../components/src/utils";
import { notFoundImageWeb } from "../../assets";
import { toast } from "react-toastify";

export const configJSON = require("../../config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  showSelectHospital: boolean;
  isEditable: boolean;
  sampleList: {
    id: string;
    label: string;
    imageSrc: string;
  }[];
  sampleId: string;
  shipItemData: {
    hospital_id: number;
    product_type_id: number;
    total_unit: string;
    hospital_name: string;
    status: string;
    barcode: string[];
    service_provider_name: string;
    docket_number: string[];
    isEditable: boolean;
  }[];
  editableRowArray: number[];
  openReadyToShip: boolean;
  openSuccessModal: boolean;
  expandedPanel: number | boolean;
  accessToken: string;
  userId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FranchiseShipCollectedItemsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sampleListApiCallId: any;
  sampleCollectedApiId: any;
  serviceProviderApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      sampleList: [],
      showSelectHospital: false,
      isEditable: false,
      sampleId: "",
      openReadyToShip: false,
      openSuccessModal: false,
      shipItemData: [],
      editableRowArray: [],
      expandedPanel: false,
      accessToken: "",
      userId: "",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const data = userDetailsFromStorage();
    if (data?.attributes && data.id) {
      this.setState(
        {
          accessToken: data.attributes.token,
          userId: data.id,
        },
        () => {
          this.getSampleListDetails();
        }
      );
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
    // Customizable Area End
  }
  // Customizable Area Start

  hideHospitalList = () => {
    this.setState({
      showSelectHospital: false,
      sampleId: "",
    });
  };

  updateShowHospitalValue = (id: string) => {
    this.setState(
      {
        showSelectHospital: true,
        sampleId: id,
        shipItemData: [],
        editableRowArray: [],
        expandedPanel: false,
      },
      () => {
        this.getSampleHospitalListing(this.state.sampleId);
      }
    );
  };

  docketNoChangeHandler = (
    index: number,
    docketIndex: number,
    value: string
  ) => {
    const { shipItemData } = this.state;
    shipItemData[docketIndex].docket_number.splice(index, 1, value);
    this.setState({
      shipItemData: shipItemData,
    });
  };

  onChangeServiceProviderName = (index: number, value: string) => {
    const { shipItemData } = this.state;
    shipItemData[index].service_provider_name = value;
    this.setState({
      shipItemData: shipItemData,
    });
  };

  deleteParameter = (index: number, docketIndex: number) => {
    const { shipItemData } = this.state;
    let { docket_number } = shipItemData[index];
    if (docket_number.length === 1) {
      toast.error("One docket no is required");
      return;
    }
    shipItemData[index].docket_number.splice(docketIndex, 1);
    this.setState({
      shipItemData: shipItemData,
    });
  };

  addGridRecord = (index: number) => {
    const { shipItemData } = this.state;
    shipItemData[index].docket_number.push("");
    this.setState({
      shipItemData: shipItemData,
    });
  };

  getSampleListDetails = async () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.sampleListContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sampleListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sampleListApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sampleListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleContinue = () => {
    const { shipItemData } = this.state;
    const collectedIndex = shipItemData.findIndex(
      (item) =>
        item.service_provider_name !== "" &&
        item.status === "collected" &&
        !item.docket_number.includes("") &&
        !item.isEditable
    );
    if (collectedIndex !== -1) {
      this.setState({
        openReadyToShip: true,
      });
    } else {
      const editIndex = shipItemData.findIndex(
        (item) =>
          (item.service_provider_name !== "" ||
            !item.docket_number.includes("")) &&
          item.status === "collected" &&
          item.isEditable
      );
      if (editIndex != -1) {
        this.setState({
          expandedPanel: editIndex,
        });
        return;
      }
      const collectedIndex = shipItemData.findIndex(
        (item) =>
          (item.service_provider_name === "" ||
            item.docket_number.includes("")) &&
          item.status === "collected" &&
          item.isEditable
      );
      if (collectedIndex !== -1) {
        this.setState({
          expandedPanel: collectedIndex,
        });
        return;
      }
    }
  };

  handleSave = (index: number) => {
    const { shipItemData } = this.state;
    const name = shipItemData[index].service_provider_name;
    if (!name) {
      toast.error("Please enter service provide name");
      return;
    } else {
      const serviceIndex = shipItemData[index].docket_number.findIndex(
        (item) => item === ""
      );
      if (serviceIndex !== -1) {
        toast.error("Please enter service provide docket no");
        return;
      }
    }
    shipItemData[index].isEditable = !shipItemData[index].isEditable;
    this.setState({
      shipItemData: [...shipItemData],
    });
  };

  handleListSortedData = (data: any) => {
    return data
      .sort((a: any, b: any) => {
        const nameA = a.attributes.name.toUpperCase();
        const nameB = b.attributes.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      })
      .map((item: any) => {
        return {
          id: item.id,
          label: item.attributes.name,
          imageSrc: item.attributes.image
            ? item.attributes.image
            : notFoundImageWeb,
        };
      });
  };

  getSampleHospitalListing = async (sampleId: string) => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.sampleCollectedHospitalContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sampleCollectedApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sampleCollectedHospitalEndPoint}?sample_id=${sampleId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sampleCollectedHospitalMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sortByStatus = (data: any) => {
    const sortedData = data?.sort(function(a: any, b: any) {
      if (a.status.toLowerCase() < b.status.toLowerCase()) {
        return -1;
      }
      if (a.status.toLowerCase() > b.status.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return sortedData;
  };

  handleResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.sampleListApiCallId) {
      if (responseJson.data) {
        const list = this.handleListSortedData(responseJson.data);
        this.setState({
          sampleList: list,
        });
      }
    } else if (apiRequestCallId === this.sampleCollectedApiId) {
      if (responseJson.data) {
        const sortedData = this.sortByStatus(responseJson.data);
        const list = sortedData
          .filter((item: any) => item.status !== "delivered")
          .map((data: any) => ({
            ...data,
            isEditable: true,
          }));
        this.setState({
          shipItemData: list,
        });
      }
    } else if (apiRequestCallId === this.serviceProviderApiCallId) {
      if (responseJson.meta.message) {
        this.setState({
          openReadyToShip: false,
          openSuccessModal: true,
        });
      }
    } else {
      toast.error("Something went to wrong please retry");
    }
  };

  handleChange = (panel: number) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    this.setState({
      expandedPanel: newExpanded ? panel : false,
    });
  };

  onSubmitReadyToShipData = () => {
    this.setState({ isLoading: true });
    const { shipItemData, sampleId } = this.state;
    const data = shipItemData.filter(
      (item) =>
        item.status === "collected" &&
        item.service_provider_name != "" &&
        !item.docket_number.includes("") &&
        !item.isEditable
    );
    const body = {
      sample_id: sampleId,
      hospitalList: data.map((item) => {
        return {
          product_type_id: item.product_type_id,
          hospital_id: item.hospital_id,
          service_provider_name: item.service_provider_name,
          docket_number: item.docket_number,
        };
      }),
    };

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.serviceProviderDetailContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serviceProviderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.serviceProviderDetailEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.serviceProviderDetailMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onSuccessButtonClick = () => {
    this.setState(
      {
        openSuccessModal: false,
      },
      () => {
        this.hideHospitalList();
      }
    );
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else {
      toast.error("Something went to wrong please retry");
    }
  };

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        this.handleError(responseJson.error);
      }
      if (apiRequestCallId && responseJson && !responseJson.error) {
        this.handleResponse(apiRequestCallId, responseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }
}
