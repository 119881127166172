// Customizable Area Start
import React from "react";

interface INotFoundProps {
  message?: string;
}

const NotFound: React.FC<INotFoundProps> = ({ message }) => {
  return (
    <div className="not-found">{message ? message : "Data Not Found"}</div>
  );
};
export default NotFound;
// Customizable Area End
