import React from "react";

// Customizable Area Start
export const ProfileImageComponent = ({
  height = 32,
  width = 32,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="IconlyBoldProfile">
        <g id="Profile" transform="translate(5.3333335 2.6666667)">
          <path d="M10.6667 26.6667C16.4195 26.6667 21.3333 25.76 21.3333 22.132C21.3333 18.5053 16.4515 17.5653 10.6667 17.5653C4.91384 17.5653 0 18.4719 0 22.1C0 25.7267 4.88316 26.6667 10.6667 26.6667Z" />
          <path d="M17.7254 7.05474C17.7254 3.1387 14.5855 0 10.6667 0C6.74925 0 3.60807 3.1387 3.60807 7.05474C3.60807 10.9708 6.74925 14.1108 10.6667 14.1108C14.5855 14.1108 17.7254 10.9708 17.7254 7.05474Z" />
        </g>
      </g>
    </svg>
  );
};

export const LogOutImageComponent = ({ height = 32, width = 32, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="IconlyBoldLogout">
        <g id="Logout" transform="translate(2.6666667 2.6666667)">
          <path d="M10.5271 12.3067L18.6667 12.3067L18.6667 5.92C18.6667 2.65333 15.9671 0 12.657 0L6.03682 0C2.71318 0 0 2.65333 0 5.93333L0 20.7467C0 24.0133 2.69961 26.6667 6.02326 26.6667L12.6298 26.6667C15.9671 26.6667 18.6667 24 18.6667 20.7333L18.6667 14.36L10.5271 14.36C9.9438 14.36 9.48256 13.8933 9.48256 13.3333C9.48256 12.76 9.9438 12.3067 10.5271 12.3067Z" />
          <path d="M23.507 8.72023C23.107 8.32023 22.4536 8.32023 22.0536 8.73356C21.6536 9.13356 21.6536 9.7869 22.0536 10.1869L24.187 12.3069L18.667 12.3069L18.667 14.3602L24.187 14.3602L22.0536 16.4802C21.6536 16.8802 21.6536 17.5336 22.0536 17.9336C22.2536 18.1336 22.5203 18.2402 22.787 18.2402C23.0403 18.2402 23.307 18.1336 23.507 17.9336L27.4003 14.0536C27.6003 13.8669 27.707 13.6002 27.707 13.3336C27.707 13.0536 27.6003 12.8002 27.4003 12.6002L23.507 8.72023Z" />
        </g>
      </g>
    </svg>
  );
};

export const WorkImageComponent = ({ height = 32, width = 32, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="IconlyBoldWork">
        <g id="Work" transform="translate(2.6666667 2.6666667)">
          <path d="M11.6059 2.02531L15.0476 2.02531C15.9414 2.02531 16.7018 2.6464 16.9152 3.48353L9.73828 3.48353C9.95172 2.6464 10.7121 2.02531 11.6059 2.02531Z" />
          <path d="M18.9428 3.48353C18.6893 1.52573 17.0485 0 15.0475 0L11.6058 0C9.6048 0 7.96398 1.52573 7.71052 3.48353L5.08254 3.48353C2.28114 3.48353 0 5.79238 0 8.6278C0 8.6278 0.0667 10.8421 0.10672 11.5307C0.10672 11.6658 0.17342 11.7873 0.28014 11.8548L1.56078 12.7459C3.76188 14.2055 6.33517 15.2492 9.05386 15.7704C9.23128 15.8041 9.4047 15.7137 9.49675 15.5571C10.2878 14.2244 11.7365 13.367 13.3267 13.367C14.9275 13.367 16.3628 14.2339 17.1312 15.5652C17.2219 15.7245 17.398 15.8176 17.5768 15.7825C20.3182 15.2627 22.8914 14.2176 25.1059 12.7324C25.1592 12.7054 25.7462 12.3139 26.3879 11.8399C26.4932 11.7616 26.5573 11.632 26.5599 11.4997C26.5866 9.82814 26.6667 8.6278 26.6667 8.6278C26.6667 5.79238 24.3855 3.48353 21.5841 3.48353L21.5841 3.48353L18.9428 3.48353Z" />
          <path d="M26.1598 14.4473C23.4651 16.2566 20.2235 17.4583 16.8218 17.9579C16.3416 18.0254 15.8613 17.7148 15.7279 17.2288C15.4344 16.1216 14.4873 15.3925 13.3534 15.3925L13.3401 15.3925L13.3134 15.3925C12.1795 15.3925 11.2323 16.1216 10.9389 17.2288C10.8055 17.7148 10.3252 18.0254 9.84499 17.9579C6.44328 17.4583 3.20166 16.2566 0.506984 14.4473C0.493644 14.4338 0.360244 14.3528 0.253524 14.4203C0.133464 14.4878 0.133464 14.6499 0.133464 14.6499L0.226844 21.5359C0.226844 24.3713 2.49464 26.6667 5.29604 26.6667L21.3574 26.6667C24.1588 26.6667 26.4266 24.3713 26.4266 21.5359L26.5333 14.6499C26.5333 14.6499 26.5333 14.4878 26.4133 14.4203C26.3466 14.3798 26.2665 14.3933 26.2132 14.4203L26.2132 14.4203C26.1865 14.4338 26.1598 14.4473 26.1598 14.4473Z" />
          <path
            d="M13.3267 21.0903C12.7797 21.0903 12.3262 20.6447 12.3262 20.0777L12.3262 18.3359C12.3262 17.7823 12.7797 17.3232 13.3267 17.3232C13.887 17.3232 14.3272 17.7823 14.3272 18.3359L14.3272 20.0777C14.3272 20.6447 13.887 21.0903 13.3267 21.0903Z"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  );
};

export const RightArrowComponent = ({ height = 24, width = 24, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="IconlyLightArrow---Right-2-Copy"
        transform="matrix(1.1924881E-08 1 -1 1.1924881E-08 24 0)"
      >
        <path
          d="M0 24L-1.04907e-06 0L24 -1.90735e-06L24 24L0 24Z"
          id="IconlyLightArrow---Right-2-(Background)"
          fill="none"
          fill-rule="evenodd"
          stroke="none"
        />
        <g
          id="IconlyLightArrow---Right-2"
          transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 24)"
        >
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="IconlyLightArrow---Right-2-(Background)"
            fill="none"
            fill-rule="evenodd"
            stroke="none"
          />
          <g id="Arrow---Right-2" transform="translate(8.5 5)">
            <path
              d="M1.35122e-07 0L7 7L-4.76837e-07 14"
              id="Stroke-1"
              fill="none"
              fill-rule="evenodd"
              stroke="#FFFFFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const DownArrowComponent = ({ height = 24, width = 24, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="IconlyLightArrow---Right-2"
        transform="matrix(-1 8.742278E-08 -8.742278E-08 -1 24 24)"
      >
        <path
          d="M0 24L-1.04907e-06 0L24 -1.90735e-06L24 24L0 24Z"
          id="IconlyLightArrow---Right-2-(Background)"
          fill="none"
          fill-rule="evenodd"
          stroke="none"
        />
        <g
          id="IconlyLightArrow---Right-2"
          transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 24)"
        >
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="IconlyLightArrow---Right-2-(Background)"
            fill="none"
            fill-rule="evenodd"
            stroke="none"
          />
          <g id="Arrow---Right-2" transform="translate(8.5 5)">
            <path
              d="M1.35122e-07 0L7 7L-4.76837e-07 14"
              id="Stroke-1"
              fill="none"
              fill-rule="evenodd"
              stroke="#FFFFFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const PersonImageComponent = ({ height = 32, width = 32, ...props }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      width={width}
      height={height}
      viewBox="0 0 500 706"
    >
      <path
        opacity="1.000000"
        stroke="none"
        d="
M1.000008,592.000000 
	C1.000000,579.312439 1.000000,566.624878 1.316105,553.808350 
	C2.420166,550.435730 3.254143,547.202332 3.988837,543.946594 
	C10.328260,515.854065 16.582798,487.742096 23.003222,459.668060 
	C26.529766,444.247894 29.252884,428.527954 34.280018,413.596985 
	C40.256229,395.847137 51.315094,380.844513 67.215225,370.542206 
	C79.963112,362.282410 93.527588,355.246948 106.935654,348.052032 
	C120.946251,340.533813 135.214233,333.496033 149.335129,326.181458 
	C150.754211,325.446350 152.240829,324.479401 153.176285,323.230652 
	C160.185699,313.873840 166.895584,304.287872 174.066223,295.058807 
	C176.977158,291.312256 180.372650,287.595032 184.327377,285.100708 
	C188.587006,282.414062 193.521027,283.519531 198.050537,286.275452 
	C197.700455,285.153259 197.143600,284.384949 196.429276,283.813507 
	C191.461716,279.839783 186.187180,276.204346 181.524002,271.904205 
	C168.430756,259.830292 157.548889,246.033630 149.872589,229.824997 
	C149.277878,228.569260 147.475754,227.286072 146.089600,227.100571 
	C136.283630,225.788269 126.958900,223.104523 119.289078,216.684998 
	C105.361183,205.027573 99.351700,189.735199 100.025787,171.757690 
	C100.542892,157.966599 107.089684,147.382584 119.166809,141.220825 
	C125.742714,137.865784 131.049927,135.208298 129.087524,126.610291 
	C128.796600,125.335594 129.928436,123.801651 129.958725,122.378220 
	C130.342636,104.333946 133.329880,86.652435 138.125961,69.337555 
	C142.097916,54.997864 148.601044,41.733562 158.853088,30.709055 
	C174.707031,13.660574 195.120850,6.066849 217.617966,2.927528 
	C218.484756,2.806573 219.209213,1.665647 220.000000,1.000000 
	C240.354233,1.000000 260.708466,1.000000 281.190979,1.324180 
	C286.306854,2.767008 291.302124,3.852539 296.280762,5.009660 
	C329.315735,12.687563 351.779419,31.989069 362.316437,64.665398 
	C369.385895,86.588531 371.645142,109.228699 372.924805,132.028214 
	C373.105255,135.242767 373.482330,136.853500 377.132202,138.267365 
	C388.852509,142.807510 397.579895,151.172485 400.807892,163.463348 
	C403.585754,174.040451 402.352356,184.751160 398.100250,195.130096 
	C390.955994,212.568558 378.919495,223.591248 359.804077,226.001892 
	C355.489532,226.545990 351.949646,227.901138 350.597229,232.721146 
	C349.955109,235.009644 348.592377,237.142761 347.320007,239.200287 
	C336.333984,256.965851 322.527039,272.093292 305.286499,284.006409 
	C304.600281,284.480621 304.271301,285.471832 303.775879,286.222168 
	C310.683990,281.850342 319.582123,283.344940 324.223114,289.741974 
	C330.141479,297.899811 335.965942,306.130615 342.080902,314.139038 
	C345.290375,318.342346 348.309540,323.409088 352.665314,325.889893 
	C368.294159,334.791016 384.439301,342.788483 400.421631,351.065155 
	C419.032166,360.702850 438.464478,369.008331 451.923950,386.309296 
	C459.774261,396.400146 466.818329,407.000366 469.853699,419.393036 
	C475.472076,442.331329 480.171570,465.494720 485.248627,488.565521 
	C489.838440,509.422119 494.406067,530.283691 499.070923,551.123474 
	C499.234955,551.856201 500.335785,552.379211 501.000000,553.000000 
	C501.000000,565.687561 501.000000,578.375122 500.690613,591.192017 
	C499.946686,591.806030 499.225159,592.235168 499.119080,592.783386 
	C495.357086,612.227661 485.714844,628.507812 471.971344,642.421143 
	C466.439850,648.020996 460.140594,652.854187 454.342346,658.200745 
	C444.156555,667.593018 434.142609,677.171875 423.944763,686.550903 
	C419.240509,690.877380 414.184021,694.819946 409.458313,699.124451 
	C406.792664,701.552490 404.474640,704.362244 402.000000,707.000000 
	C301.645782,707.000000 201.291550,707.000000 100.799095,706.754395 
	C96.585754,702.981689 92.413025,699.560425 88.454544,695.907043 
	C78.268211,686.505798 68.220726,676.954163 58.026749,667.561340 
	C53.687935,663.563538 48.892475,660.044067 44.707333,655.900391 
	C36.205681,647.482971 27.358431,639.296692 19.799608,630.075806 
	C10.992383,619.331909 5.337417,606.725342 2.857356,592.869629 
	C2.787865,592.481384 1.643289,592.285522 1.000008,592.000000 
M274.500000,681.001160 
	C310.587494,681.001160 346.675018,681.001160 382.659027,681.001160 
	C382.659027,615.973450 382.659027,551.583740 382.659027,487.368530 
	C351.589844,487.368530 320.862823,487.368530 290.000000,487.368530 
	C290.000000,505.228882 290.036560,522.720337 289.983856,540.211487 
	C289.959290,548.364990 285.026428,553.866333 277.202026,553.944458 
	C259.706268,554.119202 242.205154,554.163513 224.711578,553.901672 
	C219.257095,553.819946 213.550415,550.929260 213.002609,544.928711 
	C212.404678,538.379211 212.117111,531.785767 212.042038,525.207458 
	C211.898148,512.597717 212.000198,499.985138 212.000198,487.276184 
	C180.775742,487.276184 150.049591,487.276184 119.299316,487.276184 
	C119.299316,551.943176 119.299316,616.332764 119.299316,681.001160 
	C170.898773,681.001160 222.199387,681.001160 274.500000,681.001160 
M229.083160,184.409637 
	C219.592041,188.522827 210.085556,192.601028 200.615616,196.762421 
	C195.033356,199.215439 189.096771,200.975586 184.158173,196.444275 
	C178.872818,191.594818 174.533737,185.720551 169.718048,180.350632 
	C165.722244,175.894989 161.634094,171.522186 157.586853,167.112671 
	C157.876236,172.826706 158.710709,178.104919 160.041809,183.254745 
	C167.754547,213.093735 181.313156,239.418610 206.239700,258.706238 
	C222.256241,271.099457 240.616455,276.395233 260.544403,273.801208 
	C283.237335,270.847290 300.575623,257.749359 314.583893,240.576141 
	C327.841553,224.323151 336.413177,205.420471 341.462830,185.021912 
	C342.886292,179.271606 343.948944,173.431976 345.177094,167.633301 
	C344.767853,167.482666 344.358612,167.332031 343.949341,167.181396 
	C337.579102,174.644623 330.871246,181.849976 324.923431,189.635910 
	C317.373962,199.518417 310.797363,201.358978 299.531128,195.893341 
	C292.684906,192.572006 285.960419,188.628937 278.724396,186.573914 
	C269.255371,183.884674 259.392548,181.988785 249.588730,181.281250 
	C243.107315,180.813492 236.431976,183.032959 229.083160,184.409637 
M37.497749,610.003113 
	C42.164040,615.333374 46.517178,620.983337 51.563652,625.925354 
	C61.244862,635.406189 71.290718,644.516357 81.245026,653.715698 
	C85.261810,657.427917 89.435341,660.970459 93.999428,664.997742 
	C93.999428,622.555420 94.029160,580.791260 93.864494,539.027954 
	C93.859230,537.692688 92.052437,535.443909 90.760269,535.173218 
	C72.777328,531.405701 54.728508,531.574158 36.746517,535.208130 
	C34.474926,535.667175 31.858870,536.163574 31.759245,540.067078 
	C31.658957,543.996521 29.774981,547.845215 29.124767,551.803589 
	C27.910553,559.195435 26.004637,566.671875 26.227999,574.057434 
	C26.608656,586.644043 30.041779,598.682373 37.497749,610.003113 
M408.000000,541.690125 
	C408.000000,582.604248 408.000000,623.518372 408.000000,665.275757 
	C414.180908,659.534668 419.683319,654.407471 425.204498,649.300476 
	C429.935150,644.924805 434.541748,640.400146 439.458832,636.243774 
	C451.628540,625.956787 463.515778,615.439331 469.894562,600.312988 
	C473.916656,590.775208 476.562408,580.651489 475.757477,570.399963 
	C474.974884,560.433044 471.950500,550.661316 470.472656,540.720337 
	C469.880859,536.739441 467.684296,535.641418 464.527954,535.028687 
	C448.656860,531.947876 432.678955,531.460876 416.763855,534.197815 
	C413.356262,534.783813 406.800415,533.168091 408.000000,541.690125 
M361.949646,451.623505 
	C358.671783,447.543518 355.051147,443.683197 352.243408,439.301910 
	C350.532013,436.631378 348.714233,435.944855 345.840637,435.957397 
	C326.861633,436.040314 307.882111,435.994507 288.902740,436.013306 
	C287.632782,436.014557 286.363007,436.202057 284.686768,436.335175 
	C285.902954,444.727386 287.063965,452.738739 288.211975,460.660339 
	C315.204010,460.660339 341.851776,460.660339 368.954987,460.660339 
	C366.421234,457.601868 364.241150,454.970245 361.949646,451.623505 
M183.500000,461.000458 
	C193.601196,461.000458 203.702408,461.000458 213.773682,461.000458 
	C214.977203,452.485992 216.107315,444.490845 217.306427,436.007538 
	C195.679871,436.007538 174.762878,435.986694 153.846344,436.073425 
	C152.874405,436.077454 151.600601,436.805267 150.984192,437.597870 
	C145.063354,445.211517 139.253677,452.911621 133.097488,461.000458 
	C149.959778,461.000458 166.229889,461.000458 183.500000,461.000458 
M288.993652,336.507202 
	C286.054260,339.125122 283.114838,341.743042 280.055023,344.468201 
	C284.870117,353.637665 289.628235,362.698669 294.647797,372.257568 
	C304.257690,358.950684 313.564697,346.063202 323.169403,332.763458 
	C319.343323,327.518524 315.382812,322.089355 311.091553,316.206757 
	C303.527039,323.140717 296.511871,329.571075 288.993652,336.507202 
M190.494659,348.996948 
	C196.070694,356.654877 201.646729,364.312805 207.357422,372.155670 
	C212.397690,362.583008 217.141098,353.574127 221.904388,344.527496 
	C211.600388,335.130310 201.433716,325.858368 190.860336,316.215515 
	C186.955414,321.615265 183.369827,326.386200 180.061905,331.342438 
	C179.544617,332.117493 179.903946,333.990723 180.517654,334.920837 
	C183.550369,339.517242 186.804901,343.967346 190.494659,348.996948 
M263.999908,501.504822 
	C263.999908,496.752319 263.999908,491.999847 263.999908,487.314941 
	C254.999741,487.314941 246.619049,487.314941 238.307846,487.314941 
	C238.307846,501.002899 238.307846,514.373169 238.307846,527.685120 
	C246.999649,527.685120 255.380325,527.685120 263.999908,527.685120 
	C263.999908,519.082520 263.999908,510.793091 263.999908,501.504822 
M255.308380,436.000366 
	C251.235947,436.000366 247.163528,436.000366 243.448090,436.000366 
	C242.237228,444.731842 241.132782,452.695892 240.015717,460.750916 
	C247.646027,460.750916 254.736160,460.750916 261.916443,460.750916 
	C260.907928,452.770050 259.983490,445.038635 258.848846,437.338226 
	C258.766235,436.777618 257.158356,436.441742 255.308380,436.000366 
M372.094849,166.109161 
	C371.063934,171.192795 370.070435,176.284363 368.991119,181.357697 
	C367.995911,186.035736 366.908630,190.694168 365.861908,195.361237 
	C366.514008,195.630905 367.166107,195.900589 367.818207,196.170258 
	C370.464752,191.327759 374.124451,186.747360 375.464508,181.566910 
	C376.730774,176.671936 377.831604,170.715637 372.094849,166.109161 
M135.108307,195.515152 
	C135.403687,194.942764 136.045181,194.298294 135.943207,193.808670 
	C134.025391,184.601151 132.019531,175.411987 129.651443,164.458527 
	C128.077301,167.988281 126.397217,170.241257 126.116318,172.656937 
	C125.093513,181.452713 127.778152,189.063309 135.108307,195.515152 
z"
      />
    </svg>
  );
};

export const BarcodeImageComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      width={width}
      height={height}
      viewBox="0 0 500 500"
    >
      <path
        opacity="1.000000"
        stroke="none"
        d="
 M1.000000,485.000000 
   C1.000000,329.309265 1.000000,173.618515 1.259373,17.796455 
   C5.438363,11.104931 9.754712,4.946237 17.281021,2.003373 
   C17.313204,1.990789 17.099089,1.348306 17.000000,1.000000 
   C172.690750,1.000000 328.381500,1.000000 484.203552,1.259384 
   C490.895203,5.438267 497.053741,9.754780 499.996643,17.281004 
   C500.009216,17.313196 500.651703,17.099091 501.000000,17.000004 
   C501.000000,172.690750 501.000000,328.381500 500.740601,484.203552 
   C496.561676,490.895172 492.245209,497.053741 484.719025,499.996704 
   C484.686859,500.009308 484.900940,500.651703 485.000000,501.000000 
   C329.309265,501.000000 173.618515,501.000000 17.796436,500.740631 
   C11.104965,496.561584 4.946209,492.245300 2.003402,484.718964 
   C1.990802,484.686798 1.348309,484.900909 1.000000,485.000000 
 M346.000000,344.500000 
   C346.000000,363.996490 345.999847,383.493011 346.000153,402.989502 
   C346.000214,406.655762 345.815460,410.334015 346.050049,413.985260 
   C346.349609,418.647705 349.753601,422.705231 353.628967,422.940002 
   C358.519287,423.236267 361.184418,420.475952 362.075775,415.841278 
   C362.441284,413.940857 362.956696,412.019501 362.960510,410.106750 
   C363.017303,381.610016 362.999725,353.113129 362.999725,324.616272 
   C362.999725,322.845612 362.999725,321.074951 362.999725,319.266174 
   C369.310547,319.266174 375.046539,319.266174 380.999847,319.266174 
   C380.999847,321.167999 380.999847,322.809692 380.999847,324.451385 
   C380.999847,354.114777 380.922791,383.778748 381.137299,413.440582 
   C381.155640,415.978790 382.488922,419.247040 384.355286,420.811279 
   C386.014343,422.201691 389.543976,422.554016 391.746460,421.832855 
   C395.392914,420.639038 397.043213,417.394348 397.034424,413.303772 
   C396.970673,383.640503 397.000214,353.977020 397.000244,324.313629 
   C397.000244,322.569427 397.000244,320.825226 397.000244,319.002014 
   C410.575134,319.002014 423.405334,319.022125 436.235382,318.991760 
   C441.123199,318.980194 444.149017,315.805878 443.999878,310.992279 
   C443.850616,306.175385 440.428925,303.070099 434.844238,303.025848 
   C424.012817,302.939972 413.180176,303.000671 402.348053,303.000610 
   C400.602783,303.000610 398.857544,303.000580 397.000153,303.000580 
   C397.000153,271.418915 397.002686,240.592773 396.998169,209.766647 
   C396.997223,203.379822 394.385590,200.114090 389.226074,200.000488 
   C383.789795,199.880798 381.002808,203.192673 381.001801,209.814270 
   C380.997467,238.977722 380.999878,268.141174 380.999847,297.304626 
   C380.999847,299.083435 380.999847,300.862244 380.999847,302.713898 
   C374.757690,302.713898 369.028870,302.713898 362.999756,302.713898 
   C362.999756,300.458466 362.999756,298.486603 362.999756,296.514740 
   C362.999725,268.017883 363.012115,239.520996 362.990570,211.024155 
   C362.985504,204.320328 359.614319,200.006683 354.518707,199.999969 
   C349.413849,199.993240 346.015106,204.303268 346.009796,210.985367 
   C345.986908,239.815491 346.000000,268.645660 346.000000,297.475830 
   C346.000000,299.238281 346.000000,301.000732 346.000000,302.653076 
   C339.631989,302.653076 333.899109,302.653076 328.000244,302.653076 
   C328.000244,271.488098 328.002472,240.670334 327.998474,209.852554 
   C327.997620,203.216278 325.230988,199.899933 319.812012,200.000809 
   C314.643250,200.097000 312.003143,203.368866 312.002075,209.728516 
   C311.997192,238.891968 311.999878,268.055420 311.999847,297.218872 
   C311.999847,299.005737 311.999847,300.792603 311.999847,302.744019 
   C305.820068,302.744019 300.091309,302.744019 294.000000,302.744019 
   C294.000000,283.695038 294.000031,264.900269 294.000000,246.105515 
   C293.999969,234.273483 294.040680,222.441254 293.983826,210.609497 
   C293.952423,204.076614 290.348907,199.867432 285.104065,200.000198 
   C280.384613,200.119644 277.016876,204.557968 277.011261,210.891800 
   C276.985687,239.721924 277.000000,268.552094 277.000000,297.382263 
   C277.000000,299.153687 277.000000,300.925140 277.000000,302.685883 
   C270.701782,302.685883 264.971405,302.685883 259.000458,302.685883 
   C259.000458,300.560608 259.000488,298.748291 259.000458,296.935944 
   C259.000397,267.772491 259.005188,238.609024 258.996460,209.445572 
   C258.994629,203.246338 256.147736,199.951309 250.905182,200.000885 
   C245.708084,200.050034 243.003586,203.327042 243.002289,209.635223 
   C242.996262,238.965317 242.999573,268.295410 242.999527,297.625519 
   C242.999512,299.373474 242.999527,301.121429 242.999527,302.776855 
   C236.647659,302.776855 231.034744,302.776855 225.000000,302.776855 
   C225.000000,300.937439 225.000000,299.315002 225.000000,297.692535 
   C225.000000,282.360901 225.000015,267.029236 225.000000,251.697601 
   C224.999985,238.032440 224.931000,224.366776 225.031647,210.702347 
   C225.075897,204.694199 220.872894,198.933868 215.195923,199.942795 
   C211.152924,200.661331 208.007523,205.230469 208.005112,211.298996 
   C207.993698,239.962509 208.000000,268.626007 208.000000,297.289520 
   C208.000000,299.069794 208.000000,300.850067 208.000000,302.718994 
   C201.768417,302.718994 196.039597,302.718994 190.000183,302.718994 
   C190.000183,300.636810 190.000183,298.833038 190.000183,297.029236 
   C190.000153,268.032440 190.006363,239.035629 189.983688,210.038834 
   C189.982407,208.403427 190.104492,206.631149 189.545853,205.159119 
   C187.997223,201.078323 184.687546,199.298050 180.513229,200.091629 
   C176.100662,200.930481 173.968155,203.910721 173.975586,208.542419 
   C174.023148,238.205734 173.999741,267.869171 173.999680,297.532562 
   C173.999680,299.289551 173.999695,301.046539 173.999695,302.632996 
   C167.589920,302.632996 161.855927,302.632996 156.000000,302.632996 
   C156.000000,290.773682 156.000015,279.281555 156.000000,267.789429 
   C155.999985,248.791519 156.024994,229.793579 155.986649,210.795746 
   C155.972855,203.967010 152.296616,199.738892 146.791733,200.000275 
   C142.243332,200.216232 139.009644,204.818573 139.006470,211.213745 
   C138.992294,239.877258 139.000305,268.540771 139.000305,297.204285 
   C139.000305,298.992462 139.000290,300.780640 139.000290,302.749359 
   C132.831909,302.749359 127.103050,302.749359 121.000183,302.749359 
   C121.000183,300.714996 121.000198,298.915039 121.000198,297.115082 
   C121.000175,268.118286 121.005455,239.121475 120.986244,210.124680 
   C120.985153,208.481232 121.168045,206.689041 120.600929,205.223114 
   C119.045341,201.202194 115.808060,199.318542 111.596962,200.079742 
   C107.156433,200.882416 104.977837,203.830734 104.983025,208.456345 
   C105.016327,238.119720 104.999847,267.783142 104.999725,297.446533 
   C104.999718,299.211670 104.999718,300.976807 104.999718,302.817322 
   C103.753654,302.905731 103.107903,302.991028 102.462082,302.991516 
   C90.630058,303.000641 78.791115,302.758728 66.968811,303.098511 
   C60.691936,303.278870 57.539761,307.042267 57.926266,311.687866 
   C58.315662,316.368164 61.236572,318.994446 66.953972,319.002899 
   C70.620224,319.008331 74.286499,319.004028 77.952759,319.004059 
   C86.883759,319.004059 95.814758,319.004059 104.999710,319.004059 
   C104.999710,321.440338 104.999710,323.251343 104.999718,325.062317 
   C104.999733,354.392426 104.989151,383.722504 105.009178,413.052612 
   C105.012947,418.573090 108.453194,422.859650 112.847099,422.993195 
   C117.505196,423.134735 120.998741,418.846283 120.999191,412.967010 
   C121.001457,383.303619 121.000214,353.640198 121.000191,323.976807 
   C121.000183,322.379120 121.000191,320.781464 121.000191,319.326569 
   C127.300163,319.326569 133.038773,319.326569 139.000275,319.326569 
   C139.000275,321.462006 139.000259,323.276398 139.000259,325.090820 
   C139.000259,352.921082 138.972595,380.751434 139.043671,408.581512 
   C139.050674,411.326111 139.453598,414.114471 140.036301,416.803772 
   C141.351852,422.875549 148.201157,425.292908 152.396011,420.772797 
   C154.470016,418.537994 155.821777,414.741699 155.849045,411.637421 
   C156.103806,382.642853 156.000000,353.645111 156.000000,324.648315 
   C156.000000,322.873535 156.000000,321.098755 156.000000,319.311493 
   C162.267685,319.311493 167.996902,319.311493 173.999695,319.311493 
   C173.999695,321.426056 173.999695,323.235413 173.999695,325.044769 
   C173.999710,354.374878 173.998230,383.704987 174.001038,413.035095 
   C174.001617,418.938110 177.511597,423.182861 182.216812,422.993591 
   C186.684326,422.813873 189.998215,418.620392 189.998764,413.119263 
   C190.001755,383.622498 190.000183,354.125763 190.000183,324.628998 
   C190.000183,322.857910 190.000183,321.086792 190.000183,319.315552 
   C196.308243,319.315552 202.038284,319.315552 208.000000,319.315552 
   C208.000000,346.102844 207.999893,372.579376 208.000061,399.055878 
   C208.000092,403.888672 207.874832,408.725769 208.038620,413.553040 
   C208.190903,418.041107 210.366867,421.995697 214.746475,422.588745 
   C217.391815,422.946991 221.095840,421.045654 223.081146,418.970428 
   C224.685272,417.293671 224.914551,413.840332 224.925598,411.171661 
   C225.044830,382.341980 225.000000,353.511658 225.000000,324.681488 
   C225.000000,322.903534 225.000000,321.125580 225.000000,319.299805 
   C231.243591,319.299805 236.972122,319.299805 242.999527,319.299805 
   C242.999527,321.394470 242.999527,323.203094 242.999527,325.011688 
   C242.999542,354.341797 242.998138,383.671906 243.000748,413.002014 
   C243.001266,418.880859 246.504013,423.152008 251.177109,422.993591 
   C255.729248,422.839233 258.998291,418.736420 258.998901,413.147736 
   C259.002106,383.651001 259.000427,354.154236 259.000458,324.657501 
   C259.000458,322.883667 259.000458,321.109833 259.000458,319.305511 
   C265.287170,319.305511 271.016327,319.305511 277.000000,319.305511 
   C277.000000,343.246887 276.999969,366.887695 277.000000,390.528503 
   C277.000031,398.027679 276.948090,405.527344 277.019440,413.025848 
   C277.064087,417.719818 279.123810,421.849274 283.727234,422.576477 
   C286.331696,422.987915 290.071655,421.057190 292.061462,418.989349 
   C293.669342,417.318420 293.913574,413.865753 293.924713,411.199982 
   C294.045319,382.370300 294.000000,353.539978 294.000000,324.709808 
   C294.000000,322.929169 294.000000,321.148529 294.000000,319.289734 
   C300.223022,319.289734 305.951447,319.289734 311.999847,319.289734 
   C311.999847,321.370514 311.999847,323.176880 311.999847,324.983246 
   C311.999847,354.313354 311.998566,383.643433 312.000885,412.973541 
   C312.001343,418.759338 315.550934,423.122131 320.137421,422.993530 
   C324.710968,422.865234 327.997925,418.775146 327.998596,413.181305 
   C328.001984,383.684540 328.000244,354.187805 328.000275,324.691040 
   C328.000275,322.914001 328.000275,321.136963 328.000275,319.293610 
   C334.262695,319.293610 339.991333,319.293610 346.000000,319.293610 
   C346.000000,327.553467 346.000000,335.526733 346.000000,344.500000 
 M215.625763,92.055031 
   C225.371918,86.586021 234.966110,80.811913 244.957626,75.835548 
   C247.921127,74.359550 252.709656,73.728333 255.449265,75.111626 
   C267.150696,81.019943 278.380402,87.858879 289.818420,94.293968 
   C296.701477,98.166412 303.651123,101.920456 310.699310,105.798378 
   C310.699310,76.678131 310.699310,47.976395 310.699310,19.299690 
   C270.691193,19.299690 230.984375,19.299690 191.139664,19.299690 
   C191.139664,48.190205 191.139664,76.781464 191.139664,106.001266 
   C199.300232,101.381721 207.154694,96.935455 215.625763,92.055031 
 M35.530426,19.000198 
   C32.865822,19.000177 30.200647,18.964752 27.536728,19.007496 
   C21.972622,19.096769 19.061300,21.952997 19.014786,27.497433 
   C18.954716,34.658115 19.000185,41.819687 19.000196,48.980873 
   C19.000204,55.575939 19.000198,62.171005 19.000198,68.701202 
   C70.657623,68.701202 121.692535,68.701202 172.679199,68.701202 
   C172.679199,52.008179 172.679199,35.620087 172.679199,19.000198 
   C127.079582,19.000198 81.800369,19.000198 35.530426,19.000198 
 M453.500000,69.004097 
   C463.255615,69.004097 473.011230,69.004097 482.999939,69.004097 
   C482.999939,55.236225 483.003754,42.100315 482.998474,28.964403 
   C482.995453,21.488583 480.483826,19.001673 472.934540,19.001295 
   C426.804077,18.998983 380.673584,19.000177 334.543121,19.000202 
   C332.779907,19.000204 331.016693,19.000204 329.325500,19.000204 
   C329.325500,36.005646 329.325500,52.393883 329.325500,69.004097 
   C370.600403,69.004097 411.550201,69.004097 453.500000,69.004097 
 M420.359222,182.000000 
   C424.082581,182.062698 428.434845,180.929352 430.527863,185.175827 
   C431.900879,187.961517 432.862762,191.265488 432.891113,194.347626 
   C433.090118,215.990326 432.877747,237.637329 433.170380,259.277893 
   C433.202667,261.666382 435.232880,264.340240 436.977081,266.316315 
   C439.301056,268.949310 443.053711,268.329193 444.814453,266.312256 
   C446.965240,263.848572 448.768463,260.140259 448.821289,256.938812 
   C449.167145,235.965973 448.880676,214.983765 449.038910,194.006439 
   C449.155182,178.592621 440.291260,166.191849 424.567261,165.350922 
   C401.158539,164.098984 377.632294,164.919678 354.158264,165.118179 
   C349.437988,165.158066 346.530365,169.031662 346.296448,173.105774 
   C346.074677,176.968979 348.415009,180.571014 353.230347,181.103485 
   C355.149200,181.315689 357.048553,181.954208 358.958191,181.960297 
   C379.104034,182.024475 399.250153,182.000000 420.359222,182.000000 
 M53.238121,260.704254 
   C55.113476,265.709320 58.849598,268.655518 62.351254,267.890564 
   C67.115906,266.849701 69.000320,264.062164 69.001808,257.992249 
   C69.007011,236.682098 68.923332,215.371460 69.047440,194.062042 
   C69.091476,186.500259 71.607491,181.553299 80.930420,181.815308 
   C101.891052,182.404373 122.882339,182.168777 143.854034,181.829315 
   C147.242233,181.774490 151.056168,180.071564 153.811539,177.989807 
   C155.898560,176.413010 157.297104,172.716751 154.531250,170.264359 
   C152.054413,168.068268 148.851288,165.283844 145.922363,165.228989 
   C123.621117,164.811508 101.293137,164.496246 79.004791,165.175827 
   C63.863926,165.637466 53.342358,176.353882 53.095020,191.929810 
   C52.735577,214.565887 53.018982,237.212173 53.238121,260.704254 
 M76.758881,456.999603 
   C81.586426,456.998810 86.413979,456.997223 91.241524,456.997314 
   C110.052208,456.997711 128.863129,456.946350 147.673401,457.035156 
   C152.536499,457.058136 155.104660,453.854279 155.698624,450.114136 
   C156.241470,446.695770 154.482910,443.019958 150.230515,442.012604 
   C148.194138,441.530182 146.092148,441.050323 144.018250,441.043274 
   C123.210213,440.972443 102.396149,440.721405 81.596535,441.145966 
   C73.951569,441.302032 69.156853,439.344177 69.058929,428.350952 
   C68.870644,407.211426 69.007790,386.069000 69.001541,364.927795 
   C68.999664,358.568542 66.222870,354.949615 61.422390,355.001465 
   C56.713753,355.052307 53.012421,359.489563 53.006050,365.175323 
   C52.989643,379.824341 52.881302,394.474701 53.043522,409.121918 
   C53.147713,418.529297 53.067329,428.002625 54.164413,437.317444 
   C55.304321,446.995911 65.074799,456.564758 76.758881,456.999603 
 M448.999420,393.500000 
   C448.999451,384.000946 449.018707,374.501862 448.992310,365.002899 
   C448.976624,359.362518 445.127228,354.929169 440.388672,355.001556 
   C435.770294,355.072052 433.001648,358.654968 432.999298,364.632660 
   C432.990814,386.130432 433.026978,407.628235 432.980652,429.125916 
   C432.962067,437.762726 429.645325,440.998810 421.109467,441.000061 
   C418.443085,441.000427 415.776703,441.000122 413.110321,441.000153 
   C394.278931,441.000214 375.447510,440.973755 356.616211,441.015594 
   C350.557587,441.029053 345.906616,444.860016 346.017670,449.542084 
   C346.129852,454.270111 349.853638,456.997437 356.203278,456.997772 
   C379.200867,456.998962 402.198517,457.009979 425.196045,456.976135 
   C426.815186,456.973755 428.497986,456.861328 430.042480,456.419800 
   C441.130768,453.250153 448.856171,442.814270 448.972870,430.995911 
   C449.093018,418.831696 448.999481,406.665405 448.999420,393.500000 
 z"
      />
    </svg>
  );
};
// Customizable Area End
