// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  Typography,
  Box,
  withStyles
} from "@material-ui/core";
import { SuperTeamsSamplesStyles } from "./SuperTeamSamplesStyles.web";
import { barcodeWebImage, plusWebImage } from "../../assets";
import BackButtonTitleWeb from "../../../../../components/src/BackButtonTitleWeb.web";
import BarcodeScanModalWeb from "../../../../../components/src/BarcodeScanModal.web";
import SuperTeamsPositive, {
  IProps
} from "./SuperTeamsPositiveSamplesController.web";
import SuccessModalWeb from "../../../../../components/src/SuccessModalWeb";
import Loader from "../../../../../components/src/Loader.web";
import ReadyShipDialog from "../../../../../components/src/ReadyShipDialog.web";
import { getFormatDate } from "../../../../../components/src/utils";
// Customizable Area End

interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
class SuperTeamsPositiveSamples extends SuperTeamsPositive {
  constructor(props: IProps) {
    super(props);
  }

  renderErrorTextForPositive = (barcode: string, isError: boolean) => {
    const validData: any = this.state.successData.find(
      data => data.barcode_number === barcode
    );
    const pickupDate: string = getFormatDate(
      validData && validData.pickup_date
    );
    const productName: string = validData && validData.product_name;
    const hospitalName: string = validData && validData.hospital_name;

    if (validData && this.state.successData) {
      return (
        <div className="success-text">
          <strong>Pickup Date:-</strong>
          {pickupDate}, <strong>Product Type:-</strong> {productName},{" "}
          <strong>Hospital Name:-</strong> {hospitalName}
        </div>
      );
    }
    if (
      this.state.notInDataBase &&
      this.state.notInDataBase.includes(barcode)
    ) {
      return (
        <div className="error-text">This barcode does not exist in system</div>
      );
    } else if (
      this.state.notDelivered &&
      this.state.notDelivered.includes(barcode)
    ) {
      return (
        <div className="error-text">
          This barcode has not been delivered yet
        </div>
      );
    } else if (
      this.state.alreadyPositive &&
      this.state.alreadyPositive.includes(barcode)
    ) {
      return (
        <div className="error-text">
          This barcode has already been updated as positive or negative
        </div>
      );
    } else if (
      this.state.invalidProductType?.includes(barcode)
    ) {
      return (
        <div className="error-text">
          You can not mark this product as positive or negative
        </div>
      );
    }
    else if (this.state.invalidFranchise?.includes(barcode)) {
      return (
        <div className="error-text">
          Invalid teamlead or franchise or sub franchise
        </div>
      );
    }
    else if (isError) {
      return <div className="error-text">Barcode must be 12 digit</div>;
    } else {
      return <></>;
    }
  };

  renderPositiveBarcodeDetails() {
    return this.state.positiveData.map((data, index) => {
      let showPositive = false;
      if (data.status) {
        showPositive =
          this.state.validBarcodes &&
          this.state.validBarcodes.includes(data.barcode);
      }
      return (
        <div className="barcode-details-row" key={index}>
          <Typography variant="body1" className="sr-no-details">
            {index < 9 ? `0${index + 1}` : index + 1}
          </Typography>
          <div
            key={this.state.key}
            style={{
              position: "relative",
              width: "-webkit-fill-available"
            }}
            className={data.barcode ? "" : "disabled"}
          >
            <TextField
              index={index}
              onEnding={(value, index) =>
                this.handleDuplicatePositiveBarcode(value, index)
              }
              value={data.barcode}
            />
            {this.renderErrorTextForPositive(data.barcode, data.isError)}
          </div>
          {showPositive ? (
            <Button
              variant="contained"
              disabled
              className="sample positive-sample"
            >
              +ve
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled
              className="sample unmarked-sample"
            >
              Unmarked
            </Button>
          )}
        </div>
      );
    });
  }

  render() {
    const { classes, onBack } = this.props;

    return (
      <Container
        style={{ margin: "20px auto" }}
        maxWidth="lg"
        className={classes && classes.Picker}
      >
        <Loader loading={this.state.isLoading} />
        <BackButtonTitleWeb buttonClick={() => onBack()} />
        <Box
          className="barcode-heading-container"
          style={{
            marginRight: `${this.state.positiveData.length > 9 ? "18px" : 0}`
          }}
        >
          <div className="heading-left">
            <Typography variant="body1" className="sr-no">
              Serial No
            </Typography>
            <Typography variant="body1" className="barcode-no">
              Barcode Number
            </Typography>
          </div>
          <div className="heading-right">
            <Typography variant="body1" className="sr-no status-heading">
              Status
            </Typography>
          </div>
        </Box>
        {this.state.isError && (
          <div className="error">
            Please fill one barcode details and marked as positive
          </div>
        )}
        <Box
          className="barcode-detail-container"
          style={{
            paddingRight: `${this.state.positiveData.length > 9 ? "10px" : 0}`
          }}
        >
          {this.renderPositiveBarcodeDetails()}
        </Box>
        <Box marginBottom={4} className="action-button">
          <Button
            className="scan-barcode"
            onClick={this.handleScanBarcode}
            disabled={
              this.state.positiveData.filter(item => item.barcode === "")
                .length === 0
            }
          >
            <img src={barcodeWebImage} />
            Scan Barcode
          </Button>
          <Box className="right-action-button">
            <Button
              className="add-more"
              variant="outlined"
              onClick={() => this.addInputField()}
            >
              <img src={plusWebImage} /> Add More
            </Button>
            <Button
              className="continue-button"
              onClick={() => this.checkAnyBarcodeFill()}
            >
              Save
            </Button>
          </Box>
        </Box>
        {this.state.showBarcodeModal && (
          <BarcodeScanModalWeb
            open={this.state.showBarcodeModal}
            duplicate={this.state.duplicate}
            duplicateBarcodeIndex={this.state.duplicateBarcodeIndex}
            selectedBarcode={this.state.selectedBarcode}
            selectedNumber={this.state.selectedBarcodeIndex}
            onRetake={() => this.onDeleteValue(this.state.selectedBarcodeIndex)}
            onClose={() =>
              this.state.duplicate
                ? this.onDeleteValue(this.state.selectedBarcodeIndex)
                : this.hideScanBarcodeModal()
            }
            onNext={(codeData, index) =>
              this.handleScanBarcodeNext(codeData, index)
            }
            stlPositiveOrNegative={true}
            scanApiForPositiveOrNegative={this.scanningIndividualBarcode}
            stlPositiveOrNegativeResponse={this.state.response}
          />
        )}
        <ReadyShipDialog
          message="Are you confirm to save?"
          open={this.state.openReadyToShipPositive}
          onClose={() => this.setState({ openReadyToShipPositive: false })}
          onSubmit={() => this.scanningPositiveProductApiCall()}
        />
        <SuccessModalWeb
          message="Your items have been marked positive"
          open={this.state.showSuccessModal}
          onClose={() => this.onCloseSuccessModal()}
        />
      </Container>
    );
  }
}

export default withStyles(SuperTeamsSamplesStyles)(SuperTeamsPositiveSamples);

interface STextField {
  itemValue: string;
}

interface IPropsTextField {
  index: number;
  onEnding: (itemValue: string, index: number) => void;
  value: string;
}
class TextField extends React.Component<IPropsTextField, STextField> {
  constructor(props: IPropsTextField) {
    super(props);
    this.state = {
      itemValue: ""
    };
  }

  componentDidMount() {
    this.setState({ itemValue: this.props.value });
  }

  render() {
    return (
      <>
        <input
          className={
            this.state.itemValue
              ? "barcode-number-details"
              : "barcode-number-details error-barcode"
          }
          value={this.state.itemValue}
          key={`label${this.props.index}`}
          maxLength={12}
          onChange={event => {
            this.setState({ itemValue: event.target.value.replace(/\D/g, "") });
          }}
          onBlur={() =>
            this.props.onEnding(this.state.itemValue, this.props.index)
          }
        />
      </>
    );
  }
}
// Customizable Area End
