Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.btnExampleTitle = "CLICK ME";

exports.profileApiMethod = "GET";
exports.profileApiContentType = "application/json";
exports.profileApiEndPoint = "account_block/accounts";
exports.hospitalListApiMethod = "GET";
exports.hospitalListContentType = "application/json";
exports.hospitalListApiEndPoint = "/bx_block_catalogue/hospitals";
exports.franchiseListApiMethod = "GET";
exports.franchiseListContentType = "application/json";
exports.franchiseListApiEndPoint =  "/account_block/account/web_account_listing";
exports.shipCollectedEndPoint = "/bx_block_catalogue/total_ship_collected";
exports.shipCollectedMethod = "GET";
exports.shipCollectedContentType = "application/json";
// Customizable Area End
