// Customizable Area Start
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Link,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TopNavStyles } from "./TopNav.web.styles";
import SideMenu from "./SideMenu.web";
import { UserRoles } from "./utils";

const menuImage = require("./assets/menu.svg");
const brandLogo = require("./assets/white-logo.svg");

interface ITopNavProps {
  classes: any;
  roles: string;
}

const TopNav: React.FC<ITopNavProps> = ({ classes, roles }) => {
  const [openSideMenu, setOpenSideMenu] = useState(false);

  return (
    <AppBar className={classes.Navigation} position="static">
      <Container maxWidth="lg" className="navigation-container">
        <Toolbar>
          <IconButton
            edge="end"
            color="inherit"
            className="menu-container"
            aria-label="open drawer"
            onClick={() => setOpenSideMenu(true)}
          >
            <img src={menuImage} alt="menu" />
          </IconButton>
          <Link
            href={
              roles === UserRoles.FRANCHISE || roles === UserRoles.SUB_FRANCHISE
                ? "/franchise-dashboard"
                : roles === UserRoles.TEAM_LEADER
                ? "/team-dashboard"
                : "super-team-dashboard"
            }
          >
            <Typography component="div" className="brand-logo-container" noWrap>
              <img src={brandLogo} alt="menu" />
            </Typography>
          </Link>
        </Toolbar>
      </Container>

      <SideMenu
        roles={roles}
        onClose={() => setOpenSideMenu(false)}
        open={openSideMenu}
      />
    </AppBar>
  );
};

export default withStyles(TopNavStyles)(TopNav);

// Customizable Area End
