// Customizable Area Start
import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
} from "@material-ui/core";

import SubFranchiseDetailController, {
  ActiveProduct,
  Props,
} from "./SubFranchiseDetailController.web";
import { DashboardWebStyles } from "./SubFranchiseDetailStyle.web";
import {
  downArrow,
  RightDarkArrowComponent,
  RightWhiteArrowComponent,
} from "../../assets";
import DateRangePickerComponent from "../../../../../components/src/DateRangePicker.web";
import Loader from "../../../../../components/src/Loader.web";
import BackButtonTitleWeb from "../../../../../components/src/BackButtonTitleWeb.web";
import NotFound from "../../../../../components/src/NotFound.web";

class SubFranchiseDetail extends SubFranchiseDetailController {
  constructor(props: Props) {
    super(props);
  }

  handleIndexValue(index: number) {
    return index < 9 ? `0${index + 1}` : index + 1;
  }

  renderSampleListInSubFranchise = () => {
    return (
      <Box className="items-inside-container">
        {this.state.subFranchiseSampleDetail.map((item) => (
          <Box
            className="outer-item-container"
            key={`${item.product_name}${item.sample_id}`}
          >
            <Box
              className={`item-container ${this.state
                .subFranchiseActiveSampleValue === item.sample_id && "active"}`}
            >
              <Box
                className={`text-container ${this.state
                  .subFranchiseActiveSampleValue === item.sample_id &&
                  "active"}`}
                onClick={() => this.setActiveSampleTabValue(item.sample_id)}
              >
                <Typography className="digit-heading" title={item.value}>
                  {item.value}
                </Typography>
                <Typography className="item-name" title={item.product_name}>
                  {item.product_name}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  renderProductDetails = () => {
    return (
      <Grid container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <Box
            className={`content-box 
            ${
              this.state.subFranchiseActiveProduct === ActiveProduct.COLLECTED
                ? "active"
                : ""
            }
            `}
            onClick={() => this.setActiveProduct(ActiveProduct.COLLECTED)}
          >
            <Typography variant="h1">
              {this.state.subFranchiseData.collected}
            </Typography>
            <Typography variant="body2">TOTAL COLLECTED</Typography>
          </Box>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Box
            className={`content-box ${
              this.state.subFranchiseActiveProduct === ActiveProduct.SHIPPED
                ? "active"
                : ""
            }`}
            onClick={() => this.setActiveProduct(ActiveProduct.SHIPPED)}
          >
            <Typography variant="h1">
              {this.state.subFranchiseData.shipped}
            </Typography>
            <Typography variant="body2">TOTAL SHIPPED</Typography>
          </Box>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Box
            className={`content-box ${
              this.state.subFranchiseActiveProduct === ActiveProduct.DELIVERED
                ? "active"
                : ""
            }`}
            onClick={() => this.setActiveProduct(ActiveProduct.DELIVERED)}
          >
            <Typography variant="h1">
              {this.state.subFranchiseData.delivered}
            </Typography>
            <Typography variant="body2">Total delivered</Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Container maxWidth="lg" style={{ margin: "20px auto" }}>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.SubFranchiseDetail}>
          <BackButtonTitleWeb
            buttonClick={() => this.props.navigation.goBack()}
          />
          <Box className="main-heading">
            <Typography variant="h2">Sub-Franchise Analytics</Typography>
            <Box className="right-side">
              <Button
                className="calenderButton"
                variant="contained"
                onClick={() => this.openOrCloseDateRangePicker(false)}
              >
                <span
                  className="calendar-text"
                  title={this.state.activeCalenderValue}
                >
                  {this.state.activeCalenderValue}
                </span>
                <img src={downArrow} alt="arrow" />
              </Button>
              {this.state.openDateRange && (
                <DateRangePickerComponent
                  activeCalenderValue={this.state.activeCalenderValue}
                  range={this.state.range}
                  closeDatePicker={(range: any, name: string) => {
                    this.setState({ range, activeCalenderValue: name }, () => {
                      this.openOrCloseDateRangePicker(true);
                    });
                  }}
                />
              )}
            </Box>
          </Box>
          <Box margin="20px 0">
            <Grid container spacing={3}>
              <Grid item md={4} sm={6} xs={12}>
                <Box className="content-box-item">
                  <Typography variant="h1">
                    {this.state.subFranchiseTotalData.collected}
                  </Typography>
                  <Typography variant="body2">Collected</Typography>
                </Box>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Box className="content-box-item">
                  <Typography variant="h1">
                    {this.state.subFranchiseTotalData.shipped}
                  </Typography>
                  <Typography variant="body2">Shipped</Typography>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box className="content-box-item">
                  <Typography variant="h1">
                    {this.state.subFranchiseTotalData.delivered}
                  </Typography>
                  <Typography variant="body2">Delivered</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <div className="sub-franchise-details">
            {this.state.subFranchiseList.length !== 0 ? (
              this.state.subFranchiseList.map((detail, index) => (
                <Accordion
                  expanded={detail.id === this.state.handleSubFranchiseExpand}
                  key={`${detail.first_name}${detail.city}${detail.id}${index}`}
                  //@ts-ignore
                  onChange={this.handleSubFranchiseChange(detail)}
                >
                  <AccordionSummary
                    expandIcon={
                      <>
                        <RightDarkArrowComponent className="dark-color" />{" "}
                        <RightWhiteArrowComponent className="white-color" />
                      </>
                    }
                    aria-controls="panel1a-content"
                  >
                    <Typography className="accordion-heading">
                      <span className="digit">
                        {this.handleIndexValue(index)}
                      </span>{" "}
                      {`${detail.first_name}, ${detail.city}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="summary-details">
                      {this.renderProductDetails()}
                      {this.state.subFranchiseSampleDetail.length !== 0 && (
                        <>
                          {this.renderSampleListInSubFranchise()}
                          {this.state.subFranchiseHospitalDetail && (
                            <Box className="product-details">
                              <Grid container>
                                <Grid item md={12} sm={12} xs={12}>
                                  <Grid container className="left-container">
                                    <Grid item md={3} sm={3}>
                                      <Typography className="data-heading text-align-center">
                                        Serial no.
                                      </Typography>
                                    </Grid>
                                    <Grid item md={7} sm={7}>
                                      <Typography className="data-heading text-align-center">
                                        Hospital Name
                                      </Typography>
                                    </Grid>
                                    <Grid item md={2} sm={2}>
                                      <Typography className="data-heading text-align-center">
                                        Barcode Qty.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  {this.state.subFranchiseHospitalDetail
                                    .length !== 0 ? (
                                    this.state.subFranchiseHospitalDetail.map(
                                      (hospital_detail, index) => (
                                        <Grid
                                          container
                                          className="row-spacing"
                                          key={`${hospital_detail.hospital}`}
                                        >
                                          <Grid item md={3} sm={3}>
                                            <Typography className="data-detail text-align-center">
                                              {this.handleIndexValue(index)}
                                            </Typography>
                                          </Grid>
                                          <Grid item md={7} sm={7}>
                                            <Typography className="data-detail text-align-center">
                                              {hospital_detail.hospital}
                                            </Typography>
                                          </Grid>
                                          <Grid item md={2} sm={2}>
                                            <Typography className="data-detail text-align-center">
                                              {hospital_detail.count}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      )
                                    )
                                  ) : (
                                    <div className="not-found-tex">
                                      No Hospital allocated
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <NotFound message="No sub-franchise found" />
            )}
          </div>
        </Box>
      </Container>
      //Merge Engsine End DefaultContainer
    );
  }
}

export default withStyles(DashboardWebStyles)(SubFranchiseDetail);
// Customizable Area End
