// Customizable Area Start
export const SuperTeamsSamplesStyles = {
  Picker: {
    "& .MuiTabs-fixed::-webkit-scrollbar": {
      width: "8px",
      height: "4px"
    },
    "& .MuiTabs-fixed::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#e3e9eb"
    },
    "& .MuiTabs-fixed::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "#004d64"
    },
    "& .barcode-heading-container": {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      marginTop: "20px",
      marginBottom: "15px"
    },
    "& .heading-left": {
      background: "#004d64",
      borderRadius: "4px",
      display: "flex",
      width: "-webkit-fill-available"
    },
    "& .sr-no": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "156px",
      height: "40px",
      borderRadius: "4px",
      background: "#004d64",
      backgroundBlendMode: "normal",
      color: "#ffffff",
      fontSize: "18px",
      fontWeight: "700",
      fontFamily: "NotoSans-Regular"
    },
    "& .barcode-no": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "-webkit-fill-available",
      height: "40px",
      borderRadius: "4px",
      background: "#004d64",
      backgroundBlendMode: "normal",
      color: "#ffffff",
      fontSize: "18px",
      fontWeight: "700",
      fontFamily: "NotoSans-Regular"
    },
    "& .barcode-detail-container": {
      overflow: "auto",
      maxHeight: "80vh",
      marginBottom: "25px"
    },
    "& .barcode-detail-container::-webkit-scrollbar": {
      width: "8px",
      height: "4px"
    },
    "& .barcode-detail-container::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#e3e9eb"
    },
    "& .barcode-detail-container::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "#004d64"
    },
    "& .barcode-details-row": {
      display: "flex",
      margin: "15px 0 15px",
      gap: "20px"
    },
    "& .barcode-details-row:first-child": {
      margin: "0px 0 15px"
    },
    "& .barcode-details-row:last-child": {
      margin: "15px 0 0"
    },
    "& .sr-no-details ,& .barcode-number-details": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "156px",
      height: "40px",
      borderRadius: "4px",
      background: "#ffffff",
      border: "1px solid #004d64",
      backgroundBlendMode: "normal",
      color: "#004d64",
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "NotoSans-Regular",
      boxSizing: "border-box"
    },
    "& .status-heading": {
      minWidth: "269px"
    },
    "&  .barcode-number-details": {
      width: "-webkit-fill-available",
      textAlign: "center"
    },
    "& .barcode-number-details:focus-visible": {
      outline: "none"
    },
    "& .scan-barcode": {
      width: "275px",
      height: "64px",
      borderRadius: "4px",
      background: "#004d64",
      backgroundBlendMode: "normal",
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      "& img": {
        height: "24px",
        width: "24px"
      },
      "& .MuiButton-label": {
        gap: "10px",
        textTransform: "none"
      }
    },
    "& .delete-image": {
      position: "absolute",
      fill: "#004D64",
      top: "0",
      right: "10px",
      bottom: "0",
      height: "40px",
      width: "40px",
      cursor: "pointer"
    },
    "& .disabled .delete-image": {
      fill: "#A0A0A0"
    },
    "& .action-button": {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px"
    },
    "& .right-action-button": {
      display: "flex",
      gap: "20px"
    },
    "& .add-more": {
      width: "181px",
      height: "64px",
      borderRadius: "4px",
      backgroundBlendMode: "normal",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      boxSizing: "border-box",
      border: "1px solid #004d64",
      "& img": {
        height: "24px",
        width: "24px"
      },
      "& .MuiButton-label": {
        gap: "10px",
        textTransform: "none",
        whiteSpace: "nowrap"
      }
    },
    "& .continue-button": {
      width: "181px",
      height: "64px",
      borderRadius: "4px",
      backgroundBlendMode: "normal",
      background: "#004d64",
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      boxSizing: "1px solid #004d64",
      "& .MuiButton-label": {
        gap: "10px",
        textTransform: "none"
      }
    },
    "& .MuiTabs-indicator": {
      display: "none"
    },
    "& .sample": {
      height: "40px",
      borderRadius: "4px",
      minWidth: "269px",
      backgroundBlendMode: "normal",
      boxShadow: "none",
      cursor: "default",
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-SemiBold",
        fontSize: "18px",
        fontWeight: "400",
        textTransform: "none"
      }
    },
    "& .negative-sample": {
      background: "#e82500"
    },
    "& .unmarked-sample": {
      background: "#A0A0A0"
    },
    "& .positive-sample": {
      background: "#36B060"
    },
    "& .error": {
      color: "#E30000",
      fontFamily: "NotoSans-Regular",
      fontSize: "13px",
      fontWeight: "300",
      marginBottom: "15px"
    },
    "& .error-text": {
      color: "#E30000",
      fontFamily: "NotoSans-Regular",
      fontSize: "13px",
      fontWeight: "300"
    },
    "& .success-text": {
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "13px",
      fontWeight: "300"
    }
  }
};
// Customizable Area End
