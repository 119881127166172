// Customizable Area Start
import React from "react";

export const backArrowImage = require("../assets/web/back-arrow.png");
export const searchImage = require("../assets/web/magnify.png");
export const cancelSearchImage = require("../assets/web/crossImage.svg");
export const minusWebImage = require("../assets/web/minus_web.png");
export const plusWebImage = require("../assets/web/plus_web.svg");
export const plusImage = require("../assets/web/plus_image_web.png");
export const barcodeWebImage = require("../assets/web/barcode_white_web.png");
export const barcodeBlackImage = require("../assets/web/barcode_black_web.png");
export const afpfWebImage = require("../assets/web/af_pf_web.png");
export const cancerWebImage = require("../assets/web/cancer_fluid_web.png");
export const cordBloodWebImage = require("../assets/web/cord_blood_web.png");
export const hbsagWebImage = require("../assets/web/hbsag_web.png");
export const urineWebImage = require("../assets/web/urine_web.png");
export const editWebImage = require("../assets/web/edit_web.png");
export const redDotWebImage = require("../assets/web/red_dot_web.png");
export const greenDotWebImage = require("../assets/web/green_dot_web.png");
export const notFoundImageWeb = require("../assets/web/not_found_web.jpg");

export const DeleteImageComponent = ({ height = 21, width = 20, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="deleteon">
        <g id="fluentdelete-16-filled" transform="translate(8 8)">
          <path
            d="M10.5 4.5L13.5 4.5C13.5 3.67157 12.8284 3 12 3C11.1716 3 10.5 3.67157 10.5 4.5Z"
            id="Path"
          />
          <path
            d="M9 4.5C9 2.84315 10.3431 1.5 12 1.5C13.6569 1.5 15 2.84315 15 4.5L21 4.5C21.4142 4.5 21.75 4.83579 21.75 5.25C21.75 5.66421 21.4142 6 21 6L20.154 6L18.3465 19.257C18.093 21.1149 16.5061 22.5 14.631 22.5L9.369 22.5C7.49389 22.5 5.90702 21.1149 5.6535 19.257L3.846 6L3 6C2.58579 6 2.25 5.66421 2.25 5.25C2.25 4.83579 2.58579 4.5 3 4.5L9 4.5Z"
            id="Path"
          />
          <path
            d="M10.5 9.75C10.5 9.33579 10.1642 9 9.75 9C9.33579 9 9 9.33579 9 9.75L9 17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25L10.5 9.75Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M14.25 9C13.8358 9 13.5 9.33579 13.5 9.75L13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25L15 9.75C15 9.33579 14.6642 9 14.25 9Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
};

export const RightDarkArrowComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="IconlyLightArrow---Right-2-Copy"
        transform="matrix(1.1924881E-08 1 -1 1.1924881E-08 24 0)"
      >
        <path
          d="M0 24L-1.04907e-06 0L24 -1.90735e-06L24 24L0 24Z"
          id="IconlyLightArrow---Right-2-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g
          id="IconlyLightArrow---Right-2"
          transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 24)"
        >
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="IconlyLightArrow---Right-2-(Background)"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g id="Arrow---Right-2" transform="translate(8.5 5)">
            <path
              d="M1.35122e-07 0L7 7L-4.76837e-07 14"
              id="Stroke-1"
              fill="none"
              fillRule="evenodd"
              stroke="#004D64"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const RightWhiteArrowComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="IconlyLightArrow---Right-2-Copy"
        transform="matrix(1.1924881E-08 1 -1 1.1924881E-08 24 0)"
      >
        <path
          d="M0 24L-1.04907e-06 0L24 -1.90735e-06L24 24L0 24Z"
          id="IconlyLightArrow---Right-2-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g
          id="IconlyLightArrow---Right-2"
          transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 24)"
        >
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="IconlyLightArrow---Right-2-(Background)"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g id="Arrow---Right-2" transform="translate(8.5 5)">
            <path
              d="M1.35122e-07 0L7 7L-4.76837e-07 14"
              id="Stroke-1"
              fill="none"
              fillRule="evenodd"
              stroke="#ffffff"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export const SmallCrossImageComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 33.941406 33.941162"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Group-2-Copy"
        transform="matrix(0.70710677 -0.70710677 0.70710677 0.70710677 0 16.970581)"
      >
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          id="Oval"
          fill="#004C63"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M3.13043 3.13043L20.8696 3.13043L20.8696 20.8696L3.13043 20.8696L3.13043 3.13043Z"
          id="ViewBox"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M12.78 12.7791L17.5293 12.7842C17.9629 12.7842 18.3145 12.4327 18.3145 11.9991C18.3145 11.5655 17.9631 11.214 17.5295 11.214L12.7801 11.2199L12.786 6.47054C12.786 6.03694 12.4345 5.68546 12.0009 5.68549C11.5673 5.68552 11.2158 6.03705 11.2158 6.47065L11.2209 11.22L6.4716 11.2148C6.26311 11.214 6.06292 11.2964 5.91548 11.4439C5.76805 11.5913 5.6856 11.7915 5.68643 12C5.68557 12.2085 5.76799 12.4086 5.9154 12.5561C6.06282 12.7035 6.263 12.7859 6.47149 12.785L11.2208 12.7792L11.215 17.5285C11.2141 17.737 11.2965 17.9372 11.4439 18.0846C11.5914 18.232 11.7915 18.3144 12 18.3136C12.2085 18.3144 12.4087 18.2319 12.5561 18.0845C12.7036 17.9371 12.786 17.7369 12.7852 17.5284L12.78 12.7791Z"
          id="Path"
          fill="#FFFFFF"
          stroke="none"
        />
      </g>
    </svg>
  );
};

// Customizable Area End
