// Customizable Area Start
export const FranchiseProductScanningStyles = {
  Product: {
    "& .MuiTabs-fixed::-webkit-scrollbar": {
      width: "8px",
      height: "4px",
    },
    "& .MuiTabs-fixed::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#e3e9eb",
    },
    "& .MuiTabs-fixed::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "#004d64",
    },
    "& .page-heading": {
      color: "#004D64",
      fontFamily: "NotoSans-Regular",
      fontSize: "30px",
      fontWeight: "700",
      lineHeight: "41px",
      margin: "10px 0",
    },
    "& .tab-heading": {
      color: "#004D64",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "29px",
      minWidth: "170px",
    },
    "& .nav-tab-container": {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      "& .MuiTab-textColorPrimary.Mui-selected": {
        background: "#004d64",
        backgroundBlendMode: "normal",
        color: "#ffffff",
        fontWeight: "700",
      },
      "& .MuiTabs-fixed": {
        overflowX: "auto !important",
      },
    },
    "& .PrivateTabIndicator-colorPrimary-11": {
      height: 0,
      backgroundColor: "transparent",
    },
    "& .MuiTab-root": {
      minWidth: "207px",
      height: "64px",
      borderRadius: "4px",
      background: "#baefff",
      backgroundBlendMode: "normal",
      color: "#004d64",
    },
    "& .MuiTabs-flexContainer": {
      gap: "20px",
      paddingBottom: "5px",
    },
    "& .MuiTab-wrapper": {
      fontFamily: "NotoSans-Regular",
      fontSize: "20px",
      fontWeight: "400",
      textAlign: "center",
      textTransform: "capitalize",
    },
    "& .PrivateTabIndicator-colorPrimary-10": {
      height: 0,
    },
    "& .barcode-heading-container": {
      display: "flex",
      alignItems: "center",
      margin: "25px 0 0px",
      gap: "20px",
    },
    "& .sr-no": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "207px",
      height: "40px",
      borderRadius: "4px",
      background: "#004d64",
      backgroundBlendMode: "normal",
      color: "#ffffff",
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "NotoSans-Regular",
    },
    "& .barcode-no": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "40px",
      borderRadius: "4px",
      background: "#004d64",
      backgroundBlendMode: "normal",
      color: "#ffffff",
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "NotoSans-Regular",
    },
    "& .barcode-detail-container": {
      overflow: "auto",
      maxHeight: "80vh",
      marginBottom: "25px",
      marginTop: "20px",
    },
    "& .barcode-detail-container::-webkit-scrollbar": {
      width: "8px",
      height: "4px",
    },
    "& .barcode-detail-container::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#e3e9eb",
    },
    "& .barcode-detail-container::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "#004d64",
    },
    "& .barcode-details-row": {
      display: "flex",
      margin: "0px 0 20px",
      gap: "20px",
    },
    "& .error-text": {
      color: "#E30000",
      fontFamily: "NotoSans-Regular",
      fontSize: "13px",
      fontWeight: "300",
    },
    "& .barcode-details-row:last-child": {
      margin: "0",
    },
    "& .sr-no-details ,& .barcode-number-details": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "207px",
      height: "40px",
      borderRadius: "4px",
      background: "#ffffff",
      border: "1px solid #004d64",
      backgroundBlendMode: "normal",
      color: "#004d64",
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "NotoSans-Regular",
      boxSizing: "border-box",
    },
    "&  .barcode-number-details": {
      width: "100%",
      textAlign: "center",
    },
    "& .barcode-number-details:focus-visible": {
      outline: "none",
    },
    "& .scan-barcode": {
      width: "275px",
      height: "64px",
      borderRadius: "4px",
      background: "#004d64",
      backgroundBlendMode: "normal",
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      "& img": {
        height: "24px",
        width: "24px",
      },
      "& .MuiButton-label": {
        gap: "10px",
        textTransform: "none",
      },
    },
    "& .delete-image": {
      position: "absolute",
      fill: "#004D64",
      top: "0",
      right: "10px",
      bottom: "0",
      height: "40px",
      width: "40px",
      cursor: "pointer",
    },
    "& .disabled .delete-image": {
      fill: "#A0A0A0",
    },
    "& .action-button": {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
    },
    "& .right-action-button": {
      display: "flex",
      gap: "20px",
    },
    "& .add-more": {
      width: "181px",
      height: "64px",
      borderRadius: "4px",
      backgroundBlendMode: "normal",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      boxSizing: "border-box",
      border: "1px solid #004d64",
      "& img": {
        height: "32px",
        width: "32px",
      },
      "& .MuiButton-label": {
        textTransform: "none",
        whiteSpace: "nowrap",
      },
    },
    "& .continue-button": {
      width: "181px",
      height: "64px",
      borderRadius: "4px",
      backgroundBlendMode: "normal",
      background: "#004d64",
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      boxSizing: "1px solid #004d64",
      "& .MuiButton-label": {
        gap: "10px",
        textTransform: "none",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .error": {
      color: "#E30000",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "300",
      marginTop: "20px",
    },
    "& .error-barcode": {
      border: "1px solid #E30000",
    },
  },
  DialogBarcode: {
    "& .close-image": {
      height: 45,
      width: 45,
      cursor: "pointer",
      position: "absolute",
      right: "8px",
      top: "2px",
      zIndex: "2",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "11px",
      maxWidth: "475px",
      position: "relative",
    },
    "& .barcode-image-container": {
      padding: "40px 40px 20px",
      textAlign: "center",
      "& img": {
        width: "238px",
        height: "189px",
      },
    },
    "& .barcode-number": {
      background: "#e5f9ff",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "22px",
      margin: "15px auto",
      padding: "10px 20px",
      width: "70%",
    },
    "& .details-show": {
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "33px",
      textAlign: "center",
    },
    "& .barcode-actions": {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
      marginTop: "25px",
      alignItems: "center",
      "& button": {
        height: "64px",
        borderRadius: "8px",
        background: "#004d64",
        color: "#ffffff",
        textTransform: "none",
        width: "50%",
        "& .MuiButton-label": {
          color: "#ffffff",
          fontFamily: "NotoSans-Regular",
          fontSize: "20px",
          fontWeight: "700",
        },
      },
    },
    "& .duplicate-barcode-error": {
      color: "#4A5056",
      fontFamily: "NotoSans-Regular",
      fontSize: "13px",
      fontWeight: "300",
      lineHeight: "18px",
      textAlign: "center",
    },
  },
};
// Customizable Area End
