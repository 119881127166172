// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import {
  userDetailsFromStorage,
  userRedirectToLoginPage
} from "../../../../../components/src/utils";
import { toast } from "react-toastify";

export const configJSON = require("../../config.js");

export interface IProps {
  onBack: () => void;
  classes: any;
  navigation: any;
}

interface S {
  scanningData: {
    barcode: string;
    isError: boolean;
  }[];
  selectedBarcodeIndex: number;
  selectedBarcode: string;
  duplicate: boolean;
  showBarcodeModal: boolean;
  duplicateBarcodeIndex: number;
  key: number;
  showSuccessModal: boolean;
  isError: boolean;
  accessToken: string;
  userId: string;
  isLoading: boolean;
  notInDataBase: string[];
  notShipped: string[];
  alreadyReceived: string[];
  barcodeNotInFranchise: string[];
  openReadyToDelivered: boolean;
  scannedBarcode: string;
  validBarcodes: string[];
  stlResponse: any;
  successData: {
    product_name: string;
    hospital_name: string;
    barcode_number: string;
    pickup_date: Date;
  }[];
}

interface SS {
  id: any;
}

export default class SuperTeamDeliverItems extends BlockComponent<
  IProps,
  S,
  SS
> {
  scanningApiCallId: string = "";
  stlScanningApiCallId: string = "";

  constructor(props: IProps) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      scanningData: Array.from({ length: Math.abs(10) }, _ => ({
        barcode: "",
        isError: false
      })),
      selectedBarcodeIndex: -1,
      selectedBarcode: "",
      duplicate: false,
      showBarcodeModal: false,
      duplicateBarcodeIndex: -1,
      key: 0,
      showSuccessModal: false,
      isError: false,
      accessToken: "",
      userId: "",
      isLoading: false,
      notInDataBase: [],
      notShipped: [],
      alreadyReceived: [],
      barcodeNotInFranchise: [],
      openReadyToDelivered: false,
      scannedBarcode: "",
      validBarcodes: [],
      stlResponse: [],
      successData: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    window.scroll(0, 0);
    const data = userDetailsFromStorage();
    if (data && data.attributes && data.id) {
      this.setState({
        accessToken: data.attributes.token,
        userId: data.id
      });
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
  }

  handleDuplicateBarcode = (value: string, index: number) => {
    const barcode = value.replace(/\D/g, "");
    const { scanningData } = this.state;
    if (barcode.length === 12) {
      if (value) {
        const duplicateData = scanningData.some(item => item.barcode === value);
        if (duplicateData) {
          const parentDuplicateIndex = scanningData.findIndex(
            item => item.barcode === value
          );
          if (parentDuplicateIndex !== index) {
            this.setState({
              selectedBarcodeIndex: index,
              selectedBarcode: value,
              duplicateBarcodeIndex: parentDuplicateIndex,
              duplicate: true,
              showBarcodeModal: true
            });
            return true;
          }
        }
      }
    }
    const oneData = scanningData[index];
    oneData.barcode = barcode;
    oneData.isError = barcode.length > 0 && barcode.length < 12;
    scanningData.splice(index, 1, oneData);
    this.setState(
      {
        scanningData
      },
      () => {
        if (barcode.length === 12 && !this.state.duplicate) {
          this.stlScanningProductApiCall(barcode);
        }
      }
    );
    return false;
  };

  handleBlurFocusWithIndex = (index: number) => {
    const barcodeClass = document.querySelectorAll(".barcode-number-details");
    if (barcodeClass) {
      const singleClass = barcodeClass[index];
      if (singleClass) {
        //@ts-ignore
        singleClass.blur();
      }
    }
  };

  onDeleteValue = (index: number) => {
    const { scanningData } = this.state;
    const oneData = scanningData[index];
    oneData.barcode = "";
    oneData.isError = false;
    scanningData.splice(index, 1, oneData);
    this.setState(
      {
        scanningData,
        showBarcodeModal: false,
        duplicate: false,
        selectedBarcode: "",
        key: Number(new Date())
      },
      async () => {
        await this.handleAutoFocusWithIndex(index);
      }
    );
  };

  handleAutoFocusWithIndex = async (index: number) => {
    if (index !== -1) {
      const barcodeClass = document.querySelectorAll(".barcode-number-details");
      if (barcodeClass) {
        const singleClass = barcodeClass[index];
        if (singleClass) {
          //@ts-ignore
          singleClass.focus();
          singleClass.scrollIntoView({
            block: "nearest",
            inline: "center"
          });
        }
      }
    }
  };

  handleScanBarcode = () => {
    const { scanningData } = this.state;
    const index = scanningData.findIndex(item => {
      return item.barcode === "";
    });
    this.setState({
      selectedBarcodeIndex: index,
      selectedBarcode: "",
      showBarcodeModal: true,
      key: Number(new Date())
    });
  };

  handleScanBarcodeNext = (barcode: string, index: number) => {
    const { scanningData } = this.state;
    if (!this.handleDuplicateBarcode(barcode, index)) {
      const oneData = scanningData[index];
      oneData.barcode = barcode;
      scanningData.splice(index, 1, oneData);
      this.setState({
        scanningData,
        selectedBarcodeIndex: 0,
        selectedBarcode: "",
        showBarcodeModal: false,
        duplicate: false,
        key: Number(new Date())
      });
    }
  };

  hideScanBarcodeModal = () => {
    this.setState({
      showBarcodeModal: false
    });
  };

  addInputField = () => {
    const { scanningData } = this.state;
    const list = [
      ...scanningData,
      ...Array.from({ length: Math.abs(10) }, _ => ({
        barcode: "",
        isError: false
      }))
    ];
    this.setState({
      scanningData: list
    });
  };

  checkAnyBarcodeFill = () => {
    const { scanningData } = this.state;
    const filledData = scanningData.filter(item => item.barcode !== "");
    if (filledData.length !== 0) {
      const empty = filledData.filter(
        item =>
          !item.isError &&
          !this.state.notInDataBase.includes(item.barcode) &&
          !this.state.notShipped.includes(item.barcode) &&
          !this.state.alreadyReceived.includes(item.barcode) &&
          !this.state.barcodeNotInFranchise.includes(item.barcode)
      );
      if (filledData.length === empty.length) {
        this.setState({
          openReadyToDelivered: true
        });
        return true;
      }
    }
    const emptyBarcodeIndex = scanningData.findIndex(
      item =>
        item.barcode === "" ||
        item.isError ||
        this.state.notInDataBase.includes(item.barcode) ||
        this.state.notShipped.includes(item.barcode) ||
        this.state.alreadyReceived.includes(item.barcode) ||
        this.state.barcodeNotInFranchise.includes(item.barcode)
    );
    const barcodeClass = document.querySelectorAll(".barcode-number-details");
    if (barcodeClass) {
      const singleClass = barcodeClass[emptyBarcodeIndex];
      if (singleClass) {
        //@ts-ignore
        singleClass.focus();
        singleClass.scrollIntoView({
          block: "nearest",
          inline: "center"
        });
      }
    }
    this.setState({
      isError: filledData.length === 0 ? true : false
    });
  };

  onCloseSuccessModal = () => {
    this.setState({
      showSuccessModal: false
    });
    this.props.onBack();
  };

  scanningProductApiCall = () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.scanningProductContentType
    };

    const httpBody = {
      barcode: this.state.scanningData
        .filter(item => item.barcode !== "")
        .map(item => item.barcode)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.scanningApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.scanningProductApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.scanningProductMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  stlScanningProductApiCall = (barcode: string) => {
    this.setState({ scannedBarcode: barcode});

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.stlScanningProductContentType
    };

    const httpBody = {
      barcode: [barcode]
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.stlScanningApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stlScanningProductApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.stlScanningProductMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleErrorMessage = (error: any) => {
    const {
      notInDataBase,
      notShipped,
      alreadyReceived,
      barcodeNotInFranchise
    } = this.state;
    const newNotInDataBase = [
      ...notInDataBase,
      ...(error && error.not_in_database ? error.not_in_database : [])
    ];
    const newNotShipped = [
      ...notShipped,
      ...(error && error.not_shipped ? error.not_shipped : [])
    ];

    const newAlreadyReceived = [
      ...alreadyReceived,
      ...(error && error.already_used ? error.already_used : [])
    ];

    const newNotInFranchise = [
      ...barcodeNotInFranchise,
      ...(error && error.barcodes ? error.barcodes : [])
    ];

    this.setState({
      notInDataBase: newNotInDataBase,
      notShipped: newNotShipped,
      alreadyReceived: newAlreadyReceived,
      barcodeNotInFranchise: newNotInFranchise,
      openReadyToDelivered: false
    });
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else if (error.message) {
      this.handleErrorMessage(error);
    }
    if (error && error.barcodes) {
      toast.error(error.message);
    } else if (
      (error && error.not_in_database) ||
      (error && error.already_used) ||
      (error && error.not_shipped)
    ) {
      toast.error(error.message);
    } else {
      toast.error("Something went to wrong please retry");
    }
  };

  handleValidBarcode = (barcode: string, response: any) => {
    const newData = {
      product_name: response.data.product_name,
      hospital_name: response.data.hospital_name,
      barcode_number: response.data.barcode_number,
      pickup_date: response.data.pickup_date
    };
    this.setState(prevState => ({
      validBarcodes: [...prevState.validBarcodes, barcode],
      successData: [...prevState.successData, newData]
    }));
    toast.success("Sample Scanned Successfully!!");
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        this.handleError(responseJson.error);
        this.setState({
          stlResponse: responseJson
        });
      } else if (apiRequestCallId && responseJson && !responseJson.error) {
        if (
          apiRequestCallId === this.scanningApiCallId &&
          responseJson.success &&
          responseJson.message
        ) {
          this.setState({
            openReadyToDelivered: false,
            showSuccessModal: true
          });
        } else if (
          apiRequestCallId === this.stlScanningApiCallId &&
          responseJson.success &&
          responseJson.message
        ) {
          this.handleValidBarcode(this.state.scannedBarcode, responseJson);
          this.setState({
            stlResponse: responseJson
          });
        }
      } else {
        this.setState({ openReadyToDelivered: false });
        toast.error("Something went to wrong please retry");
      }
      this.setState({
        isLoading: false
      });
    }
  }
}
// Customizable Area End
