// Customizable Area Start
import React from "react";
import { Box, Container, withStyles } from "@material-ui/core";

import FranchiseShipCollectedItemsWebController, {
  Props,
} from "./FranchiseShipCollectedItemsController.web";
import { FranchiseShipCollectedItemsStyles } from "./FranchiseShipCollectedItemsStyles.web";

import DashboardBanner from "../../../../../components/src/DashboardBanner.web";
import BackButtonTitleWeb from "../../../../../components/src/BackButtonTitleWeb.web";
import FranchiseAddShipItems from "./FranchiseAddShipItems.web";
import Loader from "../../../../../components/src/Loader.web";
import ReadyShipDialog from "../../../../../components/src/ReadyShipDialog.web";
import SuccessModal from "../../../../../components/src/SuccessModalWeb";

class FranchiseShipCollectedItems extends FranchiseShipCollectedItemsWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, navigation } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <Loader loading={this.state.isLoading} />
        <Container
          maxWidth="lg"
          className={classes.Ship}
          style={{ margin: "20px auto" }}
        >
          <BackButtonTitleWeb
            buttonClick={() =>
              this.state.showSelectHospital
                ? this.hideHospitalList()
                : navigation.goBack()
            }
            title={`Please Select ${
              this.state.showSelectHospital ? "Hospital" : "Product"
            }`}
          />
          <Box marginBottom={4}>
            {!this.state.showSelectHospital &&
              this.state.sampleList.map((item) => (
                <DashboardBanner
                  key={item.id}
                  src={item.imageSrc}
                  bannerText={item.label}
                  buttonText="Click to Add"
                  buttonClick={() => this.updateShowHospitalValue(item.id)}
                />
              ))}
            {this.state.showSelectHospital && (
              <FranchiseAddShipItems
                expandedPanel={this.state.expandedPanel}
                handleChange={this.handleChange}
                handleContinue={this.handleContinue}
                sampleId={this.state.sampleId}
                shipItemData={this.state.shipItemData}
                onChangeServiceProviderName={(index, value) =>
                  this.onChangeServiceProviderName(index, value)
                }
                addGridRecord={this.addGridRecord}
                docketNoChangeHandler={(index, docketIndex, value) =>
                  this.docketNoChangeHandler(index, docketIndex, value)
                }
                deleteParameter={this.deleteParameter}
                handleSave={(index) => this.handleSave(index)}
              />
            )}
          </Box>
          <ReadyShipDialog
            message="Are you ready to ship items now?"
            open={this.state.openReadyToShip}
            onClose={() =>
              this.setState({
                openReadyToShip: false,
              })
            }
            onSubmit={() => this.onSubmitReadyToShipData()}
          />
          <SuccessModal
            message="Your scanned items have been marked shipped"
            open={this.state.openSuccessModal}
            onClose={() => this.onSuccessButtonClick()}
          />
        </Container>
      </>
      //Merge Engsine End DefaultContainer
    );
  }
}

export default withStyles(FranchiseShipCollectedItemsStyles)(
  FranchiseShipCollectedItems
);
// Customizable Area End
