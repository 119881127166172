// Customizable Area Start
import React from "react";

import { Box } from "@material-ui/core";

const DeleteImageComponent = ({ height = 21, width = 20, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="deleteon">
        <g id="fluentdelete-16-filled" transform="translate(8 8)">
          <path
            d="M10.5 4.5L13.5 4.5C13.5 3.67157 12.8284 3 12 3C11.1716 3 10.5 3.67157 10.5 4.5Z"
            id="Path"
          />
          <path
            d="M9 4.5C9 2.84315 10.3431 1.5 12 1.5C13.6569 1.5 15 2.84315 15 4.5L21 4.5C21.4142 4.5 21.75 4.83579 21.75 5.25C21.75 5.66421 21.4142 6 21 6L20.154 6L18.3465 19.257C18.093 21.1149 16.5061 22.5 14.631 22.5L9.369 22.5C7.49389 22.5 5.90702 21.1149 5.6535 19.257L3.846 6L3 6C2.58579 6 2.25 5.66421 2.25 5.25C2.25 4.83579 2.58579 4.5 3 4.5L9 4.5Z"
            id="Path"
          />
          <path
            d="M10.5 9.75C10.5 9.33579 10.1642 9 9.75 9C9.33579 9 9 9.33579 9 9.75L9 17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25L10.5 9.75Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M14.25 9C13.8358 9 13.5 9.33579 13.5 9.75L13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25L15 9.75C15 9.33579 14.6642 9 14.25 9Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
};
interface IProps {
  value: string;
  onEnding: (itemValue: string, index: number) => void;
  index: number;
  deleteBarCode: (index: number) => void; 
  setSample: () => void;
  isError: boolean;
  notInDataBase: string[];
  alreadyUsed: string[];
  collected: string[];
  validBarcodes?: string[];
}
interface S {
  itemValue: string;
}

export default class TextField extends React.Component<IProps, S> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      itemValue: "",
    };
  }

  componentDidMount() {
    this.setState({
      itemValue: this.props.value,
    });
  }

  renderProductError = (barcode: string, isError: boolean) => {
    if (this.props.notInDataBase.includes(barcode)) {
      return <div className="error-text">This barcode does not exist in system</div>;
    } else if (this.props.alreadyUsed.includes(barcode)) {
      return <div className="error-text">This barcode has already been received</div>;
    } else if (this.props.collected.includes(barcode)) {
      return <div className="error-text">This barcode has already been collected</div>;
    } else if (isError) {
      return <div className="error-text">Barcode must be 12 digit</div>;
    } else {
      return <></>;
    }
  };

  render() {
    const isBarcodeValid = this.state.itemValue !== '' && this.props.validBarcodes &&  this.props.validBarcodes.includes(this.state.itemValue);
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
        className={this.state.itemValue ? "" : "disabled"}
        onClick={() => this.props.setSample()}
      >
        <input
          className={
            this.state.itemValue
              ? "barcode-number-details"
              : "barcode-number-details error-barcode"
          }
          value={this.state.itemValue}
          key={`label${this.props.index}`}
          maxLength={12}
          onChange={(event) => {
            this.setState({
              itemValue: event.target.value.replace(/\D/g, ""),
            });
          }}
          onBlur={() =>
            this.props.onEnding(this.state.itemValue, this.props.index)
          }
          readOnly={isBarcodeValid}
        />
        <DeleteImageComponent
          className="delete-image"
          onClick={() =>
            this.state.itemValue && this.props.deleteBarCode(this.props.index)
          }
        />
        {this.renderProductError(this.state.itemValue, this.props.isError)}
      </div>
    );
  }
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
// Customizable Area End
