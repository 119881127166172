import React from "react";

// Customizable Area Start
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  withStyles,
  Typography,
} from "@material-ui/core";
import { inputStyles } from "./CustomInput.Styles.web";

const infoError = require("./assets/info_circle.png");

interface CustomSelectProps {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  type?: string;
  errors?: string | undefined;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
  isRequired?: boolean;
  rows?: number;
  profile?: boolean;
  onBlur?: (event: React.FocusEvent) => void;
  onKeyPress?: (event: React.KeyboardEvent) => void;
  onFocus?: (event: React.FocusEvent) => void;
  classes: any;
}

const CustomInputContent: React.FC<CustomSelectProps> = (props) => {
  const {
    value,
    errors,
    onChange,
    label,
    placeholder,
    classes,
    startAdornment,
    endAdornment,
    disabled,
    isRequired,
    rows,
    onBlur,
    onKeyPress,
    onFocus,
    autoFocus,
    type,
    profile,
  } = props;
  return (
    <FormControl
      fullWidth
      error={errors ? errors.length > 0 : undefined}
      margin="dense"
      className={classes?.Input}
    >
      {label && (
        <InputLabel
          shrink
          className="label"
          required={!!isRequired}
          error={errors ? errors.length > 0 : false}
        >
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        labelWidth={0}
        value={value}
        type={type || "text"}
        onChange={onChange}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        error={errors ? errors.length > 0 : undefined}
        className={`input ${disabled ? "disabled" : ""}${
          !label ? " no-margin" : ""
        }  ${profile ? "profile" : ""}`}
        placeholder={placeholder}
        disabled={disabled}
        multiline={!!rows}
        fullWidth
        rows={rows}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        inputProps={{ className: "inputProps" }}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
      {errors && (
        // eslint-disable-next-line react/no-array-index-key
        <FormHelperText error component="div" className="label-error">
          <Typography component="div">
            <Typography component="div">
              <img src={infoError} alt="Icon" className="info-error-image" />
            </Typography>
            <Typography component="div" className="error-text">
              {errors}
              {/* Wrong password. To recover, your password please contact or send
              an email to the admin to get a new password */}
            </Typography>
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const CustomInput = withStyles(inputStyles)(CustomInputContent);
// Customizable Area End
