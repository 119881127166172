// Customizable Area Start
import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  withStyles,
} from "@material-ui/core";

import FranchiseDashboardController, { Props } from "./FranchiseController.web";
import { DashboardWebStyles } from "./FranchiseDashboardStyle.web";
import { labSampleImage, deliveryImage, downArrow } from "../../assets";
import DashboardBanner from "../../../../../components/src/DashboardBanner.web";
import DateRangePickerComponent from "../../../../../components/src/DateRangePicker.web";
import Loader from "../../../../../components/src/Loader.web";
import { backArrowImage } from "../../../../search/src/assets";

class FranchiseDashboard extends FranchiseDashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Container maxWidth="lg" style={{ margin: "20px auto" }}>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.FranchiseDashboard}>
          <Box className="main-heading">
            {this.state.isTeamLeader ? (
              <Button
                variant="contained"
                className="back-button"
                onClick={() => this.props.navigation.goBack()}
              >
                <img src={backArrowImage} alt="back" />
                Back
              </Button>
            ) : (
              <Typography variant="h2">
                Hello, {this.state.userName || ""}
              </Typography>
            )}
            <Box className="right-side">
              <Button
                className="calenderButton"
                variant="contained"
                onClick={() => this.openOrCloseDateRangePicker(false)}
              >
                <span
                  className="calendar-text"
                  title={this.state.activeCalenderValue}
                >
                  {this.state.activeCalenderValue}
                </span>
                <img src={downArrow} alt="arrow" />
              </Button>
              {this.state.openDateRange && (
                <DateRangePickerComponent
                  activeCalenderValue={this.state.activeCalenderValue}
                  range={this.state.range}
                  closeDatePicker={(range: any, name: string) => {
                    this.setState({ range, activeCalenderValue: name }, () => {
                      this.openOrCloseDateRangePicker(true);
                    });
                  }}
                />
              )}
            </Box>
          </Box>
          <Box margin="20px 0">
            <Grid container spacing={3}>
              <Grid item md={4} sm={6} xs={12}>
                <Box className="content-box">
                  <Typography variant="h1">
                    {this.state.dashboardData.collected}
                  </Typography>
                  <Typography variant="body2">Collected</Typography>
                </Box>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Box className="content-box">
                  <Typography variant="h1">
                    {this.state.dashboardData.shipped}
                  </Typography>
                  <Typography variant="body2">Shipped</Typography>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box className="content-box">
                  <Typography variant="h1">
                    {this.state.dashboardData.delivered}
                  </Typography>
                  <Typography variant="body2">Delivered</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={4}>
            <DashboardBanner
              src={labSampleImage}
              bannerText="COLLECT BIO PRODUCT"
              buttonText="Collect"
              buttonClick={() => this.collectedSampleClick()}
            />
            <DashboardBanner
              src={deliveryImage}
              bannerText="SHIP COLLECTED PRODUCT"
              buttonText="See Details"
              buttonClick={() => this.shipItemsClick()}
            />
          </Box>
        </Box>
      </Container>
      //Merge Engsine End DefaultContainer
    );
  }
}

export default withStyles(DashboardWebStyles)(FranchiseDashboard);
// Customizable Area End
