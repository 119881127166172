// Customizable Area Start
const primaryColor = "#004d64";

export const SuperTeamLeaderProfileDetailStyles = {
  ProfileDetail: {
    "& .inputProps": {
      color: "#004d64",
      fontFamily: "NotoSans-Light",
      fontSize: "20px",
      fontWeight: "400",
    },
  },
};
// Customizable Area End
