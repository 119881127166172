Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.loginAPiEndPoint = "login/login";

// Customizable Area Start
exports.errorEmailNotValid = "Please enter valid Username / Login id.";
exports.errorPasswordNotValid = "Please enter your Password.";
exports.errorLoginFail = "Please check Username / Login id and Password, To recover, please contact or send email to the administration.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "Hello!\nWelcome\nBio Collection";
exports.labelHeaderAgain = "Hello Again!\nWelcome\nBack";
exports.btnTxtLogin = "Sign In";
exports.labelRememberMe = "Remember";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.loginEmailEndPoint = "bx_block_login/logins";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End