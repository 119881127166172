// Customizable Area Start
import React from "react";
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backArrowImage = require("../assets/back-arrow.png");

export const RightArrowComponent = ({ height = 24, width = 24, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="IconlyLightArrow---Right-2-Copy"
        transform="matrix(1.1924881E-08 1 -1 1.1924881E-08 24 0)"
      >
        <path
          d="M0 24L-1.04907e-06 0L24 -1.90735e-06L24 24L0 24Z"
          id="IconlyLightArrow---Right-2-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g
          id="IconlyLightArrow---Right-2"
          transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 24)"
        >
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="IconlyLightArrow---Right-2-(Background)"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g id="Arrow---Right-2" transform="translate(8.5 5)">
            <path
              d="M1.35122e-07 0L7 7L-4.76837e-07 14"
              id="Stroke-1"
              fill="none"
              fillRule="evenodd"
              stroke="#FFFFFF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const RightDarkArrowComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="IconlyLightArrow---Right-2-Copy"
        transform="matrix(1.1924881E-08 1 -1 1.1924881E-08 24 0)"
      >
        <path
          d="M0 24L-1.04907e-06 0L24 -1.90735e-06L24 24L0 24Z"
          id="IconlyLightArrow---Right-2-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g
          id="IconlyLightArrow---Right-2"
          transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 24)"
        >
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="IconlyLightArrow---Right-2-(Background)"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g id="Arrow---Right-2" transform="translate(8.5 5)">
            <path
              d="M1.35122e-07 0L7 7L-4.76837e-07 14"
              id="Stroke-1"
              fill="none"
              fillRule="evenodd"
              stroke="#004D64"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const RightWhiteArrowComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="IconlyLightArrow---Right-2-Copy"
        transform="matrix(1.1924881E-08 1 -1 1.1924881E-08 24 0)"
      >
        <path
          d="M0 24L-1.04907e-06 0L24 -1.90735e-06L24 24L0 24Z"
          id="IconlyLightArrow---Right-2-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g
          id="IconlyLightArrow---Right-2"
          transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 24)"
        >
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="IconlyLightArrow---Right-2-(Background)"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g id="Arrow---Right-2" transform="translate(8.5 5)">
            <path
              d="M1.35122e-07 0L7 7L-4.76837e-07 14"
              id="Stroke-1"
              fill="none"
              fillRule="evenodd"
              stroke="#ffffff"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
// Customizable Area End
