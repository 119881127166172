// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Dialog,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FranchiseAddProductWebStyles } from "./FranchiseAddProductDetailStyles.web";
import {
  cancelSearchImage,
  minusWebImage,
  plusWebImage,
} from "../../../assets";
import { Formik } from "formik";
import * as Yup from "yup";
import { CustomInput } from "../../../../../../components/src/CustomInput.web";

interface Props {
  props?: any;
  open: boolean;
  classes: any;
  closeDialog: any;
  sampleList: {
    label: string;
    disabled: boolean;
    quantity: number;
  }[];
  submitSampleValues: (
    data: {
      label: string;
      disabled: boolean;
      quantity: number;
    }[]
  ) => void;
}

interface S {}

class FranchiseAddProductDetail extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
  }

  isButtonDisabled = (data: any) => {
    for (const element of data) {
      if (element.quantity > 0) {
        return false;
      }
    }
    return true;
  };

  sampleSchema = Yup.object().shape({
    label: Yup.string(),
    disabled: Yup.boolean(),
    quantity: Yup.number().max(4),
  });
  FormSchemaWeb = Yup.array().of(this.sampleSchema);

  checkMinusButtonDisabled = (detail: any) => {
    return detail.quantity > 0 ? false : true;
  };

  checkPositiveButtonDisabled = (detail: any) => {
    return detail.quantity >= 9999 ? true : false;
  };

  render() {
    const {
      open,
      classes,
      closeDialog,
      submitSampleValues,
      sampleList,
    } = this.props;
    return (
      <Dialog
        open={open}
        className={classes?.Dialog}
        onClose={() => {}}
        maxWidth="xs"
        fullWidth
        scroll="body"
      >
        <Box className="image-container">
          <img
            src={cancelSearchImage}
            className="close-image"
            alt="close"
            onClick={() => closeDialog()}
          />
        </Box>
        {sampleList.length > 0 ? (
          <Box className="product-detail-container">
            <Typography variant="h5">Select Product Type</Typography>
            <Formik
              enableReinitialize={true}
              initialValues={{ sampleList }}
              validationSchema={this.FormSchemaWeb}
              onSubmit={(data) => submitSampleValues(data.sampleList)}
            >
              {({ values, handleSubmit, setFieldValue }) => {
                return (
                  <form onSubmit={() => {}} autoComplete="off">
                    {values.sampleList.map((detail, index) => {
                      return (
                        <Box className="button-container" key={index}>
                          <Grid container spacing={2}>
                            <Grid item xs={7}>
                              <Typography variant="body1">
                                {detail.label}
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              {detail.disabled ? (
                                <Button
                                  variant="contained"
                                  className="add-button"
                                  fullWidth
                                  onClick={() => {
                                    setFieldValue(
                                      `sampleList[${index}].disabled`,
                                      false
                                    );
                                    setFieldValue(
                                      `sampleList[${index}].quantity`,
                                      detail.quantity + 1
                                    );
                                  }}
                                >
                                  + Add
                                </Button>
                              ) : (
                                <CustomInput
                                  type="number"
                                  value={detail.quantity}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 4) {
                                      setFieldValue(
                                        `sampleList[${index}].quantity`,
                                        Number(e.target.value)
                                      );
                                    }
                                  }}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <IconButton
                                        disabled={this.checkMinusButtonDisabled(
                                          detail
                                        )}
                                        onClick={() =>
                                          setFieldValue(
                                            `sampleList[${index}].quantity`,
                                            detail.quantity - 1
                                          )
                                        }
                                      >
                                        <img src={minusWebImage} />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={this.checkPositiveButtonDisabled(
                                          detail
                                        )}
                                        onClick={() =>
                                          setFieldValue(
                                            `sampleList[${index}].quantity`,
                                            detail.quantity + 1
                                          )
                                        }
                                      >
                                        <img src={plusWebImage} />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                    <Button
                      className="submit-button"
                      variant="outlined"
                      disabled={this.isButtonDisabled(values.sampleList)}
                      onClick={() => handleSubmit()}
                      fullWidth
                    >
                      Submit
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </Box>
        ) : (
          <div className="not-found-tex margin-b-10">
            No product allocated to this hospital
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(FranchiseAddProductWebStyles)(
  FranchiseAddProductDetail
);
// Customizable Area End
