// Customizable Area Start
import React from "react";
import { Dialog, Box, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { SuccessModalWebStyles } from "./SuccessModalWebStyles.web";

interface ISuccessDialogProps {
  classes: any;
  open: boolean;
  onClose: () => void;
  message: string;
}

const doneImage = require("./assets/done_web.png");

const SuccessModalWeb: React.FC<ISuccessDialogProps> = ({
  classes,
  open,
  onClose,
  message,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={() => onClose()}
      className={classes?.SuccessDialog}
    >
      <Box className="dialog-inside-container">
        <img src={doneImage} alt="image" />
        <Typography className="modal-detail">{message}</Typography>
        <Box className="button-action-container">
          <Button
            variant="contained"
            className="yes-button"
            onClick={() => onClose()}
          >
            Done
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default withStyles(SuccessModalWebStyles)(SuccessModalWeb);
// Customizable Area End
