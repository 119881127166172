// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  Typography,
  Box,
  withStyles
} from "@material-ui/core";
import { SuperTeamsSamplesStyles } from "./SuperTeamSamplesStyles.web";
import { barcodeWebImage, plusWebImage } from "../../assets";
import BackButtonTitleWeb from "../../../../../components/src/BackButtonTitleWeb.web";
import BarcodeScanModalWeb from "../../../../../components/src/BarcodeScanModal.web";
import SuperTeamsNegative, {
  IProps
} from "./SuperTeamsNegativeSamplesController.web";
import SuccessModalWeb from "../../../../../components/src/SuccessModalWeb";
import Loader from "../../../../../components/src/Loader.web";
import ReadyShipDialog from "../../../../../components/src/ReadyShipDialog.web";
import { getFormatDate } from "../../../../../components/src/utils";
// Customizable Area End

interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
class SuperTeamsNegativeSamples extends SuperTeamsNegative {
  constructor(props: IProps) {
    super(props);
  }

  renderText = (barcode: string, isError: boolean) => {
    const validData: any = this.state.successData.find(
      data => data.barcode_number === barcode
    );
    const product_name: string = validData && validData.product_name;
    const pickup_date: string = getFormatDate(
      validData && validData.pickup_date
    );
    const hospital_name: string = validData && validData.hospital_name;

    if (this.state.successData && validData) {
      return (
        <div className="success-text">
          <strong>Pickup Date:-</strong> {pickup_date},
          <strong>Product Type:-</strong> {product_name},{" "}
          <strong>Hospital Name:-</strong> {hospital_name}
        </div>
      );
    }
    if (
      this.state.notInDataBase &&
      this.state.notInDataBase.includes(barcode)
    ) {
      return (
        <div className="error-text">This barcode does not exist in system</div>
      );
    } else if (
      this.state.alreadyNegative &&
      this.state.alreadyNegative.includes(barcode)
    ) {
      return (
        <div className="error-text">
          {" "}
          This barcode has already been updated as positive or negative
        </div>
      );
    }
    else if (this.state.invalidProduct?.includes(barcode)) {
      return (<div className="error-text"> You can not mark this product as positive or negative </div>);
    } else if (
      this.state.notDelivered &&
      this.state.notDelivered.includes(barcode)
    ) {
      return (
        <div className="error-text">
          This barcode has not been delivered yet
        </div>
      );
    } else if (this.state.invalidTeamLead?.includes(barcode)) {
      return (<div className="error-text">Invalid teamlead or franchise or sub franchise</div>);
    }
    else if (isError) {
      return <div className="error-text">Barcode must be 12 digit</div>;
    } else {
      return <></>;
    }
  };

  renderBarcodeDetails() {
    return this.state.negativeData.map((data, index) => {
      let showNegative = false;
      if (data.status) {
        showNegative =
          this.state.validBarcodes &&
          this.state.validBarcodes.includes(data.barcode);
      }
      return (
        <>
          <Box className="barcode-details-row" key={index}>
            <Typography variant="body1" className="sr-no-details">
              {index < 9 ? `0${index + 1}` : index + 1}
            </Typography>
            <div
              style={{
                position: "relative",
                width: "100%"
              }}
              className={data.barcode ? "" : "disabled"}
              key={this.state.key}
            >
              <TextField
                index={index}
                onEnding={(value, index) =>
                  this.handleDuplicateNegativeBarcode(value, index)
                }
                value={data.barcode}
              />
              {this.renderText(data.barcode, data.isError)}
            </div>
            {showNegative ? (
              <Button
                variant="contained"
                disabled
                className="sample negative-sample"
              >
                -ve
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled
                className="sample unmarked-sample"
              >
                Unmarked
              </Button>
            )}
          </Box>
        </>
      );
    });
  }

  render() {
    const { classes, onBack } = this.props;

    return (
      <Container
        style={{ margin: "20px auto" }}
        maxWidth="lg"
        className={classes && classes.Picker}
      >
        <Loader loading={this.state.isLoading} />
        <BackButtonTitleWeb buttonClick={() => onBack()} />
        <Box
          className="barcode-heading-container"
          style={{
            marginRight: `${this.state.negativeData.length > 9 ? "18px" : 0}`
          }}
        >
          <div className="heading-left">
            <Typography variant="body1" className="sr-no">
              Serial No
            </Typography>
            <Typography variant="body1" className="barcode-no">
              Barcode Number
            </Typography>
          </div>
          <div className="heading-right">
            <Typography variant="body1" className="sr-no status-heading">
              Status
            </Typography>
          </div>
        </Box>
        {this.state.isError && (
          <div className="error">
            Please fill one barcode details and marked as negative
          </div>
        )}
        <Box
          className="barcode-detail-container"
          style={{
            paddingRight: `${this.state.negativeData.length > 9 ? "10px" : 0}`
          }}
        >
          {this.renderBarcodeDetails()}
        </Box>
        <Box marginBottom={4} className="action-button">
          <Button
            className="scan-barcode"
            onClick={this.handleScanBarcode}
            disabled={
              this.state.negativeData.filter(item => item.barcode === "")
                .length === 0
            }
          >
            <img src={barcodeWebImage} />
            Scan Barcode
          </Button>
          <Box className="right-action-button">
            <Button
              className="add-more"
              variant="outlined"
              onClick={() => this.addInputField()}
            >
              <img src={plusWebImage} /> Add More
            </Button>
            <Button
              className="continue-button"
              onClick={() => this.checkAnyBarcodeFill()}
            >
              Save
            </Button>
          </Box>
        </Box>
        {this.state.showBarcodeModal && (
          <BarcodeScanModalWeb
            stlPositiveOrNegative={true}
            stlPositiveOrNegativeResponse={this.state.response}
            scanApiForPositiveOrNegative={this.scanningIndividualBarcode}
            open={this.state.showBarcodeModal}
            onClose={() =>
              this.state.duplicate
                ? this.onDeleteValue(this.state.selectedBarcodeIndex)
                : this.hideScanBarcodeModal()
            }
            duplicateBarcodeIndex={this.state.duplicateBarcodeIndex}
            duplicate={this.state.duplicate}
            selectedBarcode={this.state.selectedBarcode}
            selectedNumber={this.state.selectedBarcodeIndex}
            onRetake={() => this.onDeleteValue(this.state.selectedBarcodeIndex)}
            onNext={(codeData, index) =>
              this.handleScanBarcodeNext(codeData, index)
            }
          />
        )}
        <ReadyShipDialog
          message="Are you confirm to save?"
          open={this.state.openReadyToShipNegative}
          onClose={() =>
            this.setState({
              openReadyToShipNegative: false
            })
          }
          onSubmit={() => this.scanningNegativeProductApiCall()}
        />
        <SuccessModalWeb
          message="Your items have been marked negative"
          open={this.state.showSuccessModal}
          onClose={() => this.onCloseSuccessModalForNegative()}
        />
      </Container>
    );
  }
}

export default withStyles(SuperTeamsSamplesStyles)(SuperTeamsNegativeSamples);

interface IPropsTextField {
  value: string;
  onEnding: (itemValue: string, index: number) => void;
  index: number;
}
interface STextField {
  itemValue: string;
}
class TextField extends React.Component<IPropsTextField, STextField> {
  constructor(props: IPropsTextField) {
    super(props);
    this.state = {
      itemValue: ""
    };
  }

  componentDidMount() {
    this.setState({
      itemValue: this.props.value
    });
  }

  render() {
    return (
      <>
        <input
          className={
            this.state.itemValue
              ? "barcode-number-details"
              : "barcode-number-details error-barcode"
          }
          value={this.state.itemValue}
          key={`label${this.props.index}`}
          maxLength={12}
          onChange={event => {
            this.setState({
              itemValue: event.target.value.replace(/\D/g, "")
            });
          }}
          onBlur={() =>
            this.props.onEnding(this.state.itemValue, this.props.index)
          }
        />
      </>
    );
  }
}
// Customizable Area End
