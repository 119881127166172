// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData } from "../../framework/src/Utilities";
import moment from "moment";

export const getPasswordFromLocalStorage = () => {
    let details = localStorage.getItem("userDetails");
    return details;
}

export enum sampleValue {
    AFPF = "afPf",
    hbsAG = "hsbAg",
    CANCERFLUID = "cancerFluid",
    CORDBLOOD = "cordBlood",
    URINE = "urine",
}


export const userDetailsFromStorage = () => {
    const detail = localStorage.getItem("userData");
    let userData: {
        id: string;
        attributes: { first_name: string; token: string; user_name: string };
    };
    if (detail) {
        userData = JSON.parse(detail);
        if (userData.attributes && userData.id) {
            return { attributes: userData.attributes, id: userData.id }
        }
    }
}

export const getRole = async () => {
    const userName = await getStorageData("userName", false);
    const role = await getStorageData("role", false);
    return {
        userName,
        role
    }
}
export enum UserRoles {
    SUB_FRANCHISE = "sub_franchise",
    FRANCHISE = "franchise",
    TEAM_LEADER = "teamlead",
    SUPER_TEAM_LEAD = "super_teamlead"
}

export const userLogOut = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRoles");
    localStorage.removeItem("userName");
    localStorage.removeItem("userData");
}

export const FirstLetterCapital = (value: string) => {
    if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return ''
}

export const userRedirectToLoginPage = (navigation: any,) => {
    toast.error("Your session has been expired. So please re-login");
    navigation.navigate("EmailAccountLoginBlock");
    userLogOut();
    return true;
}

export const sortByLabel = (data: any) => {
    const sortedData = data?.sort(function (a: any, b: any) {
        if ((a.attributes.name).toLowerCase() < (b.attributes.name).toLowerCase()) { return -1; }
        if ((a.attributes.name).toLowerCase() > (b.attributes.name).toLowerCase()) { return 1; }
        return 0;
    })
    return sortedData;
}

export const sortByStatus = (data: any) => {
    const sortedData = data?.sort(function (a: any, b: any) {
        if ((a.status).toLowerCase() < (b.status).toLowerCase()) { return -1; }
        if ((a.status).toLowerCase() > (b.status).toLowerCase()) { return 1; }
        return 0;
    })
    return sortedData;
}

const dateFormate = "DD/MM/YYYY"

export const getThisMonthDate = () => {
    return {
        startDate: moment().startOf('month').format(dateFormate),
        endDate: moment().format(dateFormate)
    }
}

export const getPercentage = (partialValue: number, totalValue: number) => {
    const percentage = (100 * partialValue) / totalValue;
    if (String(percentage) === 'NaN') {
        return 0
    }
    return percentage.toFixed();
}

export const getFormatDate = (date: Date) => {
    return moment(date).format(
        dateFormate
    )
}

export const removeDuplicates = (arr: any) => {
    const unique = arr.filter((obj: any, index: number) => {
        return index === arr.findIndex((o: any) => obj.name === o.name);
    });
    return unique
}

export const removeDuplicateValues = (arr: any) => {
    return arr.filter((item: any, index: number) => arr.indexOf(item) === index);
}


// Customizable Area End
