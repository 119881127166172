// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import * as Yup from "yup";
import {
  getPasswordFromLocalStorage,
  UserRoles,
} from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  username: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  isPasswordInLocalStorage: boolean;
  isPasswordForgot: boolean;
  errorInApi: string;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      username: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      isPasswordInLocalStorage: false,
      isPasswordForgot: false,
      errorInApi: "",
      isLoading: false,
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const detail = getPasswordFromLocalStorage();
    let userDetails: { username: string; password: string };

    if (detail) {
      userDetails = JSON.parse(detail);
      if (userDetails.username && userDetails.password) {
        this.setState({
          checkedRememberMe: true,
          isPasswordInLocalStorage: true,
          username: userDetails.username,
          password: userDetails.password,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setUserName = (text: string) => {
    this.setState({
      username: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  setIsPasswordForgot = () => {
    this.setState({
      isPasswordForgot: true,
      errorInApi: "",
    });
  };

  redirectLoginScreen = () => {
    this.setState({
      isPasswordForgot: false,
      isPasswordInLocalStorage: false,
      password: "",
      username: "",
      errorInApi: "",
    });
  };

  FormSchemaWeb = Yup.object().shape({
    password: Yup.string().trim().required("Password is required"),
    username: Yup.string().trim().required("Login ID is required"),
    isChecked: Yup.bool().default(true),
  });

  dataSetInStorage = (responseJson: any) => {
    localStorage.setItem("userName", responseJson.data.attributes.full_name);
    localStorage.setItem("authToken", responseJson.data.attributes.token);
    localStorage.setItem("userData", JSON.stringify(responseJson.data));
  };

  setRoleInStorage = (roleId: number) => {
    let role: string, redirectLocation: string;
    switch (roleId) {
      case 1:
        role = UserRoles.TEAM_LEADER;
        redirectLocation = "TeamLeaderDashboard";
        break;
      case 2:
        role = UserRoles.SUPER_TEAM_LEAD;
        redirectLocation = "SuperTeamLeaderDashboard";
        break;
      case 3:
        role = UserRoles.FRANCHISE;
        redirectLocation = "FranchiseDashboard";
        break;
      default:
        role = UserRoles.SUB_FRANCHISE;
        redirectLocation = "FranchiseDashboard";
        break;
    }
    return { role, redirectLocation };
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.loginApiCallId) {
          if (responseJson.data && responseJson.data.attributes.token) {
            this.dataSetInStorage(responseJson);
            const result = this.setRoleInStorage(
              responseJson.data.attributes.role_id
            );
            localStorage.setItem("userRoles", result.role);
            this.props.navigation.navigate(result.redirectLocation);
          } else {
            this.setState({
              errorInApi: responseJson.error,
            });
          }
        }
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  loginApi = async () => {
    this.setState({ isLoading: true, errorInApi: "" });
    if (this.state.checkedRememberMe) {
      const userDetails = {
        username: this.state.username,
        password: this.state.password,
      };
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    } else {
      localStorage.removeItem("userDetails");
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      username: this.state.username,
      password: this.state.password,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEmailEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}
// Customizable Area End
