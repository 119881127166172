// Customizable Area Start
import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  withStyles,
} from "@material-ui/core";

import TeamLeaderFranchiseController, {
  Props,
  ActiveProduct,
  IFranchise,
} from "./TeamLeaderFranchiseController.web";
import { DashboardWebStyles } from "./TeamLeaderDashboardStyle.web";
import {
  RightDarkArrowComponent,
  RightWhiteArrowComponent,
  searchImage,
  infoPrimaryImage,
  downArrow,
} from "../../assets";
import { CustomInput } from "../../../../../components/src/CustomInput.web";
import BackButtonTitleWeb from "../../../../../components/src/BackButtonTitleWeb.web";
import DateRangePickerComponent from "../../../../../components/src/DateRangePicker.web";
import Loader from "../../../../../components/src/Loader.web";
import NotFound from "../../../../../components/src/NotFound.web";

class TeamLeaderFranchise extends TeamLeaderFranchiseController {
  constructor(props: Props) {
    super(props);
  }

  handleIndexValue(index: number) {
    return index < 9 ? `0${index + 1}` : index + 1;
  }

  renderSubFranchiseList = (data: IFranchise) => {
    return (
      <>
        {this.state.franchiseIndividualCombined.map((item, index) => (
          <Accordion
            expanded={index === this.state.handleIndividualExpand}
            key={`${data.attributes.franchise_name}${data.id}${index}`}
            //@ts-ignore
            onChange={this.handleIndividualChange(
              data.attributes.franchise_name,
              data.attributes.id,
              index,
              item
            )}
            className="sub-franchise-details"
          >
            <AccordionSummary
              expandIcon={
                <>
                  <RightDarkArrowComponent className="dark-color" />{" "}
                  <RightWhiteArrowComponent className="white-color" />
                </>
              }
              aria-controls="panel1a-content"
            >
              <Typography className="accordion-heading">
                <span className="digit">{this.handleIndexValue(index)}</span>{" "}
                {`${data.attributes.franchise_name} [${item}] [FR]`}
              </Typography>
              <Box className="right-side">
                <Typography>
                  Quantity-
                  {item === "Individual"
                    ? data.attributes.quantity
                    : this.getTotalQuantity(data)}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>{this.renderProductDetails()}</AccordionDetails>
          </Accordion>
        ))}
        {data.attributes.sub_franchises.map(
          (sub_franchise_detail, index: number) => (
            <Accordion
              expanded={
                sub_franchise_detail.id === this.state.handleSubFranchiseExpand
              }
              key={`${sub_franchise_detail.franchise_name}${index}`}
              className="sub-franchise-details"
              //@ts-ignore
              onChange={this.handleSubFranchiseChange(
                sub_franchise_detail,
                "individual"
              )}
            >
              <AccordionSummary
                expandIcon={
                  <>
                    <RightDarkArrowComponent className="dark-color" />{" "}
                    <RightWhiteArrowComponent className="white-color" />
                  </>
                }
                aria-controls="panel1a-content"
              >
                <Typography className="accordion-heading">
                  <span className="digit">
                    {this.handleIndexValue(index + 2)}
                  </span>{" "}
                  {`${sub_franchise_detail.franchise_name} [SF]`}
                </Typography>
                <Box className="right-side">
                  <Typography>
                    Quantity-{sub_franchise_detail.quantity}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>{this.renderProductDetails()}</AccordionDetails>
            </Accordion>
          )
        )}
      </>
    );
  };

  renderProductDetails = () => {
    return (
      <Box className="summary-details">
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              className={`content-box ${
                this.state.franchiseActiveProduct === ActiveProduct.COLLECTED
                  ? "active"
                  : ""
              }`}
              onClick={() => this.setActiveProduct(ActiveProduct.COLLECTED)}
            >
              <Typography variant="h1">
                {this.state.franchiseData.collected}
              </Typography>
              <Typography variant="body2">TOTAL COLLECTED</Typography>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              className={`content-box ${
                this.state.franchiseActiveProduct === ActiveProduct.SHIPPED
                  ? "active"
                  : ""
              }`}
              onClick={() => this.setActiveProduct(ActiveProduct.SHIPPED)}
            >
              <Typography variant="h1">
                {this.state.franchiseData.shipped}
              </Typography>
              <Typography variant="body2">TOTAL SHIPPED</Typography>
            </Box>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Box
              className={`content-box ${
                this.state.franchiseActiveProduct === ActiveProduct.DELIVERED
                  ? "active"
                  : ""
              }`}
              onClick={() => this.setActiveProduct(ActiveProduct.DELIVERED)}
            >
              <Typography variant="h1">
                {this.state.franchiseData.delivered}
              </Typography>
              <Typography variant="body2">Total delivered</Typography>
            </Box>
          </Grid>
        </Grid>
        {this.state.franchiseSampleDetail.length !== 0 && (
          <>
            <Box className="items-inside-container">
              {this.state.franchiseSampleDetail.map((item) => (
                <Box
                  className="outer-item-container"
                  key={`${item.product_name}${item.sample_id}`}
                >
                  <Box className="item-container">
                    <Box
                      className={`text-container ${this.state
                        .activeSampleValue === item.sample_id && "active"}`}
                      onClick={() =>
                        this.setActiveSampleTabValue(item.sample_id)
                      }
                    >
                      <Typography className="digit-heading" title={item.value}>
                        {item.value}
                      </Typography>
                      <Typography
                        className="item-name"
                        title={item.product_name}
                      >
                        {item.product_name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            {this.state.franchiseHospitalDetail && (
              <Box className="product-details">
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <Grid container className="left-container">
                      <Grid item md={3} sm={3}>
                        <Typography className="data-heading text-align-center">
                          Serial no.
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={7}>
                        <Typography className="data-heading text-align-center">
                          Hospital Name
                        </Typography>
                      </Grid>
                      <Grid item md={2} sm={2}>
                        <Typography className="data-heading text-align-center">
                          Barcode Qty.
                        </Typography>
                      </Grid>
                    </Grid>
                    {this.state.franchiseHospitalDetail.length !== 0 ? (
                      this.state.franchiseHospitalDetail.map(
                        (hospital_detail, index) => (
                          <Grid
                            container
                            className="row-spacing"
                            key={`${hospital_detail.hospital}${index}`}
                          >
                            <Grid item md={3} sm={3}>
                              <Typography className="data-detail text-align-center">
                                {this.handleIndexValue(index)}
                              </Typography>
                            </Grid>
                            <Grid item md={7} sm={7}>
                              <Typography className="data-detail text-align-center">
                                {hospital_detail.hospital}
                              </Typography>
                            </Grid>
                            <Grid item md={2} sm={2}>
                              <Typography className="data-detail text-align-center">
                                {hospital_detail.count}
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                      )
                    ) : (
                      <div className="not-found-tex">No details Found</div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        )}
        <Typography className="right-side-info-text">
          <img
            src={infoPrimaryImage}
            alt="info"
            className="info"
            onClick={() => this.setShowHospitalValue()}
          />
          <span
            className="info-icon-text"
            onClick={() => this.setShowHospitalValue()}
          >
            Click here to see listed hospitals
          </span>
        </Typography>
      </Box>
    );
  };

  renderFranchiseHeader = (data: IFranchise, index: number) => {
    return (
      <Accordion
        expanded={data.attributes.id === this.state.handleFranchiseExpand}
        key={`${data.id}`}
        //@ts-ignore
        onChange={this.handleFranchiseChange(data, "individual")}
      >
        <AccordionSummary
          expandIcon={
            <>
              <RightDarkArrowComponent className="dark-color" />{" "}
              <RightWhiteArrowComponent className="white-color" />
            </>
          }
          aria-controls="panel1a-content"
        >
          <Typography className="accordion-heading">
            <span className="digit">{this.handleIndexValue(index)}</span>{" "}
            {`${data.attributes.franchise_name} [FR]`}
          </Typography>
          {data.attributes.sub_franchises.length === 0 && (
            <Box className="right-side">
              <Typography>Quantity-{data.attributes.quantity}</Typography>
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {data.attributes.sub_franchises.length > 0
            ? this.renderSubFranchiseList(data)
            : this.renderProductDetails()}
        </AccordionDetails>
      </Accordion>
    );
  };

  renderFranchise = () => {
    const filterData = this.state.franchiseList.filter((item) => {
      if (
        item.attributes.franchise_name
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase())
      ) {
        return item;
      }
    });
    if (filterData.length === 0) {
      return <div className="not-found-tex">No Data Found</div>;
    } else {
      return filterData.map((data, index) =>
        this.renderFranchiseHeader(data, index)
      );
    }
  };

  renderCalendarModal = () => {
    return (
      this.state.openDateRange && (
        <DateRangePickerComponent
          activeCalenderValue={this.state.activeCalenderValue}
          range={this.state.range}
          closeDatePicker={(range: any, name: string) => {
            this.setState({ range, activeCalenderValue: name }, () => {
              this.openOrCloseDateRangePicker(true);
            });
          }}
        />
      )
    );
  };

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Container maxWidth="lg" style={{ margin: "20px auto" }}>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.TeamLeader}>
          <BackButtonTitleWeb
            buttonClick={() => this.props.navigation.goBack()}
          />
          <Box className="main-heading">
            <CustomInput
              value={this.state.searchText}
              onChange={this.setSearchText}
              placeholder="Search Franchise"
              endAdornment={
                <InputAdornment position="end">
                  <img
                    src={searchImage}
                    alt="search"
                    className="search-image"
                  />
                </InputAdornment>
              }
            />
            <Box className="right-side">
              <Button
                className="calenderButton"
                variant="contained"
                onClick={() => this.openOrCloseDateRangePicker(false)}
              >
                <span
                  className="calendar-text"
                  title={this.state.activeCalenderValue}
                >
                  {this.state.activeCalenderValue}
                </span>
                <img src={downArrow} alt="arrow" />
              </Button>
              {this.renderCalendarModal()}
            </Box>{" "}
          </Box>
          <Box margin="20px 0">
            <Grid container spacing={2}>
              <Grid item md={10} sm={9} xs={8} className="right-side-grid">
                <Box className="details">
                  {this.state.franchiseList.length > 0 ? (
                    this.renderFranchise()
                  ) : (
                    <NotFound message="No franchise associate with this team leader" />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      //Merge Engsine End DefaultContainer
    );
  }
}

export default withStyles(DashboardWebStyles)(TeamLeaderFranchise);
// Customizable Area End
