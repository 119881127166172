// Customizable Area Start
const primaryColor = "#004d64";
const whiteColor = "#FFFFFF";

export const HospitalSelectWebStyles = {
  HospitalSearch: {
    "& .back-button": {
      height: "46px",
      width: "107.81px",
      background: "#eafaff",
      backgroundBlendMode: "normal",
      boxShadow: "none",
      "& img": {
        height: 19,
        width: 18,
        marginRight: 5,
      },
      "& .MuiButton-label": {
        color: primaryColor,
        fontFamily: "NotoSans-Medium",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "22px",
        textTransform: "capitalize",
      },
    },
    "& .search-box": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "20px",
      margin: "20px 0 ",
      "& h3": {
        fontSize: "30px",
        fontWeight: "700",
        fontFamily: "NotoSans-Regular",
        lineHeight: "41px",
        color: primaryColor,
        width: "500px",
      },
      "& img": {
        height: 24,
        width: 24,
      },
    },
    "& .detail": {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "NotoSans-Regular",
      lineHeight: "22px",
      color: primaryColor,
      marginBottom: "10px",
      borderRadius: "4px",
      padding: "11px 20px",
      background: "rgba(171,235,255,0.25)",
      backgroundBlendMode: "normal",
      cursor: "pointer",
    },
    "& .selected": {
      border: "1px solid #004d64",
    },
    "& .detail:last-child": {
      marginBottom: "0px",
    },
    "& .heading": {
      color: whiteColor,
      backgroundColor: primaryColor,
      fontSize: "20px",
      fontWeight: "400",
      fontFamily: "NotoSans-Regular",
      lineHeight: "27px",
      marginBottom: "15px",
      borderRadius: "4px",
      padding: "11px 20px",
    },
    "& .item-details-container": {
      maxHeight: "80vh",
      overflow: "auto",
    },
    "& .item-details-container::-webkit-scrollbar": {
      width: "8px",
    },
    "& .item-details-container::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#e3e9eb",
    },
    "& .item-details-container::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "#004d64",
    },
  },
  Dialog: {
    "& .image-container": {
      padding: "16px 16px 0",
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .close-image": {
      height: 45,
      width: 45,
      cursor: "pointer",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "11px",
      maxWidth: "475px",
    },
    "& .product-detail-container": {
      padding: "0px 40px 20px",
      "& h5": {
        color: primaryColor,
        fontSize: "24px",
        fontWeight: "700",
        fontFamily: "NotoSans-Regular",
        lineHeight: "33px",
      },
      "& .button-container": {
        marginTop: 15,
        marginBottom: 15,
      },
      "& .add-button": {
        color: whiteColor,
        backgroundColor: primaryColor,
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "NotoSans-Regular",
        height: "48px",
      },
      "& .MuiTypography-body1": {
        height: "48px",
        borderRadius: "4px",
        background: "#76c9e3",
        backgroundBlendMode: "normal",
        boxShadow: "0px 0px 18px rgba(0,0,0,0.13)",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "NotoSans-Medium",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#004d64",
      },
      "& .submit-button": {
        height: 64,
        backgroundColor: primaryColor,
        borderRadius: "4px",
        backgroundBlendMode: "normal",
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        marginTop: "5px",
      },
      "& .MuiFormControl-marginDense": {
        marginTop: "0",
        marginBottom: "0",
      },
      "&  .input": {
        height: "48px",
        borderRadius: "4px",
      },
      "&  .MuiIconButton-root": {
        padding: "5px",
      },
      "& .inputProps": {
        textAlign: "center",
        zIndex: "1",
      },
      "& .MuiInputAdornment-positionEnd": {
        marginLeft: "0px",
        zIndex: "1",
      },
      "& .MuiInputAdornment-positionStart": {
        marginRight: "0px",
        zIndex: "1",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        backgroundColor: "#e5f9ff",
      },
    },
  },
};
// Customizable Area End
