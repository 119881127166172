// Customizable Area Start
export const SuccessModalWebStyles = {
  SuccessDialog: {
    "& .dialog-inside-container": {
      padding: "50px 52px 25px",
      textAlign: "center",
    },
    "& img": {
      height: "109px",
      width: "109px",
    },
    "& .modal-detail": {
      margin: "25px 0",
      color: "#004c63",
      fontFamily: "NotoSans-Medium",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22px",
    },
    "& .button-action-container": {
      display: "flex",
      gap: "25px",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .cancel-button": {
      height: "64px",
      borderRadius: "4px",
      width: "50%",
      background: "#d8d8d8",
      backgroundBlendMode: "normal",
      "& .MuiButton-label": {
        color: "#1c1c1b",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        textTransform: "capitalize",
      },
    },
    "& .yes-button": {
      height: "64px",
      borderRadius: "4px",
      width: "50%",
      background: "#004d64",
      backgroundBlendMode: "normal",
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        textTransform: "capitalize",
      },
    },
  },
};
// Customizable Area End
