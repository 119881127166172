// Customizable Area Start
const primaryColor = "#004d64";

export const TeamLeaderProfileStyles = {
  Profile: {
    "& .back-button": {
      height: "46px",
      width: "107.81px",
      background: "#eafaff",
      backgroundBlendMode: "normal",
      boxShadow: "none",
      "& img": {
        height: 19,
        width: 18,
        marginRight: 5,
      },
      "& .MuiButton-label": {
        color: primaryColor,
        fontFamily: "NotoSans-Medium",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "22px",
        textTransform: "capitalize",
      },
    },
    "& .navigation-tabs": {
      marginTop: "20px",
      "& .MuiTabs-flexContainer": {
        gap: "100px",
      },
      "& .MuiTab-root": {
        minWidth: "100px",
        color: "#a0a0a0",
      },
      "& .MuiTab-wrapper": {
        lineHeight: "33px",
        fontSize: "24px",
        fontWeight: "400",
        fontFamily: "NotoSans-Regular",
        textTransform: "capitalize",
      },
      "& .MuiTab-textColorPrimary.Mui-selected": {
        color: `${primaryColor} !important`,
        "& .MuiTab-wrapper": {
          fontWeight: "700 !important",
        },
      },
    },
    "& .MuiTabs-indicator": {
      backgroundColor: primaryColor,
      height: "3px",
      borderRadius: "10px",
    },
    "& .tab-details": {
      "& .tab-full-details": {
        padding: "24px 0",
      },
    },
    "& .notice": {
      lineHeight: "20px",
      color: primaryColor,
      fontSize: "15px",
      fontWeight: "300",
      fontFamily: "NotoSans-Regular",
      margin: "0 auto",
      position: "fixed",
      bottom: "0",
      background: "white",
      height: "70px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
  },
};

// Customizable Area End
