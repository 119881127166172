// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import {
  userDetailsFromStorage,
  userRedirectToLoginPage,
} from "../../../../../../components/src/utils";
import { toast } from "react-toastify";

export const configJSON = require("../../../config");

export interface Props {
  classes: any;
  navigation: any;
  sampleList: {
    id: number;
    label: string;
    disabled: boolean;
    quantity: number;
    barcode: {
      barcode_no: string;
      isError: boolean;
    }[];
    sampleValue: number;
    hasMore: boolean;
    showItems: number;
  }[];
  hospitalId: string;
  onBack: () => void;
}

interface S {
  sampleList: {
    id: number;
    label: string;
    disabled: boolean;
    quantity: number;
    barcode: {
      barcode_no: string;
      isError: boolean;
    }[];
    sampleValue: number;
    hasMore: boolean;
    showItems: number;
  }[];
  openAddProductModel: boolean;
  selectedBarcode: string;
  selectedBarcodeIndex: number;
  duplicate: boolean;
  showBarcodeModal: boolean;
  tabShowValue: number;
  selectedSampleValue: number;
  openProductDetailPage: boolean;
  openProductPage: boolean;
  selectedIndex: number;
  error: boolean;
  selectedSample: number;
  key: number;
  parentDuplicateText: string;
  isLoading: boolean;
  showReadyToShipModal: boolean;
  showSuccessModal: boolean;
  duplicateBarcodeIndex: number;
  accessToken: string;
  userId: string;
  notInDataBase: string[];
  alreadyUsed: string[];
  collected: string[];
  scannedBarcode: string;
  validBarcodes: string[];
  response: any;
}

interface SS {
  id: any;
}

export default class FranchiseProductScanningController extends BlockComponent<
  Props,
  S,
  SS
> {
  scrollParentRef: any = null;
  addSampleApiCallId: string = "";
  checkSampleApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      sampleList: [],
      selectedBarcode: "",
      selectedBarcodeIndex: 0,
      duplicate: false,
      showBarcodeModal: false,
      openAddProductModel: false,
      tabShowValue: 0,
      selectedSampleValue: -1,
      openProductDetailPage: false,
      openProductPage: true,
      selectedIndex: 0,
      error: true,
      selectedSample: -1,
      key: 0,
      parentDuplicateText: "",
      isLoading: false,
      showReadyToShipModal: false,
      showSuccessModal: false,
      duplicateBarcodeIndex: -1,
      accessToken: "",
      userId: "",
      notInDataBase: [],
      alreadyUsed: [],
      collected: [],
      scannedBarcode: '',
      validBarcodes: [],
      response: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const data = userDetailsFromStorage();
    if (data?.attributes && data.id) {
      this.setState({
        accessToken: data.attributes.token,
        userId: data.id,
        sampleList: this.props.sampleList,
        selectedSample: 0,
      });
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
  }

  openCloseAddProductModal = () => {
    this.setState({
      openAddProductModel: !this.state.openAddProductModel,
    });
  };

  createObject = (
    digit: number,
    dataList: {
      barcode_no: string;
      isError: boolean;
    }[]
  ) => {
    let list = [...dataList];
    const no = list.length - digit;
    if (no > 0) {
      list = list.splice(0, digit);
    } else {
      list = [
        ...list,
        ...Array.from({ length: Math.abs(no) }, (_) => ({
          barcode_no: "",
          isError: false,
        })),
      ];
    }
    return list;
  };

  isHaveMorePages = (digit: number, showItems: number) => {
    if (digit > showItems) {
      return true;
    }
    return false;
  };

  submitSampleValues = (
    data: {
      label: string;
      disabled: boolean;
      quantity: number;
    }[]
  ) => {
    const newListData = data.map((detail, index) => {
      return {
        ...this.state.sampleList[index],
        quantity: detail.quantity,
        disabled: detail.quantity > 0 ? false : true,
        hasMore: this.isHaveMorePages(detail.quantity, 10),
        showItems: 10,
        barcode: this.createObject(
          detail.quantity,
          this.state.sampleList[index].barcode
        ),
      };
    });
    this.setState({
      sampleList: newListData,
      openAddProductModel: false,
      tabShowValue: 0,
    });
  };

  handleChange = async (event: any, newValue: any) => {
    this.setState(
      {
        tabShowValue: newValue,
      },
      () => this.setSample(newValue)
    );
  };

  showValidSample = () => {
    const { sampleList } = this.state;
    const data = sampleList.filter((item: any) => {
      if (item.quantity > 0) return item;
    });
    return data;
  };

  deleteBarCode = async (index: number) => {
    const { selectedSample } = this.state;
    const sampleData = this.showValidSample();
    const oneData = sampleData[selectedSample].barcode[index];
    oneData.barcode_no = "";
    oneData.isError = false;
    sampleData[selectedSample].barcode.splice(index, 1, oneData);
    this.setState(
      {
        sampleList: this.handleDuplicateSample(sampleData),
        selectedBarcodeIndex: 0,
        key: Number(new Date()),
        selectedBarcode: "",
        showBarcodeModal: false,
        duplicate: false,
        duplicateBarcodeIndex: -1,
      },
      async () => {
        await this.handleAutoFocusWithIndex(index);
      }
    );
  };

  handleAutoFocusWithIndex = async (index: number) => {
    if (index !== -1) {
      const barcodeClass = document.querySelectorAll(".barcode-number-details");
      if (barcodeClass) {
        const singleClass = barcodeClass[index];
        if (singleClass) {
          //@ts-ignore
          singleClass.focus();
          singleClass.scrollIntoView({
            block: "nearest",
            inline: "center",
          });
        }
      }
    }
  };

  handleDuplicateSample = (sampleData: any) => {
    const { sampleList } = this.state;
    let data = [...sampleList, ...sampleData];
    let uniqueArr = [...new Set(data)];
    return uniqueArr;
  };

  handleDuplicateBarcode = (value: string, index: any) => {
    const barcode = value.replace(/\D/g, "");
    const { selectedSample } = this.state;
    const sampleList = this.showValidSample();
    if (barcode.length === 12) {
      if (barcode) {
        const ParentIndex = sampleList.findIndex((item) =>
          item.barcode.find((item) => item.barcode_no === barcode)
        );
        const duplicateData = sampleList.some((item) =>
          item.barcode.find((item) => item.barcode_no === barcode)
        );
        if (duplicateData) {
          const parentDuplicateIndex = sampleList[ParentIndex].barcode.findIndex(
            (item) => {
              return item.barcode_no === barcode;
            }
          );
          if (
            parentDuplicateIndex === index &&
            ParentIndex === this.state.selectedSample
          ) {
            return true;
          }
          this.setState({
            selectedBarcodeIndex: index,
            selectedBarcode: barcode,
            showBarcodeModal: true,
            duplicate: true,
            duplicateBarcodeIndex: parentDuplicateIndex,
            key: Number(new Date()),
            parentDuplicateText: sampleList[ParentIndex].label,
          });
          return true;
        }
      }
    }
    const oneData = sampleList[selectedSample].barcode[index];
    oneData.barcode_no = barcode;
    oneData.isError = barcode.length > 0 && barcode.length < 12;
    sampleList[selectedSample].barcode.splice(index, 1, oneData);
    this.setState({
      sampleList: this.handleDuplicateSample(sampleList),
    },
    () => {
      if (barcode.length === 12 && !this.state.duplicate) {
        this.checkBarcodeSampleApiCall(barcode);
      }
    }
    );
    return false;
  };

  setSample = (index: number) => {
    this.setState({
      selectedSample: index,
    });
  };

  handleScanBarcode = () => {
    const { selectedSample } = this.state;
    const sampleData = this.showValidSample();
    const index = sampleData[selectedSample].barcode.findIndex((item) => {
      return item.barcode_no === "";
    });
    this.setState({
      selectedBarcodeIndex: index,
      selectedBarcode: "",
      showBarcodeModal: true,
      duplicate: false,
    });
  };

  handleScanBarcodeNext = (barcode: string, index: number) => {
    const { selectedSample } = this.state;
    const sampleData = this.showValidSample();
    if (!this.handleDuplicateBarcode(barcode, index)) {
      const oneData = sampleData[selectedSample].barcode[index];
      oneData.barcode_no = barcode;
      oneData.isError = false;
      sampleData[selectedSample].barcode.splice(index, 1, oneData);
      this.setState({
        sampleList: this.handleDuplicateSample(sampleData),
        selectedBarcodeIndex: 0,
        key: Number(new Date()),
        selectedBarcode: "",
        showBarcodeModal: false,
      });
    }
  };

  onRemoveSampleOption = (sampleValue: number) => {
    const { sampleList } = this.state;
    const selectedSample = sampleList.findIndex(
      (i) => i.sampleValue === sampleValue
    );
    sampleList[selectedSample] = {
      ...this.state.sampleList[selectedSample],
      barcode: [],
      disabled: true,
      quantity: 0,
    };
    this.setState({
      sampleList,
      tabShowValue: 0,
    });
  };

  onEditProductDetails = (sampleValue: number) => {
    this.setState({
      tabShowValue: sampleValue,
      openProductDetailPage: false,
      openProductPage: true,
    });
  };

  backProductPage = () => {
    this.setState({
      openProductPage: true,
      openProductDetailPage: false,
    });
  };

  redirectToInputField = (sampleIndex: number) => {
    let matchedIndex = this.showValidSample()[sampleIndex]?.barcode.findIndex(
      (item) => {
        return item.barcode_no === "" || item.isError;
      }
    );
    if (matchedIndex !== -1) {
      const barcodeClass = document.querySelectorAll(".barcode-number-details");
      if (barcodeClass) {
        const singleClass = barcodeClass[matchedIndex];
        if (singleClass) {
          //@ts-ignore
          singleClass.focus();
          singleClass.scrollIntoView({
            block: "nearest",
            inline: "center",
          });
        }
      }
    }
  };

  handleContinues = async () => {
    const sampleIndex = this.showValidSample().findIndex((item) =>
      item.barcode.some(
        (no) =>
          no.barcode_no === "" ||
          no.isError ||
          this.state.notInDataBase.includes(no.barcode_no) ||
          this.state.alreadyUsed.includes(no.barcode_no) ||
          this.state.collected.includes(no.barcode_no)
      )
    );
    if (sampleIndex !== -1) {
      const isError = this.showValidSample().findIndex((item) =>
        item.barcode.some((no) => no.barcode_no === "" || no.isError)
      );
      if (isError !== -1) {
        this.setState({
          error: true,
        });
      }
      if (this.state.tabShowValue !== sampleIndex) {
        await this.handleChange({}, sampleIndex);
      }
      this.redirectToInputField(sampleIndex);
      return;
    }
    this.setState({
      error: false,
      openProductDetailPage: true,
      openProductPage: false,
    });
  };

  increasePageNoValue = (sampleValue: number) => {
    const { sampleList } = this.state;
    const selectedSample = sampleList.findIndex(
      (i) => i.sampleValue === sampleValue
    );
    if (
      this.isHaveMorePages(
        sampleList[selectedSample].quantity,
        sampleList[selectedSample].showItems
      )
    ) {
      sampleList[selectedSample].showItems =
        sampleList[selectedSample].showItems + 10;
      sampleList[selectedSample].hasMore = true;
      this.setState({
        sampleList,
      });
    } else {
      sampleList[selectedSample].hasMore = false;
      this.setState({
        sampleList,
      });
    }
  };

  showReadyToShipModal = () => {
    this.setState({
      showReadyToShipModal: true,
    });
  };

  hideReadyToShipModal = () => {
    this.setState({
      showReadyToShipModal: false,
    });
  };

  showSuccessModal = () => {
    this.setState({
      showSuccessModal: true,
    });
  };

  hideSuccessModal = () => {
    this.setState(
      {
        showSuccessModal: false,
      },
      () => {
        this.props.onBack();
      }
    );
  };

  addBarcodeSampleApiCall = () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.addSampleContentType,
    };

    const httpBody = {
      hospital_id: this.props.hospitalId,
      sampleList: this.showValidSample().map((item) => ({
        sample_id: item.id,
        quantity: item.quantity,
        barcode: item.barcode.map((item) => item.barcode_no),
      })),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addSampleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addSampleApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addSampleApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  checkBarcodeSampleApiCall = (barcode: string) => {
    this.setState({scannedBarcode: barcode});

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.checkSampleContentType,
    };

    const httpBody = {
      hospital_id: this.props.hospitalId,
      quantity: 1,
      barcode: [barcode]
     
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkSampleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkSampleApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.checkSampleApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleError = (error: any) => {
    this.hideReadyToShipModal();
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else if (error?.message) {
      const { notInDataBase, alreadyUsed, collected, duplicate } = this.state;
      const newNotInDataBase = [
        ...notInDataBase,
        ...error?.not_in_database || [],
      ];
      const newAlreadyUsed = [
        ...alreadyUsed,
        ...error?.already_used || [],
      ];
      const newCollected = [
        ...collected,
        ...error?.already_collected|| [],
      ];  
      this.setState(
        {
          notInDataBase: newNotInDataBase,
          alreadyUsed: newAlreadyUsed,
          collected: newCollected
        },
        () => {
          this.backProductPage();
        }
      );
      toast.error(error.message);
    } else {
      toast.error("Something went to wrong please retry");
    }
  };

  handleValidBarcode = (barcode: string) => {
    this.setState((prevState) => ({
      validBarcodes: [...prevState.validBarcodes, barcode],
    }));
    toast.success('Sample Scanned Successfully!!');
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        this.handleError(responseJson.error);
        this.setState({
          response: responseJson
        })
      }
      else if (apiRequestCallId && responseJson && !responseJson.error) {
        if (
          apiRequestCallId === this.addSampleApiCallId &&
          responseJson?.meta.message
        ) {
          this.hideReadyToShipModal();
          this.showSuccessModal();
        }
        else if (
          apiRequestCallId === this.checkSampleApiCallId
        ) {
          this.handleValidBarcode(this.state.scannedBarcode)
          this.setState({
            response: responseJson
          })
        }
      }
      else{
        toast.error("Something went to wrong please retry.");
      }
      this.setState({
        isLoading: false,
      });
    }
  }
}
// Customizable Area End