// Customizable Area Start
import React from "react";
export const logo = require("../assets/logo.png");
export const userIcon = require("../assets/avatar.png");
export const collectIcon = require("../assets/Bitmap.png");
export const ship = require("../assets/ship.png");
export const subFranchise = require("../assets/TeamleaderDashboard/sub_franchise.png");
export const home = require("../assets/home.png");
export const profile = require("../assets/profile/profile.png");
export const rightArrow = require("../assets/profile/right_arrow.png");
export const work = require("../assets/profile/Work.png");
export const logout = require("../assets/profile/Logout.png");
export const plus = require("../assets/profile/plus.png");
export const minus = require("../assets/profile/minus.png");
export const close = require("../assets/shipCollected/close.png");
export const calander = require("../assets/TeamleaderDashboard/Shape_4.png");
export const whitecalander = require("../assets/TeamleaderDashboard/Shape_5.png");
export const dropdown = require("../assets/TeamleaderDashboard/Path_3.png");
export const dot = require("../assets/TeamleaderDashboard/ibutton.png");
export const ic_search = require("../assets/TeamleaderDashboard/ic_search.png");
export const ic_close = require("../assets/TeamleaderDashboard/close.png");
export const ic_plus = require("../assets/TeamleaderDashboard/ic_plus.png");
export const ic_minus = require("../assets/TeamleaderDashboard/ic_minus.png");
export const side_arrow = require("../assets/TeamleaderDashboard/Side_arrow.png");
export const Check_box = require("../assets/TeamleaderDashboard/Check_box.png");
export const Leftbtn = require("../assets/TeamleaderDashboard/Leftbtn.png");
export const Scanner = require("../assets/TeamleaderDashboard/Scanner.png");
export const deliveryImage = require("../assets/delivery.png");
export const labSampleImage = require("../assets/labsample-image.png");
export const viewWorld = require("../assets/view-world.png");
export const calenderImage = require("../assets/Calendar.png");
export const downArrow = require("../assets/Arrow.png");
export const afpfWebImage = require("../assets/af_pf_web.png");
export const cancerWebImage = require("../assets/cancer_fluid_web.png");
export const cordBloodWebImage = require("../assets/cord_blood_web.png");
export const hbsagWebImage = require("../assets/hbsag_web.png");
export const urineWebImage = require("../assets/urine_web.png");
export const searchImage = require("../assets/magnify.png");
export const infoPrimaryImage = require("../assets/info_primary.png");
export const closeUpScientistLaboratory = require("../assets/close_up_scientist_laboratory _web.png");
export const threeSimilarcardboardBoxesWithCovers = require("../assets/three_similar_product_web.png");
export const closeUpHandHoldingScanner = require("../assets/close_up_hand_scanner_web.png");
export const plusWebImage = require("../assets/plus_image_web.png");
export const barcodeWebImage = require("../assets/barcode_white_web.png");
export const barcodeWebImageBlack = require("../assets/barcode_black_web.png");
export const Done = require("../assets/TeamleaderDashboard/Done.png");
export const sampleImage = require("../assets/sample_image.jpeg");
export const notFoundImageWeb = require("../assets/not_found_web.jpg");

export const DeleteImageComponent = ({ height = 21, width = 20, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="deleteon">
        <g id="fluentdelete-16-filled" transform="translate(8 8)">
          <path
            d="M10.5 4.5L13.5 4.5C13.5 3.67157 12.8284 3 12 3C11.1716 3 10.5 3.67157 10.5 4.5Z"
            id="Path"
          />
          <path
            d="M9 4.5C9 2.84315 10.3431 1.5 12 1.5C13.6569 1.5 15 2.84315 15 4.5L21 4.5C21.4142 4.5 21.75 4.83579 21.75 5.25C21.75 5.66421 21.4142 6 21 6L20.154 6L18.3465 19.257C18.093 21.1149 16.5061 22.5 14.631 22.5L9.369 22.5C7.49389 22.5 5.90702 21.1149 5.6535 19.257L3.846 6L3 6C2.58579 6 2.25 5.66421 2.25 5.25C2.25 4.83579 2.58579 4.5 3 4.5L9 4.5Z"
            id="Path"
          />
          <path
            d="M10.5 9.75C10.5 9.33579 10.1642 9 9.75 9C9.33579 9 9 9.33579 9 9.75L9 17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25L10.5 9.75Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M14.25 9C13.8358 9 13.5 9.33579 13.5 9.75L13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25L15 9.75C15 9.33579 14.6642 9 14.25 9Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
};

export const search = require("../assets/search/search.png");
export const right = require("../assets/search/right.png");
export const upArrow = require("../assets/search/up.png");
export const upDown = require("../assets/search/down.png");
export const add = require("../assets/search/add.png");
export const deleteIcon = require("../assets/search/delete.png");
export const scan = require("../assets/search/scan.png");
export const edit = require("../assets/search/edit.png");

export const DashboardImageFillComponent = ({
  height = 24,
  width = 24,
  color = "#FFFFFF",
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="IconlyBoldCategory">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="IconlyBoldCategory-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Category" transform="translate(2 2)">
          <path
            d="M2.54 0C1.14 0 0 1.15 0 2.561L0 5.97C0 7.39 1.14 8.53 2.54 8.53L5.92 8.53C7.33 8.53 8.46 7.39 8.46 5.97L8.46 2.561C8.46 1.15 7.33 0 5.92 0L2.54 0Z"
            id="Fill-1"
            fill={color}
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M2.54 11.4697C1.14 11.4697 0 12.6107 0 14.0307L0 17.4397C0 18.8497 1.14 19.9997 2.54 19.9997L5.92 19.9997C7.33 19.9997 8.46 18.8497 8.46 17.4397L8.46 14.0307C8.46 12.6107 7.33 11.4697 5.92 11.4697L2.54 11.4697Z"
            id="Fill-4"
            fill={color}
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M14.08 0C12.67 0 11.54 1.15 11.54 2.561L11.54 5.97C11.54 7.39 12.67 8.53 14.08 8.53L17.46 8.53C18.86 8.53 20 7.39 20 5.97L20 2.561C20 1.15 18.86 0 17.46 0L14.08 0Z"
            id="Fill-7"
            fill={color}
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M14.08 11.4697C12.67 11.4697 11.54 12.6107 11.54 14.0307L11.54 17.4397C11.54 18.8497 12.67 19.9997 14.08 19.9997L17.46 19.9997C18.86 19.9997 20 18.8497 20 17.4397L20 14.0307C20 12.6107 18.86 11.4697 17.46 11.4697L14.08 11.4697Z"
            id="Fill-9"
            fill={color}
            fillRule="evenodd"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
};

export const DashboardImageEmptyComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="IconlyLightCategory">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="IconlyLightCategory-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Category" transform="translate(2 2)">
          <path
            d="M17.5522 0C18.9037 0 20.0001 1.1059 20.0001 2.47018L20.0001 5.7641C20.0001 7.12735 18.9037 8.23429 17.5522 8.23429L14.2856 8.23429C12.933 8.23429 11.8367 7.12735 11.8367 5.7641L11.8367 2.47018C11.8367 1.1059 12.933 0 14.2856 0L17.5522 0Z"
            id="Stroke-1"
            fill="none"
            fillRule="evenodd"
            stroke="#909192"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.71449 0C7.06703 0 8.16341 1.1059 8.16341 2.47018L8.16341 5.7641C8.16341 7.12735 7.06703 8.23429 5.71449 8.23429L2.44892 8.23429C1.09638 8.23429 0 7.12735 0 5.7641L0 2.47018C0 1.1059 1.09638 0 2.44892 0L5.71449 0Z"
            id="Stroke-3"
            fill="none"
            fillRule="evenodd"
            stroke="#909192"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.71449 11.7657C7.06703 11.7657 8.16341 12.8716 8.16341 14.2369L8.16341 17.5298C8.16341 18.8941 7.06703 20 5.71449 20L2.44892 20C1.09638 20 0 18.8941 0 17.5298L0 14.2369C0 12.8716 1.09638 11.7657 2.44892 11.7657L5.71449 11.7657Z"
            id="Stroke-5"
            fill="none"
            fillRule="evenodd"
            stroke="#909192"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.5522 11.7657C18.9037 11.7657 20.0001 12.8716 20.0001 14.2369L20.0001 17.5298C20.0001 18.8941 18.9037 20 17.5522 20L14.2856 20C12.933 20 11.8367 18.8941 11.8367 17.5298L11.8367 14.2369C11.8367 12.8716 12.933 11.7657 14.2856 11.7657L17.5522 11.7657Z"
            id="Stroke-7"
            fill="none"
            fillRule="evenodd"
            stroke="#909192"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export const ChartImageEmptyComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="IconlyLightChart">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="IconlyLightChart-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Chart" transform="translate(2 2)">
          <path
            d="M5.37145 8.20172L5.37145 15.0619"
            id="Line_182"
            fill="none"
            fillRule="evenodd"
            stroke="#909192"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.0382 4.91913L10.0382 15.0618"
            id="Line_183"
            fill="none"
            fillRule="evenodd"
            stroke="#909192"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6285 11.8268L14.6285 15.0619"
            id="Line_184"
            fill="none"
            fillRule="evenodd"
            stroke="#909192"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.31429 0C2.04762 0 0 2.31208 0 5.58516L0 14.4148C0 17.6879 2.0381 20 5.31429 20L14.6857 20C17.9619 20 20 17.6879 20 14.4148L20 5.58516C20 2.31208 17.9619 0 14.6857 0L5.31429 0Z"
            id="Path"
            fill="none"
            fillRule="evenodd"
            stroke="#909192"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export const ChartImageFillComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="IconlyBoldChart">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="IconlyBoldChart-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Chart" transform="translate(2.0003967 2.0004272)">
          <path
            d="M0 5.33C0 1.929 1.929 0 5.33 0L14.669 0C18.07 0 19.99 1.929 20 5.33L20 14.67C20 18.07 18.07 20 14.669 20L5.33 20C1.929 20 0 18.07 0 14.67L0 5.33ZM9.219 15.11C9.27 15.54 9.629 15.86 10.049 15.86C10.48 15.86 10.839 15.54 10.879 15.11L10.879 4.92C10.919 4.61 10.77 4.299 10.5 4.13C10.219 3.96 9.879 3.96 9.61 4.13C9.339 4.299 9.19 4.61 9.219 4.92L9.219 15.11ZM5.389 15.86C4.959 15.86 4.599 15.54 4.56 15.11L4.56 8.19997C4.53 7.88897 4.679 7.57997 4.95 7.40997C5.219 7.23997 5.56 7.23997 5.83 7.40997C6.099 7.57997 6.25 7.88897 6.219 8.19997L6.219 15.11C6.179 15.54 5.82 15.86 5.389 15.86ZM13.82 15.11C13.86 15.54 14.219 15.86 14.65 15.86C15.07 15.86 15.429 15.54 15.48 15.11L15.48 11.83C15.509 11.509 15.36 11.21 15.089 11.04C14.82 10.87 14.48 10.87 14.2 11.04C13.929 11.21 13.78 11.509 13.82 11.83L13.82 15.11Z"
            id="Chart"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
};

export const RightDarkArrowComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="IconlyLightArrow---Right-2-Copy"
        transform="matrix(1.1924881E-08 1 -1 1.1924881E-08 24 0)"
      >
        <path
          d="M0 24L-1.04907e-06 0L24 -1.90735e-06L24 24L0 24Z"
          id="IconlyLightArrow---Right-2-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g
          id="IconlyLightArrow---Right-2"
          transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 24)"
        >
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="IconlyLightArrow---Right-2-(Background)"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g id="Arrow---Right-2" transform="translate(8.5 5)">
            <path
              d="M1.35122e-07 0L7 7L-4.76837e-07 14"
              id="Stroke-1"
              fill="none"
              fillRule="evenodd"
              stroke="#004D64"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const RightWhiteArrowComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="IconlyLightArrow---Right-2-Copy"
        transform="matrix(1.1924881E-08 1 -1 1.1924881E-08 24 0)"
      >
        <path
          d="M0 24L-1.04907e-06 0L24 -1.90735e-06L24 24L0 24Z"
          id="IconlyLightArrow---Right-2-(Background)"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g
          id="IconlyLightArrow---Right-2"
          transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 24)"
        >
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="IconlyLightArrow---Right-2-(Background)"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g id="Arrow---Right-2" transform="translate(8.5 5)">
            <path
              d="M1.35122e-07 0L7 7L-4.76837e-07 14"
              id="Stroke-1"
              fill="none"
              fillRule="evenodd"
              stroke="#ffffff"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const AddImageComponentArrowComponent = ({
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M14.2857 4L14.2857 14.2857L4 14.2857L4 17.7143L14.2857 17.7143L14.2857 28L17.7143 28L17.7143 17.7143L28 17.7143L28 14.2857L17.7143 14.2857L17.7143 4L14.2857 4Z"
          id="path_1"
        />
        <clipPath id="mask_1">
          <use xlinkHref="#path_1" />
        </clipPath>
      </defs>
      <g id="Icon--Add--Filled">
        <path
          d="M14.2857 4L14.2857 14.2857L4 14.2857L4 17.7143L14.2857 17.7143L14.2857 28L17.7143 28L17.7143 17.7143L28 17.7143L28 14.2857L17.7143 14.2857L17.7143 4L14.2857 4Z"
          id="Mask"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g clip-path="url(#mask_1)">
          <g id="↳-Icon-Color">
            <path
              d="M0 0L32 0L32 32L0 32L0 0Z"
              id="Rectangle"
              fill-opacity="1"
              fillRule="evenodd"
              stroke="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

// Customizable Area End
