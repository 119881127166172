// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  withStyles,
} from "@material-ui/core";

import FranchiseShipCollectedController, {
  Props,
} from "./FranchiseShipCollectedController.web";
import { FranchiseShipCollectedStyles } from "./FranchiseShipCollectedStyles.web";
import { RightArrowComponent } from "../../assets";
import BackButtonTitleWeb from "../../../../../components/src/BackButtonTitleWeb.web";

class FranchiseShipCollectedWeb extends FranchiseShipCollectedController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, navigation } = this.props;
    return (
      <Container
        maxWidth="lg"
        className={classes.ShipCollected}
        style={{ margin: "20px auto 0" }}
      >
        <BackButtonTitleWeb
          title="Total ship collected"
          buttonClick={() => navigation.goBack()}
        />
        <Box className="details">
          <Accordion>
            <AccordionSummary
              expandIcon={<RightArrowComponent />}
              aria-controls="panel1a-content"
            >
              <Typography className="accordion-heading">
                <span className="digit">01</span> AWB0004594940030000
              </Typography>
              <Box className="right-side">
                <Typography>10:11AM</Typography>
                <Typography>01/11/2022</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item md={8} sm={12} xs={12}>
                  <Box>
                    <div className="table">
                      <div className="table-header-row">
                        <div className="table-header-data">Serial no</div>
                        <div className="table-header-data">Barcode</div>
                      </div>
                      <div className="table-body">
                        <div className="table-row">
                          <div className="table-row-data">01</div>
                          <div className="table-row-data">
                            73764839292838899
                          </div>
                        </div>
                        <div className="table-row">
                          <div className="table-row-data">02</div>
                          <div className="table-row-data">
                            73764839292838899
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box className="content-right-details">
                    <Typography variant="h6">
                      Enter Service Provider Name
                    </Typography>
                    <Typography variant="body1">
                      DTDC Courier Services
                    </Typography>
                    <Typography variant="h6" className="margin-top">
                      Enter Docket Number
                    </Typography>
                    <Typography variant="body1">
                      1. AWB0004594940030000
                    </Typography>
                  </Box>
                </Grid>{" "}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    );
  }
}

export default withStyles(FranchiseShipCollectedStyles)(
  FranchiseShipCollectedWeb
);

// Customizable Area End
