// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  Typography,
  Box,
  withStyles
} from "@material-ui/core";
import { SuperTeamDeliverItemsStyle } from "./SuperTeamDeliveredItemsStyles.web";
import {
  barcodeWebImage,
  DeleteImageComponent,
  plusWebImage
} from "../../assets";
import BackButtonTitleWeb from "../../../../../components/src/BackButtonTitleWeb.web";
import BarcodeScanModalWeb from "../../../../../components/src/BarcodeScanModal.web";
import SuperTeamDeliverItems, {
  IProps
} from "./SuperTeamDeliveredItemController.web";
import SuccessModalWeb from "../../../../../components/src/SuccessModalWeb";
import Loader from "../../../../../components/src/Loader.web";
import ReadyShipDialog from "../../../../../components/src/ReadyShipDialog.web";
import { getFormatDate } from "../../../../../components/src/utils";
// Customizable Area End
interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
class SuperTeamDeliveredItem extends SuperTeamDeliverItems {
  constructor(props: IProps) {
    super(props);
  }

  renderSuperTeamError = (barcode: string, isError: boolean) => {
    const {
      notInDataBase,
      notShipped,
      alreadyReceived,
      barcodeNotInFranchise,
      successData
    } = this.state;
    const validData: any = successData.find(
      data => data.barcode_number === barcode
    );
    const productName: string = validData && validData.product_name;
    const hospitalName: string = validData && validData.hospital_name;
    const pickupDate: string = getFormatDate(
      validData && validData.pickup_date
    );

    let notInDataBaseMessage = "This barcode does not exist in the system";
    let notShippedMessage = "This barcode has not been shipped yet";
    let alreadyReceivedMessage = "This barcode has already been received";
    let notInFranchiseMessage =
      "Invalid teamlead or franchise or sub franchise";
    if (validData && successData) {
      return (
        <div className="success-text">
          <strong>Pickup Date:-</strong>
          {pickupDate}, <strong>Product Type:-</strong> {productName},{" "}
          <strong>Hospital Name:-</strong> {hospitalName}
        </div>
      );
    }
    if (notInDataBase.includes(barcode)) {
      return <div className="error-text">{notInDataBaseMessage}</div>;
    } else if (alreadyReceived.includes(barcode)) {
      return <div className="error-text">{alreadyReceivedMessage}</div>;
    } else if (notShipped.includes(barcode)) {
      return <div className="error-text">{notShippedMessage}</div>;
    } else if (barcodeNotInFranchise.includes(barcode)) {
      return <div className="error-text">{notInFranchiseMessage}</div>;
    } else if (isError) {
      return <div className="error-text">Barcode must be 12 digit</div>;
    } else {
      return <></>;
    }
  };

  render() {
    const { classes, onBack } = this.props;
    return (
      <Container
        style={{ margin: "20px auto" }}
        maxWidth="lg"
        className={classes && classes.Picker}
      >
        <Loader loading={this.state.isLoading} />
        <BackButtonTitleWeb buttonClick={() => onBack()} />
        <Box
          className="barcode-heading-container"
          style={{
            marginRight: `${this.state.scanningData.length > 9 ? "18px" : 0}`
          }}
        >
          <Typography variant="body1" className="sr-no">
            Serial No
          </Typography>
          <Typography variant="body1" className="barcode-no">
            Barcode Number
          </Typography>
        </Box>
        {this.state.isError && (
          <div className="error">Please fill one barcode details</div>
        )}
        <Box
          className="barcode-detail-container"
          style={{
            paddingRight: `${this.state.scanningData.length > 9 ? "10px" : 0}`
          }}
        >
          {this.state.scanningData.map((data, index) => {
            return (
              <>
                <Box className="barcode-details-row" key={index}>
                  <Typography variant="body1" className="sr-no-details">
                    {index < 9 ? `0${index + 1}` : index + 1}
                  </Typography>
                  <div
                    style={{
                      position: "relative",
                      width: "-webkit-fill-available"
                    }}
                    className={data.barcode ? "" : "disabled"}
                    key={this.state.key}
                  >
                    <TextField
                      index={index}
                      onEnding={(value, index) =>
                        this.handleDuplicateBarcode(value, index)
                      }
                      value={data.barcode}
                      validBarcodes={this.state.validBarcodes}
                    />
                    <DeleteImageComponent
                      className="delete-image"
                      onClick={() => data.barcode && this.onDeleteValue(index)}
                    />
                    {this.renderSuperTeamError(data.barcode, data.isError)}
                  </div>
                </Box>
              </>
            );
          })}
        </Box>
        <Box marginBottom={4} className="action-button">
          <Button
            className="scan-barcode"
            onClick={this.handleScanBarcode}
            disabled={
              this.state.scanningData.filter(item => item.barcode === "")
                .length === 0
            }
          >
            <img src={barcodeWebImage} />
            Scan Barcode
          </Button>
          <Box className="right-action-button">
            <Button
              className="add-more"
              variant="outlined"
              onClick={() => this.addInputField()}
            >
              <img src={plusWebImage} /> Add More
            </Button>
            <Button
              className="continue-button"
              onClick={() => this.checkAnyBarcodeFill()}
            >
              Save
            </Button>
          </Box>
        </Box>
        {this.state.showBarcodeModal && (
          <BarcodeScanModalWeb
            superTeamLead={true}
            stlResponse={this.state.stlResponse}
            scanApiforStl={this.stlScanningProductApiCall}
            open={this.state.showBarcodeModal}
            duplicateBarcodeIndex={this.state.duplicateBarcodeIndex}
            onClose={() =>
              this.state.duplicate
                ? this.onDeleteValue(this.state.selectedBarcodeIndex)
                : this.hideScanBarcodeModal()
            }
            duplicate={this.state.duplicate}
            selectedBarcode={this.state.selectedBarcode}
            selectedNumber={this.state.selectedBarcodeIndex}
            onRetake={() => this.onDeleteValue(this.state.selectedBarcodeIndex)}
            onNext={(codeData, index) =>
              this.handleScanBarcodeNext(codeData, index)
            }
          />
        )}
        <SuccessModalWeb
          message="Your items have been marked received"
          open={this.state.showSuccessModal}
          onClose={() => this.onCloseSuccessModal()}
        />
        <ReadyShipDialog
          message="Are you confirm to save?"
          open={this.state.openReadyToDelivered}
          onClose={() =>
            this.setState({
              openReadyToDelivered: false
            })
          }
          onSubmit={() => this.scanningProductApiCall()}
        />
      </Container>
    );
  }
}

export default withStyles(SuperTeamDeliverItemsStyle)(SuperTeamDeliveredItem);
interface IPropsTextField {
  value: string;
  onEnding: (itemValue: string, index: number) => void;
  index: number;
  validBarcodes?: string[];
}
interface STextField {
  itemValue: string;
}
class TextField extends React.Component<IPropsTextField, STextField> {
  constructor(props: IPropsTextField) {
    super(props);
    this.state = {
      itemValue: ""
    };
  }

  componentDidMount() {
    this.setState({
      itemValue: this.props.value
    });
  }

  render() {
    const isBarcodeValid =
      this.state.itemValue !== "" &&
      this.props.validBarcodes &&
      this.props.validBarcodes.includes(this.state.itemValue);
    return (
      <>
        <input
          className={
            this.state.itemValue
              ? "barcode-number-details"
              : "barcode-number-details error-barcode"
          }
          value={this.state.itemValue}
          key={`label${this.props.index}`}
          maxLength={12}
          onChange={event => {
            this.setState({
              itemValue: event.target.value.replace(/\D/g, "")
            });
          }}
          onBlur={() =>
            this.props.onEnding(this.state.itemValue, this.props.index)
          }
          readOnly={isBarcodeValid}
        />
      </>
    );
  }
}
// Customizable Area End
