// Customizable Area Start
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { SwipeableDrawer, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import { SideMenuStyles } from "./SideMenu.Styles.web";
import {
  LogOutImageComponent,
  PersonImageComponent,
  ProfileImageComponent,
  BarcodeImageComponent,
} from "./ImageComponent.web";
import { withRouter } from "react-router";
import { UserRoles } from "./utils";
import { ChartImageEmptyComponent, DashboardImageEmptyComponent } from "../../blocks/dashboard/src/assets";

const closeImage = require("./assets/close.svg");

interface ISideMenuProps {
  onClose: () => void;
  roles: string;
  open: boolean;
  classes?: any;
  location: any;
  history: any;
  match: any;
}

const SideMenu: React.FC<ISideMenuProps> = ({
  open,
  onClose,
  classes,
  history,
  roles,
}) => {
  const [activeKey, setActiveKey] = useState("profile");

  useEffect(() => {
    setActiveKey(window.location.pathname.replace("/", ""));
  }, [window.location.pathname]);

  const toggleDrawer = () => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    onClose();
  };

  const userLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRoles");
    localStorage.removeItem("userName");
    localStorage.removeItem("userData");
    history.push("/login");
  };

  const profileTab = () => {
    if (roles === UserRoles.FRANCHISE || roles === UserRoles.SUB_FRANCHISE) {
      return "franchise-profile";
    } else if (roles === UserRoles.SUPER_TEAM_LEAD) {
      return "super-team-profile";
    } else {
      return "team-profile";
    }
  };

  const collectMenuActive = () => {
    let classNames = "";
    if (
      activeKey.includes("/franchise-dashboard") ||
      activeKey.includes("hospital-search") ||
      activeKey.includes("team-dashboard") ||
      activeKey.includes("/team-franchise") ||
      activeKey.includes("ship-items")
    ) {
      classNames = "active";
    }
    return classNames;
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      className={classes.SideMenu}
      onClose={toggleDrawer()}
      onOpen={toggleDrawer()}
    >
      <div className="side-menu-header">
        <IconButton edge="end" onClick={toggleDrawer()}>
          <img src={closeImage} alt="close" />
        </IconButton>
      </div>
      <div className="side-menu-list-container">
        <List className="action-list">
          {roles === UserRoles.TEAM_LEADER && (
            <Link href="/team-dashboard">
              <ListItem button
                className={collectMenuActive()}
              >
                <ListItemIcon>
                  <DashboardImageEmptyComponent />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </Link>
          )}
          <Link href={profileTab()}>
            <ListItem
              button
              className={activeKey.includes("profile") ? "active" : ""}
            >
              <ListItemIcon>
                <ProfileImageComponent />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
          </Link>

          {roles === UserRoles.TEAM_LEADER && (
            <Link href="/team-franchise">
              <ListItem
                button
                className={activeKey.includes("team-franchise") ? "active" : ""}
              >
                <ListItemIcon>
                  <ChartImageEmptyComponent />
                </ListItemIcon>
                <ListItemText primary="Franchise" />
              </ListItem>
            </Link>
          )}
          {roles === UserRoles.TEAM_LEADER && (
            <Link href="/franchise-dashboard">
              <ListItem
                button
                className={activeKey.includes("franchise-dashboard") ? "active" : ""}
              >
                <ListItemIcon>
                  <PersonImageComponent />
                </ListItemIcon>
                <ListItemText primary="Collection and Shipping" />
              </ListItem>
            </Link>
          )}
          {roles === UserRoles.FRANCHISE && (
            <Link href="sub-franchise">
              <ListItem
                button
                className={activeKey.includes("sub-franchise") ? "active" : ""}
              >
                <ListItemIcon>
                  <BarcodeImageComponent />
                </ListItemIcon>
                <ListItemText primary="Sub Franchise" />
              </ListItem>
            </Link>
          )}
        </List>
        <List className="logout-list">
          <ListItem button onClick={() => userLogout()}>
            <ListItemIcon>
              <LogOutImageComponent />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  );
};
export default withRouter(withStyles(SideMenuStyles)(SideMenu));
// Customizable Area End
