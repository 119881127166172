Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.scanningProductApiEndPoint =
  "bx_block_catalogue/deliver_product_type_in_bulk";
exports.scanningProductMethod = "POST";
exports.scanningProductContentType = "application/json";
exports.stlScanningProductApiEndPoint =
  "bx_block_catalogue/individual_barcode_deliver";
exports.stlScanningProductMethod = "POST";
exports.stlScanningProductContentType = "application/json";
exports.stlScanningProductPositiveApiEndPoint =
  "bx_block_catalogue/check_barcode_to_mark_result";
exports.stlScanningProductPositiveMethod = "POST";
exports.stlScanningProductPositiveContentType = "application/json";
exports.stlScanningProductNegativeApiEndPoint =
  "bx_block_catalogue/check_barcode_to_mark_result";
exports.stlScanningProductNegativeMethod = "POST";
exports.stlScanningProductNegativeContentType = "application/json";
exports.dashboardSTLGetUrl = "bx_block_catalogue/get_sample_data_stl_dashboard";
exports.productScanningWithStatusApiEndPoint =
  "bx_block_catalogue/update_sample_status";
exports.productScanningWithStatusMethod = "PUT";
exports.productScanningWithStatusContentType = "application/json";
exports.superTeamDashboardApiEndPoint =
  "bx_block_catalogue/get_sample_data_stl_dashboard";
exports.superTeamDashboardMethod = "GET";
exports.superTeamDashboardContentType = "application/json";
exports.superTeamHospitalAndQuantityApiEndPoint =
  "bx_block_catalogue/get_teamlead_dashboard_data_for_samples";
exports.superTeamHospitalAndQuantityMethod = "GET";
exports.superTeamHospitalAndQuantityContentType = "application/json";
exports.franchiseDashboardApiEndPoint =
  "bx_block_catalogue/franchise_dashboard";
exports.franchiseDashboardMethod = "GET";
exports.franchiseDashboardContentType = "application/json";
exports.franchiseListApiMethod = "GET";
exports.franchiseListApiEndPoint = "account_block/account/franchise_listing";
exports.franchiseListApiContentType = "application/json";
exports.hospitalListApiMethod = "GET";
exports.hospitalListContentType = "application/json";
exports.hospitalListApiEndPoint = "/bx_block_catalogue/hospitals";
exports.teamDashboardApiEndPoint =
  "bx_block_catalogue/get_teamlead_dashboard_data";
exports.teamDashboardMethod = "GET";
exports.teamDashboardContentType = "application/json";
exports.docketAndBarcodeApiEndPoint =
  "bx_block_catalogue/docket_details_according_to_order";
exports.docketAndBarcodeMethod = "GET";
exports.docketAndBarcodeContentType = "application/json";
exports.productDetailTeamLeadDashboardApiEndPoint =
  "bx_block_catalogue/samples_type_data";
exports.productDetailTeamLeadDashboardMethod = "GET";
exports.productDetailTeamLeadDashboardContentType = "application/json";
exports.teamLeaderFranchiseProductDetailApiEndPoint =
  "bx_block_catalogue/data_of_sample_status_for_franchise";
exports.teamLeaderFranchiseProductDetailApiMethod = "GET";
exports.teamLeaderFranchiseProductDetailContentType = "application/json";
exports.productDetailFranchiseApiEndPoint = "bx_block_catalogue/product_types";
exports.productDetailFranchiseMethod = "GET";
exports.productDetailFranchiseContentType = "application/json";
// Customizable Area End