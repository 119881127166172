// Customizable Area Start
const primaryColor = "#004D64";

export const TermConditionPageStyles = {
  PageStyles: {
    "& .NavigationStyles": {
      backgroundColor: primaryColor,
      "& .navigation-container": {
        paddingTop: 10,
        paddingBottom: 10,
        "& .MuiIconButton-root": {
          paddingLeft: 0,
        },
        "& .MuiIconButton-colorInherit": {
          marginRight: 5,
        },
        "& .MuiToolbar-gutters": {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      "& .brand-logo-container img": {
        height: 67,
        width: 161.5,
      },
    },
    "& .PageStyles": {
      margin: "20px auto 0px",
      paddingBottom: "40px",
      "& .main-title": {
        color: primaryColor,
        margin: "0px auto 20px",
        fontSize: "50px",
        fontWeight: "600",
        lineHeight: "60px",
        textAlign: "center",
        fontFamily: "NotoSans-Regular",
        ["@media (max-width:962px)"]: {
          fontSize: "30px",
          lineHeight: "40px",
          fontWeight: "400",
        },
      },
      "& .description-text": {
        fontSize: "14px",
        margin: "0 0 10px",
        color: primaryColor,
      },
      "& .bold-text": {
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
    "& .FooterStyles": {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      minHeight: "40px",
      padding: "4px 0",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: primaryColor,
      "& .footer-container": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
      },
      "& .footer-text": {
        color: "#fff",
        fontSize: "14px",
      },
    },
  },
};
// Customizable Area End
