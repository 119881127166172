// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import {
  userDetailsFromStorage,
  userRedirectToLoginPage,
} from "../../../../../components/src/utils";
import React from "react";
import { toast } from "react-toastify";

export const configJSON = require("../../config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number;

  isLoading: boolean;
  userDetails: {
    full_name: string;
    phone_number: any;
    payroll_type: boolean;
    city: string;
    region: string;
    state: string;
    super_teamlead: string;
  };
  franchiseDetail: {
    id: string;
    type: string;
    attributes: {
      franchise_name: string;
      subfranchise_name: {
        data: {
          id: string;
          type: string;
          attributes: {
            name: string;
            hospital: {
              id: number;
              name: string;
              contact_person_name: string;
              contact_number: string;
              street: string;
              city: string;
              region: string;
              state: string;
              pincode: number;
            }[];
          };
        }[];
      };
      hospital: {
        id: number;
        name: string;
        contact_person_name: string;
        contact_number: string;
        street: string;
        city: string;
        region: string;
        state: string;
        pincode: number;
      }[];
    };
  }[];
  accessToken: string;
  userId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TeamLeaderProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  profileTeamApiCallId: string = "";
  franchiseListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];
    // Customizable Area Start
    this.state = {
      value: 0,
      isLoading: false,
      userDetails: {
        full_name: "",
        phone_number: "",
        payroll_type: false,
        super_teamlead: "",
        city: "",
        region: "",
        state: "",
      },
      franchiseDetail: [],
      accessToken: "",
      userId: "",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const data = userDetailsFromStorage();
    if (data?.attributes && data.id) {
      this.setState(
        {
          accessToken: data.attributes.token,
          userId: data.id,
        },
        () => {
          this.getTeamProfileDetails();
        }
      );
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
    // Customizable Area End
  }

  handleValueChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      value: newValue,
    });
    if (newValue === 0) {
      this.getTeamProfileDetails();
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        this.handleError(responseJson.error);
      }
      if (apiRequestCallId && responseJson && !responseJson.error) {
        if (
          apiRequestCallId === this.profileTeamApiCallId &&
          responseJson.data
        ) {
          this.handleResponseProfile(responseJson);
        } else if (
          apiRequestCallId === this.franchiseListApiCallId &&
          responseJson.data
        ) {
          this.setState({
            franchiseDetail: responseJson.data,
          });
        } else {
          toast.error("Something went to wrong please retry.");
        }
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleResponseProfile = (responseJson: any) => {
    this.setState({
      userDetails: {
        full_name: responseJson.data.attributes.full_name || "",
        phone_number: responseJson.data.attributes.phone_number || "",
        payroll_type: responseJson.data.attributes.payroll_type || false,
        city: responseJson.data.attributes.city || "",
        region: responseJson.data.attributes.area || "",
        state: responseJson.data.attributes.state || "",
        super_teamlead: responseJson.data.attributes.super_teamlead || "",
      },
    });
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else {
      toast.error("Something went to wrong please retry.");
    }
  };

  getTeamProfileDetails = async () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.profileApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileTeamApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.profileApiEndPoint}/${this.state.userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.profileApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFranchiseListDetails = async () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.franchiseListContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.franchiseListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.franchiseListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.franchiseListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
