// Customizable Area Start
const primaryColor = "#004d64";

export const FranchiseShipCollectedItemsStyles = {
  Ship: {
    "::-webkit-scrollbar": {
      width: "8px",
      height: "4px",
    },
    "& ::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#e3e9eb",
    },
    "& ::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "#004d64",
    },
  },
};

// Customizable Area End
