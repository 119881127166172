// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import {
  getFormatDate,
  userDetailsFromStorage,
  userRedirectToLoginPage,
} from "../../../../../components/src/utils";
import moment from "moment";
import { toast } from "react-toastify";

export const configJSON = require("../../config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dashboardData: {
    negativity: number;
    positivity: number;
    total_negative_sample: number;
    total_positive_sample: number;
    total_sample_received: number;
    total_sample_collected: number;
  };
  isLoading: boolean;
  userName: string;
  range: any;
  openDateRange: boolean;
  showDeliverItems: boolean;
  sampleValue: "Positive" | "Negative" | "Items";
  showPositiveSample: boolean;
  showNegativeSample: boolean;
  accessToken: string;
  userId: string;
  activeCalenderValue: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SuperTeamController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      dashboardData: {
        negativity: 0,
        positivity: 0,
        total_negative_sample: 0,
        total_positive_sample: 0,
        total_sample_received: 0,
        total_sample_collected: 0,
      },
      isLoading: false,
      userName: "",
      openDateRange: false,
      sampleValue: "Positive",
      showNegativeSample: false,
      showPositiveSample: false,
      range: [
        {
          startDate: moment()
            .clone()
            .startOf("month")
            .toDate(),
          endDate: new Date(),
          key: "rollup",
        },
      ],
      showDeliverItems: false,
      accessToken: "",
      userId: "",
      activeCalenderValue: "This Month",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    window.scroll(0, 0);
    const name = localStorage.getItem("userName");
    if (name) {
      this.setState({
        userName: name,
      });
    }
    const data = userDetailsFromStorage();
    if (data?.attributes && data.id) {
      this.setState(
        {
          accessToken: data.attributes.token,
          userId: data.id,
        },
        () => {
          this.getDashboardDetails();
        }
      );
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  openOrCloseDateRangePicker = (isApiCall: boolean) => {
    this.setState({
      openDateRange: !this.state.openDateRange,
    });
    if (isApiCall) {
      this.getDashboardDetails();
    }
  };

  showPositiveSample = () => {
    this.setState({
      showPositiveSample: true,
      sampleValue: "Positive",
    });
  };

  hideDeliverItems = () => {
    this.setState(
      {
        showDeliverItems: false,
      },
      () => {
        this.getDashboardDetails();
      }
    );
  };

  hidePositiveSample = () => {
    this.setState(
      {
        showPositiveSample: false,
      },
      () => {
        this.getDashboardDetails();
      }
    );
  };

  showNegativeSample = () => {
    this.setState({
      showNegativeSample: true,
      sampleValue: "Negative",
    });
  };

  hideNegativeSample = () => {
    this.setState(
      {
        showNegativeSample: false,
      },
      () => {
        this.getDashboardDetails();
      }
    );
  };

  getDashboardDetails = () => {
    this.setState({ isLoading: true });

    const start_Date = getFormatDate(this.state.range[0].startDate);
    const end_Date = getFormatDate(this.state.range[0].endDate);

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.superTeamDashboardContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDashboardItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.superTeamDashboardApiEndPoint}?start_date=${start_Date}&end_date=${end_Date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.superTeamDashboardMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else {
      toast.error("Something went to wrong please retry");
    }
  };

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
      // istanbul ignore next
    if (apiRequestCallId === this.apiDashboardItemCallId) {
      // istanbul ignore next
      if (responseJson.total_sample_received >= 0) {
      // istanbul ignore next
        this.setState({
          dashboardData: {
            total_sample_received: responseJson.total_sample_received,
            negativity: responseJson.negativity,
            positivity: responseJson.positivity,
            total_negative_sample: responseJson.total_negative_sample,
            total_positive_sample: responseJson.total_positive_sample,
            total_sample_collected: responseJson.total_sample_collected,
          },
        });
      } else {
      // istanbul ignore next
      // istanbul ignore next
        this.setState({
          dashboardData: {
            total_sample_received: 0,
            negativity: 0,
            positivity: 0,
            total_negative_sample: 0,
            total_positive_sample: 0,
            total_sample_collected: 0,
          },
        });
      }
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        if (apiRequestCallId === this.apiDashboardItemCallId)
          this.handleError(responseJson.error);
      }
      if (apiRequestCallId && responseJson && !responseJson.error) {
        if (apiRequestCallId === this.apiDashboardItemCallId)
          this.handleResponse(apiRequestCallId, responseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }
}
