// Customizable Area Start
import React, { useEffect, useCallback } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import "./demo.css";

interface IProps {
  _onDetected: (result: any) => void;
  showAlert?: boolean;
}

const BarcodeScanner: React.FC<IProps> = ({ _onDetected, showAlert }) => {
  const codeReader = new BrowserMultiFormatReader();

  const initializeSingleScan = useCallback(async () => {
    try {
      const result = await codeReader.decodeFromInputVideoDevice(
        undefined,
        "video"
      );
      codeReader.reset();
      _onDetected(result);
    } catch (e) {
      console.log(e);
    }
  }, [codeReader]);

  useEffect(() => {
    initializeSingleScan();
    return () => {
      codeReader.reset();
    };
  }, [showAlert]);
  return <video id="video" width="362px" height="220px" />;
};
export default BarcodeScanner;
// Customizable Area End
