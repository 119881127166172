// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  withStyles,
} from "@material-ui/core";
import {
  barcodeWebImage,
  plusWebImage,
} from "../../../assets";
import { FranchiseProductScanningStyles } from "./FranchiseProductScanningStyles.web";
import BarcodeScanModalWeb from "../../../../../../components/src/BarcodeScanModal.web";
import BackButtonTitleWeb from "../../../../../../components/src/BackButtonTitleWeb.web";
import FranchiseAddProductDetail from "./FranchiseAddProductDetail.web";
import FranchiseProductDetail from "./FranchiseProductDetail.web";
import InfiniteScroll from "react-infinite-scroller";
import FranchiseProductScanningController, {
  Props,
} from "./FranchiseProductScanningController.web";
import Loader from "../../../../../../components/src/Loader.web";
import ReadyShipDialog from "../../../../../../components/src/ReadyShipDialog.web";
import SuccessModalWeb from "../../../../../../components/src/SuccessModalWeb";
import TextField, {
  TabPanel,
} from "../../../../../../components/src/TextField.web";

class FranchiseProductScanning extends FranchiseProductScanningController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes, onBack } = this.props;
    const validSampleList = this.showValidSample();
    return (
      <>
        <Loader loading={this.state.isLoading} />
        {this.state.openProductPage && (
          <Box className={classes?.Product}>
            <>
              <BackButtonTitleWeb
                title="Scan Barcode Here"
                buttonClick={() => onBack()}
              />
              {validSampleList[0] && (
                <Box className="nav-tab-container">
                  <Typography className="tab-heading">
                    Select Product
                  </Typography>
                  <Tabs
                    value={this.state.tabShowValue}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="standard"
                    aria-label="full width tabs example"
                    className="navigation-tabs"
                  >
                    {validSampleList.map((item, index) => {
                      return (
                        <Tab
                          value={index}
                          label={item.label}
                          key={item.label}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
              )}
            </>
            <Box>
              {validSampleList.map((data: any, outer_index: any) => {
                return (
                  <TabPanel
                    value={this.state.tabShowValue}
                    index={outer_index}
                    dir={"x"}
                    key={outer_index}
                  >
                    <Box className="barcode-heading-container">
                      <Typography variant="body1" className="sr-no">
                        Serial No
                      </Typography>
                      <Typography variant="body1" className="barcode-no">
                        Barcode Number
                      </Typography>
                    </Box>

                    {this.state.error && (
                      <Typography className="error">
                        Please scan all the barcode
                      </Typography>
                    )}
                    <div
                      className="barcode-detail-container"
                      ref={(ref) => (this.scrollParentRef = ref)}
                      style={{
                        paddingRight: `${data.quantity > 5 ? "10px" : 0}`,
                      }}
                    >
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={() =>
                          this.increasePageNoValue(data.sampleValue)
                        }
                        hasMore={data.hasMore}
                        loader={<h4>Loading ...</h4>}
                        useWindow={false}
                        getScrollParent={() => this.scrollParentRef}
                      >
                        {data.barcode
                          .slice(0, data.showItems)
                          .map((item: any, index: number) => {
                            return (
                              <Box className="barcode-details-row" key={index}>
                                <Typography
                                  variant="body1"
                                  className="sr-no-details"
                                >
                                  {index < 9 ? `0${index + 1}` : index + 1}
                                </Typography>
                                <TextField
                                  validBarcodes={this.state.validBarcodes}
                                  notInDataBase={this.state.notInDataBase}
                                  alreadyUsed={this.state.alreadyUsed}
                                  collected={this.state.collected}
                                  value={item.barcode_no}
                                  index={index}
                                  key={this.state.key}
                                  isError={item.isError}
                                  setSample={() => this.setSample(outer_index)}
                                  deleteBarCode={this.deleteBarCode}
                                  onEnding={(value, index) => {
                                    this.setState({
                                      selectedIndex: -1,
                                      selectedBarcodeIndex: index,
                                    });
                                    this.handleDuplicateBarcode(value, index);
                                  }}
                                />
                              </Box>
                            );
                          })}
                      </InfiniteScroll>
                    </div>
                  </TabPanel>
                );
              })}
              <Box marginBottom={4} className="action-button">
                {validSampleList[0] && (
                  <Button
                    className="scan-barcode"
                    disabled={
                      validSampleList[this.state.tabShowValue].barcode.filter(
                        (item) => item.barcode_no === ""
                      ).length === 0
                    }
                    onClick={() => this.handleScanBarcode()}
                  >
                    <img src={barcodeWebImage} />
                    Scan Barcode
                  </Button>
                )}
                <Box className="right-action-button">
                  <Button
                    className="add-more"
                    variant="outlined"
                    onClick={() => this.openCloseAddProductModal()}
                  >
                    <img src={plusWebImage} /> Add More
                  </Button>
                  {validSampleList[0] && (
                    <Button
                      className="continue-button"
                      onClick={() => this.handleContinues()}
                    >
                      Continue
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <FranchiseAddProductDetail
          open={this.state.openAddProductModel}
          closeDialog={() => this.openCloseAddProductModal()}
          submitSampleValues={this.submitSampleValues}
          sampleList={this.state.sampleList.map((item) => {
            return {
              label: item.label,
              quantity: item.quantity,
              disabled: item.disabled,
            };
          })}
        />
        {this.state.showBarcodeModal && (
          <BarcodeScanModalWeb
            response={this.state.response}
            franchise={true}
            checkBarcodeSampleApiForFranchise={this.checkBarcodeSampleApiCall}
            open={this.state.showBarcodeModal}
            onClose={() => this.deleteBarCode(this.state.selectedBarcodeIndex)}
            duplicateSampleName={this.state.parentDuplicateText}
            duplicate={this.state.duplicate}
            duplicateBarcodeIndex={this.state.duplicateBarcodeIndex}
            selectedNumber={this.state.selectedBarcodeIndex}
            selectedBarcode={this.state.selectedBarcode}
            onRetake={() => this.deleteBarCode(this.state.selectedBarcodeIndex)}
            onNext={(value: string, index: number) =>
              this.handleScanBarcodeNext(value, index)
            }
          />
        )}
        {this.state.openProductDetailPage && (
          <FranchiseProductDetail
            onBack={() => this.backProductPage()}
            onDiscard={() => onBack()}
            onRemoveSampleOption={(sampleValue: number) =>
              this.onRemoveSampleOption(sampleValue)
            }
            onEditProductDetails={(name) => this.onEditProductDetails(name)}
            sampleList={validSampleList}
            showReadyToShipModal={this.showReadyToShipModal}
          />
        )}
        <ReadyShipDialog
          message="Are you ready to collect items now?"
          open={this.state.showReadyToShipModal}
          onClose={() => this.hideReadyToShipModal()}
          onSubmit={() => this.addBarcodeSampleApiCall()}
        />
        <SuccessModalWeb
          message="Your scanned items have been marked collected"
          open={this.state.showSuccessModal}
          onClose={() => this.hideSuccessModal()}
        />
      </>
    );
  }
}

export default withStyles(FranchiseProductScanningStyles)(
  FranchiseProductScanning
);
// Customizable Area End