// Customizable Area Start
import React from "react";

import {
  Container,
  Grid,
  Box,
  Link,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  withStyles,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  brandLogo,
  checkImage,
  flashScreenImage,
  infoError,
  uncheckImage,
  visibility,
  visibilityOff,
} from "./assets";
import { CustomInput } from "../../../components/src/CustomInput.web";
import { EmailAccountLoginStyle } from "./EmailAccountLoginStyle.web";
import { Formik } from "formik";

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";

class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  showLabelValue = () => {
    if (this.state.isPasswordForgot) {
      return (
        <Typography variant="h2">Request to recover your password</Typography>
      );
    } else if (this.state.isPasswordInLocalStorage) {
      return (
        <Typography variant="h2">
          Hello Again!
          <br />
          Welcome Back
        </Typography>
      );
    } else {
      return (
        <Typography variant="h2">
          Hello!
          <br />
          Welcome to Bio collection
        </Typography>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.Login}>
        <Grid
          container
          spacing={0}
          className="full-width-container"
          justifyContent="center"
        >
          <Grid item md={6} sm={12} xs={12}>
            <Box
              className="background-image-block"
              display={{ xs: "none", sm: "none", md: "block" }}
            >
              <img src={flashScreenImage} alt="login" />
              <Box className="image-text-container">
                <Typography variant="h1">Let's get started</Typography>
                <Typography variant="body1">
                  At Advy, we believe in building a rewarding culture that
                  promotes diversity, creativity, inclusion, and collaboration
                  among team members. We trust our associates to always deliver
                  the best outcome that is essential for our customers.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={8} xs={12}>
            <Box className="form-container">
              <Box className="brand-image-container">
                <img src={brandLogo} alt="Logo" />
              </Box>
              <br />
              <br />
              <br />
              <Box className="login-form">
                {this.showLabelValue()}
                <br />
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    username: this.state.username,
                    password: this.state.password,
                    isChecked: this.state.checkedRememberMe,
                  }}
                  validationSchema={this.FormSchemaWeb}
                  onSubmit={() => this.loginApi()}
                >
                  {({
                    errors,
                    values,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                  }) => {
                    return (
                      <form onSubmit={() => {}} autoComplete="off">
                        {this.state.isPasswordForgot ? (
                          <Typography
                            component="p"
                            className="recover-password-text"
                          >
                            To Recover your password please call or mail to
                            administrative for your new password. <br />
                            Nitesh Choudhary
                            <br />
                            +91 9082861725 <br />
                            nitesh.chaudhary@advychemical.com
                          </Typography>
                        ) : (
                          <>
                            <CustomInput
                              value={values.username}
                              onChange={(e) => {
                                setFieldValue("username", e.target.value);
                                this.setUserName(e.target.value);
                              }}
                              placeholder="Login ID"
                              errors={errors.username}
                              autoFocus
                            />
                            <CustomInput
                              value={values.password}
                              type={
                                this.state.enablePasswordField
                                  ? "password"
                                  : "text"
                              }
                              onChange={(e) => {
                                setFieldValue("password", e.target.value);
                                this.setPassword(e.target.value);
                              }}
                              errors={errors.password}
                              placeholder="Password"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    edge="end"
                                    className="password-show-images"
                                  >
                                    {this.state.enablePasswordField ? (
                                      <img src={visibilityOff} alt="" />
                                    ) : (
                                      <img src={visibility} alt="" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            {!this.state.errorInApi && (
                              <Box className="forgot-or-remember">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.isChecked}
                                      icon={
                                        <img
                                          src={uncheckImage}
                                          alt="unchecked"
                                        />
                                      }
                                      checkedIcon={
                                        <img src={checkImage} alt="unchecked" />
                                      }
                                      onChange={(e) => {
                                        this.setRememberMe(e.target.checked);
                                        setFieldValue(
                                          "isChecked",
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  }
                                  label="Remember Me"
                                />
                                <Typography
                                  component="p"
                                  className="forgot-text"
                                  onClick={this.setIsPasswordForgot}
                                >
                                  Forgot Password?
                                </Typography>
                              </Box>
                            )}
                          </>
                        )}

                        {this.state.errorInApi && (
                          <FormHelperText
                            component="div"
                            error
                            className="label-error"
                          >
                            <Typography component="div">
                              <Typography component="div">
                                <img
                                  src={infoError}
                                  alt="Icon"
                                  className="info-error-image"
                                />
                              </Typography>
                              <Typography
                                component="div"
                                className="error-text"
                              >
                                {this.state.errorInApi}
                              </Typography>
                            </Typography>
                          </FormHelperText>
                        )}

                        <Button
                          fullWidth
                          className="sign-in-button"
                          disabled={this.state.isLoading}
                          onClick={() => {
                            this.state.isPasswordForgot
                              ? (this.redirectLoginScreen(), resetForm())
                              : handleSubmit();
                          }}
                        >
                          {this.state.isLoading ? (
                            <CircularProgress className="loader-white" />
                          ) : (
                            "Sign in"
                          )}
                        </Button>
                        {this.state.errorInApi && (
                          <Typography
                            component="div"
                            className="text-center"
                            onClick={this.redirectLoginScreen}
                          >
                            Remember Password?
                            <Link className="link-text"> Login</Link>
                          </Typography>
                        )}
                      </form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(EmailAccountLoginStyle)(EmailAccountLoginBlock);

// Customizable Area End
