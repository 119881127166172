Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.hospitalListApiMethod = "GET";
exports.hospitalListContentType = "application/json";
exports.hospitalListApiEndPoint = "/bx_block_catalogue/hospitals";
exports.sampleListApiEndPoint = "/bx_block_catalogue/samples";
exports.sampleListApiMethod = "GET";
exports.sampleListContentType = "application/json";
exports.addSampleApiEndPoint = "/bx_block_catalogue/samples/add_quantity";
exports.addSampleApiMethod = "POST";
exports.addSampleContentType = "application/json";
exports.checkSampleApiEndPoint = "/bx_block_catalogue/check_barcode";
exports.checkSampleApiMethod = "POST";
exports.checkSampleContentType = "application/json";
exports.sampleCollectedHospitalEndPoint =
  "/bx_block_catalogue/sample_collected_hospitals_listing";
exports.sampleCollectedHospitalMethod = "GET";
exports.sampleCollectedHospitalContentType = "application/json";
exports.httpGetMethod = "GET";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.hospitalListApiMethod = "GET";
exports.hospitalListContentType = "application/json";
exports.hospitalListApiEndPoint = "/bx_block_catalogue/hospitals";
exports.serviceProviderDetailEndPoint =
  "bx_block_catalogue/service_provider_detail";
exports.serviceProviderDetailMethod = "POST";
exports.serviceProviderDetailContentType = "application/json";
exports.hospitalAssociationSampleEndPoint =
  "bx_block_catalogue/hospitals_associated_product";
exports.hospitalAssociationSampleMethod = "GET";
exports.hospitalAssociationSampleContentType = "application/json";
exports.shippingDetailsEndPoint = "bx_block_catalogue/shipping_orders";
exports.shippingDetailsMethod = "GET";
exports.shippingDetailsContentType = "application/json";
exports.serviceProviderDetailEndPoint = "bx_block_catalogue/service_provider_detail";
exports.serviceProviderDetailMethod = "POST";
exports.serviceProviderDetailContentType = "application/json";
// Customizable Area End
