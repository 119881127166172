// Customizable Area Start
export const FranchiseProductStyle = {
  ProductDetails: {
    "& .page-heading": {
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "30px",
      fontWeight: "700",
      lineHeight: "41px",
      marginTop: "16px",
    },
    "& .product-details": {
      height: "142px",
      borderRadius: "8px",
      justifyContent: "space-between",
      display: "flex",
      background: "#baeaf9",
      backgroundBlendMode: "normal",
      padding: "24px",
      marginBottom: "16px",
    },
    "& .product-details:last-child": { marginBottom: "0" },
    "& .product-details-left": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    "& .product-name": {
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "27px",
    },
    "& .remove-button": {
      padding: "0",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "18px",
      fontWeight: "300",
      textDecoration: "underline",
      lineHeight: "25px",
      letterSpacing: "normal",
      textTransform: "capitalize",
    },
    "& .product-details-right": {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
    "& .product-value": {
      color: "#004d64",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "24px",
      fontWeight: "400",
      borderRadius: "4px",
      background: "#e5f9ff",
      display: "flex",
      justifyContent: "center",
      backgroundBlendMode: "normal",
      alignItems: "center",
      padding: "2px 32px",
    },
    "& .edit-button": {
      padding: "0",
      minWidth: "unset",
      gap: "4px",
      "& .MuiButton-label": {
        color: "#004d64",
        fontFamily: "NotoSans-SemiBold",
        fontSize: "18px",
        fontWeight: "400",
        textTransform: "capitalize",
      },
      "& img": {
        width: "24px",
        height: "24px",
      },
    },
    "& .button-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .discard-button": {
      height: "64px",
      borderRadius: "8px",
      background: "#e30000",
      backgroundBlendMode: "normal",
      width: "162px",
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-SemiBold",
        fontSize: "20px",
        fontWeight: "400",
        textAlign: "center",
        textTransform: "capitalize",
      },
    },
    "& .ready-ship-button": {
      height: "64px",
      borderRadius: "8px",
      background: "#004d64;",
      backgroundBlendMode: "normal",
      width: "280px",
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-SemiBold",
        fontSize: "24px",
        fontWeight: "700",
        textAlign: "center",
        textTransform: "none",
      },
    },
  },
};
// Customizable Area End
