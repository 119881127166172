// Customizable Area Start
import React from "react";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FranchiseHospitalDetailStyles } from "./FranchiseHospitalDetailsStyles.web";
import { RightArrowComponent } from "../../assets";
import NotFound from "../../../../../components/src/NotFound.web";

interface Props {
  classes: any;
  loading: boolean;
  getHospitalListDetails: () => void;
  hospitalList: {
    id: string;
    type: string;
    attributes: {
      name: string;
      hospital: string;
      city: string;
      street: string;
      address: string;
    };
  }[];
}

interface S {}

class FranchiseHospitalDetails extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.props.getHospitalListDetails();
  }
  render() {
    const { classes, hospitalList, loading } = this.props;

    return (
      <Box className={classes.HospitalDetail}>
        {!loading && hospitalList.length === 0 ? (
          <NotFound />
        ) : (
          hospitalList.map((data, index) => {
            return (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<RightArrowComponent />}
                  aria-controls="panel1a-content"
                >
                  <Typography className="accordion-heading">
                    <span className="digit">
                      {index < 9 ? `0${index + 1}` : index + 1}
                    </span>{" "}
                    {data.attributes.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <Typography className="hospital-detail">
                        {data.attributes.name}
                      </Typography>
                      <Typography className="hospital-address">
                        {data.attributes.address}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </Box>
    );
  }
}
export default withStyles(FranchiseHospitalDetailStyles)(
  FranchiseHospitalDetails
);
// Customizable Area End
