// Customizable Area Start
const primaryColor = "#004d64";

export const FranchiseHospitalDetailStyles = {
  HospitalDetail: {
    "& .MuiAccordionSummary-root": {
      height: 64,
      padding: "0 20px",
      borderRadius: "4px",
      backgroundColor: primaryColor,
      color: "#fff",
    },
    "& .accordion-heading": {
      fontSize: "18px",
      lineHeight: "22px",
      fontFamily: "NotoSans-SemiBold",
      fontWeight: "400",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .digit": {
      marginRight: "5px",
    },
    "& .MuiAccordionDetails-root": {
      padding: "20px",
      color: "#004C63",
      backgroundColor: "#E5F9FF",
      borderRadius: "0 0 4px 4px",
      backgroundBlendMode: "normal",
      fontFamily: "NotoSans-Regular",
      fontSize: "14px",
      fontWeight: "300",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      marginBottom: "20px",
    },
    "& .MuiPaper-elevation1:last-child": {
      boxShadow: "none",
      marginBottom: "0",
    },
    "& .hospital-detail": {
      color: "#004D64",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "19px",
      marginBottom: "8px",
    },
    "& .hospital-address": {
      color: "#004c63",
      fontFamily: "NotoSans-Light",
      fontSize: "14px",
      fontWeight: "300",
      lineHeight: "19px",
    },
  },
};
// Customizable Area End
