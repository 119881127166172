// Customizable Area Start
import React, { useEffect, useState } from "react";

import { Dialog, Box, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { BarcodeModalStyles } from "./BarcodeScanModalStyles.web";
// @ts-ignore
import BarcodeScanner from "../../blocks/dashboard/src/web/superTeam/Barcodescanner/demo";
import Alert from "@material-ui/lab/Alert";
import { getFormatDate } from "./utils";
const barcodeBlackImage = require("./assets/barcode_black_web.png");
const cancelSearchImage = require("./assets/crossImage.svg");

interface IBarcodeScanProps {
  open: boolean;
  onClose: () => void;
  onRetake: () => void;
  classes: any;
  duplicate: boolean;
  selectedNumber: number;
  selectedBarcode: string;
  duplicateSampleName?: string;
  onNext: (value: string, index: number) => void;
  duplicateBarcodeIndex?: number;
  franchise?: boolean;
  checkBarcodeSampleApiForFranchise?: any;
  scanApiforStl?: any;
  superTeamLead?: boolean;
  response?: any;
  stlResponse?: any;
  stlPositiveOrNegative?: boolean;
  stlPositiveOrNegativeResponse?: any;
  scanApiForPositiveOrNegative?: any;
}

const BarcodeScanModalWeb: React.FC<IBarcodeScanProps> = ({
  open,
  onClose,
  classes,
  duplicate,
  selectedNumber,
  onRetake,
  selectedBarcode,
  onNext,
  duplicateSampleName,
  duplicateBarcodeIndex,
  franchise,
  checkBarcodeSampleApiForFranchise,
  response,
  stlResponse,
  scanApiforStl,
  superTeamLead,
  stlPositiveOrNegative,
  stlPositiveOrNegativeResponse,
  scanApiForPositiveOrNegative
}) => {
  const [retakeBarcode, setRetakeBarcode] = useState(false);
  const [isRetake, setIsRetake] = useState<boolean>(false)
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [duplicateData, setDuplicateData] = useState(false);
  const [state, setState] = useState("");

  useEffect(() => {
    if (selectedBarcode) {
      setScannedBarcode(selectedBarcode);
      setRetakeBarcode(duplicate);
    } else {
      setRetakeBarcode(false);
    }
  }, [selectedBarcode]);

  useEffect(() => {
    setDuplicateData(duplicate);
  }, [duplicate]);

  useEffect(() => {
    // @ts-ignore
    navigator.permissions.query({ name: "camera" }).then((res) => {
      if (res.state == "granted") {
        // has permission
        setState("granted");
      } else {
        setState("Not granted");
      }
    });
  }, [navigator.permissions]);

  const duplicateBarcodeNumber = (no: number) => {
    return no + 1;
  };

  const _onDetected = (result: any) => {
    //   @ts-ignore
    if (result && result.text && (result.format === 7 || result.format === 14)) {
      let resultBarcode;
      if (result.format === 14) {
        resultBarcode = 0 + result.text
      } else {
        resultBarcode = result.text
      }
      setScannedBarcode(result.text?.length > 0 ? resultBarcode?.slice(0, -1) : '');
      setShowAlert(false);
      setDuplicateData(false);
      setIsRetake(false);
    } else {
      setScannedBarcode("");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    return () => {
      setRetakeBarcode(false);
      setShowAlert(false);
    };
  }, []);

  useEffect(() => {
    const fetchBarcodeData = async () => {
      if (scannedBarcode && franchise && !duplicateData) {
        try {
          await checkBarcodeSampleApiForFranchise(scannedBarcode);
        } catch (error) {
          console.error("Error checking barcode:", error);
        }
      } else if (scannedBarcode && superTeamLead && !duplicateData) {
        try {
          await scanApiforStl(scannedBarcode);
        } catch (error) {
          console.error("Error checking barcode:", error);
        }
      } else if (scannedBarcode && stlPositiveOrNegative && !duplicateData) {
        try {
          await scanApiForPositiveOrNegative(scannedBarcode);
        } catch (error) {
          console.error("Error checking barcode:", error);
        }
      }

    };
    fetchBarcodeData();
  }, [scannedBarcode, duplicateData]);

  const renderBarcodeScanner = !scannedBarcode || isRetake || showAlert;
  const renderNextButton = (response?.success || stlResponse?.success || stlPositiveOrNegativeResponse?.success ) && scannedBarcode;

  const stlData = stlResponse?.data;
  const stlProductName = stlData?.product_name;
  const stlHospitalName = stlData?.hospital_name;
  const stlPickupDate = getFormatDate(stlData?.pickup_date);
  const stlSuccessCase = stlData && !isRetake && scannedBarcode;

  const stlPositiveOrNegativeData = stlPositiveOrNegativeResponse?.data;
  const stlPositiveOrNegativeProductName =  stlPositiveOrNegativeData?.product_name;
  const stlPositiveOrNegativeHospitalName = stlPositiveOrNegativeData?.hospital_name;
  const stlPositiveOrNegativePickupDate = getFormatDate(stlPositiveOrNegativeData?.pickup_date);
  const stlPositiveOrNegativeSuccessCase = stlPositiveOrNegativeData && !isRetake && scannedBarcode;

  const showStlErrorMessage = stlResponse?.error?.message && !isRetake && scannedBarcode;
  const showFranchiseErrorMessage = response?.error?.message && !isRetake && scannedBarcode;
  const showStlPositiveErrorMessage = stlPositiveOrNegativeResponse?.error?.message && !isRetake && scannedBarcode;

  const franchiseSuccessCase = response?.success && !isRetake && scannedBarcode;

  const showSuccessMessage = stlSuccessCase || franchiseSuccessCase || stlPositiveOrNegativeSuccessCase;

  return (
    <Dialog
      open={open}
      className={classes.BarcodeDialogStyles}
      onClose={() => { }}
      maxWidth="xs"
      fullWidth
      scroll="body"
    >
      <img
        src={cancelSearchImage}
        className="close-image"
        alt="close"
        onClick={() => {
          duplicate ? onRetake() : onClose();
        }}
      />
      <Box className="barcode-image-container">
        {scannedBarcode && <img src={barcodeBlackImage} alt="barcode" />}
        {renderBarcodeScanner && <BarcodeScanner _onDetected={_onDetected} showAlert={showAlert} />}
        {state !== "granted" && (
          <Alert severity="error" style={{ marginTop: "10px" }}>
            Please give Camera Permissions of your device!
          </Alert>
        )}
        {showAlert ? (
          <Alert severity="error" style={{ marginTop: "10px" }}>
            Please select valid format!
          </Alert>
        ) : (
          <Typography className="barcode-number">
            {scannedBarcode ? scannedBarcode : "No Data Scanned"}
          </Typography>
        )}
        {retakeBarcode && duplicateData && (
          <Typography className="duplicate-barcode-error">
            {`This barcode number already exists in${duplicateSampleName ? `${duplicateSampleName} and` : ""
              } serial no.${retakeBarcode &&
              //@ts-ignore
              duplicateBarcodeNumber(duplicateBarcodeIndex)}`}
          </Typography>
        )}
        <div>
          <br />
          <br />
        </div>
        {retakeBarcode && scannedBarcode ? (
          <Typography className="details-show">
            Duplicate Barcode Scanned
          </Typography>
        ) : (
          <>
            {showSuccessMessage && <Typography className="success">Sample scanned Successfully!!</Typography>}
            {showFranchiseErrorMessage && <Typography className="error">{response?.error?.message}</Typography>}
            {showStlErrorMessage && <Typography className="error">{stlResponse?.error?.message}</Typography>}
            {showStlPositiveErrorMessage && <Typography className="error">{stlPositiveOrNegativeResponse?.error?.message}</Typography>}
            {stlSuccessCase &&
              <div className="stl-success">
                <Typography>Pickup Date:- {stlPickupDate}</Typography>
                <Typography>Product Type:- {stlProductName}</Typography>
                <Typography>Hospital Name:- {stlHospitalName}</Typography>
              </div>}
              {stlPositiveOrNegativeSuccessCase &&
              <div className="stl-success">
                <Typography>Pickup Date:- {stlPositiveOrNegativePickupDate}</Typography>
                <Typography>Product Type:- {stlPositiveOrNegativeProductName}</Typography>
                <Typography>Hospital Name:- {stlPositiveOrNegativeHospitalName}</Typography>
              </div>}
            <Typography className="details-show">Do you want to?</Typography>
          </>
        )}
        <Box className="barcode-actions">
          <Button
            variant="contained"
            onClick={() => {
              setScannedBarcode("");
              setIsRetake(true);
              setShowAlert(false); 
              setRetakeBarcode(false);
              setDuplicateData(false);
            }}
          >
            Retake
          </Button>
          {!duplicateData && renderNextButton &&(
            <Button
              variant="contained"
              onClick={() => {
                onNext(scannedBarcode, selectedNumber);
              }}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
export default withStyles(BarcodeModalStyles)(BarcodeScanModalWeb);
// Customizable Area End
