// Customizable Area Start
const primaryColor = "#004d64";
const whiteColor = "#FFFFFF";
export const FranchiseAddProductWebStyles = {
  Dialog: {
    "& .image-container": {
      padding: "16px 16px 0",
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .close-image": {
      height: 45,
      width: 45,
      cursor: "pointer",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "11px",
      maxWidth: "475px",
    },
    "& .product-detail-container": {
      padding: "0px 40px 20px",
      "& h5": {
        color: primaryColor,
        fontSize: "24px",
        fontWeight: "700",
        fontFamily: "NotoSans-Regular",
        lineHeight: "33px",
      },
      "& .button-container": {
        marginTop: 15,
        marginBottom: 15,
      },
      "& .add-button": {
        color: whiteColor,
        backgroundColor: primaryColor,
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "NotoSans-Regular",
        height: "48px",
        "& .MuiButton-label": {
          textTransform: "none",
        },
      },
      "& .MuiTypography-body1": {
        height: "48px",
        borderRadius: "4px",
        background: "#76c9e3",
        backgroundBlendMode: "normal",
        boxShadow: "0px 0px 18px rgba(0,0,0,0.13)",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "NotoSans-Medium",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#004d64",
      },
      "& .submit-button": {
        height: 64,
        backgroundColor: primaryColor,
        borderRadius: "4px",
        backgroundBlendMode: "normal",
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        marginTop: "5px",
        "& .MuiButton-label": {
          textTransform: "none",
        },
      },
      "& .MuiFormControl-marginDense": {
        marginTop: "0",
        marginBottom: "0",
      },
      "&  .input": {
        height: "48px",
        borderRadius: "4px",
      },
      "&  .MuiIconButton-root": {
        padding: "5px",
      },
      "& .inputProps": {
        textAlign: "center",
        zIndex: "1",
      },
      "& .MuiInputAdornment-positionEnd": {
        marginLeft: "0px",
        zIndex: "1",
      },
      "& .MuiInputAdornment-positionStart": {
        marginRight: "0px",
        zIndex: "1",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        backgroundColor: "#e5f9ff",
      },
    },
  },
};
// Customizable Area End
