// Customizable Area Start
import React from "react";

import { Box, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FranchiseProductStyle } from "./FranchiseProductDetailStyle.web";
import { editWebImage } from "../../../assets";
import BackButtonTitleWebWeb from "../../../../../../components/src/BackButtonTitleWeb.web";

interface IFranchiseProductDetailsProps {
  classes: any;
  sampleList: {
    label: string;
    disabled: boolean;
    quantity: number;
    barcode: { barcode_no: string; isError: boolean }[];
    sampleValue: number;
  }[];
  onBack: () => void;
  onDiscard: () => void;
  onRemoveSampleOption: (sampleValue: number) => void;
  onEditProductDetails: (sampleValue: number) => void;
  showReadyToShipModal: () => void;
}

const FranchiseProductDetail: React.FC<IFranchiseProductDetailsProps> = ({
  classes,
  onBack,
  onRemoveSampleOption,
  onEditProductDetails,
  sampleList,
  onDiscard,
  showReadyToShipModal,
}) => {
  return (
    <>
      <Box className={classes?.ProductDetails}>
        <BackButtonTitleWebWeb
          title="Product Details"
          buttonClick={() => onBack()}
        />
        <Box className="product-details-container" margin="32px 0">
          {sampleList.map((data, index) => {
            return (
              <Box className="product-details">
                <Box className="product-details-left">
                  <Typography className="product-name">{data.label}</Typography>
                  <Button
                    className="remove-button"
                    onClick={() => onRemoveSampleOption(data.sampleValue)}
                  >
                    remove
                  </Button>
                </Box>
                <Box className="product-details-right">
                  <Typography className="product-value">
                    {data.quantity <= 9 ? `0${data.quantity}` : data.quantity}
                  </Typography>
                  <Button
                    className="edit-button"
                    onClick={() => onEditProductDetails(index)}
                  >
                    <img src={editWebImage} alt="edit" />
                    Edit
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box className="button-container" marginBottom="40px">
          <Button
            className="discard-button"
            variant="contained"
            onClick={() => onDiscard()}
          >
            Discard
          </Button>
          {sampleList[0] && (
            <Button
              className="ready-ship-button"
              variant="contained"
              onClick={() => showReadyToShipModal()}
            >
              Collect
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default withStyles(FranchiseProductStyle)(FranchiseProductDetail);
// Customizable Area End
