// Customizable Area Start
export const inputStyles: any = {
  Input: {
    "& .label ": {
      fontSize: "20px",
      fontFamily: "NotoSans-Regular",
      width: "max-content",
      marginBottom: 15,
      position: "relative",
      lineHeight: "24px",
      fontWeight: "400",
      color: "#004D64",
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& .inputProps:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: "transparent !important",
      "-webkit-text-fill-color": "#9e9e9e !important",
      fonSize: 16,
    },
    "& .inputProps": {
      color: "#004D64",
    },
    "& .inputProps::placeholder": {
      color: "#004D64",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#004d64",
      border: "1px solid",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#004d64",
      border: "1px solid",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      outline: "none",
      border: "1px solid #004D64;",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DE350B",
    },
    "& .Mui-error .inputProps": {
      color: "#D93025 !important",
      "-webkit-text-fill-color": "#D93025 !important",
    },
    "& .input": {
      height: 56,
      borderRadius: 10,
      color: "rgba(0,0,0,0.6)",
      fontFamily: "NotoSans-Regular",
      fontSize: 16,
      fontWeight: "400",
      lineHeight: 24,
      letterSpacing: "0.15px",
      "& .no-margin": {
        marginTop: 0,
      },
    },
    "& .profile": {
      fontSize: 20,
      fontWeight: "300",
      fontFamily: "NotoSans-Regular",
      lineHeight: "27px",
    },
    "& .disabled": {
      opacity: 1,
      backgroundColor: " #E5F9FF",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .password-show-images img": {
      height: 20,
      width: 20,
    },
    "& .label-error": {
      color: "#d93025",
      fontFamily: "NotoSans-Regular",
      fontSize: 14,
      fontWeight: "300",
      lineHeight: "19px",
    },
    "& .label-error > div": {
      display: "flex",
      gap: "10px",
    },
    "& .label-error .info-error-image": {
      height: 24,
      width: 24,
    },
    "& .label-error .error-text": {
      color: "#d93025",
      fontFamily: "NotoSans-Regular",
      fontSize: 14,
      fontWeight: "300",
    },
  },
};

// Customizable Area End
