// Customizable Area Start
const primaryColor = "#004D64";
const whiteColor = "#FFFFFF";

export const DashboardWebStyles = {
  SubFranchiseDetail: {
    "& .main-heading": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "20px",
      "& .MuiTypography-h2": {
        fontWeight: "700",
        lineHeight: "54px",
        fontSize: 40,
        color: primaryColor,
        fontFamily: "NotoSans-Regular",
      },

      "& .right-side": {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        position: "relative",
      },
      "& .calenderButton": {
        height: "63px",
        borderRadius: "8px",
        color: primaryColor,
        backgroundColor: "#E5F9FF",
        backgroundBlendMode: "normal",
        width: "166px",
        minWidth: "166px",
        textTransform: "none",
        border: " 1px solid #004d64",
        boxShadow: "none",
        "& .MuiButton-label": {
          gap: "15px",
          color: primaryColor,
          fontFamily: "NotoSans-Regular",
          fontSize: "20px",
          fontWeight: "700",
        },
        "& img": {
          height: 7,
          width: 14,
          marginTop: "4px",
        },
      },
    },
    "& .content-box.active": {
      backgroundColor: "#004d64 !important",
      boxShadow: " 0px 2px 12px rgba(0,0,0,0.53)!important",
      "& .MuiTypography-h1": {
        color: whiteColor,
      },
      "& .MuiTypography-body2": {
        color: whiteColor,
      },
    },
    "& .content-box-item": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "8px",
      background: " #004d64",
      backgroundBlendMode: "normal",
      boxShadow: "inset 0px 4px 20px rgba(0,0,0,0.4)",
      padding: "14px",
      backgroundColor: primaryColor,
      "& .MuiTypography-h1": {
        fontWeight: "700",
        lineHeight: "71px",
        fontSize: 52,
        color: whiteColor,
        fontFamily: "NotoSans-Regular",
        marginBottom: "10px",
      },
      "& .MuiTypography-body2": {
        fontWeight: "700",
        lineHeight: "27px",
        fontSize: 20,
        color: whiteColor,
        fontFamily: "NotoSans-Regular",
        textTransform: "uppercase",
      },
    },
    "& .sub-franchise-details": {
      margin: "20px 0",
      "& .content-box": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "8px",
        background: "#76c9e3",
        backgroundBlendMode: "normal",
        cursor: "pointer",
        padding: "12px",
        boxShadow: "none",
        "& .MuiTypography-h1": {
          fontWeight: "700",
          fontSize: 32,
          fontFamily: "NotoSans-Regular",
          marginBottom: "10px",
          lineHeight: "44px",
          color: "#004d64",
        },
        "& .MuiTypography-body2": {
          fontSize: "18px",
          lineHeight: "25px",
          color: "#004d64",
          fontWeight: "700",
          fontFamily: "NotoSans-Regular",
          textTransform: "uppercase",
          whiteSpace: "normal",
          textOverflow: "ellipsis",
          " -webkit-line-clamp": "1",
          display: "-webkit-box",
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
        },
      },
      "& .left-side-grid": {
        maxWidth: "195px !important",
        flexBasis: "195px !important",
      },
      "& .right-side-grid": {
        maxWidth: "calc(100% - 195px) !important",
        flexBasis: "calc(100% - 195px) !important",
      },
      "& .not-found-tex": {
        fontSize: "14px",
      },
      "& .items-container": {
        borderRadius: "8px",
        background: "#e5f9ff",
        backgroundBlendMode: "normal",
        boxShadow: "0px 2px 12px rgb(0 0 0 / 11%)",
        padding: "24px",
        marginTop: "20px",
      },
      "& .items-inside-container": {
        display: "flex",
        flexWrap: "wrap",
        marginRight: "-12px",
        marginLeft: "-12px",
        rowGap: "24px",
        marginTop: "15px",
      },
      "& .outer-item-container": {
        flex: "0 0 20%",
        maxWidth: "20%",
        position: "relative",
        width: "100%",
        paddingRight: "12px",
        paddingLeft: "12px",
        boxSizing: "border-box",
      },
      "& .item-container.active": {
        boxShadow: "0px 2px 12px rgba(0,0,0,0.53)!important",
      },
      "& .item-container": {
        height: "110px",
        border: "1px solid #004d64",
        cursor: "pointer",
        boxSizing: "border-box",
        borderRadius: "8px",
        position: "relative",
        width: "100%",
        overflow: "hidden",
        "& img": {
          height: "100%",
          width: "100%",
          backgroundSize: "cover",
        },
      },
      "& .text-container": {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        background: "#fff",
        backgroundBlendMode: "normal",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
        "& .digit-heading": {
          color: "#004d64",
          fontSize: "24px",
          lineHeight: "33px",
          fontWeight: "700",
        },
        "& .item-name": {
          color: "#004d64",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "22px",
        },
      },
      "& .digit-heading": {
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "40px",
        fontWeight: "700",
        "text-overflow": "ellipsis",
        "-webkit-line-clamp": "1",
        display: "-webkit-box",
        overflow: "hidden",
        wordBreak: "break-all",
        "-webkit-box-orient": "vertical",
        "white-space": "normal",
      },
      "& .item-name": {
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        "text-overflow": "ellipsis",
        "-webkit-line-clamp": "1",
        display: "-webkit-box",
        overflow: "hidden",
        wordBreak: "break-all",
        "-webkit-box-orient": "vertical",
        "white-space": "normal",
      },
      "& .MuiAccordionSummary-root": {
        height: 64,
        padding: "0 20px",
        borderRadius: "4px",
        backgroundColor: "#fff",
        color: primaryColor,
        border: "1px solid #004D64",
      },
      "& .MuiAccordionSummary-root.Mui-expanded": {
        height: 64,
        padding: "0 20px",
        borderRadius: "4px",
        backgroundColor: primaryColor,
        color: "#fff",
        border: "1px solid #004D64",
      },
      "& .accordion-heading": {
        fontSize: "18px",
        lineHeight: "22px",
        fontFamily: "NotoSans-Medium",
        fontWeight: "400",
      },
      "& .dark-color": {
        display: "block",
      },
      "& .white-color": {
        display: "none",
      },
      "& .sub-franchise-details.Mui-expanded": {
        margin: "0 0 20px 0",
      },
      "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
        transform: "rotate(90deg)",
        "& .dark-color": {
          display: "none",
        },
        "& .white-color": {
          display: "block",
        },
      },
      "& .digit": {
        marginRight: "5px",
        fontFamily: "NotoSans-Medium",
        fontSize: "18px",
        fontWeight: "400",
      },
      "& .MuiAccordionDetails-root": {
        marginTop: "5px",
        padding: "20px",
        backgroundColor: whiteColor,
        borderRadius: "11px",
        backgroundBlendMode: "normal",
        fontFamily: "NotoSans-Regular",
        border: "1px solid #004D64",
        flexDirection: "column",
      },
      "& .MuiPaper-elevation1": {
        boxShadow: "none",
        marginBottom: "20px",
      },
      "& .MuiPaper-elevation1:last-child": {
        marginBottom: "0px",
      },
      "& .MuiAccordionSummary-content": {
        alignItems: "center",
        justifyContent: "space-between",
      },
      "& .right-side": {
        display: "flex",
        marginRight: "5%",
        gap: "100px",
        alignItems: "center",
        justifyContent: "space-around",
        "& .MuiTypography-body1": {
          fontFamily: "NotoSans-Medium",
          fontSize: "18px",
          fontWeight: "400",
        },
      },
      "& .summary-details": {
        width: "100%",
      },
      "& .text-container.active": {
        background: "#004d64",
        boxShadow: " 0px 2px 12px rgba(0,0,0,0.53)",
        "& .digit-heading": {
          color: "#fff",
        },
        "& .item-name": {
          color: "#fff",
        },
      },
      "& .left-container": {
        borderRadius: "4px",
        background: "#baefff",
        backgroundBlendMode: "normal",
        padding: "17px 20px",
      },
      "& .data-heading": {
        color: "#004d64",
        fontFamily: "NotoSans-SemiBold",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "22px",
      },
      "& .data-detail": {
        color: "#004d64",
        fontFamily: "NotoSans-Regular",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "22px",
      },
      "& .text-align-center": {
        textAlign: "center",
      },
      "& .row-spacing": {
        margin: "20px 0 27px",
      },
      "& .content-right-details": {
        backgroundColor: "#BAEFFF",
        padding: "20px",
        borderRadius: "8px",
        "& .MuiTypography-h6": {
          color: "#65ABC0",
          backgroundBlendMode: "normal",
          fontFamily: "NotoSans-Medium",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "22px",
        },
        "& .MuiTypography-body1": {
          color: "#004D64",
          backgroundBlendMode: "normal",
          fontFamily: "NotoSans-Regular",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "25px",
          padding: "3px 0",
        },
        "& .MuiTypography-body1:last-child": {
          paddingBottom: "0",
        },
      },
      "& .product-details": {
        padding: "20px",
        backgroundColor: "#E5F9FF",
        borderRadius: "0 0 4px 4px",
        backgroundBlendMode: "normal",
        marginTop: "10px",
      },
    },
  },
};
// Customizable Area End
