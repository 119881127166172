export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// Customizable Area Start
export const logo = require("../assets/logo.png");
export const infoCircle = require("../assets/info-circle.png");
export const flashScreenImage = require("../assets/group_Image_bitmap.png");
export const brandLogo = require("../assets/brand_logo.png");
export const visibilityOff = require("../assets/visibility_off.png");
export const visibility = require("../assets/visibility.png");
export const infoError = require("../assets/info_circle.png");
export const checkImage = require("../assets/checked.png");
export const uncheckImage = require("../assets/unchecked.png");
// Customizable Area End
