// Customizable Area Start
export const SideMenuStyles: any = {
  SideMenu: {
    "& .MuiDrawer-paperAnchorLeft": {
      width: 360,
      overflow: "hidden",
    },
    "& .side-menu-header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 20px 0 0",
      "& img": {
        height: 32,
        width: 32,
      },
    },
    "& .MuiLink-underlineHover:hover": {
      textDecoration: "none",
    },
    "& .side-menu-list-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      padding: "0 0 50px 55px",
      "& .MuiListItem-gutters": {
        paddingRight: 0,
      },
      "& .MuiListItemIcon-root": {
        minWidth: 45,
      },
      "& .active": {
        color: "Pink",
        "&::after": {
          right: "0",
          width: 46,
          height: 4,
          content: "''",
          position: "absolute",
          marginRight: "-21px",
          backgroundColor: "#004D64",
          borderRadius: "8px",
          transform: "rotate(90deg)",
        },
      },
      "& .active .MuiListItemIcon-root svg": {
        fill: "#004D64",
      },
      "& .MuiListItemIcon-root svg": {
        fill: "#A0A0A0",
      },
      "& .active .MuiListItemText-root .MuiTypography-root": {
        color: "#004D64",
      },
      "& .MuiListItemText-root .MuiTypography-root": {
        fontFamily: "NotoSans-Regular",
        fontWeight: "400",
        color: "#A0A0A0",
        fontSize: 20,
        lineHeight: "24px",
      },
      "& .logout-list .MuiListItemIcon-root svg": {
        fill: "#E30000",
      },
      "& .logout-list .MuiListItemText-root .MuiTypography-root": {
        color: "#E30000",
      },
      "&  .MuiListItem-root": {
        paddingBottom: "13px",
        paddingTop: "13px",
      },
    },
  },
};
// Customizable Area End
