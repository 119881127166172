// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import moment from "moment";
import {
  getFormatDate,
  userDetailsFromStorage,
  userRedirectToLoginPage
} from "../../../../../components/src/utils";
import { toast } from "react-toastify";
import { notFoundImageWeb } from "../../assets";

export const configJSON = require("../../config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dashboardData: {
    collected_samples: number;
    delivered_samples: number;
    shipped_samples: number;
  };
  dashboardProductData: {
    value: string;
    product_name: string;
    sample_id: number;
    imageSrc: string;
  }[];
  isLoading: boolean;
  userName: string;
  activeTab: string;
  showHospitalList: boolean;
  range: any;
  openDateRange: boolean;
  accessToken: string;
  userId: string;
  dashboardActiveProduct: string;
  activeCalenderValue: string;
  activeSampleId: number;
  franchiseHospitalDetail: {
    barcodes: string[];
    product: string;
    hospital_name: string;
    qunatity: number;
  }[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export enum ActiveTab {
  DASH_BOARD = "dashboard",
  FRANCHISE = "franchise"
}

export enum ActiveProduct {
  COLLECTED = "collected",
  SHIPPED = "shipped",
  DELIVERED = "delivered"
}

// Customizable Area End
export default class TeamLeaderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  productDetailDashboardApiCallId: string = "";
  dashboardHospitalApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      dashboardData: {
        collected_samples: 0,
        delivered_samples: 0,
        shipped_samples: 0
      },
      dashboardProductData: [],
      isLoading: false,
      userName: "",
      activeTab: ActiveTab.DASH_BOARD,
      showHospitalList: false,
      openDateRange: false,
      range: [
        {
          startDate: moment()
            .clone()
            .startOf("month")
            .toDate(),
          endDate: new Date(),
          key: "rollup"
        }
      ],
      accessToken: "",
      userId: "",
      dashboardActiveProduct: "collected",
      activeCalenderValue: "This Month",
      activeSampleId: -1,
      franchiseHospitalDetail: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    window.scroll(0, 0);
    const name = window.localStorage.getItem("userName");
    if (name) {
      this.setState({
        userName: name
      });
    }
    const data = userDetailsFromStorage();
    if (data && data.attributes && data.id) {
      this.setState(
        {
          accessToken: data.attributes.token,
          userId: data.id
        },
        () => {
          this.getDashboardDetails();
        }
      );
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  openOrCloseDateRangePicker = (isApiCall: boolean) => {
    this.setState({
      openDateRange: !this.state.openDateRange
    });
    if (isApiCall) {
      if (this.state.activeTab === ActiveTab.DASH_BOARD) {
        this.getDashboardDetails();
      }
    }
  };

  setActiveProduct = (product: ActiveProduct) => {
    this.setState(
      {
        dashboardActiveProduct: product,
        franchiseHospitalDetail: []
      },
      () => {
        this.getProductDetailDashboard();
      }
    );
  };

  getDashboardDetails = async () => {
    this.setState({ isLoading: true });

    const start_Date = getFormatDate(this.state.range[0].startDate);
    const end_Date = getFormatDate(this.state.range[0].endDate);

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.teamDashboardContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDashboardItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${
        configJSON.teamDashboardApiEndPoint
      }?start_date=${start_Date}&end_date=${end_Date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.teamDashboardMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setActiveSampleValue = async (id: number) => {
    this.setState(
      {
        activeSampleId: id,
        franchiseHospitalDetail: []
      },
      () => {
        this.getHospitalsAndQuantityDetails();
      }
    );
  };

  getHospitalsAndQuantityDetails = async () => {
    this.setState({ isLoading: true });

    const start_Date = moment(this.state.range[0].startDate).format(
      "DD/MM/YYYY"
    );
    const end_Date = moment(this.state.range[0].endDate).format("DD/MM/YYYY");
    const quantityEndPoint = `${
      configJSON.superTeamHospitalAndQuantityApiEndPoint
    }?sample_id=${this.state.activeSampleId}&status=${
      this.state.dashboardActiveProduct
    }&start_date=${start_Date}&end_date=${end_Date}`;

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.superTeamHospitalAndQuantityContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dashboardHospitalApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      quantityEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.superTeamHospitalAndQuantityMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProductDetailDashboard = async () => {
    this.setState({ isLoading: true });
    const start_Date = moment(this.state.range[0].startDate).format(
      "DD/MM/YYYY"
    );
    const end_Date = moment(this.state.range[0].endDate).format("DD/MM/YYYY");
    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.productDetailTeamLeadDashboardContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productDetailDashboardApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${
        configJSON.productDetailTeamLeadDashboardApiEndPoint
      }?start_date=${start_Date}&end_date=${end_Date}&status=${
        this.state.dashboardActiveProduct
      }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productDetailTeamLeadDashboardMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleListSortedData = (data: any) => {
    return data
      .sort((a: any, b: any) => {
        const nameA = a.product_name.toUpperCase();
        const nameB = b.product_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      })
      .map((item: any) => {
        return {
          sample_id: item.sample_id,
          product_name: item.product_name,
          value: item.value,
          imageSrc: item.image ? item.image : notFoundImageWeb
        };
      });
  };

  handleProductDetailDashboard = (responseJson: any) => {
    const list = this.handleListSortedData(responseJson.data);
    this.setState(
      {
        dashboardProductData: list,
        franchiseHospitalDetail: [],
        activeSampleId: list.length !== 0 ? list[0].sample_id : -1
      },
      () => {
        if (this.state.activeSampleId !== -1) {
          this.getHospitalsAndQuantityDetails();
        }
      }
    );
  };

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiDashboardItemCallId) {
      if (responseJson.collected_samples >= 0) {
        this.setState({
          dashboardData: {
            collected_samples: responseJson.collected_samples,
            delivered_samples: responseJson.delivered_samples,
            shipped_samples: responseJson.shipped_samples
          }
        });
        this.getProductDetailDashboard();
      } else {
        toast.error("Something went to wrong please retry.");
      }
    } else if (apiRequestCallId === this.productDetailDashboardApiCallId) {
      if (responseJson.data) {
        this.handleProductDetailDashboard(responseJson);
      } else {
        toast.error("Something went to wrong please retry.");
      }
    } else if (
      apiRequestCallId === this.dashboardHospitalApiCallId &&
      responseJson.data
    ) {
      const filteredHospitalData = responseJson.data.filter(
        (item: any) => item.barcodes.length > 0
      );
      this.setState({
        franchiseHospitalDetail: filteredHospitalData
      });
    }
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else {
      toast.error("Something went to wrong please retry.");
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        (apiRequestCallId === this.apiDashboardItemCallId ||
          apiRequestCallId === this.productDetailDashboardApiCallId ||
          apiRequestCallId === this.dashboardHospitalApiCallId) &&
        responseJson.error
      ) {
        this.handleError(responseJson.error);
      }
      if (apiRequestCallId && responseJson && !responseJson.error) {
        this.handleResponse(apiRequestCallId, responseJson);
      }
      this.setState({
        isLoading: false
      });
    }
    // Customizable Area End
  }
}
