// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import {
  sortByLabel,
  userDetailsFromStorage,
  userRedirectToLoginPage,
} from "../../../../../../components/src/utils";
import { toast } from "react-toastify";

export const configJSON = require("../../../config");

interface IHospital {
  id: string;
  type: string;
  attributes: {
    name: string;
    hospital: string;
    city: string;
    street: string;
    address: string;
  };
}
export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  searchText: string;
  openAddProductModel: boolean;
  sampleList: {
    id: number;
    label: string;
    disabled: boolean;
    quantity: number;
    barcode: {
      barcode_no: string;
      isError: boolean;
    }[];
    sampleValue: number;
    hasMore: boolean;
    showItems: number;
  }[];
  hospitalList: IHospital[];
  selectedHospitalId: string;
  isLoading: boolean;
  showProductScanning: boolean;
  showHospitalListPage: boolean;
  accessToken: string;
  userId: string;
}

interface SS {
  id: any;
}

export default class FranchiseHospitalSelectController extends BlockComponent<
  Props,
  S,
  SS
> {
  hospitalListApiCallId: string = "";
  sampleListApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      searchText: "",
      openAddProductModel: false,
      sampleList: [],
      hospitalList: [],
      selectedHospitalId: "",
      isLoading: false,
      showHospitalListPage: true,
      showProductScanning: false,
      accessToken: "",
      userId: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const data = userDetailsFromStorage();
    if (data?.attributes && data.id) {
      this.setState(
        {
          accessToken: data.attributes.token,
          userId: data.id,
        },
        () => {
          this.getHospitalListDetails();
        }
      );
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
  }

  dialogOpen = (id: string) => {
    this.setState(
      {
        selectedHospitalId: id,
      },
      () => this.getSampleListDetails()
    );
  };

  closeDialog = () => {
    this.setState({
      openAddProductModel: false,
      selectedHospitalId: "",
    });
  };

  setSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchText: event.target.value,
    });
  };

  clearSearchText = () => {
    this.setState({
      searchText: "",
    });
  };

  onBackHospitalListPage = () => {
    this.setState({
      showHospitalListPage: true,
      showProductScanning: false,
      selectedHospitalId: "",
      sampleList: [],
    });
  };

  createObject = (
    digit: number,
    dataList: { barcode_no: string; isError: boolean }[]
  ) => {
    let list = [...dataList];
    const no = list.length - digit;
    list = [
      ...list,
      ...Array.from({ length: Math.abs(no) }, (_) => ({
        barcode_no: "",
        isError: false,
      })),
    ];
    return list;
  };

  isHaveMorePages = (digit: number, showItems: number) => {
    if (digit > showItems) {
      return true;
    }
    return false;
  };

  submitSampleValues = (
    data: {
      label: string;
      disabled: boolean;
      quantity: number;
    }[]
  ) => {
    const newListData = data.map((detail, index) => {
      return {
        ...this.state.sampleList[index],
        quantity: detail.quantity,
        disabled: detail.disabled,
        hasMore: this.isHaveMorePages(detail.quantity, 10),
        showItems: 10,
        barcode: this.createObject(
          detail.quantity,
          this.state.sampleList[index].barcode
        ),
      };
    });
    this.setState({
      sampleList: newListData,
      showHospitalListPage: false,
      showProductScanning: true,
      openAddProductModel: false,
    });
  };

  getHospitalListDetails = async () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.hospitalListContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.hospitalListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hospitalListApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.hospitalListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSampleListDetails = async () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.hospitalAssociationSampleContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sampleListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hospitalAssociationSampleEndPoint}?hospital_id=${this.state.selectedHospitalId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.hospitalAssociationSampleMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleListSort = (data: any) => {
    return data
      .map((item: any, index: number) => {
        return {
          id: item.id,
          label: item.attributes.name,
          disabled: true,
          quantity: 0,
          barcode: [],
          sampleValue: index,
          hasMore: false,
          showItems: 10,
        };
      })
      .sort((a: any, b: any) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      });
  };

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.hospitalListApiCallId) {
      if (responseJson.data) {
        this.setState({
          hospitalList: sortByLabel(responseJson.data),
        });
      }
    } else if (apiRequestCallId === this.sampleListApiCallId) {
      if (responseJson.data) {
        const list = this.handleListSort(responseJson.data);
        this.setState({
          openAddProductModel: true,
          sampleList: list,
        });
      }
    } else {
      toast.error("Something went to wrong please retry");
    }
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else {
      toast.error("Something went to wrong please retry");
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        apiRequestCallId &&
        (apiRequestCallId === this.hospitalListApiCallId ||
          apiRequestCallId === this.sampleListApiCallId)
      ) {
        if (responseJson.error || responseJson.errors) {
          this.handleError(responseJson.error || responseJson.errors);
        }
        if (responseJson && !responseJson.error) {
          this.handleResponse(apiRequestCallId, responseJson);
        }
      }
      this.setState({
        isLoading: false,
      });
    }
  }
}
// Customizable Area End
