// Customizable Area Start
import React from "react";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { TeamLeaderFranchiseDetailStyles } from "./TeamLeaderFranchiseDetailsStyles.web";
import {
  RightDarkArrowComponent,
  RightWhiteArrowComponent,
} from "../../assets";
import NotFound from "../../../../../components/src/NotFound.web";

interface Props {
  classes: any;
  loading: boolean;
  getFranchiseListDetails: () => void;
  franchiseDetail: {
    id: string;
    type: string;
    attributes: {
      franchise_name: string;
      subfranchise_name: {
        data: {
          id: string;
          type: string;
          attributes: {
            name: string;
            hospital: {
              id: number;
              name: string;
              contact_person_name: string;
              contact_number: string;
              street: string;
              city: string;
              region: string;
              state: string;
              pincode: number;
            }[];
          };
        }[];
      };
      hospital: {
        id: number;
        name: string;
        contact_person_name: string;
        contact_number: string;
        street: string;
        city: string;
        region: string;
        state: string;
        pincode: number;
      }[];
    };
  }[];
}

interface S {}

class TeamLeaderFranchiseDetail extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.props.getFranchiseListDetails();
  }

  handleIndexValue(index: number) {
    return index < 9 ? `0${index + 1}` : index + 1;
  }

  handleLengthValue = (value: number) => {
    return value < 10 ? `0${value}` : value;
  };

  renderHospitalDetails = (
    hospital: {
      id: number;
      name: string;
      contact_person_name: string;
      contact_number: string;
      street: string;
      city: string;
      region: string;
      state: string;
      pincode: number;
    }[]
  ) => {
    return (
      <Box className="summary-details">
        <div className="hospital-details">
          <Typography className="no-of-hospital">
            No. of hospital- {this.handleLengthValue(hospital.length)}
          </Typography>
          <Grid container spacing={2}>
            {hospital.map((hospitalDetail: any, inner_index: number) => (
              <Grid
                item
                md={4}
                sm={4}
                key={`${hospitalDetail.name}${inner_index}`}
              >
                <Typography className="hospital-detail">
                  {inner_index + 1}. {hospitalDetail.name}
                </Typography>
                <Typography className="hospital-address">
                  {`${hospitalDetail.street}, ${hospitalDetail.city}, ${hospitalDetail.state} ${hospitalDetail.pincode}`}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>
    );
  };

  render() {
    const { classes, loading, franchiseDetail } = this.props;

    return (
      <Box className={classes?.FranchiseDetail}>
        {!loading && franchiseDetail.length === 0 ? (
          <NotFound />
        ) : (
          franchiseDetail.map((franchise_detail, franchiseIndex) => (
            <Accordion
              className="main-detail"
              key={`${franchise_detail.attributes.franchise_name}${franchise_detail.id}`}
            >
              <AccordionSummary
                className="main-summary"
                expandIcon={
                  <>
                    <RightDarkArrowComponent className="dark-color" />{" "}
                    <RightWhiteArrowComponent className="white-color" />
                  </>
                }
                aria-controls="panel1a-content"
              >
                <Typography className="accordion-heading">
                  <span className="digit">
                    {this.handleIndexValue(franchiseIndex)}
                  </span>{" "}
                  {`${franchise_detail.attributes.franchise_name} [FR]`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="main-summary-details">
                <Box className="summary-details">
                  {franchise_detail.attributes.subfranchise_name.data.length !==
                  0 ? (
                    <>
                      <Accordion
                        className="sub-detail"
                        key={`${franchise_detail.attributes.franchise_name}${franchise_detail.id}${franchiseIndex}`}
                      >
                        <AccordionSummary
                          className="sub-summary"
                          expandIcon={<RightDarkArrowComponent />}
                          aria-controls="panel1-content"
                        >
                          <Typography className="franchise-name">
                            {`${franchise_detail.attributes.franchise_name} [FR]`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="sub-summary-detail">
                          {this.renderHospitalDetails(
                            franchise_detail.attributes.hospital
                          )}
                        </AccordionDetails>
                      </Accordion>
                      {franchise_detail.attributes.subfranchise_name.data.map(
                        (sub_franchise_detail) => (
                          <Accordion
                            className="sub-detail"
                            key={`${sub_franchise_detail.attributes.name}${sub_franchise_detail.id}`}
                          >
                            <AccordionSummary
                              className="sub-summary"
                              expandIcon={<RightDarkArrowComponent />}
                              aria-controls="panel1-content"
                            >
                              <Typography className="franchise-name">
                                {`${sub_franchise_detail.attributes.name} [SF]`}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="sub-summary-detail">
                              {this.renderHospitalDetails(
                                sub_franchise_detail.attributes.hospital
                              )}
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}
                    </>
                  ) : (
                    this.renderHospitalDetails(
                      franchise_detail.attributes.hospital
                    )
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>
    );
  }
}
export default withStyles(TeamLeaderFranchiseDetailStyles)(
  TeamLeaderFranchiseDetail
);
// Customizable Area End
