// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import {
  userDetailsFromStorage,
  userRedirectToLoginPage
} from "../../../../../components/src/utils";
import { toast } from "react-toastify";

export const configJSON = require("../../config.js");

export interface IProps {
  onBack: () => void;
  classes: any;
  navigation: any;
}

interface S {
  positiveData: {
    barcode: string;
    status: boolean;
    isError: boolean;
  }[];
  selectedBarcodeIndex: number;
  selectedBarcode: string;
  duplicate: boolean;
  showBarcodeModal: boolean;
  duplicateBarcodeIndex: number;
  key: number;
  showSuccessModal: boolean;
  isError: boolean;
  accessToken: string;
  userId: string;
  isLoading: boolean;
  notInDataBase: string[];
  invalidFranchise: string[];
  notDelivered: string[];
  alreadyPositive: string[];
  invalidProductType: string[];
  openReadyToShipPositive: boolean;
  validBarcodes: string[];
  scannedBarcode: string;
  successData: {
    product_name: string;
    hospital_name: string;
    barcode_number: string;
    pickup_date: Date;
  }[];
  response: any;
}

interface SS {
  id: any;
}

export default class SuperTeamsPositive extends BlockComponent<IProps, S, SS> {
  positiveScanningApiCallId: string = "";
  scanningIndividualApiCallId: string = "";

  constructor(props: IProps) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      positiveData: Array.from({ length: Math.abs(10) }, _ => ({
        barcode: "",
        status: false,
        isError: false
      })),
      selectedBarcodeIndex: -1,
      selectedBarcode: "",
      duplicate: false,
      showBarcodeModal: false,
      response: [],
      duplicateBarcodeIndex: -1,
      key: 0,
      successData: [],
      showSuccessModal: false,
      isError: false,
      accessToken: "",
      userId: "",
      isLoading: false,
      notInDataBase: [],
      notDelivered: [],
      alreadyPositive: [],
      invalidProductType: [],
      openReadyToShipPositive: false,
      validBarcodes: [],
      invalidFranchise: [],
      scannedBarcode: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    window.scroll(0, 0);
    const data = userDetailsFromStorage();
    if (data && data.attributes && data.id) {
      this.setState({ accessToken: data.attributes.token, userId: data.id });
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
  }

  onDeleteValue = (index: number) => {
    const { positiveData } = this.state;
    const oneData = positiveData[index];
    oneData.barcode = "";
    oneData.isError = false;
    oneData.status = false;
    positiveData.splice(index, 1, oneData);
    this.setState(
      {
        positiveData,
        showBarcodeModal: false,
        duplicate: false,
        selectedBarcode: "",
        key: Number(new Date())
      },
      () => {
        this.handleAutoFocusWithIndex(index);
      }
    );
  };

  handleDuplicatePositiveBarcode = (value: string, index: number) => {
    const barcode = value.replace(/\D/g, "");
    const { positiveData } = this.state;
    if (barcode.length === 12) {
      if (value) {
        const duplicateData = positiveData.some(item => item.barcode === value);

        if (duplicateData) {
          const parentDuplicateIndex = positiveData.findIndex(
            item => item.barcode === value
          );
          if (parentDuplicateIndex !== index) {
            this.setState({
              selectedBarcodeIndex: index,
              selectedBarcode: value,
              duplicateBarcodeIndex: parentDuplicateIndex,
              duplicate: true,
              showBarcodeModal: true
            });
            return true;
          }
        }
      }
    }

    const oneData = positiveData[index];
    oneData.barcode = barcode;
    oneData.isError = barcode.length > 0 && barcode.length < 12;
    oneData.status = barcode.length === 12;
    positiveData.splice(index, 1, oneData);
    this.setState(
      {
        positiveData
      },
      () => {
        if (barcode.length === 12 && !this.state.duplicate) {
          this.scanningIndividualBarcode(barcode);
        }
      }
    );

    return false;
  };

  handleAutoFocusWithIndex = async (index: number) => {
    if (index !== -1) {
      const barcodeClass = document.querySelectorAll(".barcode-number-details");
      if (barcodeClass) {
        const singleClass = barcodeClass[index];
        if (singleClass) {
          //@ts-ignore
          singleClass.focus();
          singleClass.scrollIntoView({
            block: "nearest",
            inline: "center"
          });
        }
      }
    }
  };

  handleScanBarcode = () => {
    const { positiveData } = this.state;
    const index = positiveData.findIndex(item => {
      return item.barcode === "";
    });
    this.setState({
      selectedBarcodeIndex: index,
      selectedBarcode: "",
      showBarcodeModal: true,
      key: Number(new Date())
    });
  };

  handleScanBarcodeNext = (barcode: string, index: number) => {
    const { positiveData } = this.state;
    if (!this.handleDuplicatePositiveBarcode(barcode, index)) {
      const oneData = positiveData[index];
      oneData.barcode = barcode;
      positiveData.splice(index, 1, oneData);
      this.setState({
        positiveData,
        selectedBarcodeIndex: 0,
        selectedBarcode: "",
        showBarcodeModal: false,
        duplicate: false,
        key: Number(new Date())
      });
    }
  };

  hideScanBarcodeModal = () => {
    this.setState({
      showBarcodeModal: false
    });
  };

  addInputField = () => {
    const { positiveData } = this.state;
    const list = [
      ...positiveData,
      ...Array.from({ length: Math.abs(10) }, _ => ({
        barcode: "",
        status: false,
        isError: false
      }))
    ];
    this.setState({
      positiveData: list
    });
  };

  checkAnyBarcodeFill = () => {
    const { positiveData } = this.state;
    const filledData = positiveData.filter(item => item.barcode !== "");
    if (filledData.length !== 0) {
      const empty = filledData.filter(
        item =>
          !item.isError &&
          !this.state.notInDataBase.includes(item.barcode) &&
          !this.state.notDelivered.includes(item.barcode) &&
          !this.state.alreadyPositive.includes(item.barcode) && 
          !this.state.invalidProductType.includes(item.barcode) && 
          !this.state.invalidFranchise.includes(item.barcode)
      );
      if (filledData.length === empty.length) {
        this.setState({
          openReadyToShipPositive: true
        });
        return true;
      }
    }
    const emptyBarcodeIndex = positiveData.findIndex(
      item =>
        item.barcode === "" ||
        item.isError ||
        this.state.alreadyPositive.includes(item.barcode) ||
        this.state.notInDataBase.includes(item.barcode) ||
        this.state.notDelivered.includes(item.barcode) ||
        this.state.invalidProductType.includes(item.barcode) ||
        this.state.invalidFranchise.includes(item.barcode)
    );
    const barcodeClass = document.querySelectorAll(".barcode-number-details");
    if (barcodeClass) {
      const singleclass = barcodeClass[emptyBarcodeIndex];
      if (singleclass) {
        //@ts-ignore
        singleclass.focus();
        singleclass.scrollIntoView({
          inline: "center",
          block: "nearest"
        });
      }
    }
    this.setState({ isError: filledData.length === 0 ? true : false });
  };

  onChangePositiveBarcodeStatus = (index: number) => {
    const { positiveData } = this.state;
    const oneData = positiveData[index];
    oneData.status = !oneData.status;
    positiveData.splice(index, 1, oneData);
    this.setState({ positiveData });
  };

  onCloseSuccessModal = () => {
    this.setState({ showSuccessModal: false });
    this.props.onBack();
  };

  scanningIndividualBarcode = (barcode: string) => {
    this.setState({ scannedBarcode: barcode });
    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.stlScanningProductPositiveContentType
    };

    const httpBody = { barcode: [barcode] };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.scanningIndividualApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stlScanningProductPositiveApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.stlScanningProductPositiveMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  scanningPositiveProductApiCall = () => {
    this.setState({ isLoading: true });

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.productScanningWithStatusContentType
    };

    const httpBody = {
      barcodes: this.state.positiveData
        .filter(item => item.barcode !== "")
        .map(item => ({
          barcode: item.barcode,
          result_status: item.status ? "positive" : "Unmarked"
        }))
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.positiveScanningApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productScanningWithStatusApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productScanningWithStatusMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleError = (error: any) => {
    const newAlreadyPositiveOrNegative = [
      ...this.state.alreadyPositive,
      ...(error && error.already_pos_or_neg_marked
        ? error.already_pos_or_neg_marked
        : [])
    ];
    const newNotInDataBaseForPositive = [
      ...this.state.notInDataBase,
      ...(error && error.not_in_database ? error.not_in_database : [])
    ];
    const newNotDeliveredForPositive = [
      ...this.state.notDelivered,
      ...(error && error.not_delivered ? error.not_delivered : [])
    ];
    const newInvalidProductType = [
      ...this.state.invalidProductType, 
      ...(error && error.invalid_product_type ? error.invalid_product_type : [])
    ];
    const newInvalidFranchise = [
      ...this.state.invalidFranchise,
      ...(error && error.barcodes ? error.barcodes : [])
    ]

    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else if (error.message) {
      this.setState({
        notInDataBase: newNotInDataBaseForPositive,
        notDelivered: newNotDeliveredForPositive,
        alreadyPositive: newAlreadyPositiveOrNegative,
        invalidProductType: newInvalidProductType,
        invalidFranchise: newInvalidFranchise,
        openReadyToShipPositive: false
      });
      toast.error(error.message);
    } else {
      toast.error("Something went to wrong please retry");
    }
  };

  handleValidBarcodeForPositive = (barcode: string, response: any) => {
    const newData = {
      hospital_name: response.data.hospital_name,
      pickup_date: response.data.pickup_date,
      product_name: response.data.product_name,
      barcode_number: response.data.barcode_number
    };
    this.setState(prevState => ({
      successData: [...prevState.successData, newData],
      validBarcodes: [...prevState.validBarcodes, barcode]
    }));
    toast.success("Sample Scanned Successfully!!");
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        this.handleError(responseJson.error);
        this.setState({ response: responseJson });
      } else if (responseJson && !responseJson.error && apiRequestCallId) {
        if (
          apiRequestCallId === this.positiveScanningApiCallId &&
          responseJson.message
        ) {
          this.setState({
            openReadyToShipPositive: false,
            showSuccessModal: true
          });
        } else if (apiRequestCallId === this.scanningIndividualApiCallId) {
          this.handleValidBarcodeForPositive(
            this.state.scannedBarcode,
            responseJson
          );
          this.setState({ response: responseJson });
        } 
      } else {
        this.setState({ openReadyToShipPositive: false });
        toast.error("Something went to wrong please retry");
      }
      this.setState({
        isLoading: false
      });
    }
  }
}
// Customizable Area End
