// Customizable Area Start
import React from "react";

import {
  Container,
  Typography,
  withStyles,
  Toolbar,
  AppBar,
  Box,
} from "@material-ui/core";
import { TermConditionPageStyles } from "./TermAndConditionStyles.web";
import PrivatePolicyController, { Props } from "./PrivatePolicyController.web";

const brandLogo = require("../../assets/white-logo.svg");

class PrivatePolicy extends PrivatePolicyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.PageStyles}>
        <AppBar className="NavigationStyles" position="sticky">
          <Container className="navigation-container" maxWidth="xl">
            <Toolbar>
              <Typography
                className="brand-logo-container"
                component="div"
                noWrap
              >
                <img src={brandLogo} alt="menu" />
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
        <Container maxWidth="xl" className="PageStyles">
          <Typography variant="h1" className="main-title">
            Privacy Policy
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Introduction</span>
            <br /> Advy Chemical Pvt Ltd respects your privacy and is committed
            to protecting your personal information. This Privacy Policy
            describes how we collect, use, and disclose information when you use
            the Advy App ("App") available on Google Play Store. By downloading,
            installing, accessing, or using the App, you consent to the
            collection, use, and disclosure of your information as described in
            this Privacy Policy.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Information Collection</span>
            <br /> We do not collect any personal information. In addition, we
            automatically collect certain information when you use the App, such
            as your IP address, device identifier, and the date and time of your
            access. We may use cookies, web beacons, and other similar
            technologies to collect this information.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Use of Information</span>
            <br /> We use the information that we collect to provide, maintain,
            and improve the App, to communicate with you, and to personalize
            your experience. We may also use the information to analyze usage
            patterns, to detect and prevent fraud and other illegal activities,
            and to comply with applicable laws and regulations.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Disclosure of Information</span>
            <br /> We may disclose your information to third-party service
            providers that help us to provide the App, such as hosting
            providers, payment processors, and analytics providers. We may also
            disclose your information if we believe that such disclosure is
            necessary to comply with applicable laws or regulations, to respond
            to a subpoena, court order, or other legal process, or to protect
            our rights, property, or safety, or the rights, property, or safety
            of others.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Data Security</span>
            <br /> We use commercially reasonable measures to protect your
            information from unauthorized access, use, or disclosure. However,
            no system can be completely secure, and we cannot guarantee the
            security of your information.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Data Retention</span>
            <br /> We retain your information for as long as necessary to
            provide the App and to comply with applicable laws and regulations.
            We may also retain your information for a longer period if necessary
            to resolve disputes or to establish, exercise, or defend legal
            claims.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Children's Privacy</span>
            <br /> The App is not intended for use by children under the age of
            13. We do not knowingly collect personal information from children
            under the age of 13. If we learn that we have collected personal
            information from a child under the age of 13, we will take steps to
            delete the information as soon as possible.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Changes to this Privacy Policy</span>{" "}
            <br /> We may update this Privacy Policy from time to time. If we
            make material changes to this Privacy Policy, we will notify you by
            email or by posting a notice on the App. Your continued use of the
            App after we make changes to this Privacy Policy will signify your
            acceptance of the updated Privacy Policy.
          </Typography>
        </Container>
        <br />
        <br />
        <Box className="FooterStyles">
          <Container maxWidth="xl" className="footer-container">
            <Typography variant="h3" className="footer-text">
              Copyright © {new Date().getFullYear()} ADVY CHEMICAL. All rights
              reserved.
            </Typography>
          </Container>
        </Box>
      </Box>
    );
  }
}

export default withStyles(TermConditionPageStyles)(PrivatePolicy);
// Customizable Area End
