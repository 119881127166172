// Customizable Area Start
const primaryColor = "#004D64";
const whiteColor = "#FFFFFF";
const yellowColor = "#FFD700";

export const DashboardBannerStyles = {
  Banner: {
    marginBottom: "20px",
    "& .MuiGrid-container": {
      height: "242px",
      overflow: "hidden",
      borderRadius: "8px",
    },
    "& .left-part-image": {
      borderRadius: "8px 0 0 8px",
      overflow: "hidden",
      maxHeight: "242px",
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
      },
    },
    "& .right-part-image": {
      position: "relative",
      borderRadius: " 0 8px 8px 0",
      overflow: "hidden",
      maxHeight: "242px",
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
      },
      "& .image-upper-text": {
        position: "absolute",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        borderRadius: "0px",
        background: "rgba(0,76,99,0.46)",
        backgroundBlendMode: "normal",
        backdropFilter: " blur(2px)",
        padding: "0 55px",
      },
      "& .image-upper-text .MuiTypography-h3": {
        fontWeight: "700",
        lineHeight: "44px",
        fontSize: 32,
        color: whiteColor,
        fontFamily: "NotoSans-Regular",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        " -webkit-line-clamp": "1",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        wordBreak: "break-all",
      },
      "& .image-upper-text .MuiDivider-root": {
        backgroundColor: yellowColor,
        width: "100%",
      },
      "& .image-upper-text .MuiButton-contained": {
        color: primaryColor,
        width: "100%",
        height: "64px",
        backgroundColor: yellowColor,
        borderRadius: "8px",
        backgroundBlendMode: "normal",
        marginTop: "18px",
        "& .MuiButton-label": {
          fontWeight: "700",
          lineHeight: "27px",
          fontSize: 20,
          fontFamily: "NotoSans-Regular",
          textTransform: "capitalize",
          whiteSpace: "normal",
          textOverflow: "ellipsis",
          " -webkit-line-clamp": "1",
          display: "-webkit-box",
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
          wordBreak: "break-all",
        },
      },
    },
  },
};

// Customizable Area End
