// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { BackButtonTitleWebStyles } from "./BackButtonTitleWebStyles.web";

const backArrowImage = require("./assets/back-arrow.png");

interface IBackButtonTitleWebProps {
  buttonClick: () => void;
  title?: string;
  classes: any;
}

const BackButtonTitleWeb: React.FC<IBackButtonTitleWebProps> = ({
  buttonClick,
  title,
  classes,
}) => {
  return (
    <Box className={classes.BackButton}>
      <Box>
        <Button
          variant="contained"
          className="back-button"
          onClick={() => buttonClick()}
        >
          <img src={backArrowImage} alt="back" />
          Back
        </Button>
      </Box>
      {title && <Typography variant="h3">{title}</Typography>}
    </Box>
  );
};

export default withStyles(BackButtonTitleWebStyles)(BackButtonTitleWeb);
// Customizable Area End
