// Customizable Area Start
import React from "react";

import {
  Button,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  IconButton,
  withStyles,
} from "@material-ui/core";
import { FranchiseAddShipStyles } from "./FranchiseAddShipItemsStyles.web";
import {
  editWebImage,
  greenDotWebImage,
  plusWebImage,
  redDotWebImage,
  RightDarkArrowComponent,
  RightWhiteArrowComponent,
  SmallCrossImageComponent,
} from "../../assets";
// @ts-ignore
import { DebounceInput } from "react-debounce-input";
import NotFound from "../../../../../components/src/NotFound.web";

interface IShipItemData {
  hospital_id: number;
  product_type_id: number;
  total_unit: string;
  hospital_name: string;
  status: string;
  barcode: string[];
  service_provider_name: string;
  docket_number: string[];
  isEditable: boolean;
}

interface Props {
  classes: any;
  sampleId: string;
  expandedPanel: number | boolean;
  handleChange: (
    panel: number
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  handleContinue: () => void;
  addGridRecord: (index: number) => void;
  docketNoChangeHandler: (
    index: number,
    docketIndex: number,
    value: string
  ) => void;
  deleteParameter: (index: number, docketIndex: number) => void;
  onChangeServiceProviderName: (index: number, value: string) => void;
  shipItemData: IShipItemData[];

  handleSave: (index: number) => void;
}

interface State {}

class FranchiseAddShipItems extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  renderViewText = (value: string) => {
    return (
      <p className="service-name-view" title={value}>
        {value}
      </p>
    );
  };

  renderTextTitle = (isEditable: boolean) => {
    return `${(isEditable && "Enter") || ""} Service Provider Details`;
  };

  handleIndexValue(index: number) {
    return index < 9 ? `0${index + 1}` : index + 1;
  }

  renderSaveButton = (data: IShipItemData, index: number) => {
    return (
      data.status === "collected" &&
      data.isEditable && (
        <div className="save-button-container">
          <Button
            variant="contained"
            onClick={() => this.props.handleSave(index)}
            className="save-button"
          >
            Save
          </Button>
        </div>
      )
    );
  };

  renderDocketNoDetails = (data: IShipItemData, index: number) => {
    return data.docket_number.map((noDetails, docketNoDetailIndex) => {
      return (
        <Grid
          container
          className="input-spacing"
          key={`${docketNoDetailIndex}`}
        >
          <Grid item md={12} sm={12} xs={12}>
            <Box className="input-container">
              <Box className="input-left-container">
                {data.isEditable && data.status === "collected" ? (
                  <DebounceInput
                    className={
                      noDetails ? "service-name" : "service-name error-barcode"
                    }
                    placeholder="Enter Docket No."
                    value={noDetails}
                    debounceTimeout={500}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.props.docketNoChangeHandler(
                        docketNoDetailIndex,
                        index,
                        e.target.value
                      )
                    }
                  />
                ) : (
                  this.renderViewText(noDetails)
                )}
                {data.isEditable && data.status === "collected" && (
                  <Box
                    className="cross-image-container"
                    onClick={() =>
                      this.props.deleteParameter(index, docketNoDetailIndex)
                    }
                  >
                    <SmallCrossImageComponent />
                  </Box>
                )}
              </Box>
              {data.isEditable &&
                data.status === "collected" &&
                (data.docket_number.length - 1 === docketNoDetailIndex ? (
                  <IconButton
                    className="input-right-container"
                    onClick={() => this.props.addGridRecord(index)}
                  >
                    <img src={plusWebImage} />
                  </IconButton>
                ) : (
                  <div className="input-right-container"></div>
                ))}
            </Box>
          </Grid>
        </Grid>
      );
    });
  };

  renderRedOrGreenImage = (data: any) => {
    return data.status === "collected" ? (
      <>
        {" "}
        <img src={greenDotWebImage} alt="green" />
        <span className="accordion-heading width150px"> To be shipped</span>
      </>
    ) : (
      <>
        {" "}
        <img src={redDotWebImage} alt="red" />
        <span className="accordion-heading width150px"> In transit</span>
      </>
    );
  };

  paddingRightInPixel = (maxLength: number, totalLength: number) => {
    return totalLength > maxLength ? "10px" : 0;
  };

  render() {
    const { classes, shipItemData, handleContinue, handleChange } = this.props;

    return (
      <Box className={classes?.Franchise}>
        {shipItemData.length > 0 ? (
          <div
            className="barcode-detail-container"
            style={{
              paddingRight: `${this.paddingRightInPixel(
                2,
                shipItemData.length
              )}`,
            }}
          >
            {shipItemData.map((data, index: number) => {
              return (
                <Accordion
                  expanded={this.props.expandedPanel === index}
                  // @ts-ignore
                  onChange={handleChange(index)}
                  key={`${data.hospital_name}${data.product_type_id}`}
                >
                  <AccordionSummary
                    expandIcon={
                      <>
                        <RightDarkArrowComponent className="dark-color" />{" "}
                        <RightWhiteArrowComponent className="white-color" />
                      </>
                    }
                    aria-controls="panel1a-content"
                  >
                    <Typography className="accordion-heading">
                      <span className="digit">
                        {this.handleIndexValue(index)}
                      </span>{" "}
                      {data.hospital_name}
                    </Typography>
                    <Typography className="accordion-heading">
                      Total Units -{data.total_unit}
                    </Typography>
                    {this.renderRedOrGreenImage(data)}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container className="left-container">
                          <Grid item md={3} sm={4}>
                            <Typography className="data-heading text-align-center">
                              Serial no.
                            </Typography>
                          </Grid>
                          <Grid item md={8} sm={8}>
                            <Typography className="data-heading text-align-center">
                              {" "}
                              Barcode
                            </Typography>
                          </Grid>
                        </Grid>
                        {data.barcode.map(
                          (barcodeDetail, barcodeDetailIndex) => {
                            return (
                              <Grid
                                container
                                className="row-spacing"
                                key={`${barcodeDetail}${barcodeDetailIndex}`}
                              >
                                <Grid item md={3} sm={4}>
                                  <Typography className="data-detail text-align-center">
                                    {this.handleIndexValue(barcodeDetailIndex)}
                                  </Typography>
                                </Grid>
                                <Grid item md={8} sm={8}>
                                  <Typography className="data-detail text-align-center">
                                    {barcodeDetail}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} className="right-container">
                            <Typography className="data-heading bold">
                              {this.renderTextTitle(data.isEditable)}
                            </Typography>
                            {data.status === "collected" && !data.isEditable && (
                              <Button
                                className="edit-button"
                                onClick={() => this.props.handleSave(index)}
                              >
                                <img src={editWebImage} alt="edit" />
                                Edit
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                        <Box className="box-overflow">
                          <Grid container className="input-spacing">
                            <Grid
                              item
                              md={12}
                              style={{
                                paddingRight: `${this.paddingRightInPixel(
                                  2,
                                  data.docket_number.length
                                )}`,
                                width: "100%",
                              }}
                            >
                              {data.isEditable &&
                              data.status === "collected" ? (
                                <DebounceInput
                                  type="text"
                                  value={data.service_provider_name}
                                  placeholder="Enter service provider name"
                                  debounceTimeout={500}
                                  className={
                                    data.service_provider_name
                                      ? "service-name"
                                      : "service-name error-barcode"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    this.props.onChangeServiceProviderName(
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              ) : (
                                this.renderViewText(data.service_provider_name)
                              )}
                            </Grid>
                          </Grid>
                          {this.renderDocketNoDetails(data, index)}
                          {this.renderSaveButton(data, index)}
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        ) : (
          <NotFound message="No hospital available on this product" />
        )}
        {shipItemData.length != 0 && (
          <Box className="Button" margin="60px 0 40px">
            <Button
              variant="contained"
              className="submit-button"
              onClick={() => handleContinue()}
            >
              Continue
            </Button>
          </Box>
        )}
      </Box>
    );
  }
}

export default withStyles(FranchiseAddShipStyles)(FranchiseAddShipItems);

// Customizable Area End
