// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import FranchiseHospitalSelectController, {
  Props,
} from "./FranchiseHospitalController.web";
import {
  backArrowImage,
  cancelSearchImage,
  searchImage,
} from "../../../assets";
import { HospitalSelectWebStyles } from "./FranchiseHospitalSelectStyles.web";
import { CustomInput } from "../../../../../../components/src/CustomInput.web";
import FranchiseProductScanning from "./FranchiseProductScanning.web";
import FranchiseAddProductDetail from "./FranchiseAddProductDetail.web";
import Loader from "../../../../../../components/src/Loader.web";
import NotFound from "../../../../../../components/src/NotFound.web";

class FranchiseHospitalSelect extends FranchiseHospitalSelectController {
  constructor(props: Props) {
    super(props);
  }

  renderHospitalList = () => {
    const filterResult = this.state.hospitalList.filter((item) => {
      if (
        item.attributes.name
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase())
      ) {
        return item;
      }
    });
    if (!filterResult.length) {
      return (
        <Typography variant="body1" className="detail">
          No result found
        </Typography>
      );
    } else {
      return filterResult.map((item) => (
        <Typography
          variant="body1"
          key={item.id}
          className={
            this.state.selectedHospitalId === item.id
              ? "detail selected"
              : "detail"
          }
          onClick={() => this.dialogOpen(item.id)}
        >
          {item.attributes.name}
        </Typography>
      ));
    }
  };

  render() {
    const { classes, navigation } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Container
          maxWidth="lg"
          className={classes.HospitalSearch}
          style={{ margin: "20px auto 0" }}
        >
          {this.state.showHospitalListPage && (
            <>
              <Box>
                <Button
                  variant="contained"
                  className="back-button"
                  onClick={navigation.goBack}
                >
                  <img src={backArrowImage} alt="back" />
                  Back
                </Button>
              </Box>
              <Box className="search-box">
                <Typography variant="h3">Please Select Hospital</Typography>
                <CustomInput
                  value={this.state.searchText}
                  onChange={this.setSearchText}
                  placeholder="Search Hospital"
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src={searchImage}
                        alt="search"
                        className="search-image"
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={this.clearSearchText}>
                        <img
                          src={cancelSearchImage}
                          alt="cancel"
                          className="cancel-image"
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <Box className="hospital-list" style={{ marginBottom: "20px" }}>
                <Typography variant="body1" className="heading">
                  Name of Hospital
                </Typography>
                <Box className="item-details-container">
                  {this.state.hospitalList.length === 0 &&
                  !this.state.isLoading ? (
                    <NotFound />
                  ) : (
                    this.renderHospitalList()
                  )}
                </Box>
              </Box>
            </>
          )}
          {this.state.showProductScanning && (
            <FranchiseProductScanning
              sampleList={this.state.sampleList}
              onBack={this.onBackHospitalListPage}
              hospitalId={this.state.selectedHospitalId}
              navigation={this.props.navigation}
            />
          )}
        </Container>
        <FranchiseAddProductDetail
          open={this.state.openAddProductModel}
          closeDialog={this.closeDialog}
          submitSampleValues={this.submitSampleValues}
          sampleList={this.state.sampleList.map((item) => {
            return {
              label: item.label,
              quantity: item.quantity,
              disabled: item.disabled,
            };
          })}
        />
      </>
    );
  }
}

export default withStyles(HospitalSelectWebStyles)(FranchiseHospitalSelect);
// Customizable Area End
