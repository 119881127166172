// Customizable Area Start
const primaryColor = "#004D64";
const whiteColor = "#FFFFFF";
const yellowColor = "#FFD700";

export const DashboardWebStyles = {
  SuperTeamLeader: {
    "& .main-heading": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "20px",
      "& .MuiTypography-h2": {
        fontWeight: "700",
        lineHeight: "54px",
        fontSize: 40,
        color: primaryColor,
        fontFamily: "NotoSans-Regular",
      },
      "& .right-side": {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        position: "relative",
      },
      "& .calenderButton": {
        height: "63px",
        borderRadius: "8px",
        color: primaryColor,
        backgroundColor: "#E5F9FF",
        backgroundBlendMode: "normal",
        width: "166px",
        minWidth: "166px",
        textTransform: "none",
        border: " 1px solid #004d64",
        boxShadow: "none",
        "& .MuiButton-label": {
          gap: "15px",
          color: primaryColor,
          fontFamily: "NotoSans-Regular",
          fontSize: "20px",
          fontWeight: "700",
        },
        "& img": {
          height: 7,
          width: 14,
          marginTop: "4px",
        },
      },
    },
    "& .scanningButton": {
      height: "66px",
      borderRadius: "8px",
      background: yellowColor,
      backgroundBlendMode: "normal",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      textTransform: "none",
      boxShadow: "none",
    },
    "& .positiveButton": {
      height: "66px",
      borderRadius: "8px",
      background: "#36b060",
      backgroundBlendMode: "normal",
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      textTransform: "none",
      boxShadow: "none",
    },
    "& .negativeButton": {
      height: "66px",
      borderRadius: "8px",
      background: "#e30000",
      backgroundBlendMode: "normal",
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      textTransform: "none",
      boxShadow: "none",
    },
    "& .right-side-grid": {
      maxWidth: "calc(100% - 195px) !important",
      flexBasis: "calc(100% - 195px) !important",
    },
    "& .side-button": {
      marginBottom: "15px",
    },
    "& .items-container": {
      borderRadius: "16px",
      height: "506px",
      boxSizing: "border-box",
      background: "#e5f9ff",
      backgroundBlendMode: "normal",
      padding: "24px",
    },
    "& .items-inside-container": {
      display: "flex",
      flexWrap: "wrap",
      marginRight: "-12px",
      marginLeft: "-12px",
    },
    "& .outer-item-container": {
      flex: "0 0 20%",
      position: "relative",
      width: "100%",
      boxSizing: "border-box",
    },
    "& .item-container": {
      height: "120px",
      borderRadius: "8px",
      position: "relative",
      width: "100%",
      overflow: "hidden",
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
      },
    },
    "& .inner-mrg-container": {
      margin: "16px 0",
    },
    "& .inner-item-container": {
      height: "458px",
      width: "100%",
    },
    "& .inner-scanner-container": {
      height: "186px",
      width: "100%",
    },
    "& .text-container": {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      background: "rgba(0,76,99,0.6)",
      backdropFilter: "blur(2px)",
      backgroundBlendMode: "normal",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    "& .text-container-left": {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      background: "rgba(0,76,99,0.6)",
      backdropFilter: "blur(2px)",
      backgroundBlendMode: "normal",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      padding: "24px",
    },
    "& .digit-heading": {
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "50px",
      fontWeight: "700",
    },
    "& .digit-heading-scanned": {
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "60px",
      lineHeight: "82px",
      fontWeight: "700",
    },
    "& .item-percentage": {
      color: "#ffffff",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "400",
      marginTop: "28px",
    },
    "& .item-name": {
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "33px",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
      " -webkit-line-clamp": "1",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
    },
    "& .item-name-product": {
      color: "#ffffff",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "24px",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
      wordBreak: "break-all",
      " -webkit-line-clamp": "1",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
    },
    "& .item-name-product-m-12": {
      marginBottom: "12px",
    },
    "& .item-name-product-m-40": {
      marginBottom: "40px",
    },
    "& .item-click-text": {
      color: "#ffffff",
      fontFamily: "NotoSans-Regular",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "19px",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
      " -webkit-line-clamp": "1",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      marginTop: "18px",
      marginBottom: "12px",
    },
    "& .search-image": {
      height: "24px",
      width: "24px",
    },
    "& .input": {
      height: "63px",
    },
    "& .back-button": {
      marginBottom: "10px",
    },
    "& hr": {
      border: "1px solid #ffffff",
      background: "#ffffff",
      zIndex: "10",
      margin: "30px 0",
      width: "100%",
    },
  },
};
// Customizable Area End
