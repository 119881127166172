// Customizable Area Start
export const BarcodeModalStyles = {
  BarcodeDialogStyles: {
    "& .close-image": {
      height: 45,
      width: 45,
      cursor: "pointer",
      position: "absolute",
      right: "8px",
      top: "2px",
      zIndex: "2",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "11px",
      maxWidth: "475px",
      position: "relative",
    },
    "& .barcode-image-container": {
      padding: "40px 40px 20px",
      textAlign: "center",
      "& img": {
        width: "238px",
        height: "189px",
      },
    },
    "& .barcode-number": {
      background: "#e5f9ff",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "22px",
      margin: "15px auto",
      padding: "10px 20px",
      width: "70%",
    },
    "& .details-show": {
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "33px",
      textAlign: "center",
    },
    "& .stl-success": {
      marginBottom: '20px',
    },
    "& .error": {
      color: "#E30000",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "300",
      marginTop: "20px",
    },
    "& .success": {
      color: "green",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "300",
    },
    "& .barcode-actions": {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
      marginTop: "30px",
      alignItems: "center",
      "& button": {
        height: "64px",
        borderRadius: "8px",
        background: "#004d64",
        color: "#ffffff",
        textTransform: "none",
        width: "50%",
        "& .MuiButton-label": {
          color: "#ffffff",
          fontFamily: "NotoSans-Regular",
          fontSize: "20px",
          fontWeight: "700",
        },
      },
    },
    "& .duplicate-barcode-error": {
      color: "#4A5056",
      fontFamily: "NotoSans-Regular",
      fontSize: "13px",
      fontWeight: "300",
      lineHeight: "18px",
      textAlign: "center",
    },
  },
};
// Customizable Area End
