// Customizable Area Start
import React from "react";

import { Box, Grid, Typography, Button, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { DashboardBannerStyles } from "./DashboardBannerStyles.web";

interface IDashboardBannerProps {
  classes: any;
  src: string;
  buttonText: string;
  bannerText: string;
  buttonClick: () => void;
}

const viewWorld = require("./assets/view_world_web.png");

const DashboardBanner: React.FC<IDashboardBannerProps> = ({
  classes,
  src,
  buttonText,
  bannerText,
  buttonClick,
}) => {
  return (
    <Box className={classes?.Banner}>
      <Grid container spacing={0}>
        <Grid item md={7} sm={4} xs={4}>
          <Box className="left-part-image">
            <img src={src} alt="left" />
          </Box>
        </Grid>
        <Grid item md={5} sm={8} xs={8}>
          <Box className="right-part-image">
            <img src={viewWorld} alt="right" />
            <Box className="image-upper-text">
              <Typography variant="h3" title={bannerText}>
                {bannerText}
              </Typography>
              <Divider />
              <Button
                fullWidth
                variant="contained"
                onClick={() => buttonClick()}
                title={buttonText}
              >
                {buttonText}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(DashboardBannerStyles)(DashboardBanner);
// Customizable Area End
