// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import moment from "moment";
import {
  userDetailsFromStorage,
  userRedirectToLoginPage,
} from "../../../../../components/src/utils";
import { toast } from "react-toastify";

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  onBack: () => void;
}

export interface ISubFranchise {
  id: number;
  franchise_name: string;
  quantity: number;
}

export interface IFranchise {
  id: string;
  attributes: {
    id: number;
    franchise_name: string;
    quantity: number;
    sub_franchises: ISubFranchise[];
  };
}

interface S {
  isLoading: boolean;
  showHospitalList: boolean;
  range: any;
  openDateRange: boolean;
  searchText: string;
  franchiseList: IFranchise[];
  activeSampleValue: number;
  accessToken: string;
  userId: string;
  hospitalList: {
    id: string;
    type: string;
    attributes: {
      name: string;
      hospital: string;
      city: string;
      street: string;
      address: string;
    };
  }[];
  franchiseId: number;
  franchiseName: string;
  franchiseActiveProduct: string;
  handleFranchiseExpand: number | boolean;
  handleSubFranchiseExpand: number | boolean;
  handleIndividualExpand: number | boolean;
  franchiseSampleDetail: {
    value: string;
    product_name: string;
    sample_id: number;
  }[];
  franchiseData: {
    collected: number;
    delivered: number;
    shipped: number;
  };
  franchiseIndividualCombined: string[];
  franchiseHospitalDetail: {
    hospital: string;
    count: number;
  }[];
  selectedFranchiseType: string;
  activeCalenderValue: string;
}

interface SS {
  id: any;
}
export enum ActiveTab {
  DASH_BOARD = "dashboard",
  FRANCHISE = "franchise",
}

export enum ActiveProduct {
  COLLECTED = "collected",
  SHIPPED = "shipped",
  DELIVERED = "delivered",
}

export default class TeamLeaderFranchiseController extends BlockComponent<
  Props,
  S,
  SS
> {
  franchiseListApiCallId: string = "";
  hospitalListApiCallId: string = "";
  franchiseProductDetailApiCallId: string = "";
  franchiseHospitalApiCallId: string = "";
  sampleDetailApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isLoading: false,
      showHospitalList: false,
      openDateRange: false,
      range: [
        {
          startDate: moment()
            .clone()
            .startOf("month")
            .toDate(),
          endDate: new Date(),
          key: "rollup",
        },
      ],
      searchText: "",
      activeSampleValue: 0,
      accessToken: "",
      userId: "",
      hospitalList: [],
      franchiseList: [],
      franchiseId: 0,
      franchiseName: "",
      franchiseActiveProduct: "collected",
      handleFranchiseExpand: false,
      handleSubFranchiseExpand: false,
      franchiseSampleDetail: [],
      franchiseIndividualCombined: ["Combined", "Individual"],
      handleIndividualExpand: false,
      franchiseData: {
        collected: 0,
        delivered: 0,
        shipped: 0,
      },
      franchiseHospitalDetail: [],
      selectedFranchiseType: "",
      activeCalenderValue: "This Month",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const data = userDetailsFromStorage();
    if (data?.attributes && data.id) {
      this.setState(
        {
          accessToken: data.attributes.token,
          userId: data.id,
        },
        () => {
          this.getFranchiseListDetails();
        }
      );
    } else {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
  }

  openOrCloseDateRangePicker = (isApiCall: boolean) => {
    this.setState({
      openDateRange: !this.state.openDateRange,
    });
    if (isApiCall) {
      this.setState(
        {
          handleFranchiseExpand: false,
          handleIndividualExpand: false,
          handleSubFranchiseExpand: false,
        },
        () => {
          this.getFranchiseListDetails();
        }
      );
    }
  };

  setActiveProduct = (product: ActiveProduct) => {
    this.setState(
      {
        franchiseActiveProduct: product,
        franchiseSampleDetail: [],
        franchiseHospitalDetail: [],
      },
      () => {
        this.getSampleDetailFranchise();
      }
    );
  };

  setShowHospitalValue = () => {
    this.setState({
      showHospitalList: true,
    });
  };

  setHideHospitalValue = () => {
    this.setState({
      showHospitalList: false,
      franchiseName: "",
      hospitalList: [],
    });
  };

  setSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchText: event.target.value,
    });
  };

  setActiveSampleTabValue = (index: number) => {
    this.setState(
      {
        activeSampleValue: index,
        franchiseHospitalDetail: [],
      },
      () => {
        this.getHospitalsAndQuantityDetails();
      }
    );
  };

  handleFranchiseChange = (data: IFranchise, type: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    this.setState({
      handleFranchiseExpand: newExpanded ? data.attributes.id : false,
      handleIndividualExpand: newExpanded && !newExpanded
    });
    if (newExpanded && data.attributes.sub_franchises.length === 0) {
      this.setState(
        {
          franchiseId: data.attributes.id,
          franchiseName: data.attributes.franchise_name,
          handleSubFranchiseExpand: false,
          selectedFranchiseType: type.toLocaleLowerCase(),
          franchiseData: {
            collected: 0,
            delivered: 0,
            shipped: 0,
          },
          franchiseSampleDetail: [],
          franchiseHospitalDetail: [],
          franchiseActiveProduct: "collected",
        },
        () => {
          this.getFranchiseProductDetails(this.state.franchiseId);
        }
      );
    }
  };

  handleSubFranchiseChange = (data: ISubFranchise, type: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    this.setState({
      handleSubFranchiseExpand: newExpanded ? data.id : false,
      handleIndividualExpand: false,
    });
    if (newExpanded) {
      this.setState(
        {
          franchiseId: data.id,
          franchiseName: data.franchise_name,
          selectedFranchiseType: type.toLocaleLowerCase(),
          franchiseData: {
            collected: 0,
            delivered: 0,
            shipped: 0,
          },
          franchiseSampleDetail: [],
          franchiseHospitalDetail: [],
          franchiseActiveProduct: "collected",
        },
        () => {
          this.getFranchiseProductDetails(this.state.franchiseId);
        }
      );
    }
  };

  handleIndividualChange = (
    franchiseName: string,
    franchiseId: number,
    index: number,
    type: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    this.setState({
      handleIndividualExpand: newExpanded ? index : false,
      handleSubFranchiseExpand: false,
    });
    if (newExpanded) {
      this.setState(
        {
          franchiseId,
          franchiseName,
          selectedFranchiseType: type.toLocaleLowerCase(),
          franchiseData: {
            collected: 0,
            delivered: 0,
            shipped: 0,
          },
          franchiseSampleDetail: [],
          franchiseHospitalDetail: [],
          franchiseActiveProduct: "collected",
        },
        () => {
          this.getFranchiseProductDetails(this.state.franchiseId);
        }
      );
    }
  };

  getFranchiseListDetails = () => {
    this.setState({ isLoading: true });
    const start_Date = moment(this.state.range[0].startDate).format(
      "DD/MM/YYYY"
    );
    const end_Date = moment(this.state.range[0].endDate).format("DD/MM/YYYY");

    const franchiseEndPoint = `${configJSON.franchiseListApiEndPoint}?start_date=${start_Date}&end_date=${end_Date}`;
    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.franchiseListApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.franchiseListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      franchiseEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.franchiseListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFranchiseProductDetails = async (franchiseId: number) => {
    this.setState({ isLoading: true });
    const start_Date = moment(this.state.range[0].startDate).format(
      "DD/MM/YYYY"
    );
    const end_Date = moment(this.state.range[0].endDate).format("DD/MM/YYYY");

    const productEndPoint = `${configJSON.teamLeaderFranchiseProductDetailApiEndPoint}?franchise_id=${franchiseId}&data_type=${this.state.selectedFranchiseType}&start_date=${start_Date}&end_date=${end_Date}`;
    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.teamLeaderFranchiseProductDetailContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.franchiseProductDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      productEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.teamLeaderFranchiseProductDetailApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getHospitalListDetails = async () => {
    this.setState({ isLoading: true });
    const hospitalEndPoint = `${configJSON.hospitalListApiEndPoint}?franchise_id=${this.state.franchiseId}`;
    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.hospitalListContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.hospitalListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hospitalEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.hospitalListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSampleDetailFranchise = async () => {
    this.setState({ isLoading: true });
    const start_Date = moment(this.state.range[0].startDate).format(
      "DD/MM/YYYY"
    );
    const end_Date = moment(this.state.range[0].endDate).format("DD/MM/YYYY");
    const sampleEndPoint = `${configJSON.productDetailFranchiseApiEndPoint}?franchise_id=${this.state.franchiseId}&status=${this.state.franchiseActiveProduct}&start_date=${start_Date}&end_date=${end_Date}&data_type=${this.state.selectedFranchiseType}`;
    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.productDetailFranchiseContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sampleDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      sampleEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productDetailFranchiseMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getHospitalsAndQuantityDetails = async () => {
    const start_Date = moment(this.state.range[0].startDate).format(
      "DD/MM/YYYY"
    );
    const end_Date = moment(this.state.range[0].endDate).format("DD/MM/YYYY");
    const quantityEndPoint = `${configJSON.docketAndBarcodeApiEndPoint}?sample_id=${this.state.activeSampleValue}&franchise_id=${this.state.franchiseId}&status=${this.state.franchiseActiveProduct}&data_type=${this.state.selectedFranchiseType}&start_date=${start_Date}&end_date=${end_Date}`;
    this.setState({ isLoading: true });
    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.docketAndBarcodeContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.franchiseHospitalApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      quantityEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.docketAndBarcodeMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTotalQuantity = (data: IFranchise) => {
    let total = data.attributes.sub_franchises
      .map((item) => item.quantity)
      .reduce((prev, next) => prev + next);
    total = total + data.attributes.quantity;
    return total;
  };

  handleFranchiseProductDetail = (responseJson: any) => {
    this.setState(
      {
        franchiseData: responseJson.data,
      },
      () => {
        this.getSampleDetailFranchise();
      }
    );
  };

  handleFranchiseHospitalData = (responseJson: any) => {
    this.setState({
      franchiseHospitalDetail: responseJson.data,
    });
  };

  handleSampleDetails = (responseJson: any) => {
    this.setState({
      franchiseSampleDetail: responseJson.data,
    });
    if (responseJson.data.length !== 0) {
      this.setState(
        {
          activeSampleValue: responseJson.data[0].sample_id,
        },
        () => {
          this.getHospitalsAndQuantityDetails();
        }
      );
    }
  };

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.franchiseListApiCallId && responseJson.data) {
      this.setState({
        franchiseList: responseJson.data,
      });
    } else if (
      apiRequestCallId === this.hospitalListApiCallId &&
      responseJson.data
    ) {
      this.setState({
        hospitalList: responseJson.data,
      });
    } else if (
      apiRequestCallId === this.franchiseProductDetailApiCallId &&
      responseJson.data
    ) {
      this.handleFranchiseProductDetail(responseJson);
    } else if (
      apiRequestCallId === this.franchiseHospitalApiCallId &&
      responseJson.data
    ) {
      this.handleFranchiseHospitalData(responseJson);
    } else if (
      apiRequestCallId === this.sampleDetailApiCallId &&
      responseJson.data
    ) {
      this.handleSampleDetails(responseJson);
    } else {
      toast.error("Something went to wrong please retry.");
    }
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else {
      toast.error("Something went to wrong please retry.");
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        this.handleError(responseJson.error);
      }
      if (apiRequestCallId && responseJson && !responseJson.error) {
        this.handleResponse(apiRequestCallId, responseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
