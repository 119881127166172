// Customizable Area Start
import React from "react";
import { CustomInput } from "../../../../../components/src/CustomInput.web";

import { Grid, withStyles } from "@material-ui/core";
import { SuperTeamLeaderProfileDetailStyles } from "./SuperTeamLeaderProfileDetailStyles.web";

interface Props {
  classes: any;
  userDetails: {
    full_name: string;
    phone_number: any;
    payroll_type: boolean;
    city: string;
    region: string;
    state: string;
  };
}

interface S {}

class SuperTeamLeaderProfileDetail extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, userDetails } = this.props;

    return (
      <div className={classes?.ProfileDetail}>
        <Grid container>
          <Grid item md={6} sm={8}>
            <CustomInput
              label="Super Team Leader Name"
              value={`${userDetails.full_name}`}
              profile
              disabled
              onChange={() => {}}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6} sm={8}>
            <CustomInput
              label="Contact No"
              value={`${userDetails.phone_number}`}
              disabled
              profile
              onChange={() => {}}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6} sm={8}>
            <CustomInput
              label="Payroll Type"
              value={userDetails.payroll_type ? "On payroll" : "Off payroll"}
              disabled
              profile
              onChange={() => {}}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6} sm={8}>
            <CustomInput
              label="City"
              value={`${userDetails.city}`}
              disabled
              profile
              onChange={() => {}}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} sm={6}>
            <CustomInput
              label="Region"
              value={`${userDetails.region}`}
              disabled
              profile
              onChange={() => {}}
            />
          </Grid>
          <Grid item md={3} sm={6}>
            <CustomInput
              label="State"
              value={`${userDetails.state}`}
              disabled
              profile
              onChange={() => {}}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(SuperTeamLeaderProfileDetailStyles)(
  SuperTeamLeaderProfileDetail
);
// Customizable Area End
