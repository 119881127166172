// Customizable Area Start
import React from "react";

import {
  Container,
  Typography,
  withStyles,
  Toolbar,
  AppBar,
  Box,
} from "@material-ui/core";
import TermsAndConditionsController, {
  Props,
} from "./TermAndConditionController.web";
import { TermConditionPageStyles } from "./TermAndConditionStyles.web";

const brandLogo = require("../../assets/white-logo.svg");

class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.PageStyles}>
        <AppBar className="NavigationStyles" position="sticky">
          <Container maxWidth="xl" className="navigation-container">
            <Toolbar>
              <Typography
                component="div"
                className="brand-logo-container"
                noWrap
              >
                <img src={brandLogo} alt="menu" />
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
        <Container maxWidth="xl" className="PageStyles">
          <Typography variant="h1" className="main-title">
            Terms & Conditions
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Introduction</span>
            <br /> These terms and conditions ("Terms") govern your access to
            and use of the Advy Chemical Pvt Ltd App ("App") available on Google
            Play Store. By downloading, installing, accessing, or using the App,
            you agree to be bound by these Terms. If you do not agree with these
            Terms, do not use the App.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Eligibility</span>
            <br /> The App is intended only for the internal use of employees of
            the Company ("Company"). By accessing or using the App, you
            represent and warrant that you are an employee of the Company or
            have been authorized by the Company to access and use the App.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">License Grant and Restrictions</span>
            <br />
            Subject to these Terms, the Company grants you a non-exclusive,
            non-transferable, revocable license to use the App on your device
            solely for your personal, non-commercial use. You may not distribute
            or make the App available over a network where it could be used by
            multiple devices at the same time. You may not rent, lease, lend,
            sell, transfer, redistribute, or sublicense the App. You may not
            copy, modify, adapt, translate, reverse engineer, decompile,
            disassemble or create derivative works based on the App.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">User Content</span> <br /> The App
            allows you to upload, submit, store, send, or receive content,
            including but not limited to text, photos, videos, and audio
            recordings ("User Content"). You are solely responsible for your
            User Content and the consequences of posting or publishing it. You
            represent and warrant that you have all necessary rights and
            permissions to post, upload, submit, or transmit your User Content
            and that your User Content does not violate any applicable laws,
            regulations, or third-party rights.
          </Typography>
          <Typography className="description-text">
            By posting, uploading, submitting, or transmitting your User
            Content, you grant the Company a non-exclusive, transferable,
            sub-licensable, royalty-free, worldwide license to use, copy,
            modify, create derivative works based on, distribute, publicly
            display, publicly perform, and otherwise exploit in any manner such
            User Content in all formats and distribution channels now known or
            hereafter devised (including in connection with the App and the
            Company's business and on third-party sites and services), without
            further notice to or consent from you, and without the requirement
            of payment to you or any other person or entity.
          </Typography>
          <Typography className="description-text">
            You agree that you will not post, upload, submit, or transmit any
            User Content that:<br />  (a) infringes or violates the intellectual
            property rights or any other rights of any person or entity;
            <br /> (b) is defamatory, obscene, pornographic, abusive, offensive,
            or otherwise illegal; <br /> (c) promotes or encourages illegal
            activity or violence;
            <br />
            (d) contains viruses, worms, Trojan horses, or any other harmful or
            malicious code or files;
            <br /> (e) is false, misleading, or deceptive; or (f) violates these
            Terms or any applicable laws or regulations.
          </Typography>
          <Typography className="description-text">
            The Company reserves the right to remove or delete any User Content
            that violates these Terms or that the Company deems, in its sole
            discretion, to be objectionable.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">App Store Rules</span> <br /> You
            acknowledge and agree that your use of the App is subject to the
            rules and policies of the Google Play Store. The Company has no
            control over the Google Play Store and is not responsible for any
            actions or decisions made by Google with respect to the App. You
            agree to comply with all applicable rules and policies of the Google
            Play Store.
          </Typography>
          <Typography className="description-text">
            <span className="bold-text">Disclaimer of Warranties</span>
            <br /> THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
            WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
            LAW, THE COMPANY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED,
            STATUTORY, OR OTHERWISE, INCLUDING WITHOUT LIMITATION.
          </Typography>
        </Container>
        <br />
        <br />
        <Box className="FooterStyles">
          <Container className="footer-container" maxWidth="xl">
            <Typography variant="h3" className="footer-text">
              Copyright © {new Date().getFullYear()} ADVY CHEMICAL. All rights
              reserved.
            </Typography>
          </Container>
        </Box>
      </Box>
    );
  }
}
export default withStyles(TermConditionPageStyles)(TermsAndConditions);
// Customizable Area End
