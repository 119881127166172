// Customizable Area Start
import React from "react";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  withStyles,
} from "@material-ui/core";
import { SuperTeamLeaderAssociateTLStyles } from "./SuperTeamLeaderAssociateTLStyles.web";
import {
  RightDarkArrowComponent,
  RightWhiteArrowComponent,
} from "../../assets";
import NotFound from "../../../../../components/src/NotFound.web";

interface Props {
  classes: any;
  loading: boolean;
  tlDetailList: {
    id: string;
    type: string;
    attributes: {
      teamleader_name: string;
      franchise_name: {
        data: {
          id: string;
          type: string;
          attributes: {
            franchise_name: string;
            hospital: {
              id: number;
              name: string;
              street: string;
              city: string;
              region: string;
              state: string;
              pincode: number;
            }[];
            subfranchise_name: {
              data: {
                id: string;
                type: string;
                attributes: {
                  name: string;
                  hospital: {
                    id: number;
                    name: string;
                    street: string;
                    city: string;
                    region: string;
                    state: string;
                    pincode: number;
                  }[];
                };
              }[];
            };
          };
        }[];
      };
    };
  }[];
  getAssociationTlListDetails: () => void;
}

interface S {}

class SuperTeamLeaderAssociateTL extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.props.getAssociationTlListDetails();
  }

  handleIndexValue(index: number) {
    return index < 9 ? `0${index + 1}` : index + 1;
  }

  handleLengthValue = (value: number) => {
    return value < 10 ? `0${value}` : value;
  };

  renderHospitalList = (franchiseDetail: any) => {
    return (
      <div className="hospital-details">
        <Typography className="no-of-hospital">
          No. of hospital-{" "}
          {this.handleLengthValue(franchiseDetail.attributes.hospital.length)}
        </Typography>
        <Grid container spacing={2}>
          {franchiseDetail.attributes.hospital.map(
            (hospitalName: any, hospitalIndex: number) => (
              <Grid
                item
                md={4}
                sm={4}
                key={`${hospitalName.name}${hospitalName.id}`}
              >
                <Typography className="hospital-detail">
                  {hospitalIndex + 1}. {hospitalName.name}
                </Typography>
                <Typography className="hospital-address">
                  {`${hospitalName.street}, ${hospitalName.city}, ${hospitalName.state} ${hospitalName.pincode}`}
                </Typography>
              </Grid>
            )
          )}
        </Grid>
      </div>
    );
  };

  render() {
    const { classes, tlDetailList, loading } = this.props;

    return (
      <Box className={classes?.FranchiseDetail}>
        {tlDetailList.length && !loading ? (
          tlDetailList.map((tlDetail, tlIndex) => (
            <Accordion
              className="main-detail"
              key={`${tlDetail.attributes.teamleader_name}${tlIndex}`}
            >
              <AccordionSummary
                className="main-summary"
                expandIcon={
                  <>
                    <RightDarkArrowComponent className="dark-color" />{" "}
                    <RightWhiteArrowComponent className="white-color" />
                  </>
                }
                aria-controls="panel1a-content"
              >
                <Typography className="accordion-heading">
                  <span className="digit">
                    {this.handleIndexValue(tlIndex)}
                  </span>{" "}
                  {`${tlDetail.attributes.teamleader_name} [TL]`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="main-summary-details">
                <Box className="summary-details">
                  {tlDetail.attributes.franchise_name.data.length > 0 ? (
                    tlDetail.attributes.franchise_name.data.map(
                      (franchiseDetail, franchiseDetailIndex) => {
                        return (
                          <Accordion
                            className="sub-detail"
                            key={`${franchiseDetail.attributes.franchise_name}${franchiseDetail.id}`}
                          >
                            <AccordionSummary
                              className="sub-summary"
                              expandIcon={<RightDarkArrowComponent />}
                              aria-controls="panel1-content"
                            >
                              <Typography className="franchise-name">
                                {`${franchiseDetail.attributes.franchise_name} [FR]`}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="sub-summary-detail">
                              <Box className="summary-details">
                                {franchiseDetail.attributes.subfranchise_name
                                  .data.length === 0 ? (
                                  this.renderHospitalList(franchiseDetail)
                                ) : (
                                  <>
                                    <Accordion
                                      className="sub-franchise-detail"
                                      key={`${franchiseDetail.attributes.franchise_name}${franchiseDetail.id}${franchiseDetailIndex}`}
                                    >
                                      <AccordionSummary
                                        className="sub-summary"
                                        expandIcon={<RightDarkArrowComponent />}
                                        aria-controls="panel1-content"
                                      >
                                        <Typography className="franchise-name">
                                          {`${franchiseDetail.attributes.franchise_name} [FR]`}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails className="sub-summary-detail">
                                        <Box className="summary-details">
                                          {this.renderHospitalList(
                                            franchiseDetail
                                          )}
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    {franchiseDetail.attributes.subfranchise_name.data.map(
                                      (subFranchiseDetail) => (
                                        <Accordion
                                          className="sub-franchise-detail"
                                          key={`${subFranchiseDetail.attributes.name}${subFranchiseDetail.id}`}
                                        >
                                          <AccordionSummary
                                            className="sub-summary"
                                            expandIcon={
                                              <RightDarkArrowComponent />
                                            }
                                            aria-controls="panel1-content"
                                          >
                                            <Typography className="franchise-name">
                                              {`${subFranchiseDetail.attributes.name} [SF]`}
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails className="sub-summary-detail">
                                            <Box className="summary-details">
                                              {this.renderHospitalList(
                                                subFranchiseDetail
                                              )}
                                            </Box>
                                          </AccordionDetails>
                                        </Accordion>
                                      )
                                    )}
                                  </>
                                )}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        );
                      }
                    )
                  ) : (
                    <div className="not-found-text">
                      No associated franchise found
                    </div>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <NotFound />
        )}
      </Box>
    );
  }
}
export default withStyles(SuperTeamLeaderAssociateTLStyles)(
  SuperTeamLeaderAssociateTL
);
// Customizable Area End
