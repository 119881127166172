// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import moment from "moment";
import {
  getFormatDate,
  userDetailsFromStorage,
  userRedirectToLoginPage,
} from "../../../../../components/src/utils";
import { toast } from "react-toastify";

export const configJSON = require("../../config.js");

export enum ActiveProduct {
  COLLECTED = "collected",
  SHIPPED = "shipped",
  DELIVERED = "delivered",
}

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  userName: string;
  token: string;
  errorMsg: string;
  isLoading: boolean;
  range: any;
  openDateRange: boolean;
  accessToken: string;
  userId: string;
  subFranchiseActiveProduct: string;
  subFranchiseSampleDetail: {
    value: string;
    product_name: string;
    sample_id: number;
  }[];
  subFranchiseHospitalDetail: {
    hospital: string;
    count: number;
  }[];
  subFranchiseData: {
    collected: number;
    delivered: number;
    shipped: number;
  };
  subFranchiseTotalData: {
    collected: number;
    delivered: number;
    shipped: number;
  };
  subFranchiseActiveSampleValue: number;
  franchiseId: number;
  subFranchiseList: {
    first_name: string;
    last_name: string;
    city: string;
    id: number;
  }[];
  handleSubFranchiseExpand: number | boolean;
  activeCalenderValue: string;
}

interface SS {
  id: any;
}

export default class SubFranchiseDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  subFranchiseDetailApiCallId: string = "";
  subFranchiseProductDetailApiCallId: string = "";
  subFranchiseHospitalApiCallId: string = "";
  subsubFranchiseSampleDetailApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      errorMsg: "",
      token: "",
      isLoading: false,
      userName: "",
      openDateRange: false,
      range: [
        {
          startDate: moment()
            .clone()
            .startOf("month")
            .toDate(),
          endDate: new Date(),
          key: "rollup",
        },
      ],
      accessToken: "",
      userId: "",
      subFranchiseActiveProduct: ActiveProduct.COLLECTED,
      subFranchiseHospitalDetail: [],
      subFranchiseSampleDetail: [],
      subFranchiseData: {
        collected: 0,
        delivered: 0,
        shipped: 0,
      },
      subFranchiseTotalData: {
        collected: 0,
        delivered: 0,
        shipped: 0,
      },
      franchiseId: 0,
      subFranchiseActiveSampleValue: 0,
      subFranchiseList: [],
      handleSubFranchiseExpand: false,
      activeCalenderValue: "This Month",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    window.scroll(0, 0);
    const name = localStorage.getItem("userName");
    if (name) {
      this.setState({
        userName: name,
      });
    }
    const data = userDetailsFromStorage();
    if (data?.attributes && data.id) {
      this.setState(
        {
          accessToken: data.attributes.token,
          userId: data.id,
        },
        () => {
          this.getSubFranchiseList();
        }
      );
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
  }

  setActiveProduct = (product: ActiveProduct) => {
    this.setState(
      {
        subFranchiseActiveProduct: product,
        subFranchiseSampleDetail: [],
        subFranchiseHospitalDetail: [],
      },
      () => {
        this.getSampleDetailFranchise();
      }
    );
  };

  openOrCloseDateRangePicker = (isApiCall: boolean) => {
    this.setState({
      openDateRange: !this.state.openDateRange,
    });
    if (isApiCall) {
      this.setState(
        {
          handleSubFranchiseExpand: false,
        },
        () => {
          this.getSubFranchiseList();
        }
      );
    }
  };

  handleSubFranchiseChange = (data: any) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    this.setState({
      handleSubFranchiseExpand: newExpanded ? data.id : false,
    });
    if (newExpanded) {
      this.setState(
        {
          franchiseId: data.id,
          subFranchiseData: {
            collected: 0,
            delivered: 0,
            shipped: 0,
          },
          subFranchiseSampleDetail: [],
          subFranchiseHospitalDetail: [],
          subFranchiseActiveProduct: "collected",
        },
        () => {
          this.getFranchiseProductDetails(this.state.franchiseId);
        }
      );
    }
  };

  setActiveSampleTabValue = (index: number) => {
    this.setState(
      {
        subFranchiseActiveSampleValue: index,
        subFranchiseHospitalDetail: [],
      },
      () => {
        this.getHospitalsAndQuantityDetails();
      }
    );
  };

  getSubFranchiseList = async () => {
    this.setState({ isLoading: true });

    const start_Date = getFormatDate(this.state.range[0].startDate);
    const end_Date = getFormatDate(this.state.range[0].endDate);

    this.subFranchiseDetailApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/account_block/account/franchise_get_sub_franchise_data?start_date=${start_Date}&end_date=${end_Date}`,
    });
  };

  getSampleDetailFranchise = async () => {
    const start_Date = moment(this.state.range[0].startDate).format(
      "DD/MM/YYYY"
    );
    const end_Date = moment(this.state.range[0].endDate).format("DD/MM/YYYY");
    const sampleEndPoint = `${configJSON.productDetailFranchiseApiEndPoint}?franchise_id=${this.state.franchiseId}&status=${this.state.subFranchiseActiveProduct}&data_type=individual&start_date=${start_Date}&end_date=${end_Date}`;
    this.subsubFranchiseSampleDetailApiCallId = await this.apiCall({
      contentType: "application/json",
      method: configJSON.productDetailFranchiseMethod,
      endPoint: sampleEndPoint,
    });
  };

  getHospitalsAndQuantityDetails = async () => {
    const start_Date = moment(this.state.range[0].startDate).format(
      "DD/MM/YYYY"
    );
    const end_Date = moment(this.state.range[0].endDate).format("DD/MM/YYYY");
    const quantityEndPoint = `${configJSON.docketAndBarcodeApiEndPoint}?sample_id=${this.state.subFranchiseActiveSampleValue}&franchise_id=${this.state.franchiseId}&data_type=individual&status=${this.state.subFranchiseActiveProduct}&start_date=${start_Date}&end_date=${end_Date}`;
    this.subFranchiseHospitalApiCallId = await this.apiCall({
      contentType: "application/json",
      method: configJSON.docketAndBarcodeMethod,
      endPoint: quantityEndPoint,
    });
  };

  getFranchiseProductDetails = async (franchiseId: number) => {
    const start_Date = moment(this.state.range[0].startDate).format(
      "DD/MM/YYYY"
    );
    const end_Date = moment(this.state.range[0].endDate).format("DD/MM/YYYY");

    const productEndPoint = `${configJSON.teamLeaderFranchiseProductDetailApiEndPoint}?franchise_id=${franchiseId}&data_type=individual&start_date=${start_Date}&end_date=${end_Date}`;
    this.subFranchiseProductDetailApiCallId = await this.apiCall({
      contentType: "application/json",
      method: configJSON.teamLeaderFranchiseProductDetailApiMethod,
      endPoint: productEndPoint,
    });
  };

  apiCall = async (data: any) => {
    this.setState({ isLoading: true });
    const { contentType, method, endPoint, body } = data;

    const header = {
      "Content-Type": contentType,
      accessToken: this.state.accessToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else if (error === "You are not authorised to perform this action.") {
      userRedirectToLoginPage(this.props.navigation);
    } else {
      toast.error("Something went to wrong please retry");
    }
  };

  handleFranchiseProductDetail = (responseJson: any) => {
    this.setState(
      {
        subFranchiseData: responseJson.data,
      },
      () => {
        this.getSampleDetailFranchise();
      }
    );
  };

  handleFranchiseHospitalData = (responseJson: any) => {
    this.setState({
      subFranchiseHospitalDetail: responseJson.data,
    });
  };

  handleSampleDetails = (responseJson: any) => {
    this.setState({
      subFranchiseSampleDetail: responseJson.data,
    });
    if (responseJson.data.length !== 0) {
      this.setState(
        {
          subFranchiseActiveSampleValue: responseJson.data[0].sample_id,
        },
        () => {
          this.getHospitalsAndQuantityDetails();
        }
      );
    }
  };

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (
      apiRequestCallId === this.subFranchiseProductDetailApiCallId &&
      responseJson.data
    ) {
      this.handleFranchiseProductDetail(responseJson);
    } else if (
      apiRequestCallId === this.subFranchiseHospitalApiCallId &&
      responseJson.data
    ) {
      this.handleFranchiseHospitalData(responseJson);
    } else if (
      apiRequestCallId === this.subsubFranchiseSampleDetailApiCallId &&
      responseJson.data
    ) {
      this.handleSampleDetails(responseJson);
    } else if (apiRequestCallId === this.subFranchiseDetailApiCallId) {
      if (responseJson.collected >= 0) {
        this.setState({
          subFranchiseTotalData: {
            collected: responseJson?.collected,
            delivered: responseJson?.delivered,
            shipped: responseJson?.shipped,
          },
          subFranchiseList: responseJson?.sub_franchises,
        });
      }
    } else {
      toast.error("Something went to wrong please retry.");
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        this.handleError(responseJson.error);
      }
      if (apiRequestCallId && responseJson && !responseJson.error) {
        this.handleResponse(apiRequestCallId, responseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
  }
}
// Customizable Area End
