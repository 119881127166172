// Customizable Area Start
const primaryColor = "#004d64";

export const BackButtonTitleWebStyles = {
  BackButton: {
    "& .back-button": {
      height: "46px",
      width: "107.81px",
      background: "#eafaff",
      backgroundBlendMode: "normal",
      boxShadow: "none",
      "& img": {
        height: 19,
        width: 17,
        marginRight: 5,
      },
      "& .MuiButton-label": {
        color: primaryColor,
        fontFamily: "NotoSans-Medium",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "22px",
        textTransform: "capitalize",
      },
    },
    "& .MuiTypography-h3": {
      color: primaryColor,
      fontFamily: "NotoSans-Regular",
      fontSize: "30px",
      fontWeight: "700",
      lineHeight: "41px",
      margin: "20px 0 ",
    },
  },
};
// Customizable Area End
