// Customizable Area Start
export const DateRangePickerStyle = {
  Picker: {
    borderRadius: "4px",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 2px 8px 2px rgba(0,0,0,0.16)",
    position: "absolute",
    top: "75px",
    right: "0px",
    zIndex: 10,
    "& .choose-button": {
      float: "right",
      height: "48px",
      borderRadius: "8px",
      background: "#003a4b",
      position: "absolute",
      right: "12px",
      bottom: "24px",
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-Medium",
        fontSize: "16px",
        fontWeight: "400",
      },
    },
    "& .rdrDateRangePickerWrapper": {
      "& .rdrStaticRanges": {
        padding: "10px 0",
      },
      "& .rdrStaticRange": {
        borderBottom: "none",
        color: "#003a4b !important",
        fontFamily: "NotoSans-Regular",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "22px",
        margin: "5px 0",
        "& img": {
          float: "right",
          height: "24px",
          width: "24px",
          marginTop: "-1px",
        },
      },
      "& .rdrCalendarWrapper": {
        height: "fit-content",
        padding: "10px 0 0",
      },
      "& .drop-down-item": {
        display: "block",
      },
      "& .rdrDefinedRangesWrapper": {
        width: "195px",
        minHeight: "404px",
      },
      "& .rdrDateDisplayItemActive": {
        border: "1px solid #003a4b !important",
      },
      "& .rdrDateDisplayItem": {
        border: "none",
        borderRadius: "4px",
        background: "#e5f9ff !important",
        boxShadow: "none",
      },
      "& .rdrDateDisplayItem input": {
        color: "#1a8bac !important",
        fontFamily: "NotoSans-Regular",
        fontSize: "16px",
        fontWeight: "400",
      },
      "& ::placeholder": {
        color: "#1a8bac !important",
      },
      "& .rdrEndEdge, & .rdrStartEdge ": {
        borderRadius: "12px",
        background: "#003a4b",
        backgroundBlendMode: "normal",
      },
      "& .rdrDateDisplayWrapper": {
        backgroundColor: "transparent",
      },
      "& .rdrMonthName": {
        display: "none",
      },
      "& .rdrDayNumber span": {
        color: "#0b708f",
        fontFamily: "NotoSans-Medium",
        fontSize: "14.536592px",
        fontWeight: "400",
      },
      "& .rdrInRange": {
        background: "#e5f9ff",
        borderRadius: "1.042em",
      },
      "& .rdrDayHovered": {
        "& .rdrDayStartPreview, & .rdrDayEndPreview": {
          color: "#003a4b !important",
        },
      },
      "& .rdrDayInPreview ,& .rdrDayStartPreview ": {
        color: "#003a4b !important",
      },
      "& .rdrDayInPreview ,& .rdrDayEndPreview ": {
        color: "#003a4b !important",
      },
      "& .rdrDayStartOfWeek .rdrDayNumber span": {
        color: "#ff5353",
      },
      "& .rdrWeekDays .rdrWeekDay": {
        color: "#1a8bac !important",
        fontFamily: "NotoSans-Medium",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "19px",
      },
      "& .rdrMonthAndYearPickers select": {
        color: "#004d64",
        fontFamily: "NotoSans-SemiBold",
        fontSize: "16px",
        fontWeight: "400",
      },
      "& .rdrStaticRange:hover .rdrStaticRangeLabel,& .rdrStaticRange:focus .rdrStaticRangeLabel": {
        backgroundColor: "#E5F9FF",
      },
      "& .rdrInputRanges": {
        display: "none",
      },
      "& .rdrDayToday .rdrDayNumber span:after": {
        display: "none",
      },
      "& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,& .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,& .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,& .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span": {
        color: "#fff !important",
      },

      "& .rdrNextPrevButton i ": {
        border: "solid #004d64",
        borderWidth: "0 2px 2px 0",
        display: "inline-block",
        padding: "3px",
        transform: "rotate(135deg) translate(-1px, -1px)",
        "-webkit-transform": "rotate(135deg) translate(-1px, -1px)",
        borderRadius: "2px",
      },
      "& .rdrNextButton  i": {
        border: "solid #004d64",
        borderWidth: "0 2px 2px 0",
        display: "inline-block",
        padding: "3px",
        transform: "rotate(-45deg) translate(-4px, -4px)",
        "-webkit-transform": "rotate(-45deg) translate(-4px, -4px)",
        borderRadius: "2px",
      },
      "& .rdrNextPrevButton": {
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        background: "#e5f9ff",
      },
      "& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span": {
        color: "#0b708f !important",
      },
      "& .rdrMonthAndYearWrapper": {
        padding: "5px 0 10px",
      },
    },
  },
};
// Customizable Area End
