// App.js - WEB

// Customizable Area Start
// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import SamplesToBeShipped from '../../blocks/search/src/web/franchise/SamplesToBeShipped.web';
import FranchiseDashboard from '../../blocks/dashboard/src/web/franchise/FranchiseDashboard.web';
import SubFranchiseDetail from '../../blocks/dashboard/src/web/subFranchise/SubFranchiseDetail.web';
import SuperTeamLeaderDashboard from '../../blocks/dashboard/src/web/superTeam/SuperTeamLeaderDashboard.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLogin.web';
import FranchiseProfile from '../../blocks/CustomisableUserProfiles/src/web/franchise/FranchiseProfile.web';
import SuperTeamLeaderProfile from '../../blocks/CustomisableUserProfiles/src/web/superTeam/SuperTeamLeaderProfile.web';
import TeamLeaderProfile from '../../blocks/CustomisableUserProfiles/src/web/teamLeader/TeamLeaderProfile.web';
import FranchiseShipCollectedWeb from '../../blocks/CustomisableUserProfiles/src/web/franchise/FranchiseShipCollected.web';
import FranchiseHospitalSelect from '../../blocks/search/src/web/franchise/selectHospital/FranchiseHospitalSelect.web';
import FranchiseShipCollectedItems from '../../blocks/search/src/web/franchise/FranchiseShipCollectedItems.web';
import TeamLeaderDashboard from '../../blocks/dashboard/src/web/teamLeader/TeamLeaderDashboard.web';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/web/TermAndCondition.web';
import PrivatePolicy from '../../blocks/TermsAndConditions/src/web/PrivatePolicy.web';
import './assets/styles/style.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { UserRoles } from '../../components/src/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TeamLeaderFranchiseWeb from '../../blocks/dashboard/src/web/teamLeader/TeamLeaderFranchise.web';

const routeMap = {
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/login',
    exact: true
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/terms-and-condition',
    exact: true
  },
  PrivatePolicy: {
    component: PrivatePolicy,
    path: '/privacy-policy',
    exact: true
  },
  FranchiseDashboard: {
    component: FranchiseDashboard,
    path: '/franchise-dashboard',
    exact: true,
    isProtected: true,
    roles: `${UserRoles.FRANCHISE},${UserRoles.SUB_FRANCHISE},${
      UserRoles.TEAM_LEADER
    }`
  },
  TeamLeaderDashboard: {
    component: TeamLeaderDashboard,
    path: '/team-dashboard',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.TEAM_LEADER}`
  },
  TeamLeaderFranchise: {
    component: TeamLeaderFranchiseWeb,
    path: '/team-franchise',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.TEAM_LEADER}`
  },
  FranchiseProfile: {
    component: FranchiseProfile,
    path: '/franchise-profile',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.FRANCHISE},${UserRoles.SUB_FRANCHISE}`
  },
  TeamLeaderProfile: {
    component: TeamLeaderProfile,
    path: '/team-profile',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.TEAM_LEADER}`
  },
  SuperTeamLeaderDashboard: {
    component: SuperTeamLeaderDashboard,
    path: '/super-team-dashboard',
    exact: true,
    isProtected: true,
    roles: `${UserRoles.SUPER_TEAM_LEAD}`
  },
  SuperTeamLeaderProfile: {
    component: SuperTeamLeaderProfile,
    path: '/super-team-profile',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.SUPER_TEAM_LEAD}`
  },
  FranchiseShipCollectedWeb: {
    component: FranchiseShipCollectedWeb,
    path: '/ship-collected',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.FRANCHISE},${UserRoles.SUB_FRANCHISE}`
  },
  FranchiseHospitalSelect: {
    component: FranchiseHospitalSelect,
    path: '/hospital-search',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.FRANCHISE},${UserRoles.SUB_FRANCHISE},${
      UserRoles.TEAM_LEADER
    }`
  },
  FranchiseShipCollectedItems: {
    component: FranchiseShipCollectedItems,
    path: '/ship-items',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.FRANCHISE},${UserRoles.SUB_FRANCHISE},${
      UserRoles.TEAM_LEADER
    }`
  },
  SubFranchiseDetail: {
    component: SubFranchiseDetail,
    path: '/sub-franchise',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.FRANCHISE}`
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  SamplesToBeShipped: {
    component: SamplesToBeShipped,
    path: '/shipping-details',
    isProtected: true,
    exact: true,
    roles: `${UserRoles.FRANCHISE},${UserRoles.SUB_FRANCHISE},${UserRoles.TEAM_LEADER}`
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <View style={{ height: '100vh' }}>
        <ToastContainer autoClose={3000} position="top-right" />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;

// Customizable Area End
