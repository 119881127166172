
// Customizable Area Start
const primaryColor = "#004d64";
const whiteColor = "#FFFFFF";
const greenColor = "#004D64";
const greyColor = "#eaeaea";


export const SamplesToBeShippedWebStyle = {
  ShippingDetails: {
    "& .MuiTableCell-root": {
      border: '1px solid black'
    },
    boxShadow: "none",
    marginBottom: "16px",
    "& .barcode-detail-container": {
      overflow: "auto",
      maxHeight: "80vh",
      marginBottom: "25px",
      marginTop: "20px",
    },
    "& .barcode-detail-container::-webkit-scrollbar": {
      width: "8px",
      height: "4px",
    },
    "& .barcode-detail-container::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#e3e9eb",
    },
    "& .barcode-detail-container::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "#004d64",
    },
    "& .barcode-details-row": {
      display: "flex",
      alignItems: "center",
      margin: "0px 0 20px",
      gap: "20px",
    },
    "& .MuiAccordionSummary-root": {
      padding: "8px 20px",
      border: "1px solid #004d64",
      borderRadius: "4px",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      minHeight: "unset",
    },
    "& .MuiAccordionSummary-content": {
      gap: "20px",
      alignItems: "center",
      "& img": {
        height: 12,
        width: 12,
      },
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0",
    },
    "& .accordion-heading": {
      color: "#004d64",
      fontFamily: "NotoSans-Medium",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22px",
      width: "50%",
    },
    "& .width150px": {
      width: "150 !important",
    },
    "& .white-color": {
      display: "none",
    },
    "& .dark-color": {
      display: "block",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .Mui-expanded": {
      "& .accordion-heading": {
        color: " #ffffff",
      },
      "& .white-color": {
        display: "block",
      },
      "& .dark-color": {
        display: "none",
      },
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      background: "#004d64",
      backgroundBlendMode: "normal",
    },
    "& .MuiCollapse-wrapper": {
      borderRadius: "8px",
      background: "#e5f9ff",
      backgroundBlendMode: "normal",
      marginTop: "20px",
    },
    "& .left-container": {
      borderRadius: "4px 0 0 4px",
      background: "#baefff",
      backgroundBlendMode: "normal",
      padding: "17px 20px",
      ["@media (max-width:962px)"]: {
        borderRadius: "4px",
      },
    },
    "& .right-container": {
      borderRadius: "0 4px 4px 0",
      background: "#baefff",
      backgroundBlendMode: "normal",
      padding: "17px 20px 17px 0px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ["@media (max-width:962px)"]: {
        borderRadius: "4px",
        padding: "17px 20px 17px 20px",
        width: "100%",
      },
    },
    "& .data-heading": {
      color: "#004d64",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22px",
    },
    "& .data-detail": {
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "22px",
    },
    "& .bold": {
      fontWeight: "700",
    },
    "& .MuiAccordionDetails-root": {
      padding: "16px 16px",
    },
    "& .text-align-center": {
      textAlign: "center",
    },
    "& .row-spacing": {
      margin: "20px 0 27px",
    },
    "& .service-name-view": {
      height: "56px",
      borderRadius: "4px",
      border: "1px solid #55b4d1",
      padding: "0 20px",
      backgroundColor: "#baefff",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "300",
      outline: "none",
      display: "flex",
      alignItems: "center",
      margin: 0,
    },
    "& .service-name": {
      height: "56px",
      borderRadius: "4px",
      border: "1px solid #55b4d1",
      width: "100%",
      padding: "0 20px",
      backgroundColor: "transparent",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "300",
      outline: "none",
      "& ::-ms-input-placeholder ,&  :-ms-input-placeholder": {
        color: "#004d64",
      },
    },
    "&  .service-name::-ms-input-placeholder": {
      color: "#004d64",
    },
    "& .input-spacing": {
      marginTop: "15px",
      width: "100%",
    },
    "& .MuiAccordion-root": {
      marginBottom: "16px",
      boxShadow: "none",
    },
    "& .MuiAccordion-root:last-child": {
      marginBottom: "0px",
    },
    "& .edit-button": {
      padding: "0",
      minWidth: "unset",
      gap: "4px",
      lineHeight: "normal",
      "& .MuiButton-label": {
        color: "#004d64",
        fontFamily: "NotoSans-SemiBold",
        fontSize: "18px",
        fontWeight: "400",
        textTransform: "capitalize",
        textDecoration: "underline",
      },
      "& img": {
        width: "24px",
        height: "24px",
      },
    },
    "& .input-container": {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      "& .input-left-container": {
        width: "100%",
        position: "relative",
        "& .gridFild": {
          position: "relative",
        },
        "& .cross-image-container": {
          position: "absolute",
          top: "-10px",
          right: "-10px",
          cursor: "pointer",
        },
      },
    },



    "& .error-barcode": {
      border: "1px solid #E30000",
    },
    "& .box-overflow": {
      maxHeight: "290px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "8px",
        margin: "10px",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        "-webkit-border-radius": " 10px",
        borderRadius: "10px",
        padding: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        "-webkit-border-radius": "10px",
        borderRadius: "10px",
        background: "#004d64",
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.5)",
      },
      "&::-webkit-scrollbar-thumb:window-inactive": {
        background: "#004d64",
      },
    },
    "& ::placeholder": {
      color: "#004d64",
    },
    "& .input-right-container": {
      height: 48,
      minWidth: 48,
    },
    "& .save-button-container": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "15px",
      "& .MuiButton-contained": {
        width: "90px",
        height: "40px",
        borderRadius: "4px",
        background: "#004d64",
        backgroundBlendMode: "normal",
      },
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "15px",
        fontWeight: "700",
        textAlign: "center",
        textTransform: "capitalize",
      },
    },
    "& .Button": {
      display: "flex",
      justifyContent: "flex-end",
      "& .MuiButton-contained": {
        width: "182px",
        height: "64px",
        borderRadius: "4px",
        background: "#004d64",
        backgroundBlendMode: "normal",
      },
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        textAlign: "center",
        textTransform: "capitalize",
      },
    },

    //edited
    "& .back-button": {
      height: "46px",
      width: "107.81px",
      background: "#eafaff",
      backgroundBlendMode: "normal",
      boxShadow: "none",
      "& img": {
        height: 19,
        width: 18,
        marginRight: 5,
      },
      "& .MuiButton-label": {
        color: primaryColor,
        fontFamily: "NotoSans-Medium",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "22px",
        textTransform: "capitalize",
      },
    },
    "& .search-box": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "20px",
      margin: "20px 0 ",
      "& h3": {
        fontSize: "30px",
        fontWeight: "700",
        fontFamily: "NotoSans-Regular",
        lineHeight: "41px",
        color: primaryColor,
        width: "500px",
      },
      "& img": {
        height: 24,
        width: 24,
      },
    },
    "& .detail": {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "NotoSans-Regular",
      lineHeight: "22px",
      color: primaryColor,
      marginBottom: "10px",
      borderRadius: "4px",
      padding: "11px 20px",
      background: "rgba(171,235,255,0.25)",
      backgroundBlendMode: "normal",
      cursor: "pointer",
    },
    "& .selected": {
      border: "1px solid #004d64",
    },
    "& .detail:last-child": {
      marginBottom: "0px",
    },
    "& .heading": {
      color: whiteColor,
      backgroundColor: primaryColor,
      fontSize: "20px",
      fontWeight: "400",
      fontFamily: "NotoSans-Regular",
      lineHeight: "27px",
      marginBottom: "15px",
      borderRadius: "4px",
      padding: "11px 20px",
    },
    "& .item-details-container": {
      maxHeight: "80vh",
      overflow: "auto",
    },
    "& .item-details-container::-webkit-scrollbar": {
      width: "8px",
    },
    "& .item-details-container::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#e3e9eb",
    },
    "& .item-details-container::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "#004d64",
    },
    "& .filterBox": {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '15px',
      marginBottom: '15px',
    },
    "& .whiteShadowBox": {
      width: '100%',
      boxShadow: '0px 0px 10px #e1e1e1',
      padding: '15px',
      marginTop: '20px',
    },
    "& .filterBoxMain": {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
    },
    "& .filterBoxInput": {
      width: '200px',
      "& .MuiFormControl-root": {
        width: '100%',
        "& .MuiInputLabel-filled": {
          transform: 'translate(18px, 15px) scale(1)',
        },
        "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
          transform: 'translate(18px, 5px) scale(0.75)',
        },
        "& .MuiInputBase-root": {
          backgroundColor: '#eaeaea',
          borderRadius: '25px',
          "& .MuiFilledInput-input": {
            paddingTop: '15px',
            paddingLeft: '15px',
          },
          "&:before": {
            display: 'none'
          },
          "&:after": {
            display: 'none'
          }
        }
      }
    },
    "& .calenderButtonBox": {
      width: '160px',
      position: 'relative',
      "& .calenderButton": {
        width: '100%',
        height: '44px',
        boxShadow: 'none',
        background: '#8edbee',
        borderRadius: '25px',
        color: '#004c65',
        "& img": {
          width: '10px',
          marginLeft: '10px',
        }
      }
    },
    "& .tableDateCollected": {
      marginTop: '15px',
      "& thead th": {
        border: 'none',
        borderBottom: 'solid 2px #e1e1e1',
        color: '#004d64',
        fontWeight: '600',
        fontSize: '15px',
      },
      "& thead th:first-child": {
        width: '75px',
      },
      "& tbody td": {
        border: 'none',
        borderBottom: 'solid 1px #e1e1e1',
      },
      "& .MuiTableCell-root": {
        padding: '10px 16px',
        textAlign: 'center',
      },
    },
    "& .tabledataBody": {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#004c65",
      },
    },
    "& .tabledataHead": {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#004c65",
      },
    },
    "& .submit-button.Mui-disabled.Mui-disabled": {
      background: '#b6b8b8',
      cursor: 'no-drop',
    },

    "& .MuiPagination-root": {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
      marginBottom: "20px",
      alignItems: 'center',
      "& button.MuiPaginationItem-root": {
        color: primaryColor,
        border: `1px solid ${greyColor}`,
        backgroundColor: `${greyColor}`,
        "&.Mui-selected": {
          color: whiteColor,
          backgroundColor: greenColor,
          border: `1px solid ${greenColor}`,
        },
        "&:hover": {
          color: whiteColor,
          backgroundColor: greenColor,
          border: `1px solid ${greenColor}`
        },
      },
    },

  },
  Dialog: {
    "& .image-container": {
      padding: "16px 16px 0",
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .close-image": {
      height: 45,
      width: 45,
      cursor: "pointer",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "11px",
      maxWidth: "475px",
    },
    "& .product-detail-container": {
      padding: "0px 40px 20px",
      "& h5": {
        color: primaryColor,
        fontSize: "24px",
        fontWeight: "700",
        fontFamily: "NotoSans-Regular",
        lineHeight: "33px",
      },
      "& .service-name": {
        height: "56px",
        borderRadius: "4px",
        border: "1px solid #55b4d1",
        width: "100%",
        padding: "0 20px",
        backgroundColor: "transparent",
        color: "#004d64",
        fontFamily: "NotoSans-Regular",
        fontSize: "16px",
        fontWeight: "300",
        outline: "none",
        "& ::-ms-input-placeholder ,&  :-ms-input-placeholder": {
          color: "#004d64",
        },
      },
      "& .error-barcode": {
        border: "1px solid #E30000",
      },
      "& .button-container": {
        marginTop: 15,
        marginBottom: 15,
        "& .MuiGrid-root.MuiGrid-container": {
          alignItems: 'center',
        },
        "& .gridFild": {
          position: "relative",
        },
        "& .cross-image-container": {
          position: "absolute",
          top: "-4px",
          right: "-4px",
          cursor: "pointer",
        },
        "& .input-right-container": {
          background: "#8edbee",
          height: '50px',
          width: '50px',
          borderRadius: '50%',
        },
      },

      "& .add-button": {
        color: whiteColor,
        backgroundColor: primaryColor,
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "NotoSans-Regular",
        height: "48px",
      },
      "& .MuiTypography-body1": {
        height: "48px",
        borderRadius: "4px",
        background: "#76c9e3",
        backgroundBlendMode: "normal",
        boxShadow: "0px 0px 18px rgba(0,0,0,0.13)",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "NotoSans-Medium",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#004d64",
      },
      "& .submit-button": {
        height: 64,
        backgroundColor: primaryColor,
        borderRadius: "4px",
        backgroundBlendMode: "normal",
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        marginTop: "5px",
      },
      "& .MuiFormControl-marginDense": {
        marginTop: "0",
        marginBottom: "0",
      },
      "&  .input": {
        height: "48px",
        borderRadius: "4px",
      },
      "&  .MuiIconButton-root": {
        padding: "5px",
      },
      "& .inputProps": {
        textAlign: "center",
        zIndex: "1",
      },
      "& .MuiInputAdornment-positionEnd": {
        marginLeft: "0px",
        zIndex: "1",
      },
      "& .MuiInputAdornment-positionStart": {
        marginRight: "0px",
        zIndex: "1",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        backgroundColor: "#e5f9ff",
      },
    },
  },
};
// Customizable Area End