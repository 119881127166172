// Customizable Area Start
import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { DateRangePickerStyle } from "./DateRangePickerStyle.web";
import { createStaticRanges, DateRangePicker } from "react-date-range";
import moment from "moment";

const downImageWeb = require("./assets/downArrowImageWeb.png");

interface Props {
  classes: any;
  activeCalenderValue: string;
  range: any;
  closeDatePicker: (ranges: any, name: string) => void;
}

interface S {
  dateRange: any;
  clickItemText: string;
}

class DateRangePickerComponent extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dateRange: [
        {
          startDate: moment()
            .clone()
            .startOf("month")
            .toDate(),
          endDate: new Date(),
          key: "rollup",
        },
      ],
      clickItemText: "",
    };
  }

  componentDidMount() {
    this.setState({
      dateRange: this.props.range,
      clickItemText: this.props.activeCalenderValue,
    });
    this.addOnClickOnSideMenuButton();
  }

  addOnClickOnSideMenuButton = () => {
    const label = document.getElementsByClassName("rdrStaticRangeLabel");
    if (label) {
      for (let value of label) {
        if (value) {
          value.addEventListener("click", () => {
            const dropDownItem = value.querySelector(".drop-down-item");
            if (dropDownItem) {
              this.setState({
                clickItemText: dropDownItem.innerHTML,
              });
            } else if (value.querySelector(".monthly")) {
              this.setState({
                clickItemText: this.state.clickItemText,
              });
            } else {
              this.setState({
                clickItemText: value.innerHTML,
              });
            }
          });
        }
      }
    }
  };

  openOrCloseDropDown = () => {
    const dropDownItem = document.getElementsByClassName("drop-down-item");
    if (dropDownItem) {
      for (let i = 0; i < dropDownItem.length; i++) {
        const parentNode = document.getElementsByClassName("drop-down-item")[i]
          .parentNode;
        if (parentNode) {
          const parentElement = parentNode.parentElement;
          this.handleButtonDisplay(parentElement);
        }
      }
    }
  };

  handleButtonDisplay = (parentElement: any) => {
    if (parentElement) {
      if (parentElement.style.display === "none") {
        parentElement.style.display = "block";
      } else {
        parentElement.style.display = "none";
      }
    }
  };

  staticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected: () => {
        return true;
      },
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: moment()
          .add(-1, "days")
          .toDate(),
        endDate: moment()
          .add(-1, "days")
          .toDate(),
      }),
      isSelected: () => {
        return true;
      },
    },
    {
      label: "Last 7 days",
      range: () => ({
        startDate: moment()
          .add(-6, "days")
          .toDate(),
        endDate: new Date(),
      }),
      isSelected: () => {
        return true;
      },
    },
    {
      label: "This Month",
      range: () => ({
        startDate: moment()
          .clone()
          .startOf("month")
          .toDate(),
        endDate: new Date(),
      }),
      isSelected: () => {
        return true;
      },
    },
    {
      // @ts-ignore
      label: (
        <span
          className="monthly"
          onClick={() => {
            this.openOrCloseDropDown();
          }}
        >
          Monthly <img src={downImageWeb} />
        </span>
      ),
      isSelected: () => {
        return false;
      },
    },
    {
      // @ts-ignore
      label: <span className="drop-down-item">Last 3 month</span>,
      range: () => ({
        startDate: moment(new Date())
          .subtract(3, "months")
          .toDate(),
        endDate: new Date(),
      }),
      isSelected: () => {
        return true;
      },
    },
    {
      // @ts-ignore
      label: <span className="drop-down-item">Last 6 month</span>,
      range: () => ({
        startDate: moment(new Date())
          .subtract(6, "months")
          .toDate(),
        endDate: new Date(),
      }),
      isSelected: () => {
        return true;
      },
    },
    {
      // @ts-ignore
      label: <span className="drop-down-item">Last 9 month</span>,
      range: () => ({
        startDate: moment(new Date())
          .subtract(9, "months")
          .toDate(),
        endDate: new Date(),
      }),
      isSelected: () => {
        return true;
      },
    },
  ]);

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.Picker}>
        <DateRangePicker
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          onChange={(ranges: any) =>
            this.setState({ dateRange: [ranges.rollup] })
          }
          moveRangeOnFirstSelection={false}
          months={1}
          maxDate={new Date()}
          ranges={this.state.dateRange}
          staticRanges={this.staticRanges}
          direction="horizontal"
        />
        <Button
          variant="contained"
          className="choose-button"
          onClick={() => {
            this.props.closeDatePicker(
              this.state.dateRange,
              this.state.clickItemText
            );
          }}
        >
          Choose
        </Button>
      </div>
    );
  }
}
// @ts-ignore *
export default withStyles(DateRangePickerStyle)(DateRangePickerComponent);
// Customizable Area End
