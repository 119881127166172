// Customizable Area Start
export const FranchiseAddShipStyles = {
  Franchise: {
    boxShadow: "none",
    marginBottom: "16px",
    "& .barcode-detail-container": {
      overflow: "auto",
      maxHeight: "80vh",
      marginBottom: "25px",
      marginTop: "20px",
    },
    "& .barcode-detail-container::-webkit-scrollbar": {
      width: "8px",
      height: "4px",
    },
    "& .barcode-detail-container::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#e3e9eb",
    },
    "& .barcode-detail-container::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "#004d64",
    },
    "& .barcode-details-row": {
      display: "flex",
      alignItems: "center",
      margin: "0px 0 20px",
      gap: "20px",
    },
    "& .MuiAccordionSummary-root": {
      padding: "8px 20px",
      border: "1px solid #004d64",
      borderRadius: "4px",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      minHeight: "unset",
    },
    "& .MuiAccordionSummary-content": {
      gap: "20px",
      alignItems: "center",
      "& img": {
        height: 12,
        width: 12,
      },
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0",
    },
    "& .accordion-heading": {
      color: "#004d64",
      fontFamily: "NotoSans-Medium",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22px",
      width: "50%",
    },
    "& .width150px": {
      width: "150 !important",
    },
    "& .white-color": {
      display: "none",
    },
    "& .dark-color": {
      display: "block",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .Mui-expanded": {
      "& .accordion-heading": {
        color: " #ffffff",
      },
      "& .white-color": {
        display: "block",
      },
      "& .dark-color": {
        display: "none",
      },
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      background: "#004d64",
      backgroundBlendMode: "normal",
    },
    "& .MuiCollapse-wrapper": {
      borderRadius: "8px",
      background: "#e5f9ff",
      backgroundBlendMode: "normal",
      marginTop: "20px",
    },
    "& .left-container": {
      borderRadius: "4px 0 0 4px",
      background: "#baefff",
      backgroundBlendMode: "normal",
      padding: "17px 20px",
      ["@media (max-width:962px)"]: {
        borderRadius: "4px",
      },
    },
    "& .right-container": {
      borderRadius: "0 4px 4px 0",
      background: "#baefff",
      backgroundBlendMode: "normal",
      padding: "17px 20px 17px 0px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ["@media (max-width:962px)"]: {
        borderRadius: "4px",
        padding: "17px 20px 17px 20px",
        width: "100%",
      },
    },
    "& .data-heading": {
      color: "#004d64",
      fontFamily: "NotoSans-SemiBold",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22px",
    },
    "& .data-detail": {
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "22px",
    },
    "& .bold": {
      fontWeight: "700",
    },
    "& .MuiAccordionDetails-root": {
      padding: "16px 16px",
    },
    "& .text-align-center": {
      textAlign: "center",
    },
    "& .row-spacing": {
      margin: "20px 0 27px",
    },
    "& .service-name-view": {
      height: "56px",
      borderRadius: "4px",
      border: "1px solid #55b4d1",
      padding: "0 20px",
      backgroundColor: "#baefff",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "300",
      outline: "none",
      display: "flex",
      alignItems: "center",
      margin: 0,
    },
    "& .service-name": {
      height: "56px",
      borderRadius: "4px",
      border: "1px solid #55b4d1",
      width: "100%",
      padding: "0 20px",
      backgroundColor: "transparent",
      color: "#004d64",
      fontFamily: "NotoSans-Regular",
      fontSize: "16px",
      fontWeight: "300",
      outline: "none",
      "& ::-ms-input-placeholder ,&  :-ms-input-placeholder": {
        color: "#004d64",
      },
    },
    "&  .service-name::-ms-input-placeholder": {
      color: "#004d64",
    },
    "& .input-spacing": {
      marginTop: "15px",
      width: "100%",
    },
    "& .MuiAccordion-root": {
      marginBottom: "16px",
      boxShadow: "none",
    },
    "& .MuiAccordion-root:last-child": {
      marginBottom: "0px",
    },
    "& .edit-button": {
      padding: "0",
      minWidth: "unset",
      gap: "4px",
      lineHeight: "normal",
      "& .MuiButton-label": {
        color: "#004d64",
        fontFamily: "NotoSans-SemiBold",
        fontSize: "18px",
        fontWeight: "400",
        textTransform: "capitalize",
        textDecoration: "underline",
      },
      "& img": {
        width: "24px",
        height: "24px",
      },
    },
    "& .input-container": {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      "& .input-left-container": {
        width: "100%",
        position: "relative",
        "& .cross-image-container": {
          position: "absolute",
          top: "-10px",
          right: "-10px",
          cursor: "pointer",
        },
      },
    },
    "& .error-barcode": {
      border: "1px solid #E30000",
    },
    "& .box-overflow": {
      maxHeight: "290px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "8px",
        margin: "10px",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        "-webkit-border-radius": " 10px",
        borderRadius: "10px",
        padding: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        "-webkit-border-radius": "10px",
        borderRadius: "10px",
        background: "#004d64",
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.5)",
      },
      "&::-webkit-scrollbar-thumb:window-inactive": {
        background: "#004d64",
      },
    },
    "& ::placeholder": {
      color: "#004d64",
    },
    "& .input-right-container": {
      height: 48,
      minWidth: 48,
    },
    "& .save-button-container": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "15px",
      "& .MuiButton-contained": {
        width: "90px",
        height: "40px",
        borderRadius: "4px",
        background: "#004d64",
        backgroundBlendMode: "normal",
      },
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "15px",
        fontWeight: "700",
        textAlign: "center",
        textTransform: "capitalize",
      },
    },
    "& .Button": {
      display: "flex",
      justifyContent: "flex-end",
      "& .MuiButton-contained": {
        width: "182px",
        height: "64px",
        borderRadius: "4px",
        background: "#004d64",
        backgroundBlendMode: "normal",
      },
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "NotoSans-Regular",
        fontSize: "24px",
        fontWeight: "700",
        textAlign: "center",
        textTransform: "capitalize",
      },
    },
  },
};

// Customizable Area End
