// Customizable Area Start
export const TopNavStyles: any = {
  Navigation: {
    backgroundColor: "#004D64",
    "& .navigation-container": {
      paddingTop: 10,
      paddingBottom: 10,
      "& .MuiToolbar-gutters": {
        paddingLeft: 0,
        paddingRight: 0,
      },
      "& .MuiIconButton-colorInherit": {
        marginRight: 5,
      },
      "& .MuiIconButton-root": {
        paddingLeft: 0,
      },
    },
    "& .menu-container img": {
      height: 35,
      width: 35,
    },
    "& .brand-logo-container img": {
      height: 67,
      width: 161.5,
    },
  },
};
// Customizable Area End
