// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  withStyles,
  Checkbox,
  TextField,
  MenuItem,
  Dialog,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import SamplesToBeShippedController, {
  Props,
} from "./SamplesToBeShippedController.web";
import { downArrow } from "../../../../dashboard/src/assets";
import { backArrowImage } from "../../assets";
import Loader from "../../../../../components/src/Loader.web";
import {
  cancelSearchImage,
  SmallCrossImageComponent,
  plusWebImage,
} from "../../assets";
import { DebounceInput } from "react-debounce-input";
import DateRangePickerComponent from "../../../../../components/src/DateRangePicker.web";
import { getFormatDate } from "../../../../../components/src/utils";
import { SamplesToBeShippedWebStyle } from "./SamplesToBeShippedStyles.web";
import ReadyShipDialog from "../../../../../components/src/ReadyShipDialog.web";
import SuccessModal from "../../../../../components/src/SuccessModalWeb";

class SamplesToBeShipped extends SamplesToBeShippedController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {
      shipItemData,
      filterHospital,
      filterProduct,
      providerName,
      docketNumbers,
      isModalOpen,
      selectedBarcodes,
      page,
      rowsPerPage,
      filterState,
      filterCity,
      selectAllChecked
    } = this.state;
    const { classes, navigation } = this.props;

    const totalPages = Math.ceil(shipItemData.length / rowsPerPage);
    const tableDataAfterSlice: any[] = shipItemData.slice(
      (page - 1) * rowsPerPage,
      (page - 1) * rowsPerPage + rowsPerPage
    );

    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.ShippingDetails}>
          <Container maxWidth="lg" style={{ margin: "20px auto 0" }}>
            <Button
              variant="contained"
              className="back-button"
              onClick={navigation.goBack}
            >
              <img src={backArrowImage} alt="back" />
              Back
            </Button>

            <Box className="whiteShadowBox">
              <Box className="filterBox">
                <Box className="filterBoxMain">
                  <Box className="filterBoxInput">
                    {/* Filter for Hospital */}
                    <TextField
                      label="Hospital"
                      value={filterHospital}
                      onChange={this.handleFilterChange}
                      name="filterHospital"
                      variant="filled"
                      select
                    >
                      <MenuItem value="all_hospitals">All Hospitals</MenuItem>
                      {this.getUniqueHospitalNames().map((hospital) => (
                        <MenuItem
                          key={hospital.id}
                          value={hospital.name}
                          onClick={this.getHospitalId.bind(null, hospital.id)}
                        >
                          {hospital.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>

                  <Box className="filterBoxInput">
                    {/* Filter for Product */}
                    <TextField
                      label="Product"
                      value={filterProduct}
                      onChange={this.handleFilterChange}
                      name="filterProduct"
                      variant="filled"
                      select
                    >
                      <MenuItem value="all_products">All Products</MenuItem>
                      {this.getUniqueProductTypes().map((sample) => (
                        <MenuItem
                          key={sample.id}
                          value={sample.name}
                          onClick={this.getSampleId.bind(null, sample.id)}
                        >
                          {sample.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>

                  <Box className="filterBoxInput">
                    {/* Filter for State */}
                    <TextField
                      label="State"
                      value={filterState}
                      onChange={this.handleFilterChange}
                      name="filterState"
                      variant="filled"
                      select
                    >
                      <MenuItem value="all_states">All States</MenuItem>
                      {this.getUniqueStates().map(
                        (state: string, index: number) => (
                          <MenuItem
                            key={index}
                            value={state}
                            onClick={this.getStates.bind(null, state)}
                          >
                            {state}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Box>

                  <Box className="filterBoxInput">
                    {/* Filter for City */}
                    <TextField
                      label="City"
                      value={filterCity}
                      onChange={this.handleFilterChange}
                      name="filterCity"
                      variant="filled"
                      select
                    >
                      <MenuItem value="all_cities">All Cities</MenuItem>
                      {this.getUniqueCities().map(
                        (city: string, index: number) => (
                          <MenuItem
                            key={index}
                            value={city}
                            onClick={this.getCity.bind(null, city)}
                          >
                            {city}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Box>

                  {/* Filter for Date */}
                  <Box className="right-side calenderButtonBox">
                    <Button
                      className="calenderButton"
                      variant="contained"
                      data-test-id="calendarbtn"
                      onClick={() => this.openOrCloseDateRangePicker(false)}
                    >
                      <span
                        className="calendar-text"
                        title={this.state.activeCalenderValue}
                      >
                        {this.state.activeCalenderValue}
                      </span>
                      <img src={downArrow} alt="arrow" />
                    </Button>
                    {this.state.openDateRange && (
                      <DateRangePickerComponent
                        activeCalenderValue={this.state.activeCalenderValue}
                        range={this.state.range}
                        closeDatePicker={(range: any, name: string) => {
                          this.setState(
                            { range, activeCalenderValue: name },
                            () => {
                              this.openOrCloseDateRangePicker(true);
                            }
                          );
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box>
                <TableContainer>
                  <Table className="tableDateCollected">
                    <TableHead className="tabledataHead">
                      <TableRow>
                        <TableCell><Checkbox disabled={shipItemData.length === 0} checked={selectAllChecked} onClick={this.selectAllBarcodesHandler} /></TableCell>
                        <TableCell>Barcode No.</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell>Hospital</TableCell>
                        <TableCell>Date Collected</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>City</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tabledataBody">
                      {tableDataAfterSlice.map((data: any) => {
                        const isRowChecked = selectedBarcodes.some(
                          (barcode) => barcode === data.barcode_number
                        );
                        return (
                          <TableRow key={data.id}>
                            <TableCell>
                              <Checkbox
                                checked={isRowChecked}
                                onClick={() => this.handleCheckboxClick(data)}
                              />
                            </TableCell>
                            <TableCell>{data.barcode_number}</TableCell>
                            <TableCell>{data.sample_name}</TableCell>
                            <TableCell>{data.hospital_name}</TableCell>
                            <TableCell>
                              {getFormatDate(data.collected_date)}
                            </TableCell>
                            <TableCell>{data.state}</TableCell>
                            <TableCell>{data.city}</TableCell>
                          </TableRow>
                        );
                      })}
                      {shipItemData.length === 0 && !this.state.isLoading && (
                        <TableRow>
                          <TableCell colSpan={7}>No Data Found.</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  <Box>
                    {shipItemData.length !== 0 && !this.state.isLoading && (
                      <>
                        <Pagination
                          count={totalPages}
                          page={page}
                          siblingCount={1}
                          boundaryCount={1}
                          shape="rounded"
                          onChange={this.handlePageChange}
                        />
                        <Box className="Button">
                          <Button
                            variant="contained"
                            className="submit-button"
                            onClick={this.handleModal}
                            disabled={selectedBarcodes.length === 0}
                          >
                            Ship
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </TableContainer>
              </Box>
            </Box>
          </Container>
          <Dialog
            open={isModalOpen}
            className={classes?.Dialog}
            onClose={() => {}}
            maxWidth="xs"
            data-test-id="provider-detail-dialog"
            fullWidth
            scroll="body"
          >
            <Box className="image-container">
              <img
                src={cancelSearchImage}
                className="close-image"
                alt="close"
                onClick={this.closeModal}
              />
            </Box>
            <Box className="product-detail-container">
              <Typography variant="h5">
                Enter Service Provider Details
              </Typography>
              <Box className="button-container">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DebounceInput
                      type="text"
                      value={providerName}
                      placeholder="Enter Service Provider Name"
                      debounceTimeout={500}
                      name="providerName"
                      className={
                        providerName
                          ? "service-name"
                          : "service-name error-barcode"
                      }
                      onChange={this.handleProviderDetails}
                    />
                  </Grid>
                  {docketNumbers.map((docketNumber, index) => (
                    <Grid className="gridFild" item xs={6} key={index}>
                      {index > 0 && (
                        <Box
                          className="cross-image-container"
                          onClick={() => this.handleRemoveButtonClick(index)}
                        >
                          <SmallCrossImageComponent />
                        </Box>
                      )}
                      <DebounceInput
                        type="text"
                        value={docketNumber}
                        placeholder="Enter Docket No."
                        debounceTimeout={500}
                        name={`docketNumbers_${index}`}
                        className={
                          docketNumber
                            ? "service-name"
                            : "service-name error-barcode"
                        }
                        onChange={(event) =>
                          this.handleDocketNumberChange(event, index)
                        }
                      />
                    </Grid>
                  ))}

                  <IconButton
                    className="input-right-container"
                    onClick={this.handleAddButtonClick}
                  >
                    <img src={plusWebImage} />
                  </IconButton>
                </Grid>
              </Box>
              <Button
                className="submit-button"
                variant="outlined"
                disabled={false}
                onClick={this.providerDetailHandler}
                fullWidth
              >
                Submit
              </Button>
            </Box>
          </Dialog>
          <ReadyShipDialog
            message="Are you ready to ship items now?"
            open={this.state.openReadyToShip}
            onClose={() =>
              this.setState({
                openReadyToShip: false,
              })
            }
            onSubmit={() => this.onSubmitReadyToShipData()}
          />
          <SuccessModal
            message="Your scanned items have been marked shipped"
            open={this.state.openSuccessModal}
            onClose={() => this.onSuccessButtonClick()}
          />
        </Box>
      </>
    );
  }
}
export default withStyles(SamplesToBeShippedWebStyle)(SamplesToBeShipped);
// Customizable Area End
