// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import moment from "moment";
import {
  getFormatDate,
  userDetailsFromStorage,
  userRedirectToLoginPage,
} from "../../../../../components/src/utils";
import { toast } from "react-toastify";

export const configJSON = require("../../config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userName: string;
  dashboardData: {
    collected: number;
    shipped: number;
    delivered: number;
  };
  token: string;
  errorMsg: string;
  isLoading: boolean;
  range: any;
  openDateRange: boolean;
  accessToken: string;
  userId: string;
  isTeamLeader: boolean;
  activeCalenderValue:string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FranchiseDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      dashboardData: {
        collected: 0,
        shipped: 0,
        delivered: 0,
      },
      errorMsg: "",
      token: "",
      isLoading: false,
      userName: "",
      openDateRange: false,
      range: [
        {
          startDate: moment()
            .clone()
            .startOf("month")
            .toDate(),
          endDate: new Date(),
          key: "rollup",
        },
      ],
      accessToken: "",
      userId: "",
      isTeamLeader: false,
      activeCalenderValue:"This Month"
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    window.scroll(0, 0);
    const name = localStorage.getItem("userName");
    if (name) {
      this.setState({
        userName: name,
      });
    }
    const role = localStorage.getItem("userData");
    if (role) {
      const data = JSON.parse(role);
      this.setState({
        isTeamLeader: data.attributes.role_id === 1,
      });
    }
    const data = userDetailsFromStorage();
    if (data?.attributes && data.id) {
      this.setState(
        {
          accessToken: data.attributes.token,
          userId: data.id,
        },
        () => {
          this.getDashboardDetails();
        }
      );
    } else {
      userRedirectToLoginPage(this.props.navigation);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  collectedSampleClick = () => {
    this.props.navigation.navigate("FranchiseHospitalSelect");
  };

  shipItemsClick = () => {
    // this.props.navigation.navigate("FranchiseShipCollectedItems");
    this.props.navigation.navigate("SamplesToBeShipped");
  };

  openOrCloseDateRangePicker = (isApiCall: boolean) => {
    this.setState({
      openDateRange: !this.state.openDateRange,
    });
    if (isApiCall) {
      this.getDashboardDetails();
    }
  };

  getDashboardDetails = () => {
    this.setState({ isLoading: true });

    const start_Date = getFormatDate(this.state.range[0].startDate);
    const end_Date = getFormatDate(this.state.range[0].endDate);

    const header = {
      accessToken: this.state.accessToken,
      "Content-Type": configJSON.franchiseDashboardContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDashboardItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.franchiseDashboardApiEndPoint}?start_date=${start_Date}&end_date=${end_Date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.franchiseDashboardMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].full_message === "Session has been expired,Please login again"
      ) {
        userRedirectToLoginPage(this.props.navigation);
      }
    } else {
      toast.error("Something went to wrong please retry");
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.error) {
        this.handleError(responseJson.error);
      }
      if (apiRequestCallId && responseJson && !responseJson.error) {
        if (apiRequestCallId === this.apiDashboardItemCallId) {
          if (responseJson.collected >= 0) {
            this.setState({
              dashboardData: {
                collected: responseJson.collected,
                delivered: responseJson.delivered,
                shipped: responseJson.shipped,
              },
            });
          } else {
            toast.error("Something went to wrong please retry.");
          }
        }
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }
}
