// Customizable Area Start
const primaryColor = "#004D64";
const whiteColor = "#FFFFFF";
const yellowColor = "#FFD700";

export const DashboardWebStyles = {
  FranchiseDashboard: {
    "& .main-heading": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .back-button": {
        height: "63px",
        width: "120px",
        background: "#eafaff",
        backgroundBlendMode: "normal",
        boxShadow: "none",
        "& img": {
          height: 22,
          width: 22,
          marginRight: 5,
        },
        "& .MuiButton-label": {
          color: primaryColor,
          fontFamily: "NotoSans-Medium",
          fontSize: "22px",
          fontWeight: "400",
          lineHeight: "26px",
          textTransform: "capitalize",
        },
      },
      "& .MuiTypography-h2": {
        fontWeight: "700",
        lineHeight: "54px",
        fontSize: 40,
        color: primaryColor,
        fontFamily: "NotoSans-Regular",
      },
      "& .right-side": {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        position: "relative",
      },
      "& .calenderButton": {
        height: "63px",
        borderRadius: "8px",
        color: primaryColor,
        backgroundColor: "#E5F9FF",
        backgroundBlendMode: "normal",
        width: "166px",
        minWidth: "166px",
        textTransform: "none",
        border: " 1px solid #004d64",
        boxShadow: "none",
        "& .MuiButton-label": {
          gap: "15px",
          color: primaryColor,
          fontFamily: "NotoSans-Regular",
          fontSize: "20px",
          fontWeight: "700",
        },
        "& img": {
          height: 7,
          width: 14,
          marginTop: "4px",
        },
      },
    },
    "& .content-box": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "8px",
      background: " #004d64",
      backgroundBlendMode: "normal",
      boxShadow: "inset 0px 4px 20px rgba(0,0,0,0.4)",
      padding: "14px",
      backgroundColor: primaryColor,
      "& .MuiTypography-h1": {
        fontWeight: "700",
        lineHeight: "71px",
        fontSize: 52,
        color: whiteColor,
        fontFamily: "NotoSans-Regular",
        marginBottom: "10px",
      },
      "& .MuiTypography-body2": {
        fontWeight: "700",
        lineHeight: "27px",
        fontSize: 20,
        color: whiteColor,
        fontFamily: "NotoSans-Regular",
        textTransform: "uppercase",
      },
    },
  },
  "& .MuiMenu-paper": {
    minWidth: "190px",
  },
};
// Customizable Area End
