// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  withStyles,
} from "@material-ui/core";

import SuperTeamController, { Props } from "./SuperTeamController.web";
import { backArrowImage } from "../../assets";
import { SuperTeamLeaderProfileStyles } from "./SuperTeamLeaderProfileStyles.web";
import SuperTeamLeaderProfileDetail from "./SuperTeamLeaderProfileDetail.web";
import SuperTeamLeaderAssociateTL from "./SuperTeamLeaderAssociateTL.web";
import Loader from "../../../../../components/src/Loader.web";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className="tab-full-details">
          {children}
        </Box>
      )}
    </div>
  );
}

class SuperTeamLeaderProfile extends SuperTeamController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { navigation, classes } = this.props;
    return (
      <div className={classes.Profile}>
        <Loader loading={this.state.isLoading} />
        <Box style={{ borderBottom: "2px solid rgba(0,0,0,0.13)" }}>
          <Container maxWidth="lg" style={{ margin: "20px auto 0" }}>
            <Box>
              <Button
                variant="contained"
                className="back-button"
                onClick={() => navigation.goBack()}
              >
                <img src={backArrowImage} alt="back" />
                Back
              </Button>
            </Box>
            <Box>
              <Tabs
                value={this.state.value}
                onChange={this.handleValueChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                aria-label="full width tabs example"
                className="navigation-tabs"
              >
                <Tab label="Profile" />
                <Tab label="Associate TL" />
              </Tabs>
            </Box>
          </Container>
        </Box>
        <Container
          maxWidth="lg"
          style={{ margin: "0 auto 60px" }}
          className="tab-details"
        >
          <TabPanel value={this.state.value} index={0} dir={"x"}>
            <SuperTeamLeaderProfileDetail
              userDetails={this.state.userDetails}
            />
          </TabPanel>
          <TabPanel value={this.state.value} index={1} dir="x">
            <SuperTeamLeaderAssociateTL
              loading={this.state.isLoading}
              tlDetailList={this.state.tlDetailList}
              getAssociationTlListDetails={this.getAssociationTlListDetails}
            />
          </TabPanel>
          <Typography className="notice">
            To Change any details please call or mail to administrative for any
            changes.*
          </Typography>
        </Container>
      </div>
    );
  }
}

export default withStyles(SuperTeamLeaderProfileStyles)(SuperTeamLeaderProfile);
// Customizable Area End
